import Request from './Request'

const NoteService = {
	async createNote(client_id, data) {
		return await Request.post(`/api/clients/${client_id}/notes`, data)
	},
	async updateNote(client_id, note_id, data) {
		return await Request.post(`/api/clients/${client_id}/notes/${note_id}`, { data })
	},
	async solveNote(client_id, note_id) {
		return await Request.post(`/api/clients/${client_id}/notes/${note_id}/solve`)
	},
	async addMessage(client_id, note_id, message) {
		return await Request.post(`/api/clients/${client_id}/notes/${note_id}/addMessage`, { message })
	},
	async getNotes(client_id, params={}) {
		return await Request.get(`/api/clients/${client_id}/notes`, params)
	},
	async getNoteById(client_id, note_id) {
		return await Request.get(`/api/clients/${client_id}/notes/${note_id}`)
	},
}

export default NoteService