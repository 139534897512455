import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	IconButton,
	TextField,
	Autocomplete,
	Collapse,
} from '@mui/material'
import UserService from '../../services/UserService'
import ClientService from '../../services/ClientService'
import constants from '../../constants/constant'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';
import MessageContext from '../../providers/GlobalTip'
import UserRoleItem from './UserRoleItem'
import AuthContext from '../../providers/AuthProvider'

const jss = {
	section: {
		px: 2,
		py: 1,
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		mb:0.5
	},
	label: {
		textTransform: "Capitalize",
		fontWeight: 'bolder',
		fontSize: 12,
	},
	content: {
	},
	item: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: '#f6fafd',
		borderBottom: '0.5px solid #ddd',
		px: 1.5,
		py: 0.5,
		":hover": {
			backgroundColor: '#f5ecf8'
		}
	},
	clientName: {
		flex: 1,
		mr: 2,
		cursor: 'pointer',
	}
}

function UserRoleEditItem(props) {
	const role = props.role
	const type = props.type
	const [open, setOpen] = useState(false)
	const [open_create_form, setOpenCreateForm] = useState(false)
	const [form_loading, setFormLoading] = useState(false)
	const [clients, setClients] = useState([])
	const [selected_client, setSelectedClient] = useState()
	const messageContext = MessageContext.useMessage()
	const [creating, setCreating] = useState(false)
	const auth_context = AuthContext.useAuth()

	async function loadClients() {
		if( clients.length==0 && !form_loading ) {
			setFormLoading(true)
			const _clients = await ClientService.searchClients(null, true)
			setFormLoading(false)
			setClients(_clients)	
		}
	}

	function handlerClientSelect(event, value, reason) {
		if( reason == 'selectOption' ) {
			setSelectedClient(value)
		}
	}

	async function createRoleLink() {
		if( !selected_client ) {
			return
		}
		try {
			setCreating(true)
			await UserService.assignUserToRole(props.user.id, constants.ROLE_ID[type], selected_client.Id)
			props.onChange()
			setOpenCreateForm(false)
			setSelectedClient(null)
			setCreating(false)
		} catch(e) {
			messageContext.show(e.response?.data.message)
			setCreating(false)
		}
	}

	return <Box sx={jss.section}>
		<Box sx={jss.header}>
			<Box sx={jss.label}>{type}</Box>
			{auth_context.checkPermission('role-assign-user') && 
				<IconButton color='primary' onClick={e => {setOpenCreateForm(true)}}>
					<AddCircleOutlineOutlinedIcon />
				</IconButton>
			}
		</Box>
		<Box sx={jss.content}>
			{open_create_form && 
			<Box sx={jss.item}>
				<Box sx={jss.clientName}>
					<Autocomplete 
						openOnFocus
						size="small"
						options={clients}
						getOptionLabel={(option) => `${option.Name} [${option.Id}] `}
						sx={{
							backgroundColor: 'white',
						}}
						loadingText={
							<CircularProgress color="inherit" />
						}
						onOpen={loadClients}
						loading={form_loading}
						renderInput={params => 
							<TextField 
								{...params} 
								label="Client" 
							/>
						}
						onChange={handlerClientSelect}
					/>
				</Box>
				{!creating && 
					<>
						<IconButton color='success' onClick={createRoleLink}>
							<DoneOutlineRoundedIcon size="small" />
						</IconButton>
						<IconButton onClick={e => {setOpenCreateForm(false)}}>
							<DeleteForeverOutlinedIcon size="small" />
						</IconButton>
					</>
				}
				{creating && 
					<IconButton disabled >
						<CircularProgress color="inherit" size={22} />
					</IconButton>
				}
			</Box>
			}
			{role && role.map((item) => {
				return <Box key={`user_role_item_${item.id}`}>
					<UserRoleItem role={item}/>
				</Box>
			})}
		</Box>
	</Box>
}

export default UserRoleEditItem