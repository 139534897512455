import { useState, useEffect, useRef } from "react";
import {useNavigate} from 'react-router-dom'
import {
    Box,
    Typography,
    Paper,
    Skeleton,
    IconButton,
    Checkbox,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import SectionTitle from '../../../components/common/SectionTitle'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClientService from '../../../services/ClientService'
import _ from 'underscore'

function ClientTags(props) {
    const { client, isAdmin } = props
    const [data, setData] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        if( client ) {
            loadTags()
        }
    }, [client])

    async function loadTags() {
        const tags = await ClientService.getClientRelatedTags(client.Id)
        console.log(tags)
        setData(tags.with_client)
    }

    return <Paper elevation={2}>
        <SectionTitle
            label="Master Client Tags"
            rightComponent={
                isAdmin ?
                    <Button onClick={() => navigate(`/clients/${client.ClientCode}/tags`)} size="small">
                        Manage Tags
                    </Button>
                : null
            }
        />
        <TableContainer sx={{py:2}}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Tag</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => {
                        return <TableRow key={`tag_${index}`}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.name}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}
export default ClientTags