import React, { useState, useRef, useEffect } from "react";
import ModifyStyle from "./PlanStyle";
import {
  TextField,
  Grid,
  Button,
  Box,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Paper,
  Backdrop,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from "@mui/material";
import numeral from "numeral";
import HotKeysDrawer from "./HotKeysDrawer";
import CopyActuals from "./CopyActuals";
import Switch from '@mui/material/Switch';

function BigTable(props) {
  const {
    tableData,
    historicalPeriodDatas = [],
    displayMonths,
    colorMap,
    onInputBlur,
    onInputChange,
    createFuturePlan,
    copySaleActualsToPlan,
    copyMarkdownActualsToPlan,
  } = props;
  const label = { inputProps: { 'aria-label': 'Show History' } };
  const numberFormat = (value, displayFloat = false) => {
    return numeral(value).format(displayFloat ? "0,0.00" : "0,0");
  };
  const [rowVisibility, setRowVisibility] = useState({
    switchSales: false,
    switchInventory: false,
    switchMarkdowns: false,
  });
  const ToggleSwitch = ({ switchKey }) => (
      <Tooltip title="Toggle Historical Data">
        <Switch checked={rowVisibility[switchKey]} size={'small'} onChange={() => toggleRowVisibility(switchKey)} />
      </Tooltip>
  );
  const toggleRowVisibility = (key) => {
    setRowVisibility({
      ...rowVisibility,
      [key]: !rowVisibility[key],
    });
  };
  const checkInputError = (innerKey, valIndex) => {
    if (innerKey == "SalesPercentPlan") {
      const _actual =
        tableData.get("Sales").get("Sales") ??
        tableData.get("Sales").get("sales");
      const _plan = tableData.get("Sales").get("SalesPlan");
      if (!_actual || !_plan) {
        return false;
      }
      return (
        _plan.innerData[valIndex].value < _actual.innerData[valIndex].value
      );
    } else if (
      innerKey === "StockToSalesPlan" ||
      innerKey === "InventoryBomPlan"
    ) {
      return (
        tableData.get("Inventory").get("InflowPlanRetail").innerData[valIndex]
          .value < 0 ||
        tableData.get("Inventory").get("InflowPlanRetail").innerData[valIndex]
          .current < 0
      );
    }
    return false;
  };

  return (
    <>
      <Paper
        sx={{ ...ModifyStyle.transparentPaper, ...ModifyStyle.multipleTables }}
      >
        <TableContainer>
          <Table size={"small"}>
            {[...tableData.keys()].map((tableKey, tableIndex) => (
              <React.Fragment key={tableKey}>
                <TableHead
                  sx={{ borderBottom: 2, borderColor: colorMap[tableKey] }}
                >
                  <TableRow>
                    <TableCell
                      className="firstColumn"
                      sx={{ color: colorMap[tableKey] }}
                    >
                      {tableKey}
                      <ToggleSwitch switchKey={`switch${tableKey}`} />
                    </TableCell>
                    {displayMonths.map((period, periodIndex) => (
                      <TableCell
                        className="sameColumn"
                        sx={!periodIndex ? ModifyStyle.highlightCol : {}}
                        key={`${tableIndex}-${periodIndex}`}
                      >
                        <Box sx={!tableIndex ? {} : { visibility: "hidden" }}>
                          <Box sx={ModifyStyle.tableMonth}>{period.month}</Box>
                        </Box>
                      </TableCell>
                    ))}
                    <TableCell className="sameColumn">
                      <Box
                        component="span"
                        sx={!tableIndex ? {} : { visibility: "hidden" }}
                      >
                        Summary
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...tableData.get(tableKey).keys()].map(
                    (innerKey, itemIndex) => {
                      const item = tableData.get(tableKey).get(innerKey);

                      return (
                        <React.Fragment key={`${tableKey}-${itemIndex}`}>
                          <TableRow
                              sx={{
                                ...(item.isActive ? ModifyStyle.highlightRow : {}),
                                ...(item.label.toLowerCase().indexOf("plan % historical") > -1
                                    ? { display: rowVisibility[`switch${tableKey}`] ? 'table-row' : 'none' } : {})
                              }}
                          >
                            <TableCell key={itemIndex}>
                              {item.label} 
                              {item.label.toLowerCase().indexOf("actual %") >
                                -1 && (
                                <CopyActuals
                                  onClickAction={
                                    tableKey === "Sales"
                                      ? copySaleActualsToPlan
                                      : copyMarkdownActualsToPlan
                                  }
                                  label="Copy"
                                  tooltip={`Copy Actual % to Plan %`}
                                  buttonPosition={`right`}
                                />
                              )}
                            </TableCell>
                            {item.innerData.map(
                              (val, valIndex) =>
                                valIndex < displayMonths.length && (
                                  <TableCell
                                    sx={
                                      !valIndex ? ModifyStyle.highlightCol : {}
                                    }
                                    key={`${tableIndex}-${valIndex}`}
                                  >
                                    <Box sx={ModifyStyle.valueContainer}>
                                      {!item.isInput && (
                                        <Box sx={ModifyStyle.valueContainer}>
                                          {numberFormat(
                                            val.value,
                                            item.displayFloat
                                          )}
                                          {item.suffix ?? ""}
                                        </Box>
                                      )}
                                      {item.isInput && (
                                        <TextField
                                          onBlur={() =>
                                            onInputBlur(
                                              tableKey,
                                              innerKey,
                                              valIndex
                                            )
                                          }
                                          onChange={(e) =>
                                            onInputChange(
                                              e,
                                              tableKey,
                                              innerKey,
                                              valIndex
                                            )
                                          }
                                          sx={ModifyStyle.valueInput(!valIndex)}
                                          color={
                                            checkInputError(innerKey, valIndex)
                                              ? "error"
                                              : "primary"
                                          }
                                          focused={checkInputError(
                                            innerKey,
                                            valIndex
                                          )}
                                          fullWidth
                                          size={"small"}
                                          value={val.value}
                                        />
                                      )}
                                    </Box>
                                  </TableCell>
                                )
                            )}
                            <TableCell>
                              {item.lastItem == "sum" && (
                                <>
                                  {numberFormat(
                                    item.summary,
                                    item.displayFloat
                                  )}
                                  {item.suffix ?? ""}
                                </>
                              )}
                              {item.lastItem == "avg" && (
                                <>
                                  {numberFormat(item.avg, item.displayFloat)}
                                  {item.suffix ?? ""}
                                </>
                              )}
                              {item.lastItem == "input" && (
                                <>
                                  <Box sx={ModifyStyle.valueContainer}>
                                    <TextField
                                      onBlur={() =>
                                        onInputBlur(tableKey, innerKey, 12)
                                      }
                                      onChange={(e) =>
                                        onInputChange(e, tableKey, innerKey, 12)
                                      }
                                      sx={ModifyStyle.valueInput(!12)}
                                      fullWidth
                                      size={"small"}
                                      value={item.innerData[12]?.value}
                                    />
                                  </Box>
                                </>
                              )}
                              {item.lastItem == "column" && (
                                <>
                                  {numberFormat(
                                    item.innerData[12]?.value,
                                    item.displayFloat
                                  )}
                                  {item.suffix ?? ""}
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                          {item.label.toLowerCase().indexOf("actual") > -1 &&
                            historicalPeriodDatas.map((historyData, _index) => {
                              return (
                                <TableRow
                                  sx={{
                                    ...(ModifyStyle.historicalRow),
                                    ...(item.label.toLowerCase().indexOf("actual %") > -1
                                      ? { display: rowVisibility[`switch${tableKey}`] ? 'table-row' : 'none' } : {})
                                  }}
                                  key={`${tableKey}-${itemIndex}-history${_index}`}
                                >
                                  <TableCell>
                                    {item.label} (
                                    {historyData.yearmonth.substring(0, 4)})
                                  </TableCell>
                                  {item.innerData.map(
                                    (val, valIndex) =>
                                      valIndex < displayMonths.length && (
                                        <TableCell
                                          sx={
                                            !valIndex
                                              ? ModifyStyle.textCenter
                                              : {}
                                          }
                                          key={`${tableIndex}-${valIndex}-history${_index}`}
                                        >
                                          {numberFormat(
                                            historyData[item.sourceKey][
                                              valIndex
                                            ][item.key],
                                            item.displayFloat
                                          )}
                                          {item.suffix ?? ""}
                                        </TableCell>
                                      )
                                  )}
                                  <TableCell></TableCell>
                                </TableRow>
                              );
                            })}
                        </React.Fragment>
                      );
                    }
                  )}
                </TableBody>
              </React.Fragment>
            ))}
          </Table>
        </TableContainer>
      </Paper>
      <HotKeysDrawer createFuturePlan={createFuturePlan} />
    </>
  );
}
export default BigTable;
