import React, {useEffect, useState} from "react";
import {useForm, useFieldArray, Controller} from "react-hook-form";
import {
    Typography,
    TextField,
    Button,
    NativeSelect,
    InputLabel,
    Card,
    CardHeader,
    CardContent,
    Stack,
    Chip,
    MenuItem,
    Box,
    Collapse,
    Alert,
} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";
import IconButton, {IconButtonProps} from "@mui/material/IconButton";
import ClientService from "../../../../services/ClientService";
import ClientContext from "../../../../providers/CurrentClientProvider";
import TagService from "../../../../services/TagService";
import PosService from "../../../../services/PosService";
import LocationService from "../../../../services/LocationService";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment/moment";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TipContext from "../../../../providers/GlobalTip";

const cssInJs = {
    header: {
        backgroundColor: "#422e52",
        color: "#fff",
    },
    editTitle: {
        fontSize: 16,
        fontWeight: "bolder",
        mt: 3,
    },

    tagSection: {
        ".MuiChip-root": {
            mr: 1,
            mt: 1,
        },
    },

    subheader: {
        color: "#fff",
    },
    headerIcon: {
        backgroundColor: "#f97125",
        color: "white",
        boxShadow: "rgba(0,0,0,0.45) 0px 2px 7px 0px",
        position: "relative",
        top: "41px",
        marginRight: "10px",
    },
    textInput: {
        marginBottom: "15px",
    },
    textInputThreeInRowItem: {
        width: "30%",
    },
    select: {
        "&:hover": {
            backgroundColor: "#fff",
        },
        marginBottom: "15px",
    },
    formHeading: {
        fontSize: "15px",
        fontWeight: "500",
    },
};

const ConfirmLocation = () => {
    const [loading, setLoading] = useState(true);
    const client_context = ClientContext.useClient();
    const [tagData, setTagData] = useState();
    const [client, setClient] = useState({});
    const [locations, setLocations] = useState();
    const [pos, setPos] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientData = await ClientService.getClientByCode(
                    client_context.client.ClientCode
                );

                setClient(clientData);

                setLocations(clientData.locations)

                let tagData = await TagService.getTagGroups();

                // remove "Seasonal Profile Tags" and "Inflow Profile Tags"
                let _tagData = tagData.filter((tag) => {
                    // ids 13 and 14 are the ids of the tags we want to remove
                    return tag.id !== "13" && tag.id !== "14";
                });

                setTagData(_tagData);

                const posData = await PosService.getPos();
                setPos(posData);

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [client_context.client]);

    const updated = (location) => {
        console.log(location)
        const _locations = locations.map(i => {
            if (i.client_location_id == location.client_location_id) {
                return location
            }
            return i;
        })
        setLocations(_locations)

    }

    return (
        <Card>
            {loading && <p>Loading...</p>}
            {!loading && locations && locations.length > 0 &&
                locations.map((location) => (
                    <LocationForm
                        location={location}
                        key={location.client_location_id}
                        tagData={tagData}
                        client={client}
                        pos={pos}
                        onUpdate={updated}
                    />
                ))
            }
        </Card>
    );
};

export const LocationForm = (props) => {
    const tipContext = TipContext.useMessage()
    const {tagData, pos, location, client, onUpdate} = props;
    const [formIsVisible, setFormIsVisible] = useState(false);
    const [saving, setSaving] = useState(false);
    const [has_change, setHasChange] = useState(false);
    const {handleSubmit} = useForm();
    const [formData, setFormData] = useState({
        location_code: location.location_code,
        location_name: location.location_name,
        tags: [],
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        province: "",
        country: "",
        phone: "",
        fax: "",
        pos_id: "",
        fiscal_year_type: "",
        fiscal_year_date: null,
        breakeven: "",
        imu: '',
        active_date: "",
        billing_start_date: "",
        setup_billed_date: "",
    });

    function updateFormData(e) {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        if (!has_change) {
            setHasChange(true);
        }
    }

    function handleDeleteTag(label_id) {
        const _tags = formData.tags.filter((item) => {
            return item.id != label_id;
        });
        updateFormData({
            target: {
                name: "tags",
                value: _tags,
            },
        });
    }

    function updateTag(e) {
        const _tags = formData.tags;
        _tags.push(e.target.value);
        updateFormData({
            target: {
                name: "tags",
                value: _tags,
            },
        });
    }

    const onSubmit = async () => {
        try {
            setSaving(true);
            const locationCode = location.location_code;
            const locationName = location.location_name;

            const updatedFormData = {
                location_code: locationCode,
                location_name: locationName,
                tags: formData.tags,
                address: formData.address1,
                address2: formData.address2,
                city: formData.city,
                state: formData.state,
                zip: formData.zipcode,
                province: formData.province,
                country: formData.country,
                phone: formData.phone,
                fax: formData.fax,
                pos_id: formData.pos_id,
                fiscal_year_type: formData.fiscal_year_type,
                breakeven: formData.breakeven,
                imu: formData.imu,
                active_date: formData.active_date,
                billing_start_date: formData.billing_start_date,
                setup_billed_date: formData.setup_billed_date,
                location_code: formData.location_code,
                location_name: formData.location_name
            };

            if (formData.fiscal_year_date) {
                updatedFormData.fiscal_year_date = formData.fiscal_year_date
            }

            let loc = await LocationService.updateLocation(client.Id, location.client_location_id, updatedFormData);
            onUpdate(loc)
            setHasChange(false);
            setFormIsVisible(false);
            tipContext.showSuccess('Location Updated')
        } catch (e) {
            tipContext.show('Something went wrong: ' + e)
            console.log(e);
        }
        setSaving(false);
    };

    const common_input_props = {
        fullWidth: true,
        variant: "outlined",
        size: "small",
        onChange: (e) => updateFormData(e),
        sx: {mt: 2},
    };
    return (
        <>
            <CardHeader
                title={location.location_code}
                subheader={location.location_name}
                subheaderTypographyProps={{
                    sx: cssInJs.subheader,
                }}
                action={
                    <IconButton
                        aria-label="settings"
                        sx={cssInJs.headerIcon}
                        onClick={() => setFormIsVisible((prev) => !prev)}
                    >
                        {formIsVisible ? <KeyboardArrowUpIcon/> : <EditIcon/>}
                    </IconButton>
                }
                sx={cssInJs.header}
            />
            <CardContent>
                {formIsVisible && (
                    <CardContent>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                                    General
                                </Typography>
                                <div>
                                    <TextField
                                        {...common_input_props}
                                        label="Code"
                                        variant="standard"
                                        fullWidth
                                        sx={cssInJs.textInput}
                                        name={'location_code'}
                                        value={formData.location_code || ""}
                                    />
                                    <TextField
                                        {...common_input_props}
                                        label="Name"
                                        variant="standard"
                                        fullWidth
                                        name={'location_name'}
                                        sx={cssInJs.textInput}
                                        value={formData.location_name || ""}
                                    />
                                </div>
                            </div>
                            <div>
                                <Box sx={cssInJs.editTitle}>Tags</Box>
                                {formData && formData.tags && (
                                    <Box sx={cssInJs.tagSection}>
                                        {formData.tags.map((_tag) => {
                                            return (
                                                <Chip
                                                    key={_tag.id}
                                                    label={_tag.name}
                                                    onDelete={() => handleDeleteTag(_tag.id)}
                                                    deleteIcon={<ClearIcon/>}
                                                />
                                            );
                                        })}
                                    </Box>
                                )}

                                {tagData && (
                                    <>
                                        {tagData.map((_g) => {
                                            return (
                                                <TextField
                                                    {...common_input_props}
                                                    label={_g.name + " Tags"}
                                                    name="_g.name"
                                                    select
                                                    onChange={(e) => updateTag(e)}
                                                    key={`tagData_${_g.id}`}
                                                >
                                                    {_g.tags.map((item) => {
                                                        return (
                                                            <MenuItem key={`tag_g_${item.id}`} value={item}>
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </TextField>
                                            );
                                        })}
                                    </>
                                )}
                            </div>
                            {/* address div*/}
                            <div>
                                <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                                    Address
                                </Typography>
                                <TextField
                                    {...common_input_props}
                                    label="Address 1"
                                    name="address1"
                                    value={formData.address1 || ""}
                                />
                                <TextField
                                    {...common_input_props}
                                    label="Address 2"
                                    name="address2"
                                    value={formData.address2 || ""}
                                />
                                <TextField
                                    {...common_input_props}
                                    label="City"
                                    name="city"
                                    value={formData.city || ""}
                                />
                                <TextField
                                    {...common_input_props}
                                    label="State"
                                    name="state"
                                    value={formData.state || ""}
                                />
                                <TextField
                                    {...common_input_props}
                                    label="ZipCode"
                                    name="zipcode"
                                    value={formData.zipcode || ""}
                                />
                                <TextField
                                    {...common_input_props}
                                    label="Province"
                                    name="province"
                                    value={formData.province || ""}
                                />
                                <TextField
                                    {...common_input_props}
                                    label="Country"
                                    name="country"
                                    value={formData.country || ""}
                                />
                            </div>
                            {/* contact div*/}
                            <div>
                                <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                                    Contact details
                                </Typography>
                                <TextField
                                    {...common_input_props}
                                    label="Phone"
                                    name="phone"
                                    value={formData.phone || ""}
                                />
                                <TextField
                                    {...common_input_props}
                                    label="Fax"
                                    name="fax"
                                    value={formData.fax || ""}
                                />
                            </div>

                            {/* technical div*/}
                            <div>
                                <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                                    Technical
                                </Typography>
                                <div>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        POS System
                                    </InputLabel>
                                    <TextField
                                        {...common_input_props}
                                        label="POS System"
                                        name="pos_id"
                                        value={formData.pos_id || ""}
                                        select
                                    >
                                        {pos.map((item) => {
                                            if (item.name) {
                                                return (
                                                    <MenuItem
                                                        key={`pos_option_${item.id}`}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                );
                                            }
                                        })}
                                    </TextField>
                                </div>

                                <div>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Fiscal Year Type
                                    </InputLabel>
                                    <TextField
                                        {...common_input_props}
                                        label="Fiscal Year Type"
                                        name="fiscal_year_type"
                                        value={formData.fiscal_year_type || ""}
                                        select
                                    >
                                        <MenuItem value="calendar">Calendar</MenuItem>
                                        <MenuItem value="4-5-4">4-5-4</MenuItem>
                                        <MenuItem value="4-4-5">4-4-5</MenuItem>
                                    </TextField>
                                </div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Fiscal Year End"
                                        value={formData.fiscal_year_date}
                                        inputFormat="MMM dd"
                                        mask=""
                                        onChange={(e) =>
                                            updateFormData({
                                                target: {
                                                    value: moment(e).format("YYYY-MM-DD HH:mm:ss"),
                                                    name: "fiscal_year_date",
                                                },
                                            })
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth={true}
                                                variant="outlined"
                                                size="small"
                                                sx={{mt: 2}}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    {...common_input_props}
                                    label="Breakeven"
                                    name="breakeven"
                                    value={formData.breakeven || ""}
                                    type="number"
                                />
                                <TextField
                                    {...common_input_props}
                                    id="imu"
                                    name="imu"
                                    label="Default IMU"
                                    variant="standard"
                                    value={formData.imu || ""}
                                    type="number"
                                />
                            </div>

                            <div>
                                <Typography variant="h4" gutterBottom sx={cssInJs.formHeading}>
                                    Technical
                                </Typography>
                            </div>
                            <Button
                                variant="contained"
                                type="submit"
                                loading={saving}
                                onClick={e => onSubmit}
                            >
                                Save Location
                            </Button>
                        </form>
                    </CardContent>
                )}
            </CardContent>
        </>
    );
};
export default ConfirmLocation;
