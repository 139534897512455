export const labelList = [
  {
    "id": 468,
    "name": "Airport",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 218,
    "name": "Downtown",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 463,
    "name": "E Commerce",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 539,
    "name": "Golf Course",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 454,
    "name": "Hospital Gift Shop",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 225,
    "name": "Kiosk",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 220,
    "name": "Lifestyle Center",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 557,
    "name": "Live Selling",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 559,
    "name": "Locked",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 515,
    "name": "Multi-Use",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 223,
    "name": "Neighborhood",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 219,
    "name": "Regional Mall",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 224,
    "name": "Resort",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 226,
    "name": "Seasonal Stores",
    "description": "Pop up locations",
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 506,
    "name": "Shop Within Hotel",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 510,
    "name": "Showroom",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 374,
    "name": "Stand Alone",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 717,
    "name": "storage",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 222,
    "name": "Store within a Store",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 221,
    "name": "Strip Center",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 400,
    "name": "University",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 492,
    "name": "Warehouse",
    "description": null,
    "labelTypeId": 3,
    "labelType": null
  },
  {
    "id": 573,
    "name": "AB",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 564,
    "name": "Africa",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 250,
    "name": "AK",
    "description": "Alaska",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 249,
    "name": "AL",
    "description": "Alabama",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 252,
    "name": "AR",
    "description": "Arkansas",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 563,
    "name": "Asia",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 530,
    "name": "Australia",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 251,
    "name": "AZ",
    "description": "Arizona",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 574,
    "name": "BC",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 253,
    "name": "CA",
    "description": "California",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 489,
    "name": "Caribbean",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 241,
    "name": "Central America",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 254,
    "name": "CO",
    "description": "Colorado",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 255,
    "name": "CT",
    "description": "Connecticut",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 256,
    "name": "DE",
    "description": "Deleware",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 227,
    "name": "Eastern Canada",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 243,
    "name": "Europe",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 257,
    "name": "FL",
    "description": "Florida",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 258,
    "name": "GA",
    "description": "Georgia",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 560,
    "name": "Great Lakes",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 259,
    "name": "HI",
    "description": "Hawaii",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 263,
    "name": "IA",
    "description": "Iowa",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 260,
    "name": "ID",
    "description": "Idaho",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 261,
    "name": "IL",
    "description": "Illinois",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 262,
    "name": "IN",
    "description": "Indiana",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 245,
    "name": "Indonesia",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 264,
    "name": "KS",
    "description": "Kansas",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 265,
    "name": "KY",
    "description": "Kentucky",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 266,
    "name": "LA",
    "description": "Louisiana",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 524,
    "name": "Las Vegas",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 269,
    "name": "MA",
    "description": "Massachusetts",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 571,
    "name": "MB",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 268,
    "name": "MD",
    "description": "Maryland",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 267,
    "name": "ME",
    "description": "Maine",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 270,
    "name": "MI",
    "description": "Michigan",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 231,
    "name": "Mid Atlantic",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 499,
    "name": "Middle East",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 233,
    "name": "Midsouth",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 234,
    "name": "Midwest",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 271,
    "name": "MN",
    "description": "Minnesota",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 273,
    "name": "MO",
    "description": "Missouri",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 236,
    "name": "Mountains",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 272,
    "name": "MS",
    "description": "Mississippi",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 274,
    "name": "MT",
    "description": "Montana",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 568,
    "name": "NB",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 280,
    "name": "NC",
    "description": "North Carolina",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 281,
    "name": "ND",
    "description": "North Dakota",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 275,
    "name": "NE",
    "description": "Nebraska",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 230,
    "name": "New England",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 531,
    "name": "New Zealand",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 277,
    "name": "NH",
    "description": "New Hampshire",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 278,
    "name": "NJ",
    "description": "New Jersey",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 565,
    "name": "NL",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 396,
    "name": "Northern California",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 239,
    "name": "Northwest",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 567,
    "name": "NS",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 576,
    "name": "NT",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 577,
    "name": "NU",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 276,
    "name": "NV",
    "description": "Nevada",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 279,
    "name": "NY",
    "description": "New York",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 246,
    "name": "NYC",
    "description": "New York City",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 282,
    "name": "OH",
    "description": "Ohio",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 283,
    "name": "OK",
    "description": "Oklahoma",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 570,
    "name": "ON",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 284,
    "name": "OR",
    "description": "Oregon",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 285,
    "name": "PA",
    "description": "Pennsylvania",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 237,
    "name": "Panhandle",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 566,
    "name": "PE",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 248,
    "name": "Philly",
    "description": "Philadelphia",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 235,
    "name": "Plains",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 569,
    "name": "QC",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 286,
    "name": "RI",
    "description": "Rhode Island",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 561,
    "name": "Rocky Mountain",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 287,
    "name": "SC",
    "description": "South Carolina",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 288,
    "name": "SD",
    "description": "South Dakota",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 572,
    "name": "SK",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 710,
    "name": "South",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 242,
    "name": "South America",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 244,
    "name": "South East Asia",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 232,
    "name": "Southeast",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 493,
    "name": "Southeast Asia",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 240,
    "name": "Southern California",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 238,
    "name": "Southwest",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 247,
    "name": "Texas",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 289,
    "name": "TN",
    "description": "Tennesee",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 290,
    "name": "TX",
    "description": "Texas",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 229,
    "name": "Urban North East",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 291,
    "name": "UT",
    "description": "Utah",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 293,
    "name": "VA",
    "description": "Virginia",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 292,
    "name": "VT",
    "description": "Vermont",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 294,
    "name": "WA",
    "description": "Washington",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 562,
    "name": "West Coast",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 228,
    "name": "Western Canada",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 296,
    "name": "WI",
    "description": "Wisconsin",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 295,
    "name": "WV",
    "description": "West Virginia",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 297,
    "name": "WY",
    "description": "Wyoming",
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 575,
    "name": "YT",
    "description": null,
    "labelTypeId": 4,
    "labelType": null
  },
  {
    "id": 299,
    "name": "Company 1",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 300,
    "name": "Company 2",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 301,
    "name": "Company 3",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 302,
    "name": "Company 4",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 303,
    "name": "Company 5",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 304,
    "name": "Company 6",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 305,
    "name": "Company 7",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 306,
    "name": "Company 8",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 307,
    "name": "Company 9",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 335,
    "name": "Department 1",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 336,
    "name": "Department 2",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 337,
    "name": "Department 3",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 338,
    "name": "Department 4",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 339,
    "name": "Department 5",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 340,
    "name": "Department 6",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 341,
    "name": "Department 7",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 342,
    "name": "Department 8",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 343,
    "name": "Department 9",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 326,
    "name": "District 1",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 327,
    "name": "District 2",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 328,
    "name": "District 3",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 329,
    "name": "District 4",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 330,
    "name": "District 5",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 331,
    "name": "District 6",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 332,
    "name": "District 7",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 333,
    "name": "District 8",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 334,
    "name": "District 9",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 317,
    "name": "Division 1",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 318,
    "name": "Division 2",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 319,
    "name": "Division 3",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 320,
    "name": "Division 4",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 321,
    "name": "Division 5",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 322,
    "name": "Division 6",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 323,
    "name": "Division 7",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 324,
    "name": "Division 8",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 325,
    "name": "Division 9",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 298,
    "name": "Holding Company",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 308,
    "name": "Region 1",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 309,
    "name": "Region 2",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 310,
    "name": "Region 3",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 311,
    "name": "Region 4",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 312,
    "name": "Region 5",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 313,
    "name": "Region 6",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 314,
    "name": "Region 7",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 315,
    "name": "Region 8",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 316,
    "name": "Region 9",
    "description": null,
    "labelTypeId": 5,
    "labelType": null
  },
  {
    "id": 447,
    "name": "",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 597,
    "name": "Airport",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 505,
    "name": "Automotive",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 453,
    "name": "Bridal",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 346,
    "name": "Classic",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 445,
    "name": "College Town",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 598,
    "name": "Consignment",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 345,
    "name": "Contemporary Fashion",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 611,
    "name": "Drug Store",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 349,
    "name": "E Commerce",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 481,
    "name": "License",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 599,
    "name": "Lifestyle Center",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 395,
    "name": "Lifestyle/Active/Performance",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 344,
    "name": "Luxury",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 709,
    "name": "Mountain Town",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 600,
    "name": "Neighborhood",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 448,
    "name": "Outdoor",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 351,
    "name": "Outlet/Clearance",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 509,
    "name": "Plus Size",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 601,
    "name": "Pop Up",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 602,
    "name": "Regional Mall",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 348,
    "name": "Resale",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 603,
    "name": "Resort",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 353,
    "name": "Rural",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 347,
    "name": "Seasonal",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 511,
    "name": "Showroom",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 604,
    "name": "Stadium",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 605,
    "name": "Stand Alone",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 606,
    "name": "Store within a Store",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 491,
    "name": "Urban",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 352,
    "name": "Vertical",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 350,
    "name": "Warehouse",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 607,
    "name": "Wholesale",
    "description": null,
    "labelTypeId": 6,
    "labelType": null
  },
  {
    "id": 364,
    "name": "Adult Products",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 578,
    "name": "Airport",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 488,
    "name": "Attraction Gift Shop",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 504,
    "name": "Automative",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 503,
    "name": "Automotive",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 357,
    "name": "Bicycle",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 490,
    "name": "Book Store",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 494,
    "name": "Candy Store",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 686,
    "name": "Cannabis",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 714,
    "name": "Casino",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 360,
    "name": "Childrens",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 687,
    "name": "College Books/Accessories",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 542,
    "name": "College Bookstore",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 579,
    "name": "College Town",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 538,
    "name": "Comfort Shoe",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 580,
    "name": "Consignment",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 700,
    "name": "Contemporary Fashion",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 467,
    "name": "Costume Jewelry",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 612,
    "name": "Drug Store Independent",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 581,
    "name": "E-Commerce",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 552,
    "name": "Elevated Streetwear",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 362,
    "name": "Equestrian",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 688,
    "name": "Family Apparel Store",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 689,
    "name": "Farm/Feed",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 701,
    "name": "Fast Fashion",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 690,
    "name": "Footwear",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 691,
    "name": "Furniture",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 449,
    "name": "Garden/Landscaping",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 707,
    "name": "Gender Neutral",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 363,
    "name": "Gift, Stationery & Home Accessories",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 692,
    "name": "Golf",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 540,
    "name": "Hallmark",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 693,
    "name": "Hardware",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 694,
    "name": "Hospital",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 705,
    "name": "Hotel",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 547,
    "name": "Ice Hockey",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 459,
    "name": "Jewelry Fashion",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 695,
    "name": "Jewelry Luxury",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 370,
    "name": "Juniors",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 472,
    "name": "Kids/Maternity",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 368,
    "name": "Kitchen",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 582,
    "name": "Lifestyle Center",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 696,
    "name": "Lighting",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 697,
    "name": "Lingerie",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 698,
    "name": "Liquor/Wine",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 583,
    "name": "Live Selling",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 444,
    "name": "Luggage and Travel",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 699,
    "name": "Luxury Fashion",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 354,
    "name": "Mens",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 706,
    "name": "Monobrand",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 392,
    "name": "Museum Stores & Art Gallery",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 533,
    "name": "Music",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 462,
    "name": "Needlepoint",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 584,
    "name": "Neighborhood",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 460,
    "name": "New Age",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 708,
    "name": "Non-Gendered",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 702,
    "name": "Office Supplies Stores",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 526,
    "name": "Optical Goods",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 356,
    "name": "Outdoor",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 585,
    "name": "Outlet/Clearance",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 482,
    "name": "Party Supplies",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 361,
    "name": "Pet",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 586,
    "name": "Pop Up",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 703,
    "name": "Pro Teams",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 367,
    "name": "Quilt/Fabric",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 587,
    "name": "Regional Mall",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 466,
    "name": "Religious",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 588,
    "name": "Resort",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 589,
    "name": "Rural",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 590,
    "name": "Seasonal",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 365,
    "name": "Shoe",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 591,
    "name": "Showroom",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 358,
    "name": "Ski",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 548,
    "name": "Sporting Goods",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 592,
    "name": "Stadium",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 593,
    "name": "Stand Alone",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 594,
    "name": "Store within a Store",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 366,
    "name": "Surf/Skate/Ski/Snowboard",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 704,
    "name": "Swimwear",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 464,
    "name": "Teacher & Educational Supply",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 555,
    "name": "Tobacco",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 359,
    "name": "Toys/Hobby",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 485,
    "name": "Tweens",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 369,
    "name": "Uniforms",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 595,
    "name": "Urban",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 546,
    "name": "Vintage",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 596,
    "name": "Warehouse",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 417,
    "name": "Wellness & Nutrituion",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 457,
    "name": "Western Wear",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 355,
    "name": "Womens",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  },
  {
    "id": 450,
    "name": "Yarn",
    "description": null,
    "labelTypeId": 7,
    "labelType": null
  }
]

export const associateMarketerList = [
  {
    "id": 11,
    "name": "",
    "type": null
  },
  {
    "id": 2,
    "name": "Alan Rosman",
    "type": null
  },
  {
    "id": 7,
    "name": "American Equestrian Trade Association",
    "type": null
  },
  {
    "id": 13,
    "name": "Butch Blum",
    "type": null
  },
  {
    "id": 16,
    "name": "Dana Kantalis",
    "type": null
  },
  {
    "id": 3,
    "name": "DMS Retail",
    "type": null
  },
  {
    "id": 17,
    "name": "doug hilficker",
    "type": null
  },
  {
    "id": 15,
    "name": "ICBA",
    "type": null
  },
  {
    "id": 9,
    "name": "Prism POS",
    "type": null
  },
  {
    "id": 12,
    "name": "Retail Concepts",
    "type": null
  },
  {
    "id": 5,
    "name": "Retail Pro",
    "type": null
  },
  {
    "id": 10,
    "name": "Retail Teamwork",
    "type": null
  },
  {
    "id": 8,
    "name": "RSG Affiliate Network",
    "type": null
  },
  {
    "id": 6,
    "name": "SnapRetail",
    "type": null
  },
  {
    "id": 14,
    "name": "The Boutique Hub",
    "type": null
  },
  {
    "id": 4,
    "name": "unknown",
    "type": null
  },
  {
    "id": 1,
    "name": "WhizBang",
    "type": null
  }
]

export const posSystemList = [
  {
    "id": 1172,
    "name": null,
    "type": null
  },
  {
    "id": 1193,
    "name": null,
    "type": null
  },
  {
    "id": 1215,
    "name": null,
    "type": null
  },
  {
    "id": 71,
    "name": "A2000",
    "type": null
  },
  {
    "id": 69,
    "name": "Accupos",
    "type": null
  },
  {
    "id": 1,
    "name": "Ace Retail",
    "type": null
  },
  {
    "id": 73,
    "name": "Amber",
    "type": null
  },
  {
    "id": 99,
    "name": "Arelco",
    "type": null
  },
  {
    "id": 2,
    "name": "Artisan",
    "type": null
  },
  {
    "id": 1205,
    "name": "Artisan",
    "type": null
  },
  {
    "id": 1124,
    "name": "ASC",
    "type": null
  },
  {
    "id": 3,
    "name": "Atrex",
    "type": null
  },
  {
    "id": 72,
    "name": "Boardsports Pro from Specialtysoft",
    "type": null
  },
  {
    "id": 4,
    "name": "Bookstore",
    "type": null
  },
  {
    "id": 5,
    "name": "Bridal Solutions",
    "type": null
  },
  {
    "id": 95,
    "name": "Brightpearl",
    "type": null
  },
  {
    "id": 6,
    "name": "CAM",
    "type": null
  },
  {
    "id": 1173,
    "name": "CAM",
    "type": null
  },
  {
    "id": 7,
    "name": "Cap Retail",
    "type": null
  },
  {
    "id": 82,
    "name": "Cash Register Express",
    "type": null
  },
  {
    "id": 8,
    "name": "Cash Srawer",
    "type": null
  },
  {
    "id": 9,
    "name": "catus",
    "type": null
  },
  {
    "id": 10,
    "name": "CDS",
    "type": null
  },
  {
    "id": 83,
    "name": "Cegrid",
    "type": null
  },
  {
    "id": 11,
    "name": "Celerant",
    "type": null
  },
  {
    "id": 1122,
    "name": "Celerant Text",
    "type": null
  },
  {
    "id": 67,
    "name": "Charter",
    "type": null
  },
  {
    "id": 12,
    "name": "checkout",
    "type": null
  },
  {
    "id": 13,
    "name": "checkpoint",
    "type": null
  },
  {
    "id": 1106,
    "name": "Clover",
    "type": null
  },
  {
    "id": 1176,
    "name": "Clover",
    "type": null
  },
  {
    "id": 14,
    "name": "comCash",
    "type": null
  },
  {
    "id": 86,
    "name": "CompanyBe",
    "type": null
  },
  {
    "id": 88,
    "name": "Computer Systems of Chicago - Booklog",
    "type": null
  },
  {
    "id": 16,
    "name": "Consign Pro",
    "type": null
  },
  {
    "id": 15,
    "name": "Counterpoint",
    "type": null
  },
  {
    "id": 1183,
    "name": "Counterpoint",
    "type": null
  },
  {
    "id": 1184,
    "name": "Counterpoint",
    "type": null
  },
  {
    "id": 1207,
    "name": "Counterpoint",
    "type": null
  },
  {
    "id": 1208,
    "name": "Counterpoint",
    "type": null
  },
  {
    "id": 1209,
    "name": "Counterpoint",
    "type": null
  },
  {
    "id": 1210,
    "name": "Counterpoint",
    "type": null
  },
  {
    "id": 1211,
    "name": "Counterpoint",
    "type": null
  },
  {
    "id": 1150,
    "name": "Coyote",
    "type": null
  },
  {
    "id": 1143,
    "name": "Cybex",
    "type": null
  },
  {
    "id": 1123,
    "name": "DAT File",
    "type": null
  },
  {
    "id": 1137,
    "name": "Envision",
    "type": null
  },
  {
    "id": 96,
    "name": "Epicor",
    "type": null
  },
  {
    "id": 1130,
    "name": "ePOS",
    "type": null
  },
  {
    "id": 17,
    "name": "Erply",
    "type": null
  },
  {
    "id": 92,
    "name": "ezPower POS",
    "type": null
  },
  {
    "id": 18,
    "name": "Fashion Sense",
    "type": null
  },
  {
    "id": 94,
    "name": "Finale",
    "type": null
  },
  {
    "id": 1141,
    "name": "FTP Autoload WebFlight",
    "type": null
  },
  {
    "id": 19,
    "name": "Gallery System",
    "type": null
  },
  {
    "id": 20,
    "name": "General Store",
    "type": null
  },
  {
    "id": 66,
    "name": "Habour Touch",
    "type": null
  },
  {
    "id": 36,
    "name": "Hallmark POS System",
    "type": null
  },
  {
    "id": 59,
    "name": "Heartland Retail",
    "type": null
  },
  {
    "id": 1161,
    "name": "Heartland Retail",
    "type": null
  },
  {
    "id": 1180,
    "name": "Heartland Retail",
    "type": null
  },
  {
    "id": 1181,
    "name": "Heartland Retail",
    "type": null
  },
  {
    "id": 1182,
    "name": "Heartland Retail",
    "type": null
  },
  {
    "id": 1185,
    "name": "Heartland Retail",
    "type": null
  },
  {
    "id": 1186,
    "name": "Heartland Retail",
    "type": null
  },
  {
    "id": 1190,
    "name": "Heartland Retail",
    "type": null
  },
  {
    "id": 1203,
    "name": "Heartland Retail",
    "type": null
  },
  {
    "id": 1140,
    "name": "Heartland Retail Webflight",
    "type": null
  },
  {
    "id": 1128,
    "name": "Imagine",
    "type": null
  },
  {
    "id": 21,
    "name": "Info Touch",
    "type": null
  },
  {
    "id": 85,
    "name": "Insight",
    "type": null
  },
  {
    "id": 22,
    "name": "Intelligent Retail",
    "type": null
  },
  {
    "id": 74,
    "name": "JMM Software",
    "type": null
  },
  {
    "id": 24,
    "name": "K9Bytes",
    "type": null
  },
  {
    "id": 25,
    "name": "Keystroke",
    "type": null
  },
  {
    "id": 26,
    "name": "KWI",
    "type": null
  },
  {
    "id": 89,
    "name": "Liberty Software from ReSale World",
    "type": null
  },
  {
    "id": 27,
    "name": "Lightspeed",
    "type": null
  },
  {
    "id": 1178,
    "name": "Lightspeed",
    "type": null
  },
  {
    "id": 1212,
    "name": "Lightspeed",
    "type": null
  },
  {
    "id": 1113,
    "name": "LightSpeed Retail Sku",
    "type": null
  },
  {
    "id": 1177,
    "name": "LightSpeed Retail Sku",
    "type": null
  },
  {
    "id": 1188,
    "name": "LightSpeed Retail Sku",
    "type": null
  },
  {
    "id": 1194,
    "name": "LightSpeed Retail Sku",
    "type": null
  },
  {
    "id": 64,
    "name": "LikeSew",
    "type": null
  },
  {
    "id": 81,
    "name": "LS Retail - LS NAV",
    "type": null
  },
  {
    "id": 28,
    "name": "Manual",
    "type": null
  },
  {
    "id": 29,
    "name": "Masterpiece",
    "type": null
  },
  {
    "id": 30,
    "name": "Matrix",
    "type": null
  },
  {
    "id": 1133,
    "name": "MBS",
    "type": null
  },
  {
    "id": 32,
    "name": "Merchant",
    "type": null
  },
  {
    "id": 90,
    "name": "Microbiz",
    "type": null
  },
  {
    "id": 78,
    "name": "Microhouse Systems",
    "type": null
  },
  {
    "id": 76,
    "name": "Microsoft Dynamic 2009",
    "type": null
  },
  {
    "id": 31,
    "name": "Microsoft RMS",
    "type": null
  },
  {
    "id": 33,
    "name": "Milano",
    "type": null
  },
  {
    "id": 1117,
    "name": "Mock WebFlight",
    "type": null
  },
  {
    "id": 34,
    "name": "MSFL",
    "type": null
  },
  {
    "id": 77,
    "name": "Multi Fex RMS from Microhouse Systems",
    "type": null
  },
  {
    "id": 1121,
    "name": "Navision",
    "type": null
  },
  {
    "id": 1131,
    "name": "NCR Silver",
    "type": null
  },
  {
    "id": 35,
    "name": "Netsuites",
    "type": null
  },
  {
    "id": 1120,
    "name": "NorthStar",
    "type": null
  },
  {
    "id": 65,
    "name": "PayGo",
    "type": null
  },
  {
    "id": 57,
    "name": "pcAmerica",
    "type": null
  },
  {
    "id": 37,
    "name": "Posim",
    "type": null
  },
  {
    "id": 38,
    "name": "Posvive systems",
    "type": null
  },
  {
    "id": 39,
    "name": "Prism",
    "type": null
  },
  {
    "id": 1111,
    "name": "Profit Master",
    "type": null
  },
  {
    "id": 1134,
    "name": "Profitek",
    "type": null
  },
  {
    "id": 40,
    "name": "Purple Cactus",
    "type": null
  },
  {
    "id": 41,
    "name": "Quickbooks",
    "type": null
  },
  {
    "id": 1174,
    "name": "Quickbooks",
    "type": null
  },
  {
    "id": 1195,
    "name": "Quickbooks",
    "type": null
  },
  {
    "id": 79,
    "name": "Quoram France",
    "type": null
  },
  {
    "id": 1145,
    "name": "Ratex",
    "type": null
  },
  {
    "id": 87,
    "name": "RCM",
    "type": null
  },
  {
    "id": 43,
    "name": "Retail Edge",
    "type": null
  },
  {
    "id": 1127,
    "name": "Retail Management Hero Headquarters",
    "type": null
  },
  {
    "id": 1126,
    "name": "Retail Management Hero Store Operattions",
    "type": null
  },
  {
    "id": 44,
    "name": "Retail Pro 8",
    "type": null
  },
  {
    "id": 1102,
    "name": "Retail Pro 9",
    "type": null
  },
  {
    "id": 1191,
    "name": "Retail Pro 9",
    "type": null
  },
  {
    "id": 1103,
    "name": "Retail Pro Prism",
    "type": null
  },
  {
    "id": 45,
    "name": "Retail Star",
    "type": null
  },
  {
    "id": 62,
    "name": "Retail Systems",
    "type": null
  },
  {
    "id": 1144,
    "name": "Revel",
    "type": null
  },
  {
    "id": 46,
    "name": "Rics",
    "type": null
  },
  {
    "id": 1138,
    "name": "Rics WebFlight",
    "type": null
  },
  {
    "id": 75,
    "name": "Rocket",
    "type": null
  },
  {
    "id": 1132,
    "name": "RTI",
    "type": null
  },
  {
    "id": 47,
    "name": "RunIt",
    "type": null
  },
  {
    "id": 1187,
    "name": "RunIt",
    "type": null
  },
  {
    "id": 1204,
    "name": "RunIt",
    "type": null
  },
  {
    "id": 1139,
    "name": "RunIt WebFlight",
    "type": null
  },
  {
    "id": 1171,
    "name": "Shopify",
    "type": null
  },
  {
    "id": 56,
    "name": "Shopify Webflight",
    "type": null
  },
  {
    "id": 1214,
    "name": "Shopify Webflight",
    "type": null
  },
  {
    "id": 48,
    "name": "ShopKeep",
    "type": null
  },
  {
    "id": 1112,
    "name": "SkuUpload",
    "type": null
  },
  {
    "id": 51,
    "name": "Smart Vendor",
    "type": null
  },
  {
    "id": 50,
    "name": "Smyth",
    "type": null
  },
  {
    "id": 91,
    "name": "Softmind",
    "type": null
  },
  {
    "id": 93,
    "name": "Square",
    "type": null
  },
  {
    "id": 1110,
    "name": "StitchLabs",
    "type": null
  },
  {
    "id": 70,
    "name": "TAM Retail",
    "type": null
  },
  {
    "id": 1142,
    "name": "Teamwork Retail FTP WebFlight",
    "type": null
  },
  {
    "id": 84,
    "name": "Teamworks",
    "type": null
  },
  {
    "id": 1169,
    "name": "Teamworks",
    "type": null
  },
  {
    "id": 1175,
    "name": "Teamworks",
    "type": null
  },
  {
    "id": 1179,
    "name": "Teamworks",
    "type": null
  },
  {
    "id": 58,
    "name": "TRS",
    "type": null
  },
  {
    "id": 53,
    "name": "Uniform Solution",
    "type": null
  },
  {
    "id": 55,
    "name": "Unify POS for Retail",
    "type": null
  },
  {
    "id": 1114,
    "name": "Unknown",
    "type": null
  },
  {
    "id": 49,
    "name": "Utc",
    "type": null
  },
  {
    "id": 63,
    "name": "Vend",
    "type": null
  },
  {
    "id": 52,
    "name": "Volusion",
    "type": null
  },
  {
    "id": 1125,
    "name": "VRP",
    "type": null
  },
  {
    "id": 68,
    "name": "Windward",
    "type": null
  },
  {
    "id": 1129,
    "name": "Winn Retail",
    "type": null
  },
  {
    "id": 1170,
    "name": "YoungWorld",
    "type": null
  }
]

export const fiscalYearList = [
  {
    value: 'Calendar'
  },
  {
    value: '4-5-4'
  },
  {
    value: '4-4-5'
  },
  {
    value: 'Aramark'
  },
]

export const processClientChangeLog = {
  clientChangeLogActions: [
    {
      id: 568630,
      row: 1,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "",
      serviceType: "",
      classCategory: "",
      processedDate: "2023-12-15T17:52:38.8399303+00:00",
    },
    {
      id: 568631,
      row: 2,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "100",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Building Toys",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568632,
      row: 3,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "105",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Misc Novelties",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568633,
      row: 4,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "110",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Baby and Toddler",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568634,
      row: 5,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "115",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Plush",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568635,
      row: 6,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "120",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Craft Kits",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568636,
      row: 7,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "125",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Dolls and Access",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568637,
      row: 8,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "130",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Games",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568638,
      row: 9,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "135",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Ride On Toys",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568639,
      row: 10,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "140",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Science and Nature",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568640,
      row: 11,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "145",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Outdoor/Active Games",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568641,
      row: 12,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "150",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Picture Books and Novels",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568642,
      row: 13,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "155",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Musical Instruments",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568643,
      row: 14,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "160",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Cars and Track",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568644,
      row: 15,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "165",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Art Supplies",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568645,
      row: 16,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "170",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Puzzles",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568646,
      row: 17,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "175",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Sports",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568647,
      row: 18,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "180",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Radio Control",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568648,
      row: 19,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "185",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Action Figures",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568649,
      row: 20,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "190",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Dress Up",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568650,
      row: 21,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "195",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Décor",
      serviceType: "OTB",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
    {
      id: 568651,
      row: 22,
      clientChangeLogId: 9262,
      clientChangeLog: {
        clientChangeLogActions: null,
        id: 9262,
        clientId: 4201,
        client: null,
        name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
        fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
        createdDate: "2023-12-15T17:52:38.12",
        createdBy: null,
        processedDate: "2023-12-15T17:52:39.2774326+00:00",
        processedBy: null,
        displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
        addCount: 0,
        dropCount: 0,
        updateCount: 0,
        otbCount: 0,
        pnotbCount: 0,
        notbCount: 0,
        classCount: 0,
        locations: [
          {
            locationCode: null,
            locationName: "Total",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
          {
            locationCode: "01",
            locationName: "Main Store",
            classCount: 21,
            otbCount: 20,
            pnotbCount: 0,
            notbCount: 0,
            otherCount: 1,
          },
        ],
        errors: [],
        isProcessed: true,
      },
      action: "A",
      clientCode: "AA12345",
      locationCode: "01",
      classCode: "999",
      parentClassCode: "",
      parentLocationCode: null,
      locationName: "Main Store",
      className: "Misc",
      serviceType: "History",
      classCategory: "Toys",
      processedDate: "2023-12-15T17:52:39.0430219+00:00",
    },
  ],
  id: 9262,
  clientId: 4201,
  client: null,
  name: "client_AA12345_2020_1_9_0_5_changes.xlsx",
  fileName: "client_AA12345_2023_12_15_17_52_changes.xlsx",
  createdDate: "2023-12-15T17:52:38.12",
  createdBy: null,
  processedDate: "2023-12-15T17:52:39.2774326+00:00",
  processedBy: null,
  displayName: "client_AA12345_2020_1_9_0_5_changes.xlsx 5:52 PM",
  addCount: 0,
  dropCount: 0,
  updateCount: 0,
  otbCount: 0,
  pnotbCount: 0,
  notbCount: 0,
  classCount: 0,
  locations: null,
  errors: null,
  isProcessed: true,
}
