import {
    useState,
    useEffect,
    useRef
} from "react";
import TableauService from "../../services/TableauService";

function TableauReport(props) {
    const { tableau } = window;
    let {url, clientId, epochMonth, height = '95vh', width= '100%'} = props;
    const [viz, setViz] = useState(null)
    const ref = useRef(null)

    useEffect(() => {
        destroy()
        initViz()
    }, [epochMonth])
    function initViz(){
        TableauService.getTableauUserToken().then(res => {
            const token = res.token
            url = url.replace('{user_auth_key}', token)
            let _viz = new tableau.Viz(ref.current, url, {
                'height': height,
                'width': width,
                'clientId': clientId,
                'onFirstInteractive': function () {
                    //viz.hide();
                    _viz.getWorkbook().changeParameterValueAsync('clientId', Number(clientId));
                    if (epochMonth) {
                        _viz.getWorkbook().changeParameterValueAsync('epochMonthRM', Number(epochMonth));
                    }
                    _viz.refreshDataAsync().then(success =>  {
                        _viz.show();
                    });

                }
            });



            setViz(_viz)
        })

    }
    useEffect(() => {
        return () => {
            destroy()
        }
    },[])
    //
    function destroy() {
        if (viz) viz.dispose();
    }

    return <>
        <div ref={ref}></div>
    </>
}

export default TableauReport