import {useState, useEffect} from 'react'
import {
	Box,
	Button,
	Typography,
	TableRow,
	TableCell,
	FormControl,
	InputLabel,
	MenuItem,
	Autocomplete,
	Select,
	TextField,
} from '@mui/material'
import {
	LoadingButton
} from '@mui/lab'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import UserService from '../../services/UserService'
import UserModel from '../../models/User'
import CircularProgress from '@mui/material/CircularProgress'

function AssignNewAdminUser(props) {
	const [selected_user, setSelectedUser] = useState({})
	const [current_role_id, setCurrentRoleId] = useState("")
	const [creating, setCreating] = useState(false)
	const [all_users, setAllUsers] = useState([])
	const [loading_users, setLoadingUsers] = useState(false)

	useEffect(() => {
		loadUsers()
	}, [])

	async function loadUsers() {
		setLoadingUsers(true)
		let _users = await UserService.getUsers()
		let new_user = []
		_users.forEach(item => {
			if( item.first_name && item.last_name ) {
				new_user.push({
					label: `${item.first_name} ${item.last_name}`,
					id: item.id,
					first_name: item.first_name,
					last_name: item.last_name,
				})
			}
		})
		setAllUsers(new_user)
		setLoadingUsers(false)
	}

	async function assignRoleToNewUser() {
		try {
			setCreating(true)
			const link = await UserService.assignUserToRole(selected_user.id, current_role_id)
			setCurrentRoleId("")
			setSelectedUser({})
			setCreating(false)
			props.userAdded()
		} catch(e) {
			setCreating(false)
		}
	}

	function handleCurrentRoleChange(e) {
		setCurrentRoleId(e.target.value)
	}

	function handlerSearchUser(event, value, reason) {
		if( reason == 'selectOption' ) {
			console.log(value)
			setSelectedUser(value)
		}
	}

	return <TableRow>
		<TableCell>
			<Autocomplete
				disablePortal
				size="small"
				options={all_users}
				renderOption={(props, option) => {
					return (
					<li {...props} key={option.id}>
						{option.label}
					</li>
					);
				}}
				openOnFocus
				sx={{ width: 300 }}
				renderInput={(params) => <TextField {...params} label="Search for user" />}
				onChange={handlerSearchUser}
				loadingText={
					<CircularProgress color="inherit" />
				}
				loading={loading_users}
			/>
		</TableCell>
		<TableCell></TableCell>
		<TableCell>
			<FormControl sx={{width: 200}} size="small">
				<InputLabel>Select Role</InputLabel>
				<Select
					value={current_role_id}
					label="Select Role"
					onChange={handleCurrentRoleChange}
				>
					{props.roles.map(item => {
						return <MenuItem key={`user_role_item_${item.id}`} value={item.id} >{item.name}</MenuItem>
					})}
				</Select>
			</FormControl>
			<LoadingButton 
				color="success"
				sx={{ml:1, height:38}}
				variant="contained"
				onClick={assignRoleToNewUser}
				disabled={!(current_role_id && selected_user)}
				loading={creating}
			>
				<DoneOutlinedIcon />
			</LoadingButton>
		</TableCell>
	</TableRow>
}

export default AssignNewAdminUser
