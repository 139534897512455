import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Chip,
	Paper,
	Switch,
	CircularProgress,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Checkbox,
	TextField,
	InputAdornment,
	FormGroup,
	FormControlLabel,
} from '@mui/material';
import jss from './ProfileCommonJss'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import LocationService from '../../../services/LocationService'
import constant from "../../../constants/constant";


function ClientProfileLocationList(props) {
	const {
		locations,
		onLocationChange,
		multiMode,
		onMultiModeChange,
		onCurrentClassChange,
		currentClasses = [],
		assignAvailable = false,
		updateLocations,
		isAdmin = false,
	} = props
	const [choosedLocations, setChoosedLocations] = useState([])
	const [showDeleted, setShowDeleted] = useState(false)
	const [openFilter, setOpenFilter] = useState(false)
	const [keyword, setKeyword] = useState()
	const [showAllCheck, setShowAllCheck] = useState(false)
	const [allCheckedItems, setAllCheckedItems] = useState([])
	const [existLocations, setExistLocations] = useState([])
	const [existLocationClasses, setExistLocationClasses] = useState(null)
	const filterElement = useRef()

	useEffect(() => {
		setChoosedLocations([])
	}, [multiMode])

	useEffect(() => {
		if (choosedLocations.length > 0) {
			onLocationChange(choosedLocations)
		}
	}, [choosedLocations])

	useEffect(() => {
		if (currentClasses && currentClasses.length > 0) {
			let _exists = []
			let classServicesByLocation = {}
			currentClasses.forEach(i => {
				i.location_links.forEach(_link => {
					classServicesByLocation[_link.client_location_id] = {
						isOverride: _link.isOverride,
						service: _link.service_type_code
					}
					if (_exists.indexOf(_link.client_location_id) == -1) {
						_exists.push(_link.client_location_id)
					}
				})
			})
			setExistLocationClasses(classServicesByLocation)
			setExistLocations(_exists)
			setChoosedLocations([])
		}
	}, [currentClasses])

	function chooseLocation(location) {
		if (multiMode) {
			let _choosedLocations = choosedLocations.map(i => { return i })
			if (choosedLocations.indexOf(location) > -1) {
				_choosedLocations.splice(choosedLocations.indexOf(location), 1)
			} else {
				_choosedLocations.push(location)
			}
			setChoosedLocations(_choosedLocations)
		} else {
			setChoosedLocations([location])
		}
	}

	function switchDropped() {
		setShowDeleted(!showDeleted)
	}

	function updateKeyword(e) {
		setKeyword(e.target.value)
	}

	function swtichAllCheck() {
		setShowAllCheck(!showAllCheck)
		if (showAllCheck == true) {
			setAllCheckedItems([])
		}
	}

	async function changeClassLocationLink(location) {
		try {
			const copyCurrentClass = { ...currentClasses[0] }
			if (existLocations.indexOf(location.client_location_id * 1) == -1) {
				setExistLocations([...existLocations, location.client_location_id * 1])
				const _link = await LocationService.linkLocationClass(currentClasses[0].client_id, location.client_location_id, currentClasses[0].id)
				copyCurrentClass.location_links.push(_link)
			} else {
				setExistLocations(existLocations.filter(id => id !== location.client_location_id * 1))
				const _link = await LocationService.unlinkLocationClass(currentClasses[0].client_id, location.client_location_id, currentClasses[0].id)
				let origin_location_links = currentClasses[0].location_links.filter(item => {
					return item.locationclassid != _link.locationclassid
				})
				copyCurrentClass.location_links = origin_location_links
			}
			if (onCurrentClassChange) {	
				onCurrentClassChange(copyCurrentClass)
			}
		} catch (e) {
		}
	}

	// let filtered_locations = []

	// if (locations && locations.length) {
	// 	console.log(locations, "locations in LocationComponent")
	// 	filtered_locations = locations.filter(item => {
	// 		let name = item.name || item.location_name
	// 		if (keyword && name.toLowerCase().indexOf(keyword.toLowerCase()) == -1) {
	// 			return false
	// 		}
	// 		if (showDeleted) {
	// 			return true
	// 		} else {
	// 			console.log(!item.deleted_at, 'Condition')
	// 			return !item.deleted_at
	// 		}
	// 	})

	// 	if (!showAllCheck && assignAvailable) {
	// 		filtered_locations = filtered_locations.filter(item => {
	// 			return existLocations.indexOf(parseInt(item.client_location_id)) > -1
	// 		})
	// 	}
	// }
	let filtered_locations = [];

if (locations && locations.length) {
    filtered_locations = locations.filter(item => {
        let name = item.name || item.location_name;

        if (item.drop_date && item.drop_date !== "") {
            return false;
        }

        if (keyword && name.toLowerCase().indexOf(keyword.toLowerCase()) === -1) {
            return false;
        }

        if (showDeleted) {
            return true;
        } else {
            return !item.deleted_at;
        }
    });

    if (!showAllCheck && assignAvailable) {
        filtered_locations = filtered_locations.filter(item => {
            return existLocations.indexOf(parseInt(item.client_location_id)) > -1;
        });
    }
}

	return <Box sx={jss.dataGridItem}>
		<Box sx={jss.dataTop}>
			<Box sx={jss.dataTitle}>
				Locations
				{onMultiModeChange &&
					<FormControlLabel
						control={<Switch value={multiMode} size="small" onChange={onMultiModeChange} />}
						label="Multi select"
						sx={{ ml: 2 }}
					/>
				}
			</Box>
		</Box>
		<Box sx={jss.actionSection}>
			<TextField
				size="small"
				variant="outlined"
				placeholder="Search"
				value={keyword}
				onChange={updateKeyword}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
			<Box sx={jss.dataAction}>
				<IconButton size="small" onClick={e => setOpenFilter(true)} ref={filterElement}><FilterListRoundedIcon /></IconButton>
				{!multiMode && assignAvailable && isAdmin ?
					<IconButton size="small" sx={showAllCheck ? { ...jss.iconButtonActive } : {}} onClick={swtichAllCheck}><PlaylistAddCheckIcon /></IconButton>
				: null}
				<Menu
					open={openFilter}
					anchorEl={filterElement.current}
					onClose={e => { setOpenFilter(false) }}
				>
					<MenuItem sx={{ width: 200 }} dense onClick={switchDropped}>
						<ListItemIcon>{showDeleted && <Check />}</ListItemIcon>
						<ListItemText >Dropped</ListItemText>
					</MenuItem>
				</Menu>
			</Box>
		</Box>
		{!locations &&
			<Box sx={jss.loadingSection}>
				<CircularProgress />
			</Box>
		}
		{(locations && (!assignAvailable || currentClasses.length > 0)) &&
			<Box sx={jss.dataContent}>
				{filtered_locations.map(_location => {
					let _className = ''
					if (choosedLocations.map(i => {return i.client_location_id}).indexOf(_location.client_location_id)>-1) {
						_className += ' _active '
					}
					if (_location.deleted_at) {
						_className += ' _deleted'
					}
					if (existLocationClasses
						&& existLocationClasses[_location.client_location_id]
						&& existLocationClasses[_location.client_location_id].isOverride) {
						_className += ' _override';
					}
					return (
						<Box
							sx={jss.dataContentItem}
							className={_className}
							key={`location_item_${_location.client_location_id}`}
						>
							{showAllCheck &&
								<Checkbox
									size="small"
									checked={existLocations.indexOf(_location.client_location_id * 1) > -1}
									onChange={e => changeClassLocationLink(_location)}
								/>
							}
							<Box sx={jss.dataContentItem.name} onClick={e => chooseLocation(_location)}>
								{_location.location_name}
							</Box>
							<Box sx={jss.dataContentItem.action} onClick={e => chooseLocation(_location)}>
								{_location.location_code}
								{existLocationClasses && existLocationClasses[_location.client_location_id] &&
									<Chip label={existLocationClasses[_location.client_location_id].service} size="small" />
								}
							</Box>
						</Box>
					)
				})}
				{assignAvailable && (!filtered_locations || filtered_locations.length == 0) &&
					<Box sx={jss.dataContent.empty}>
						<Box>There are no locations for the selected class.</Box>
						<Box>You can select locations for this class.</Box>
					</Box>
				}
			</Box>
		}
	</Box>
}
export default ClientProfileLocationList