import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Switch,
	CircularProgress,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Chip,
	Menu,
	MenuItem,
	Fade,
	Collapse,
	Alert,
	TextField,
} from '@mui/material';
import constant from '../../../constants/constant'
import _ from 'underscore'
import ClientService from '../../../services/ClientService'
import moment from 'moment'


const jss = {
	tableLabel: {
		fontWeight: 'bolder',
		width: '25%',
		textAlign: 'right'
	},
	tagSection: {
		'.MuiChip-root': {
			mr:1,
			mt:1,
		}
	}
}


function ClassDisplay(props) {
	const [clientClass, setClientClass] = useState()
	const {reloadData=true} = props

	useEffect(() => {
		if( reloadData ) {
			if( props.clientClass ) {
				loadClass()
			}	
		} else {
			setClientClass(props.clientClass)
		}
	}, [props.clientClass])

	async function loadClass() {
		const data = await ClientService.getClientClassById(props.clientClass.client_id, props.clientClass.id, {
			include: ['parentClass', 'locationLinks', 'labels', 'categories', 'warehouseLocation', 'childClasses']
		})
		setClientClass(data)
	}

	if( clientClass ) {
		return <TableContainer>
			<Table>
				<TableBody>
					{clientClass.deleted_at && <TableRow>
						<TableCell sx={jss.tableLabel}>Dropped</TableCell>
						<TableCell sx={{color: 'error.main', fontWeight: 'bold'}}>{moment(clientClass.deleted_at).format('MM-DD-YYYY')}</TableCell>
					</TableRow>}
					<TableRow>
						<TableCell sx={jss.tableLabel}>Service Type</TableCell>
						<TableCell>
							{_.invert(constant.SERVICE_TYPE_ID)[clientClass.service_type_id]}
						</TableCell>
					</TableRow>
					{clientClass.parent_class_id &&
						<TableRow>
							<TableCell sx={jss.tableLabel}>Parent Class</TableCell>
							<TableCell>
								{clientClass.parent_class.name} {clientClass.parent_class.code}
							</TableCell>
						</TableRow>
					}
					{clientClass.child_classes.length>0 &&
						<TableRow>
							<TableCell sx={jss.tableLabel}>Child Classes</TableCell>
							<TableCell>{clientClass.child_classes.map((item, index) => {
								return <Chip key={`clientClass_child_${index}`} label={`${item.name} [${item.code}]`} size="small" sx={{mb:0.5,mr:0.5}}/>
							})}
							</TableCell>
						</TableRow>
					}
	                {clientClass.labels && <TableRow>
						<TableCell sx={jss.tableLabel}>M1 Tags</TableCell>
						<TableCell>{clientClass.labels.map((item, index) => {
							return <Chip key={`clientClass_tag_${index}`} label={item.name} size="small" sx={{mb:0.5,mr:0.5}}/>
						})}</TableCell>
	                </TableRow>}
	                <TableRow>
						<TableCell sx={jss.tableLabel}>Categories</TableCell>
						<TableCell>{clientClass.categories.map((item, index) => {
							return <Chip key={`clientClass_cateogory_${index}`} label={item.Name} size="small" sx={{mb:0.5,mr:0.5}}/>
						})}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell sx={jss.tableLabel}>Warehouse Location</TableCell>
						<TableCell>
							{clientClass.warehouse_location ? clientClass.warehouse_location.location_name : ''}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell sx={jss.tableLabel}>Master Class</TableCell>
						<TableCell>
						{clientClass.master_classes.map(_mc => (
							<Chip key={`clientClass_master_class_${clientClass.id}_${_mc.id}`} label={_mc.name} size="small" sx={{mb:0.5,mr:0.5}}/>
						))}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	} else {
		return <></>
	}
}
export default ClassDisplay