import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import _ from "underscore";
import ClientService from "../services/ClientService";
import { LoadingButton } from "@mui/lab";

function UserPrefClientDefault(props) {
    // Get the stored client and location id stored in local localStorage
    const defaultClientId = localStorage.getItem("defaultClient")??null;
    const defaultLocationId = localStorage.getItem("defaultLocation")??null;

  const {
    open,
  } = props;
  const [clients, setClients] = useState(null);
  const [selectClient, setSelectClient] = useState(null);
  const [selectClientDetail, setSelectClientDetail] = useState(null);
  const [selectLocation, setSelectLocation] = useState(null);

  useEffect(() => {
    if (open) {
      loadClients();
    }
    return () => {
      setClients(null);
      setSelectClient(null);
      setSelectClientDetail(null);
      setSelectLocation(null);
    };
  }, [open]);

  useEffect(() => {
    if (clients && defaultClientId && !selectClient) {
      setSelectClient(_.find(clients, (i) => i.Id == defaultClientId));
    }
  }, [clients]);

  useEffect(() => {
    if (selectClient) {
      loadClientDetail(selectClient.ClientCode);
    }
  }, [selectClient]);

  useEffect(() => {
    if (selectClientDetail) {
      if (defaultLocationId) {
        setSelectLocation(
          _.find(
            selectClientDetail.locations,
            (i) => i.client_location_id == defaultLocationId
          )
        );
      }
    }
  }, [selectClientDetail]);

  const loadClients = async () => {
    const _clients = await ClientService.searchClients();
    setClients(_clients);
  };

  const loadClientDetail = async (client_code) => {
    const detail = await ClientService.getClientByCode(client_code);
    setSelectClientDetail(detail);
  };

  // Save the default client to browser local storage
  const saveDefaultClient = (newValue) => {
    localStorage.setItem("defaultClient", newValue.Id);
    setSelectClient(newValue)
  };
  // Save the default Location to the browser local storage
  const saveDefaultLocation = (newValue) => {
    localStorage.setItem("defaultLocation", newValue.client_location_id);
    setSelectLocation(newValue);
  }

  return (
    <Box sx={css.container}>
      <Typography variant="h6">Select Defaults for "Copy from" Dialog</Typography>
      {!clients && (
        <Box sx={css.loadingSection}>
          <CircularProgress />
        </Box>
      )}
      {_.isArray(clients) && (
        <Autocomplete
          sx={{ mt: 2 }}
          getOptionLabel={(item) => `[${item.ClientCode}] ${item.Name}`}
          options={clients}
          value={selectClient}
          onChange={(e, newValue) => saveDefaultClient(newValue)}
          renderInput={(params) => <TextField {...params} label="Client" />}
          renderOption={(props, option) => (
            <li
              {...props}
              key={`client_copy_${option.Id}`}
            >{`[${option.ClientCode}] ${option.Name}`}</li>
          )}
        />
      )}
      {_.isArray(clients) && selectClient && !selectClientDetail && (
        <Box sx={css.loadingSection}>
          <CircularProgress />
        </Box>
      )}
      {selectClientDetail && (
        <Autocomplete
          getOptionLabel={(item) =>
            `[${item.location_code}] ${item.location_name}`
          }
          options={selectClientDetail.locations}
          value={selectLocation}
          sx={{ mt: 2 }}
          onChange={(e, newValue) => saveDefaultLocation(newValue)}
          renderInput={(params) => <TextField {...params} label="Location" />}
          renderOption={(props, option) => (
            <li {...props} key={`client_location_${option.client_location_id}`}>
              [{option.location_code}] {option.location_name}
            </li>
          )}
        />
      )}

    </Box>
  );
}

export default UserPrefClientDefault;

const css = {
  container: {
    top: "150px",
    px: 3,
    py: 2,
  },
  loadingSection: {
    textAlign: "center",
    mt: 2,
  },
};
