import BaseModel from "./BaseModel"

export const FuturePlanAnnualForm = {
    SalesAnnualPlan: {
        value: '0',
        label: 'Annual Sales', 
        orig: '0',
        current: '0',
        smt: '0',
        isBlur: false,
        chipProps: {}
    },
    MarkdownsAnnualPercentPlan: {
        value: '0',
        label: 'Annual Markdowns',
        orig: '0',
        current: '0',
        isBlur: false,
        chipProps: {}
    },
    TurnPlan: {
        value: '0',
        label: 'Annual Turn',
        orig: '0',
        current: '0',
        isBlur: false,
        chipProps: {}
    },
    ImuPlan: {
        value: '0',
        label: 'Annual IMU',
        orig: '0',
        current: '0',
        imt: '0',
        isBlur: false,
        chipProps: {}
    }
}

export class FuturePlanTableRow extends BaseModel {
    constructor(props) {
        super(props)
    }

    summary;
    avg;
    static _innerData;

    set innerData(innerData = []) {
        const _innerData = innerData.map(({value, current, orig}) => ({value: Number(value), current: Number(current), orig: Number(orig)}))
        this._innerData = _innerData;
        this.summary = _innerData?.slice(0, 12).reduce((temp, item) => temp + item.value * 10000, 0) / 10000;
        this.avg = this.summary / 12
    }

    get innerData() {
        return this._innerData ?? [];
    }
}

const salesData = [
    {
        isActive: true,
        label: 'Plan',
        lastItem: 'sum',
        key: 'SalesPlan',
        sourceKey: 'plandetails',
        displayFloat: false,
    },
    {
        isActive: true,
        isInput: true,
        label: 'Plan %',
        lastItem: 'sum',
        key: 'SalesPercentPlan',
        sourceKey: 'plandetails',
        suffix: '%',
        displayFloat: true,
    },
];

const inventoryData = [
    {
        label: 'Plan S/S',
        lastItem: 'input',
        isActive: true,
        isInput: true,
        key: 'StockToSalesPlan',
        sourceKey: 'plandetails',
        displayFloat: true,
    },
    {
        label: 'Plan Inv BOM',
        lastItem: 'input',
        isActive: true,
        isInput: true,
        key: 'InventoryBomPlan',
        sourceKey: 'plandetails',
        displayFloat: false,
    },
    {
        label: 'Plan Inf Ret',
        lastItem: 'sum',
        isActive: true,
        key: 'InflowPlanRetail',
        sourceKey: 'plandetails',
        displayFloat: false,
    },
    {
        label: 'Plan Inf Cost',
        lastItem: 'sum',
        isActive: true,
        key: 'InflowPlanCost',
        sourceKey: 'plandetails',
        displayFloat: false,
    },
]

const markdownsData = [
    {
        isActive: true,
        label: 'Plan',
        lastItem: 'sum',
        key: 'MarkdownsPlan',
        sourceKey: 'plandetails',
        displayFloat: false,
    },
    {
        isActive: true,
        isInput: true,
        label: 'Plan %',
        lastItem: 'sum',
        key: 'MarkdownsPercentPlan',
        sourceKey: 'plandetails',
        suffix: '%',
        displayFloat: true,
    }
];

export const FuturePlanTableData = [
    {key: 'Sales', data: salesData},
    {key: 'Inventory', data: inventoryData},
    {key: 'Markdowns', data: markdownsData}
]

export const colorMap = {
    'Sales': '#5db85c',
    'Inventory': '#f0ad4e',
    'Markdowns': '#d85350',
}

export const FuturePlanTableDataMap = new Map(FuturePlanTableData.map(item => [item.key, new Map(item.data.map(val => [val.key, new FuturePlanTableRow(val)]))]))
