import {
    useState,
    useEffect,
    useRef
} from "react";
import {Alert, Box, Collapse, Fade, IconButton, Menu, MenuItem, Paper, TextField} from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SectionTitle from "../../common/SectionTitle";
import {LoadingButton} from "@mui/lab";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import ClientService from "../../../services/ClientService";

function ClientCategoryEdit(props) {
    const { category, updateCategory, deletedCategory, isAdmin } = props
    const [open_more, setOpenMore] = useState(false)
    const more_element = useRef()
    const [form_data, setFormData] = useState()
    const [saving, setSaving] = useState()

    useEffect(() => {
        if( category ) {
            setFormData({
                Name: category.Name,
                SortOrder: category.SortOrder,
            })
        }
    }, [category])

    const common_input_props = {
        fullWidth: true,
        variant: "outlined",
        size: "small",
        onChange: e => updateFormData(e),
        sx: {mt:2},
    }

    function updateFormData(e) {
        setFormData({
            ...form_data,
            [e.target.name]: e.target.value
        })
    }

    async function deleteData() {
        const result = await ClientService.deleteCategory(category.Id)
        deletedCategory(category.Id)
    }

    async function saveCategory() {
        const result = await ClientService.updateCategory(category.Id, form_data)
        updateCategory(result)
    }

    return <Paper>
        <SectionTitle
            label={category.Name}
            type="primary"
            rightComponent={
                isAdmin ? 
                    <>
                        <IconButton onClick={e => {setOpenMore(true)}} ref={more_element}>
                            <MoreVertOutlinedIcon sx={{color: 'white'}} />
                        </IconButton>
                        <Menu
                            open={open_more}
                            anchorEl={more_element.current}
                            onClose={e => {setOpenMore(false)}}
                        >
                            <MenuItem dense onClick={deleteData}>Delete Category</MenuItem>
                        </Menu>
                    </>
                : null}
        />
        {
            form_data &&
            <Box sx={{p:2}}>
                <Box>
                    <TextField
                        {...common_input_props}
                        label="Name"
                        name="Name"
                        value={form_data.Name || ''}
                        disabled={!isAdmin}
                    />
                    <TextField
                        {...common_input_props}
                        label="Sort Order"
                        name="SortOrder"
                        value={form_data.SortOrder || ''}
                        disabled={!isAdmin}
                    />
                </Box>
                <Box sx={{mt:2}}>
                    {isAdmin ?
                        <LoadingButton loading={saving} size="large" variant="contained" onClick={saveCategory} startIcon={<SaveTwoToneIcon />}>
                            Save
                        </LoadingButton>
                    : null}
                </Box>
            </Box>

        }
    </Paper>
}

export default ClientCategoryEdit;