import constants from "../constants/constant";
import Request from "./Request";

const PosService = {
	cached_pos: null,
	async getPos() {
		if( !PosService.cached_pos ) {
			const _pods = await Request.get('/api/pos')	
			PosService.cached_pos = _pods
		}
		return PosService.cached_pos
	},

	/**
	 * Download pos mapping for client
	 * @param {*} clientId
	 * @param {*} clientCode
	 * @param {*} advancedDownload - if true, download the advanced mapping (admins only)
	 */
	async downloadPos({ clientId, clientCode, advancedDownload = false }) {
		await Request.download(`/api/clients/${clientId}/download-pos`, {'advanced': advancedDownload ? 1 : 0})
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "client_" + clientCode + "_pos.xlsx");
				document.body.appendChild(link);
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
			})
	},

	/**
	 * Sync departments for client
	 * @param {*} clientId
	 * @returns
	 */
	async syncDepartments(clientId) {
		return await Request.get(`/api/clients/${clientId}/sync-departments`)
	}
}

export default PosService