import React, { createContext, useState } from 'react';

const HotKeysContext = createContext();

const HotKeysProvider = ({ children }) => {

  return (
    <HotKeysContext.Provider>
      {children}
    </HotKeysContext.Provider>
  );
};

export { HotKeysContext, HotKeysProvider };