import {
    useState,
    useEffect,
    useRef
} from "react";
import ImportClass from "./ImportClass";
import TableauService from "../../services/TableauService";
import ClientService from "../../services/ClientService";
import {useParams} from "react-router-dom";
import CurrentClientContext from "../../providers/CurrentClientProvider";

function ClientKPI(props) {
    const currentClient = CurrentClientContext.useClient()
    // const client = currentClient.client
    const { tableau } = window;
    let { client_code, clientId } = useParams()
    const [viz, setViz] = useState(null)
    const url = "https://data.retailorbit.com/trusted/{user_auth_key}/views/PerformanceDashboard/FYTD?:showAppBanner=false&:display_count=n&:showVizHome=n&:toolbar=top&:alerts=n&:subscriptions=n&:origin=viz_share_link";
    const ref = useRef(null)

    useEffect(() => {
		document.title = 'Retail ORBIT: Client KPI'
	}, [])

    useEffect(() => {
        initViz()
    })

    useEffect(() => {
        return () => {
            if (viz) {
                viz.dispose()
            }
        }
    })

    function calculateFiscalStart() {
        let currentEpoch = ClientService.getEpochMonthFromDate(new Date());
        let fiscalDate = new Date(new Date().getFullYear(), parseInt(currentClient.client?.FiscalYearDate.slice(5,7))-1, 2);
        let fiscalEpoch = ClientService.getEpochMonthFromDate(fiscalDate);

        if(currentEpoch > fiscalEpoch) {
            return currentEpoch - (currentEpoch - fiscalEpoch)
        }
        if (currentEpoch < fiscalEpoch) {
            let previousMonth = 12 - fiscalEpoch;
            return currentEpoch - (previousMonth + currentEpoch);
        }
        if (currentEpoch === fiscalEpoch) {
            return currentEpoch - 12;
        }
    }

    function initViz(){
        TableauService.getTableauUserToken().then(res => {
            const fiscal = calculateFiscalStart()
            const token = res.token
            let trustedUrl = url.replace('{user_auth_key}', token)
            let _viz = new tableau.Viz(ref.current, trustedUrl, {
                'height': '95vh',
                'width': '100%',
                'Clientid': clientId,
                'onFirstInteractive': function () {
                    //viz.hide();
                    _viz.getWorkbook().changeParameterValueAsync('Clientid', Number(clientId));
                    _viz.getWorkbook().changeParameterValueAsync('fiscalEpoch', Number(fiscal));
                    _viz.refreshDataAsync().then(success =>  {
                        _viz.show();
                    });

                }
            });

            setViz(_viz)
        })

    }

    return <>
        <div style={{marginTop: 15}} ref={ref}></div>
    </>
}

export default ClientKPI