import Request from './Request'
import cloneDeep from 'lodash.clonedeep';
import _ from 'underscore';

const PlanCalculatorService = {
    _planData: {},
    getSubmitData(planData, annualForm, tableData, actions = null, useLocationClassId = false) {
        const SalesPercentPlan = tableData.get('Sales').get('SalesPercentPlan')['_innerData']
        const StockToSalesPlan = tableData.get('Inventory').get("StockToSalesPlan")['_innerData']
        const InventoryBomPlan = tableData.get('Inventory').get("InventoryBomPlan")['_innerData']
        const MarkdownsPercentPlan = tableData.get("Markdowns").get("MarkdownsPercentPlan")['_innerData']
        const _keys = _.keys(annualForm)
        let salesModelType = 1
        if (annualForm[_keys[0]]?.value === annualForm[_keys[1]]?.chipProps?.lm_plan_annual_sales?.value) {

        }
        switch(annualForm[_keys[0]]?.value) {
            case annualForm[_keys[0]]?.chipProps?.lm_plan_annual_sales?.value:
                salesModelType = 1
                break
            case annualForm[_keys[0]]?.chipProps?.linear_sales?.value:
                salesModelType = 2
                break
            case annualForm[_keys[0]]?.chipProps?.annual_sales_calc_trend?.value:
                salesModelType = 3
                break
            case annualForm[_keys[0]]?.chipProps?.calc_trend_sales_3?.value:
                salesModelType = 4
                break
            default:
                salesModelType = 1
                break
        }
        let requestData = {
            header: {
                annual_sales: annualForm[_keys[0]]?.value,
                annual_markdowns: annualForm[_keys[1]]?.value,
                annual_turn: annualForm[_keys[2]]?.value,
                annual_imu: annualForm[_keys[3]]?.value,
            },
            headerModels: {
                sales_model_type: salesModelType,
                markdown_model_type: annualForm[_keys[1]]?.chipProps?.actual_markdowns?.value === annualForm[_keys[1]]?.value ? 2 : 1,
                turn_model_type: annualForm[_keys[2]]?.chipProps?.actual_turn?.value === annualForm[_keys[2]]?.value ? 2 : 1,
                imu_model_type: annualForm[_keys[3]]?.chipProps?.receiving_imu_all_locations?.value === annualForm[_keys[3]]?.value ? 2 : 1,
            },
            table: {
                SalesPercentPlan: [],
                StockToSalesPlan: [],
                InventoryBomPlan: [],
                MarkdownsPercentPlan: [],
            },
            plan_version: planData.current_version,
            location_class_id: planData.locationclassid,
            epoch_month: planData.EpochMonth,
        }
        if (useLocationClassId) {
            let i = 0
            planData.plandetails.forEach(item => {
                const {plan_epoch_month} = item

                requestData.table.SalesPercentPlan[i] = {
                    epoch_month: plan_epoch_month,
                    new_value: Number(SalesPercentPlan[i].value)
                }
                requestData.table.StockToSalesPlan[i] = {
                    epoch_month: plan_epoch_month,
                    new_value: Number(StockToSalesPlan[i].value)
                }
                requestData.table.InventoryBomPlan[i] = {
                    epoch_month: plan_epoch_month,
                    new_value: Number(InventoryBomPlan[i].value)
                }
                requestData.table.MarkdownsPercentPlan[i] = {
                    epoch_month: plan_epoch_month,
                    new_value: Number(MarkdownsPercentPlan[i].value)
                }
                // requestData.next12mIds[ i ] = item?.LocationClass_forcast_id ?? item?.baseplan_forcast_id
                i++
            })
        } else {
            let epochMonth = planData.plandetails[0].plan_epoch_month
            let offsetMonth = planData.display_months[0].monthNumeric
            for (let i = 1; i <= 12; i++) {
                const index = i + 12 - offsetMonth >= 12 ? i - offsetMonth : i + 12 - offsetMonth
                requestData.SalesPercentPlan[i] = {
                    epoch_month: epochMonth,
                    new_value: Number(SalesPercentPlan[index].value)
                }
                requestData.StockToSalesPlan[i] = {
                    epoch_month: epochMonth,
                    new_value: Number(StockToSalesPlan[index].value)
                }
                requestData.InventoryBomPlan[i] = {
                    epoch_month: epochMonth,
                    new_value: Number(InventoryBomPlan[index].value)
                }
                requestData.MarkdownsPercentPlan[i] = {
                    epoch_month: epochMonth,
                    new_value: Number(MarkdownsPercentPlan[index].value)
                }
                epochMonth++
            }
            requestData.StockToSalesPlan[13] = {
                epoch_month: epochMonth,
                new_value: parseFloat(StockToSalesPlan[12].value)
            }
            requestData.InventoryBomPlan[13] = {
                epoch_month: epochMonth,
                new_value: parseFloat(InventoryBomPlan[12].value)
            }
        }
        if (actions) {
            requestData['action_month'] = actions.month
            requestData['action_year'] = actions.year
            requestData['action_type'] = actions.type
        }
        return requestData
    },
    update13thMonth(tableData) {
        const stockToSalesPlan = tableData.get('Inventory').get('StockToSalesPlan');
        const inventoryBomPlan = tableData.get('Inventory').get('InventoryBomPlan');
        const inventoryBomPlanInnerData = inventoryBomPlan.innerData;
        const stockToSalesPlanInnerData = stockToSalesPlan.innerData;
        inventoryBomPlanInnerData[12].value = inventoryBomPlanInnerData[0]?.value;
        inventoryBomPlanInnerData[12].current = inventoryBomPlanInnerData[0]?.value;
        stockToSalesPlanInnerData[12].value = stockToSalesPlanInnerData[0]?.value;
        stockToSalesPlanInnerData[12].current = stockToSalesPlanInnerData[0]?.value;
        return tableData
    },
    updateInflowRetail(_tableData, annualForm) {
        let total_ifr = 0;
        const salesPlan = _tableData.get('Sales').get('SalesPlan');
        const markdownsPlan = _tableData.get('Markdowns').get('MarkdownsPlan');
        const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
        const inflowPlanRetail = _tableData.get('Inventory').get('InflowPlanRetail');
        const stockToSalesPlan = _tableData.get('Inventory').get('StockToSalesPlan');
        const inflowPlanRetailInnerData = inflowPlanRetail.innerData;

        salesPlan.innerData.forEach((val, index) => {
            const nextIndex = index + 1;
            const sales = val.value;
            const markdowns = markdownsPlan.innerData[index].value;
            const curr_inv = inventoryBomPlan.innerData[index].value;
            const next_inv = inventoryBomPlan.innerData[nextIndex]?.value;
            let infpr = (next_inv - (curr_inv - sales - markdowns)).toFixed(0);
            if (isNaN(infpr)) {
                inflowPlanRetailInnerData[index].value = 0;
            } else {
                if (infpr < 0) {
                    inflowPlanRetailInnerData[index].value = +infpr;
                } else {
                    inflowPlanRetailInnerData[index].value = +infpr;
                    total_ifr += (+infpr);
                }
            }
        })
        inflowPlanRetail.innerData = inflowPlanRetailInnerData;

        // console.log(total_ifr)
        const per_thresh = .005; // This is the percentage threshold to remove inflow.
        let threshold = total_ifr * per_thresh;
        inflowPlanRetail.innerData.forEach((val, index) => {
            const prevIndex = index - 1;
            const nextIndex = index + 1;
            let cifr = val.value;
            if (cifr < threshold && cifr > 0) {
                const inventoryBomPlanInnerData = inventoryBomPlan.innerData;
                let prev_inv = inventoryBomPlanInnerData[prevIndex]?.value;
                if (prev_inv > 0) {
                    let extra_ifr = cifr;
                    for (let x = index + 1; x < 12; x++) {
                        let next_ifr = inflowPlanRetail.innerData[x]?.value;
                        if (next_ifr < threshold && next_ifr > 0) {
                            extra_ifr += next_ifr
                        } else {
                            break;
                        }
                    }
                    let curr_inv = inventoryBomPlanInnerData[index].value;
                    curr_inv += extra_ifr;
                    inventoryBomPlanInnerData[index].value = curr_inv;
                    inventoryBomPlanInnerData[index].current = curr_inv;
                } else {
                    let next_inv = inventoryBomPlanInnerData[nextIndex]?.value;
                    if (next_inv > cifr) {
                        next_inv -= cifr;
                        inventoryBomPlanInnerData[nextIndex].value = next_inv;
                        inventoryBomPlanInnerData[nextIndex].current = next_inv;
                    }
                }
                inventoryBomPlan.innerData = inventoryBomPlanInnerData;
                PlanCalculatorService.updateStockToSales(_tableData)
                // PlanCalculatorService.updateInflowRetail(_tableData, annualForm)
                PlanCalculatorService.updateInflowCost(_tableData, annualForm)
            }
        })

        cloneDeep(inflowPlanRetail.innerData).reverse().forEach((val, ind) => {
            const index = inflowPlanRetail.innerData.length - 1 - ind;
            const prevIndex = index - 1;
            const nextIndex = index + 1;
            let new_ifr = inflowPlanRetail.innerData[index].value;
            let next_ifr = inflowPlanRetail.innerData[nextIndex]?.value;
            let next_ss = stockToSalesPlan.innerData[nextIndex]?.value;
            const inventoryBomPlanInnerData = inventoryBomPlan.innerData;
            if (next_ifr < 0 && next_ifr == 0) {
                new_ifr = Math.abs(new_ifr);
                for (let x = index; x >= 0; x--) {
                    let prev_inv = inflowPlanRetail.innerData[x]?.value;
                    if (prev_inv > new_ifr) {
                        let new_inv = prev_inv - new_ifr;
                        inventoryBomPlanInnerData[x].value = new_inv;
                        inventoryBomPlanInnerData[x].current = new_inv;
                        break;
                    } else {
                        new_ifr -= prev_inv;
                        inventoryBomPlanInnerData[x].value = 0;
                        inventoryBomPlanInnerData[x].current = 0;
                    }
                }
                inventoryBomPlan.innerData = inventoryBomPlanInnerData;
                PlanCalculatorService.updateStockToSales(_tableData)
                PlanCalculatorService.updateInflowRetail(_tableData, annualForm)
                PlanCalculatorService.updateInflowCost(_tableData, annualForm)
            }
        })
        return _tableData
    },
    updateStockToSales(_tableData) {
        const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
        const stockToSalesPlan = _tableData.get('Inventory').get('StockToSalesPlan');
        const stockToSalesPlanVsHistorical = _tableData.get('Inventory').get('radio_plan_vs_historical')?.innerData;
        const salesPlan = _tableData.get('Sales').get('SalesPlan');
        const stockToSalesPlanInnerData = stockToSalesPlan.innerData;
        inventoryBomPlan.innerData.forEach((val, index) => {
            const inv = val.value;
            let sales = 0;
            if (index == 12) {
                sales = salesPlan.innerData[0].value;
            } else {
                sales = salesPlan.innerData[index].value;
            }
            if (sales > 0 && inv > 0) {
                const value = (inv / sales).toFixed(2);
                stockToSalesPlanInnerData[index].value = +value;
                stockToSalesPlanInnerData[index].current = +value;
            } else {
                stockToSalesPlanInnerData[index].value = 0;
                stockToSalesPlanInnerData[index].current = 0;
            }
            if (stockToSalesPlanVsHistorical) {
                stockToSalesPlanVsHistorical[index].value = PlanCalculatorService._planData.plandetails[index].avg_stock_to_sales_plan ? (stockToSalesPlanInnerData[index].value / PlanCalculatorService._planData.plandetails[index].avg_stock_to_sales_plan * 100).toFixed(2) : 0
            }
        })
        stockToSalesPlan.innerData = stockToSalesPlanInnerData;
        return _tableData
    },
    updateInflowCost(_tableData, annualForm) {
        const _keys = _.keys(annualForm)
        const imu = annualForm[_keys[3]]?.value;
        const inflowPlanCost = _tableData.get('Inventory').get('InflowPlanCost');
        const salesPlan = _tableData.get('Sales').get('SalesPlan');
        const inflowPlanRetail = _tableData.get('Inventory').get('InflowPlanRetail');
        const inflowPlanCostInnerData = inflowPlanCost.innerData;
        salesPlan.innerData.forEach((val, index) => {
            const nextIndex = index + 1;
            let infpr = inflowPlanRetail.innerData[index].value;
            if (isNaN(infpr)) {
                inflowPlanCostInnerData[index].value = 0;
            } else {
                let infpc = (infpr * (1 - (imu / 100))).toFixed(0);
                inflowPlanCostInnerData[index].value = +infpc;
            }
        })
        inflowPlanCost.innerData = inflowPlanCostInnerData;
        return _tableData
    },
    balanceInflow(_tableData, form) {
        const inflowPlanRetail = _tableData.get('Inventory').get('InflowPlanRetail');
        const inflowPlanCost = _tableData.get('Inventory').get('InflowPlanCost');
        const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
        const inflowPlanRetailInnerData = inflowPlanRetail.innerData;
        const inventoryBomPlanInnerData = inventoryBomPlan.innerData;
        const inflowPlanCostInnerData = inflowPlanCost.innerData;
        inflowPlanRetailInnerData.forEach((val, index) => {
            const nextIndex = index + 1;
            const ifr = val.value;
            const ifc = inflowPlanCostInnerData[index].value;
            if (ifr < 0) {
                let abs_ifr = Math.abs(ifr);
                inflowPlanRetailInnerData[index].value = 0;
                let inv = inventoryBomPlanInnerData[nextIndex]?.value;
                inv = inv + abs_ifr;
                if (!isNaN(inv)) {
                    inventoryBomPlanInnerData[nextIndex].value = inv;
                }
                let nifr = inflowPlanRetailInnerData[nextIndex]?.value;
                let newnifr = nifr - abs_ifr;
                if (!isNaN(newnifr)) {
                    inflowPlanRetailInnerData[nextIndex].value = newnifr;
                }
            }
            if (ifc < 0) {
                inflowPlanCostInnerData[index].value = 0
            }
        })
        PlanCalculatorService.updateStockToSales(_tableData);
        PlanCalculatorService.updateInflowRetail(_tableData, form);
        PlanCalculatorService.updateInflowCost(_tableData, form);
        PlanCalculatorService.updateTurn(_tableData, form);
    },
    updateTurn(_tableData, _annualForm) {
        const _keys = _.keys(_annualForm)
        const salesPlan = _tableData.get('Sales').get('SalesPlan');
        const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
        const total_sales = salesPlan.summary;
        const total_inv = inventoryBomPlan.summary;
        let turn = 0;
        if (total_inv > 0) {
            turn = +(total_sales / (total_inv / 12)).toFixed(2)
        }
        _annualForm[_keys[2]]['value'] = turn;
        _annualForm[_keys[2]]['current'] = turn;
    },
    getKFlow(_tableData, _annualForm) {
        const _keys = _.keys(_annualForm)
        const annualSalesAmt = _annualForm[_keys[0]]?.value;
        const annualTurn = _annualForm[_keys[2]]?.value;
        if (annualSalesAmt > 0 && annualTurn > 0) {
            const periods = +(12 / annualTurn).toFixed(2);
            const t_p = +(periods * 12).toFixed(2);
            const avg_ss = +(t_p / 12).toFixed(2);
            const turned_inv = +(annualSalesAmt * avg_ss).toFixed(0);
            const avg_inv = +(turned_inv / 12).toFixed(0);
            const avg_sales_per = +(100 / 12).toFixed(2);
            const salesPercentPlan = _tableData.get('Sales').get('SalesPercentPlan');
            const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
            const inventoryBomPlanInnerData = inventoryBomPlan.innerData;
            let total_sales_deviation = 0;
            salesPercentPlan.innerData.forEach((val, index) => {
                const sales_per = val.value;
                if (sales_per - avg_sales_per > 0) {
                    total_sales_deviation += (sales_per - avg_sales_per);
                }
            })

            salesPercentPlan.innerData.forEach((val, index) => {
                const sales_per = val.value;
                const sales = +((sales_per / 100) * annualSalesAmt).toFixed(0);
                const sales_dev = +(sales_per - avg_sales_per).toFixed(2);
                const dev_weight = +(sales_dev / total_sales_deviation).toFixed(2);
                const bom_adj = +(dev_weight * avg_inv).toFixed(0);
                const inv = +(sales * avg_ss).toFixed(0)
                const new_inv = inv - bom_adj;
                inventoryBomPlanInnerData[index].value = new_inv;
                inventoryBomPlanInnerData[index].current = new_inv;
            })
            inventoryBomPlan.innerData = inventoryBomPlanInnerData;

            PlanCalculatorService.update13thMonth(_tableData);
            PlanCalculatorService.updateStockToSales(_tableData);
            PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
            PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
            PlanCalculatorService.balanceInflow(_tableData, _annualForm);
        } else {
            alert("K-Flow Requires Annual Sales and Annual Turn to be set.");
        }
    }
}

export default PlanCalculatorService