import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
} from "react-router-dom";
import {
	Box,
	Paper,
	Skeleton,
	IconButton,
	TextField,
	FormGroup,
	FormControl,
	Checkbox,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	TableSortLabel,
	Typography,
	Button,
	Menu,
	MenuList,
	MenuItem,
	Alert,
	Grid,
	InputAdornment,
	Fade,
	Collapse,
} from '@mui/material';
import {
	LoadingButton
} from '@mui/lab'
import constants from '../../constants/constant'
import UserService from '../../services/UserService'
import AuthService from '../../services/AuthService'
import PosService from '../../services/PosService'
import SectionTitle from '../../components/common/SectionTitle'
import _ from 'underscore'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import moment from 'moment'
import TipContext from '../../providers/GlobalTip'
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import DialpadOutlinedIcon from '@mui/icons-material/DialpadOutlined';
import FaxOutlinedIcon from '@mui/icons-material/FaxOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import StormOutlinedIcon from '@mui/icons-material/StormOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import AuthContext from '../../providers/AuthProvider'
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import KeyboardCommandKeyRoundedIcon from '@mui/icons-material/KeyboardCommandKeyRounded';

const jss = {
	tableSection: {
		p: 2,
		pt: 0,
	},
	tableLabel: {
		fontWeight: 'bolder',
		width: '20%',
		textAlign: 'right'
	},
	editTitle: {
		fontSize: 16,
		fontWeight: 'bolder',
		mt: 3,
	},
	refreshLink: {
		fontSize: 10,
		mt: 0.5,
		color: 'primary.main',
		cursor: 'pointer',
	}
}

function UserProfile(props) {
	const user = props.user
	const more_element = useRef()
	const [pos, setPos] = useState([])
	const [open_more, setOpenMore] = useState(false)
	const tipContext = TipContext.useMessage()
	const [editing, setEditing] = useState(false)
	const [has_change, setHasChange] = useState(false)
	const auth_context = AuthContext.useAuth()
	const [form_data, setFormData] = useState({
		email: '',
		first_name: '',
		last_name: '',
		company_name: '',
		phone: '',
		mobile: '',
		fax: '',
		skype: '',
		website: '',
		twitter: '',
		facebook: '',
		address_1: '',
		address_2: '',
		city: '',
		state: '',
		zipcode: '',
		province: '',
		country: '',
		pod_id: '',
		client_prefix: '',
		notes: '',
		eula_signed_date: '',
		activation_key: '',
		auth_token: '',
	})
	const [saving, setSaving] = useState(false)

	useEffect(() => {
		if( !_.isEmpty(user) ) {
			setFormData({
				email: user.email,
				first_name: user.first_name,
				last_name: user.last_name,
				company_name: user.company_name,
				phone: user.phone,
				mobile: user.mobile,
				fax: user.fax,
				skype: user.skype,
				website: user.website,
				twitter: user.twitter,
				facebook: user.facebook,
				address_1: user.address_1,
				address_2: user.address_2,
				city: user.city,
				state: user.state,
				zipcode: user.zipcode,
				province: user.province,
				country: user.country,
				pod_id: user.pod_id,
				client_prefix: user.client_prefix,
				notes: user.notes,
				eula_signed_date: user.eula_signed_date,
				activation_key: user.activation_key,
				auth_token: user.auth_token,
			})
		}
	}, [user])

	useEffect(() => {
		loadPos()
	}, [])

	async function loadPos() {
		let _pos = await PosService.getPos()
		setPos(_pos)
	}

	function handleCloseMore() {
		setOpenMore(false)
	}

	async function deleteUser() {
		try {
			await UserService.deleteById(user.id)
			setOpenMore(false)
			props.onChange({
				...user,
				deleted_at: moment().format('YYYY-MM-DD HH:mm:ss')
			})
			tipContext.showSuccess('Delete successfully')
		} catch(e) {
			tipContext.show(e.response?.data.message)
		}
	}

	async function undeleteUser() {
		try {
			await UserService.undeleteById(user.id)
			setOpenMore(false)
			props.onChange({
				...user,
				deleted_at: null
			})
			tipContext.showSuccess('Restore successfully')
		} catch(e) {
			tipContext.show(e.response?.data.message)
		}
	}

	function updateFormData(e) {
		setFormData({
			...form_data,
			[e.target.name]: e.target.value
		})
		if( !has_change ) {
			setHasChange(true)
		}
	}

	async function refreshActivationKey() {
		try {
			const key = await UserService.resetActivationKey(user.id)
			setFormData({
				...form_data,
				auth_token: key.AuthKey
			})
		} catch(e) {
		}
	}

	const common_input_props = {
		fullWidth: true, 
		variant: "outlined",
		size: "small",
		onChange: e => updateFormData(e),
		sx: {mt:2},
	}

	async function saveUser() {
		try {
			setSaving(true)
			const _user = await UserService.updateUserById(user.id, form_data)
			tipContext.showSuccess('User saved')
			setEditing(false)
			setHasChange(false)
			setSaving(false)
			props.onChange(_user)
		} catch(e) {
			setSaving(false)
			tipContext.show(e.response?.data.message)
		}
	}

	async function sendResetPasswordEmail() {
		handleCloseMore()
		try {
			const send = await UserService.sendResetPasswordEmail(user.id)
			tipContext.showSuccess('Email sent successfully')	
		} catch(e) {
			tipContext.show(e.response?.data.message)
		}
	}

	async function handleSpoofUser() {
		handleCloseMore()
		try {
			await auth_context.spoofUser(user.id)
			tipContext.showSuccess('Successful operation')
		} catch(e) {
			tipContext.show(e.response?.data.message)
		}
	}

	return <>
		{!_.isEmpty(user) && 
			<Paper elevation={2}>
				<SectionTitle 
					label={user.name} 
					subLabel={!user.deleted_at ? user.email : 'DELETED'} 
					type="primary"
					rightComponent={
						<>
							{(auth_context.checkPermission('user-update') && !editing) && 
							<IconButton onClick={e => setEditing(true)}>
								<ModeEditOutlineOutlinedIcon sx={{color: 'white'}}/>
							</IconButton>
							}
							{(auth_context.checkPermission('user-update') && editing) && 
							<IconButton onClick={e => setEditing(false)}>
								<KeyboardArrowUpOutlinedIcon sx={{color: 'white'}}/>
							</IconButton>
							}
							<IconButton onClick={e => {setOpenMore(true)}} ref={more_element}>
								<MoreVertOutlinedIcon sx={{color: 'white'}} />
							</IconButton>
							<Menu
								open={open_more}
								anchorEl={more_element.current}
								onClose={e => {setOpenMore(false)}}
							>
								<MenuItem dense onClick={sendResetPasswordEmail}>Reset Password</MenuItem>
								{auth_context.checkPermission('user-spoof') &&
									<MenuItem dense onClick={handleSpoofUser}>Spoof User</MenuItem>
								}
								{auth_context.checkPermission('user-delete') && user.deleted_at && 
									<MenuItem dense onClick={undeleteUser}>Undelete User</MenuItem>
								}
								{auth_context.checkPermission('user-delete') && !user.deleted_at && 
									<MenuItem dense onClick={deleteUser}>Delete User</MenuItem>
								}		
							</Menu>
						</>
					}
				/>
				<Box sx={jss.tableSection}>
					{!editing && 
					<TableContainer>
						<Table>
							<TableBody>
								{user.deleted_at && <TableRow>
									<TableCell sx={jss.tableLabel}>Dropped</TableCell>
									<TableCell sx={{color: 'error.main', fontWeight: 'bold'}}>{moment(user.deleted_at).format('MM-DD-YYYY')}</TableCell>
								</TableRow>}
								<TableRow>
									<TableCell sx={jss.tableLabel}>Name</TableCell>
									<TableCell >{user.name}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={jss.tableLabel}>Email</TableCell>
									<TableCell >{user.email}</TableCell>
								</TableRow>
								{user.phone && <TableRow>
									<TableCell sx={jss.tableLabel}>Phone</TableCell>
									<TableCell>{user.phone}</TableCell>
								</TableRow>}
								{user.mobile && <TableRow>
									<TableCell sx={jss.tableLabel}>Mobile</TableCell>
									<TableCell>{user.mobile}</TableCell>
								</TableRow>}
								{user.skype && <TableRow>
									<TableCell sx={jss.tableLabel}>Skype</TableCell>
									<TableCell>{user.skype}</TableCell>
								</TableRow>}
								{user.fax && <TableRow>
									<TableCell sx={jss.tableLabel}>Fax</TableCell>
									<TableCell>{user.fax}</TableCell>
								</TableRow>}
								{user.website && <TableRow>
									<TableCell sx={jss.tableLabel}>Website</TableCell>
									<TableCell>{user.website}</TableCell>
								</TableRow>}
								{user.facebook && <TableRow>
									<TableCell sx={jss.tableLabel}>Facebook</TableCell>
									<TableCell>{user.facebook}</TableCell>
								</TableRow>}
								{(user.title
								|| user.address_1
								|| user.address_2
								|| user.city
								|| user.state
								|| user.zipcode
								|| user.province
								|| user.country) && 
								<TableRow>
									<TableCell sx={jss.tableLabel}>Address</TableCell>
									<TableCell>
										{user.title && <>{user.title} <br /></>}
										{user.address_1 && <>{user.address_1} <br /></>}
										{user.address_2 && <>{user.address_2} <br /></>}
										{user.city && <>{user.city} <br /></>}
										{user.state && <>{user.state} <br /></>}
										{user.zipcode && <>{user.zipcode} <br /></>}
										{user.province && <>{user.province} <br /></>}
										{user.country && <>{user.country} <br /></>}
									</TableCell>
								</TableRow>}
								{(user.pod_id && pos) && <TableRow>
									<TableCell sx={jss.tableLabel}>Pod</TableCell>
									<TableCell>{user.getMyPodName(pos)}</TableCell>
								</TableRow>}
								{user.is_pod_leader!=0 && 
								<TableRow>
									<TableCell sx={jss.tableLabel}>Is Pod Leader</TableCell>
									<TableCell>{user.is_pod_leader}</TableCell>
								</TableRow>
								}
							</TableBody>
						</Table>
					</TableContainer>
					}
					{editing && 
					<Fade timeout={500} in>
						<Box>
							<Collapse in={has_change}>
								<Alert sx={{mt:2}} severity="warning">You have unsaved changes, please hit save before continuing.</Alert>
							</Collapse>
							<Box sx={jss.editTitle}>General</Box>
							<TextField 
								{...common_input_props}
								label="Email" 
								name="email"
								value={form_data.email || ''}
								InputProps={{startAdornment: <InputAdornment position="start"><EmailOutlinedIcon /></InputAdornment>}}
							/>

							<Box sx={jss.editTitle}>Contact details</Box>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField 
										{...common_input_props}
										label="First Name" 
										name="first_name"
										value={form_data.first_name || ''}
										InputProps={{startAdornment: <InputAdornment position="start"><BadgeOutlinedIcon /></InputAdornment>}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField 
										{...common_input_props}
										label="Last Name" 
										name="last_name"
										value={form_data.last_name || ''}
										InputProps={{startAdornment: <InputAdornment position="start"><BadgeOutlinedIcon /></InputAdornment>}}
									/>
								</Grid>
							</Grid>
							<TextField 
								{...common_input_props}
								label="Company Name" 
								name="company_name"
								value={form_data.company_name || ''}
								InputProps={{startAdornment: <InputAdornment position="start"><BusinessOutlinedIcon /></InputAdornment>}}
							/>
							<TextField 
								{...common_input_props}
								label="Phone" 
								name="phone"
								value={form_data.phone || ''}
								InputProps={{startAdornment: <InputAdornment position="start"><LocalPhoneOutlinedIcon /></InputAdornment>}}
							/>
							<TextField 
								{...common_input_props}
								label="Mobile" 
								name="mobile"
								value={form_data.mobile || ''}
								InputProps={{startAdornment: <InputAdornment position="start"><DialpadOutlinedIcon /></InputAdornment>}}
							/>
							<TextField 
								{...common_input_props}
								label="Fax" 
								name="fax"
								value={form_data.fax || ''}
								InputProps={{startAdornment: <InputAdornment position="start"><FaxOutlinedIcon /></InputAdornment>}}
							/>
							<TextField 
								{...common_input_props}
								label="Skype" 
								name="skype"
								value={form_data.skype || ''}
								InputProps={{startAdornment: <InputAdornment position="start"><LocalPhoneOutlinedIcon /></InputAdornment>}}
							/>
							<TextField 
								{...common_input_props}
								label="Website" 
								name="website"
								value={form_data.website || ''}
								InputProps={{startAdornment: <InputAdornment position="start"><LanguageOutlinedIcon /></InputAdornment>}}
							/>
							<TextField 
								{...common_input_props}
								label="Twitter" 
								name="twitter"
								value={form_data.twitter || ''}
								InputProps={{startAdornment: <InputAdornment position="start"><StormOutlinedIcon /></InputAdornment>}}
							/>
							<TextField 
								{...common_input_props}
								label="Facebook" 
								name="facebook"
								value={form_data.facebook || ''}
								InputProps={{startAdornment: <InputAdornment position="start"><FacebookOutlinedIcon /></InputAdornment>}}
							/>

							<Box sx={jss.editTitle}>Primary address</Box>
							<TextField 
								{...common_input_props}
								label="Address 1" 
								name="address_1"
								value={form_data.address_1 || ''}
								InputProps={{startAdornment: <InputAdornment position="start"><LocationCityOutlinedIcon /></InputAdornment>}}
							/>
							<TextField 
								{...common_input_props}
								label="Address 2" 
								name="address_2"
								value={form_data.address_2 || ''}
								InputProps={{startAdornment: <InputAdornment position="start"><LocationCityOutlinedIcon /></InputAdornment>}}
							/>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField 
										{...common_input_props}
										label="City" 
										name="city"
										value={form_data.city || ''}
										InputProps={{startAdornment: <InputAdornment position="start"><MapOutlinedIcon /></InputAdornment>}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField 
										{...common_input_props}
										label="State" 
										name="state"
										value={form_data.state || ''}
										InputProps={{startAdornment: <InputAdornment position="start"><FmdGoodOutlinedIcon /></InputAdornment>}}
										select
										SelectProps={{native: true}}
									>
									<option value="">Select state</option>
									{constants.STATES.map(item => {
										return <option key={`state_option_${item}`} value={item} >{item}</option>
									})}
									</TextField>
								</Grid>
							</Grid>
							<TextField 
								{...common_input_props}
								label="Zipcode" 
								name="zipcode"
								value={form_data.zipcode || ''}
								InputProps={{startAdornment: <InputAdornment position="start"><DialpadOutlinedIcon /></InputAdornment>}}
							/>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField 
										{...common_input_props}
										label="Province" 
										name="province"
										value={form_data.province || ''}
										InputProps={{startAdornment: <InputAdornment position="start"><MapOutlinedIcon /></InputAdornment>}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField 
										{...common_input_props}
										label="Country" 
										name="country"
										value={form_data.country || ''}
										InputProps={{startAdornment: <InputAdornment position="start"><PublicOutlinedIcon /></InputAdornment>}}
									/>
								</Grid>
							</Grid>

							{auth_context.checkPermission('user-admin-update') && 
							<>
								<Box sx={jss.editTitle}>Admin</Box>
								<TextField 
									{...common_input_props}
									label="Pod" 
									name="pod_id"
									value={form_data.pod_id || ''}
									select
									SelectProps={{native: true}}
									InputProps={{startAdornment: <InputAdornment position="start"><PodcastsIcon /></InputAdornment>}}
								>
									<option value="">Select Pod</option>
									{pos.map(item => {
										return <option value={item.id} key={`pod_item_${item.id}`}>{item.name}</option>
									})}
								</TextField>
								<TextField 
									{...common_input_props}
									label="Client Prefix" 
									name="client_prefix"
									value={form_data.client_prefix || ''}
									InputProps={{startAdornment: <InputAdornment position="start"><ArticleOutlinedIcon /></InputAdornment>}}
								/>
								<TextField 
									{...common_input_props}
									label="General Notes" 
									name="notes"
									value={form_data.notes || ''}
									multiline
									minRows={3}
									InputProps={{startAdornment: <InputAdornment position="start"><EventNoteOutlinedIcon /></InputAdornment>}}
								/>
								<TextField 
									{...common_input_props}
									label="Signed Eula" 
									name="eula_signed_date"
									value={form_data.eula_signed_date || ''}
									InputProps={{disabled:true, startAdornment: <InputAdornment position="start"><EventOutlinedIcon /></InputAdornment>}}
								/>
								<TextField 
									{...common_input_props}
									label="Key" 
									name="activation_key"
									value={form_data.activation_key || ''}
									InputProps={{disabled:true, startAdornment: <InputAdornment position="start"><KeyOutlinedIcon /></InputAdornment>}}
								/>
								<TextField 
									{...common_input_props}
									label="Token" 
									name="auth_token"
									value={form_data.auth_token || ''}
									InputProps={{readOnly:true, startAdornment: <InputAdornment position="start"><KeyboardCommandKeyRoundedIcon /></InputAdornment>}}
								/>
								<Typography onClick={refreshActivationKey} sx={jss.refreshLink} variant="body2">Refresh Token</Typography>
							</>
							}

							<Box sx={{mt:2}}>
								<LoadingButton loading={saving} size="large" variant="contained" onClick={saveUser} startIcon={<SaveTwoToneIcon />}>
									Save User
								</LoadingButton>
							</Box>

						</Box>
					</Fade>
					}
				</Box>
			</Paper>
		}
		{_.isEmpty(user) && 
			<Paper elevation={2}>
				<SectionTitle 
				 	label="Loading"
					type="primary"
					rightComponent={
						<IconButton>
							<MoreVertOutlinedIcon sx={{color: 'white'}}/>
						</IconButton>
					}
				/>
				<Box sx={jss.tableSection}>
					<Skeleton sx={{mt: 1.5}} />
					<Skeleton sx={{mt: 1.5}} />
					<Skeleton sx={{mt: 1.5}} />
					<Skeleton sx={{mt: 1.5}} />
					<Skeleton sx={{mt: 1.5}} />
				</Box>
			</Paper>
		}
	</>
}

export default UserProfile