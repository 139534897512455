import * as React from 'react';
import {
	AppBar,
	Box,
	IconButton,
	Divider,
	Button,
	Typography,
	Backdrop,
	Fade,
	TextField,
	InputAdornment,
	Collapse,
	CircularProgress,
} from "@mui/material"
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ClientService from '../../services/ClientService'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import constants from '../../constants/constant'
import ClientContext from '../../providers/CurrentClientProvider'
import _ from 'underscore'
import AuthContext from '../../providers/AuthProvider'

const cssInJs = {
	clientsSection: {
		borderLeft: '1px solid #5c3f75',
	    borderRight: '1px solid #5c3f75',
	    background: 'linear-gradient(transparent, rgba(0,0,0,0.25))',
	    boxSizing: 'border-box',
	    position: 'relative',
	    width: "350px",
	    px: 2,
	},
	currentClient: {
		display: 'flex',
		justifyContent: 'space-between',
	    cursor: 'pointer',
		alignItems: 'center',
		height: '50px',
		lineHeight: '18px',
		'._reverse': {
			transform: 'rotate(180deg)'
		},
	},

	downIcon: {
		transition: 'all 0.3s',
	},
	clientDropdown: {
		width: "348px",
		top: '50px',
		left: 0,
		position: 'absolute',
		backgroundColor: '#3d2a4d',
		maxHeight: '390px',
		zIndex:1,
		transition: 'all 0.3s',
		p:2,
		boxShadow: '0px 3px 8px rgb(0 0 0 / 25%)',
	},
	searchInput: {
		
		color: 'text.primary'
	},
	moreButton: {
		cursor:'pointer',
		my:1,
		fontSize: 12,
		":hover": {textDecoration: 'underline'}
	},
	divider: {
		borderColor: '#6c4a89',
		borderStyle: 'dotted'
	},
	allClientSection: {
		maxHeight: '290px',
		overflowY: 'auto',
	},
	allClientItem: {
		lineHeight: '24px',
		cursor: 'pointer',
		fontSize: 12,
		":hover": {textDecoration: 'underline'}
	},
}

function ClientSelect(props) {
	const [open, setOpen] = React.useState(false)
	const [clients, setClients] = React.useState()
	const [loading, setLoading] = React.useState(true)
	const [loaded, setLoaded] = React.useState(false)
	const [keyword, setKeyword] = React.useState('')
	const [show_all, setShowAll] = React.useState(false)
	const navigate = useNavigate()
	const client_context = ClientContext.useClient();
	const auth = AuthContext.useAuth()

	React.useEffect(() => {
		if (auth.user) {
			loadClients();	
		}
	}, [auth.user])

	function handleClose() {
		setOpen(false)
	}

	function switchOpen() {
		setOpen(!open)
	}

	async function loadClients() {
		if (loaded) {
			return;
		}
		const _clients = await ClientService.searchClients()
		setClients(_clients);
		setLoading(false);
		setLoaded(true)
	}

	function chooseClient(client) {
		// window.parent.location.href = `${constants.RETAILORBIT_DOMAIN}/#/client/${client.ClientCode}`
		handleClose()
		client_context.setClient(client)
		navigate('/')
	}

	/**
	 * Unselects currently selected client
	 */
	function unselectClient() {
		client_context.setClient({})
		handleClose()
		navigate('/')
	}

	return <>
		<Box sx={cssInJs.clientsSection}>
			<Box sx={cssInJs.currentClient} onClick={switchOpen} id={'client-select'}>
				{!_.isEmpty(client_context.client) && 
					<Box>{client_context.client.Name} [{client_context.client.ClientCode}]</Box>
				}
				{_.isEmpty(client_context.client) && 
					<Box>Select a client</Box>
				}
				<KeyboardArrowDownOutlinedIcon sx={cssInJs.downIcon} className={open ? "_reverse" : ""}/>
			</Box>
			<Fade in={open}>
				<Box sx={cssInJs.clientDropdown}>
					<TextField 
						size="small"
						fullWidth
						sx={cssInJs.searchInput}
						placeholder="Search clients..."
						onChange={e => {setKeyword(e.target.value); setShowAll(true)}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchRoundedIcon />
								</InputAdornment>
							),
							sx: {
								backgroundColor: 'white',
							}
						}}
					/>
					<Box sx={cssInJs.moreButton} onClick={unselectClient} id={'unselect-client'}>Unselect Current Client</Box>
					<Box sx={cssInJs.moreButton} onClick={e => setShowAll(true)} id={'open-clients'}>Show all</Box>
					<Divider sx={cssInJs.divider}/>
					{loading && (
						<CircularProgress sx={{mt:2}} color="inherit"  />
					)}
					{clients && 
						<Collapse in={show_all}>
							<Box sx={cssInJs.allClientSection} id={'client-list'}>
								{clients.map(item => {
									if( 
										!keyword 
										|| (
											keyword 
											&& (item.Name.toLowerCase().indexOf(keyword.toLowerCase())>-1 || item.ClientCode.toLowerCase().indexOf(keyword.toLowerCase())>-1) 
											&& item.ClientCode 
										)
									)
									{
										return <Box key={`client_${item.Id}`} sx={cssInJs.allClientItem} onClick={e => chooseClient(item)}>{item.ClientCode} - {item.Name}</Box>
									}
								})}
							</Box>
						</Collapse>
					}
					
				</Box>
			</Fade>
		</Box>
		<Backdrop 
			open={open}
			sx={{top: '50px'}}
			onClick={handleClose}
		></Backdrop>
	</>
}
export default ClientSelect