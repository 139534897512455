import {useState, useEffect} from 'react'
import {
	TextField,
	Button,
	Box,
} from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { LoadingButton } from '@mui/lab'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';


const jss = {
	formControl: {
		mb:2,
	}
}


function CreatePermissionModal(props) {
	const [name, setName] = useState()
	const [slug, setSlug] = useState()
	const [roles, setRoles] = useState([])

	function handleClose() {
		props.onClose()
	}

	function submit() {

	}

	return <Dialog
		onClose={handleClose}
        open={props.open}
        fullWidth
        maxWidth="xs"
	>
		<DialogTitle>
			Create Permission
		</DialogTitle>
		<DialogContent dividers>
			<Box sx={jss.formControl}>
				<TextField 
					label="Name"
					value={name}
					fullWidth
					size="small"
				/>
			</Box>
			<Box sx={jss.formControl}>
				<TextField 
					label="Slug"
					value={slug}
					fullWidth
					size="small"
				/>
			</Box>
			<Button onClick={submit} variant="contained" color="primary" size="large" startIcon={<AddOutlinedIcon />}>
				Create
			</Button>
		</DialogContent>
	</Dialog>
}
export default CreatePermissionModal