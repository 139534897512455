import { useState, useEffect, useRef } from "react";
import {
	Box,
	Typography,
	Paper,
	Skeleton,
	IconButton,
	Checkbox,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab'
import SectionTitle from '../../../components/common/SectionTitle'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import NoteService from '../../../services/NoteService'
import moment from 'moment'
import TipContext from '../../../providers/GlobalTip'


const jss = {
	noteHistory: {
		color: 'text.secondary',
		lineHeight:1.8,
		fontSize: 13,
		borderBottom:'1px solid #f3f4f5',
		pb:1,
	},
	noteMessage: {
		'.MuiListItemText-primary': {
			wordBreak: 'break-all',
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			WebkitBoxOrient: 'vertical',
			WebkitLineClamp: 1,
			overflow: 'hidden',
		}
	}
}


function ClientNote(props) {
	const {client} = props
	const [open, setOpen] = useState(false)
	const [notes, setNotes] = useState()
	const [showNote, setShowNote] = useState()
	const [saving, setSaving] = useState(false)
	const [newMessage, setNewMessage] = useState(null)
	const tip_context = TipContext.useMessage()

	useEffect(() => {
		if( client ) {
			loadNotes()
		}
	}, [client])

	const loadNotes = async () => {
		const params = {
			'filter[closed_date]': ''
		}
		const _notes = await NoteService.getNotes(client.Id, params)
		setNotes(_notes)
	}

	const handleClickOpen = () => {
		setOpen(true);
	}

	const handleClose = () => {
		setOpen(false);
		setShowNote(null)
		setNewMessage(null)
	}

	const openNoteDetail = (note) => {
		setShowNote(note)
		handleClickOpen()
	}

	const saveNote = async () => {
		try {
			setSaving(true)
			if( showNote ) {
				let _message = ""
				const _note = await NoteService.addMessage(client.Id, showNote.id, newMessage)
			} else {
				const _note = await NoteService.createNote(client.Id, {
					message: newMessage,
					period: moment().format('YYYYMM')
				})
			}
		} catch(e) {
			tip_context.show(e.response?.data.message)
		}
		setSaving(false)
		handleClose()
		loadNotes()
	}

	const solveNote = async() => {
		try {
			const _note = await NoteService.solveNote(client.Id, showNote.id)
		} catch(e) {
			tip_context.show(e.response?.data.message)
		}
		handleClose()
		loadNotes()
	}

	return <Paper elevation={2}>
		<SectionTitle label="Notes"
					  rightComponent={
					  	<IconButton onClick={handleClickOpen}>
					  		<AddOutlinedIcon />
					  	</IconButton>
					  }/>
		<List dense>
			{notes?.map(_note => {
				return <ListItemButton key={`client_note_${_note.id}`} onClick={e => openNoteDetail(_note)}>
					<ListItemText sx={jss.noteMessage} primary={_note.last_message} secondary={`${_note.auth_user ? _note.auth_user.first_name+' '+_note.auth_user.last_name+' -' : ''} ${moment(_note.created_at).format('MMM DD')}`}/>
				</ListItemButton>
			})}
		</List>
		<Dialog open={open} onClose={handleClose} fullWidth>
			<DialogTitle>Note for {client?.Name}</DialogTitle>
			<DialogContent>
				{showNote && 
					<Box
						component="div"
						dangerouslySetInnerHTML={{__html: showNote.body}}
						sx={jss.noteHistory}
					></Box>
				}
				<TextField
					label="Note Text"
					fullWidth
					autoFocus
					multiline
          			rows={3}
          			sx={{mt:2}}
          			onChange={e => setNewMessage(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				{showNote && 
					<>
						{!showNote.authorized_date && 
							<Button variant="contained" disabled>Post To Planner</Button>
						}
						<Button variant="contained" onClick={solveNote}>Solve Note</Button>
					</>
				}
				<LoadingButton disabled={!newMessage} loading={saving} onClick={saveNote} variant="contained">Save</LoadingButton>
			</DialogActions>
		</Dialog>
	</Paper>
}
export default ClientNote