import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Switch,
	CircularProgress,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Chip,
	Menu,
	MenuItem,
	Fade,
	Collapse,
	Alert,
	TextField,
} from '@mui/material';
import constant from '../../../constants/constant'
import _ from 'underscore'
import ClientService from '../../../services/ClientService'
import moment from 'moment'


const jss = {
	tableLabel: {
		fontWeight: 'bolder',
		width: '25%',
		textAlign: 'right'
	},
	tagSection: {
		'.MuiChip-root': {
			mr:1,
			mt:1,
		}
	}
}


function LocalClassDisplay(props) {
	const {clientClass, override = false} = props

	if( clientClass ) {
		return <TableContainer>
			<Table>
				<TableBody>
					{clientClass.deleted_at && <TableRow>
						<TableCell sx={jss.tableLabel}>Dropped</TableCell>
						<TableCell sx={{color: 'error.main', fontWeight: 'bold'}}>{moment(clientClass.deleted_at).format('MM-DD-YYYY')}</TableCell>
					</TableRow>}
					<TableRow>
						<TableCell sx={jss.tableLabel}>Service Type</TableCell>
						<TableCell>
							{_.invert(constant.SERVICE_TYPE_ID)[clientClass.servicetypeid]}
						</TableCell>
					</TableRow>
					{clientClass.parent_locationclassid &&
						<TableRow>
							<TableCell sx={jss.tableLabel}>Parent Class</TableCell>
							<TableCell>
								<Chip label={`${clientClass.parent_class.locationclass_name} [${clientClass.parent_class.clientclass_code}]`} size="small" sx={{mb:0.5,mr:0.5}}/>
							</TableCell>
						</TableRow>
					}
					{clientClass.children.length>0 &&
						<TableRow>
							<TableCell sx={jss.tableLabel}>Child Classes</TableCell>
							<TableCell>{clientClass.children.map((item, index) => {
								return <Chip key={`fullClientClass_child_${index}`} label={`${item.locationclass_name} [${item.clientclass_code}]`} size="small" sx={{mb:0.5,mr:0.5}}/>
							})}
							</TableCell>
						</TableRow>
					}
	                {clientClass.labels && <TableRow>
						<TableCell sx={jss.tableLabel}>M1 Tags</TableCell>
						<TableCell>{clientClass.labels.map((item, index) => {
							return <Chip key={`fullClientClass_tag_${index}`} label={item.name} size="small" sx={{mb:0.5,mr:0.5}}/>
						})}</TableCell>
	                </TableRow>}
	                <TableRow>
						<TableCell sx={jss.tableLabel}>Categories</TableCell>
						<TableCell>{clientClass.categories.map((item, index) => {
							return <Chip key={`fullClientClass_cateogory_${index}`} label={item.Name} size="small" sx={{mb:0.5,mr:0.5}}/>
						})}</TableCell>
					</TableRow>
					{/* <TableRow>
						<TableCell sx={jss.tableLabel}>Warehouse Location</TableCell>
						<TableCell>
							{clientClass.warehouse_location ? clientClass.warehouse_location.location_name : ''}
						</TableCell>
					</TableRow> */}
					<TableRow>
						<TableCell sx={jss.tableLabel}>Master Classes</TableCell>
						<TableCell>
						{clientClass.master_classes?.map(_mc => (
							<Chip key={`fullClientClass_master_class_${clientClass.id}_${_mc.id}`} label={_mc.master_class.name} size="small" sx={{mb:0.5,mr:0.5}}/>
						))}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell sx={jss.tableLabel}>Master Categories</TableCell>
						<TableCell>
							{clientClass.master_categories?.map(_mca => (
								<Chip key={`fullClientClass_master_category_${clientClass.id}_${_mca.id}`} label={_mca.master_category.name} size="small" sx={{mb:0.5,mr:0.5}}/>
							))}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	} else {
		return <Box sx={jss.loadingSection}>
			<CircularProgress />
		</Box>
	}
}
export default LocalClassDisplay