import React from "react";
import {
  Routes,
  Route,
  Outlet,
  useParams,
  useNavigate
} from "react-router-dom";
import ClientContext from '../../providers/CurrentClientProvider'
import ClientService from '../../services/ClientService'

function ClientMiddleware() {
  const client_context = ClientContext.useClient();
  const {client_code} = useParams()
  const navigate = useNavigate()

  async function getClientByCode() {
      try {
        const _client = await ClientService.getClientByCode(client_code)

        client_context.setClient(_client)
      } catch (error) {
        // if 403, redirect to /
        if (error.response.status === 403) {
          navigate('/')
        }
      }
  }

  React.useEffect(() => {
    getClientByCode()
  }, [client_code])
  return <Outlet />
}

export default ClientMiddleware