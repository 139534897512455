import * as React from "react";
import {
	Outlet,
} from "react-router-dom";
import {
	CssBaseline,
	Container,
	Box,
	Button,
	Slide,
	Alert,
} from '@mui/material'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import Snackbar from '@mui/material/Snackbar'
import GlobalTip from '../providers/GlobalTip'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'

function MessageTransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

function Layout(props) {

	let globalTipContext = GlobalTip.useMessage()

	return (
		<>
			<CssBaseline />
			<Header />
			<Container maxWidth={false}>
				<Outlet />
				<Snackbar
					open={globalTipContext.messageOpen}
					TransitionComponent={MessageTransitionDown}
					anchorOrigin={{vertical: 'top', horizontal: 'center'}}
				>
					<Alert variant="filled" severity="warning" sx={{ width: '100%' }}>
				    {globalTipContext.messageContent}
				  </Alert>
				</Snackbar>
				<Snackbar
					open={globalTipContext.successOpen}
					TransitionComponent={MessageTransitionDown}
					anchorOrigin={
						{vertical: 'top', horizontal: 'center'}
					}
				>
					<Alert variant="filled" severity="success" sx={{ width: '100%' }}>
				    {globalTipContext.successContent}
				  </Alert>
				</Snackbar>
			</Container>
			<Footer />
		</>
	)
}

export default Layout