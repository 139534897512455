import * as React from "react";
import {
	CssBaseline,
	Container,
	Box,
} from '@mui/material'
import {
	useNavigate
} from "react-router-dom";

const jss = {
	headerSection: {

	},
}

function Header(props) {
	const navigate = useNavigate()
	function toHomePage() {
		navigate('/')
	}

	return (
		<Box sx={jss.headerSection} >
		</Box>
	)
}

export default Header