import Request from './Request'

const TagService = {
	async getTagGroups() {
		return await Request.get(`/api/tag-groups`)
	},
	async uploadClientTag(file) {
		let formData = new FormData()
		formData.append("data", file)
		return await Request.formPost(`/api/tags/upload`, formData)
	},
}

export default TagService