import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Box,
	IconButton,
	TextField,
	FormGroup,
	FormControl,
	Checkbox,
	Typography,
	Button,
	MenuItem,
	InputAdornment,
	FormControlLabel,
	Modal,
} from '@mui/material';
import {
	LoadingButton
} from '@mui/lab'
import { DesktopDatePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment'
import ClientService from '../../services/ClientService'

const jss = {
	modalSection: {
		backgroundColor: 'white',
		p:3,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 500,
		borderRadius:2,
	}
}

function AddStatusModal(props) {
	const [form_data, setFormData] = useState({
		status: props.current_status,
		notes: '',
		change_date: moment(),
		expected_update_date: null
	})
	const [saving, setSaving] = useState(false)

	function updateFormData(e) {
		setFormData({
			...form_data,
			[e.target.name]: e.target.value
		})
	}

	async function saveClientStatus() {
		try {
			setSaving(true)
			const data = await ClientService.createClientStatusHistory({
				client_id: props.client.Id,
				change_date: form_data.change_date.format('YYYY-MM-DD'),
				status_id: form_data.status,
				expected_update_date: form_data.expected_update_date ? form_data.expected_update_date.format('YYYY-MM-DD') : null,
				expected_new_status_id: 4,
				change_note: form_data.notes,
			})
			props.onClose()
		}catch(e) {
		}
		setSaving(false)
	}

	return <Modal open={props.open} onClose={e => props.onClose()}>
		<Box sx={jss.modalSection}>
			<Typography variant="h6">CLIENT STATUS</Typography>
			<TextField
				size="small"
				fullWidth
				label="Status"
				name="status"
				value={form_data.status}
				select
				onChange={updateFormData}
				sx={{mt:2}}
			>
				<MenuItem value={1}>Dropped</MenuItem>
		        <MenuItem value={2}>Hiatus</MenuItem>
		        <MenuItem value={3}>Static Plan</MenuItem>
		        <MenuItem value={4}>Fully Operational</MenuItem>
		        <MenuItem value={5}>Credit Hold</MenuItem>
			</TextField>
			<TextField
				size="small"
				fullWidth
				label="Notes"
				name="notes"
				onChange={updateFormData}
				value={form_data.notes}
				sx={{mt:2}}
				multiline
				rows={4}
			/>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				{form_data.change_date && 
					<DesktopDatePicker
						label="Change Date"
						value={form_data.change_date}
						renderInput={(params) => <TextField {...params}
							fullWidth={true}
							variant="outlined"
							size="small"
							sx={{mt:2}} 
						/>}
						onChange={e => updateFormData({
							target: {
								value: moment(e),
								name: 'change_date'
							}
						})}
					/>
				}
				<DesktopDatePicker
					label="Estimated Return Date"
					value={form_data.expected_update_date}
					renderInput={(params) => <TextField {...params}
						fullWidth={true}
						variant="outlined"
						size="small"
						sx={{mt:2}} 
					/>}
					onChange={e => updateFormData({
						target: {
							value: moment(e),
							name: 'expected_update_date'
						}
					})}
				/>
			</LocalizationProvider>
			<LoadingButton loading={saving} onClick={saveClientStatus} variant="contained" sx={{mt:2}}>Save Client Status</LoadingButton>

		</Box>
	</Modal>
}
export default AddStatusModal