import React, { useEffect } from 'react'
import { Box, Button, FormControl, InputLabel, LinearProgress, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { Autocomplete } from "@mui/lab";
import RoleService from "../../../../services/RoleService";
import Request from '../../../../services/Request.js';
import UserService from "../../../../services/UserService";
import TipContext from '../../../../providers/GlobalTip';

const AddUserModal = ({ open, onClose, onSave, clientId }) => {

    const [loading, setLoading] = React.useState(false);
    const [roles, setRoles] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState(null);
    const tip_context = TipContext.useMessage();
    const [userInfo, setUserInfo] = React.useState({
        name: '',
        email: '',
        confirmEmail: '',
    });

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                setLoading(true);
                const response = await RoleService.getRoles();
                setRoles(response);
            } catch (error) {
                console.error('Error fetching roles:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchRoles();
    }, []);

    const handleSave = async (e) => {
        e.preventDefault();

        // confirm that emails match and are not empty
        if (userInfo.email !== userInfo.confirmEmail) {
            alert('Emails do not match');
            return;
        }

        try {
            const additionalParams = {
                return_existing: true,
                role_assignments: [
                    {client_id: clientId, role_id: selectedRole.id}
                ]
            };

            let result = await UserService.createUser(userInfo.name, userInfo.email, additionalParams);

            onSave(result, selectedRole);
            onClose()
        } catch (error) {
            console.error('Error:', error);

            tip_context.show('Error adding user')
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ margin: 'auto', mt: 5, width: 400, bgcolor: 'background.paper', p: 3 }}>
                <div className="material-card">
                    <div className="material-card-header">
                        <div className="row">
                            <div className="col-xs-12">
                                <h3>Add new User</h3>
                            </div>
                        </div>
                    </div>
                    <form className="material-form" onSubmit={handleSave}>
                        <Autocomplete
                            fullWidth
                            options={roles}
                            loading={loading}
                            filterOptions={(x) => x}
                            required
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {setSelectedRole(value)}}
                            renderInput={(params) => (
                                <TextField {...params} label="Role" variant="outlined" sx={{ mb: 2 }} required>
                                    <LinearProgress sx={{ mb: 2 }} />
                                </TextField>
                            )}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    {option.name}
                                </li>
                            )}
                        />
                        <TextField
                            fullWidth
                            label="Name"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            value={userInfo.name}
                            required
                            onChange={(event) => {
                                const value = event.target.value;
                                setUserInfo((prev) => ({ ...prev, name: value }));
                            }}
                        />

                        <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            value={userInfo.email}
                            required
                            onChange={(event) => {
                                const value = event.target.value;
                                setUserInfo((prev) => ({ ...prev, email: value }));
                            }}
                        />

                        <TextField
                            fullWidth
                            label="Confirm Email"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            value={userInfo.confirmEmail}
                            required
                            onChange={(event) => {
                                const value = event.target.value;
                                setUserInfo((prev) => ({ ...prev, confirmEmail: value }));
                            }}
                        />
                        <Stack spacing={2} direction="row">
                            <Button variant="contained" type="submit">Save</Button>
                            <Button variant="outlined" onClick={onClose}>Cancel</Button>
                        </Stack>
                    </form>
                </div>
            </Box>
        </Modal>
    )
}

export default AddUserModal
