import React, {
    useState,
    useEffect,
    useRef
} from "react";

import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    Box,
    CircularProgress
} from '@mui/material';
import SectionTitle from "../../common/SectionTitle";
import jss from "../profile/ProfileCommonJss";

function ClientPosMappingSum(props) {
    const {statistics} = props

    return <Card sx={{height: '200px'}}>
        {!Object.keys(statistics).length ?
            <CardContent>
                <Grid>
                    <Box sx={jss.loadingSection}>
                        <CircularProgress />
                    </Box>
                </Grid>
            </CardContent>
        : <>
            <SectionTitle
                label="Mapping Summary"
            />
            <CardContent>
                <Grid container>
                    <Grid item md={8}>
                        <Typography variant="p" component="p" sx={{textAlign: 'center', fontWeight: 'bold', mb:2}}>
                            Total Classes
                        </Typography>
                        <Divider light />
                        <Grid container sx={{mt: 3}}>
                            <Grid item md={3}>
                                <Typography variant="p" component="p" sx={{textAlign: 'center', fontSize: 20, color: '#757575'}}>
                                    { statistics.orbit_classes }
                                </Typography>
                                <Typography variant="p" component="p" sx={{textAlign: 'center', fontSize: 11, textTransform: 'uppercase', color: '#BDBDBD'}}>
                                    orbit classes
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography variant="p" component="p" sx={{textAlign: 'center', fontSize: 20, color: '#757575'}}>
                                    { statistics.orbit_location_classes }
                                </Typography>
                                <Typography variant="p" component="p" sx={{textAlign: 'center', fontSize: 11, textTransform: 'uppercase', color: '#BDBDBD'}}>
                                    orbit location classes
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography variant="p" component="p" sx={{textAlign: 'center', fontSize: 20, color: '#757575'}}>
                                    { statistics.pos_classes }
                                </Typography>
                                <Typography variant="p" component="p" sx={{textAlign: 'center', fontSize: 11, textTransform: 'uppercase', color: '#BDBDBD'}}>
                                    pos classes
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography variant="p" component="p" sx={{textAlign: 'center', fontSize: 20, color: '#757575'}}>
                                    { statistics.pos_class_mappings }
                                </Typography>
                                <Typography variant="p" component="p" sx={{textAlign: 'center', fontSize: 11, textTransform: 'uppercase', color: '#BDBDBD'}}>
                                    pos class mappings
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4}>
                        <Typography variant="p" component="p" sx={{textAlign: 'center', fontWeight: 'bold', mb:2}}>
                            Unmapped Classes
                        </Typography>
                        <Divider light />
                        <Grid container sx={{mt: 3}}>
                            <Grid item md={6}>
                                <Typography variant="p" component="p" sx={{textAlign: 'center', fontSize: 20, color: '#d72828'}}>
                                    { statistics.unmapped_classes }
                                </Typography>
                                <Typography variant="p" component="p" sx={{textAlign: 'center', fontSize: 11, textTransform: 'uppercase', color: '#BDBDBD'}}>
                                    orbit classes
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="p" component="p" sx={{textAlign: 'center', fontSize: 20, color: '#d72828'}}>
                                    { statistics.unmapped_pos }
                                </Typography>
                                <Typography variant="p" component="p" sx={{textAlign: 'center', fontSize: 11, textTransform: 'uppercase', color: '#BDBDBD'}}>
                                    pos classes
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </>
        }
    </Card>
}

export default ClientPosMappingSum;