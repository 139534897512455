const PlanStyle = {
    mainContainer: {
        position: 'relative',
        pt: 2,
        minHeight: 'calc(100vh - 50px)',
    },
    filterHeader: {
        display: 'flex',
        alignCenter: 'center',
        justifyContent: 'space-between',
        width: '100%',
        borderBottom: 2,
        borderColor: '#ebecf1'
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    textFields: {
        background: '#5c4174',
        borderRadius: '5px 5px 0 0',
        overflow: 'hidden',
    },
    textFieldSplit: {
        height: '100%',
        alignSelf: 'stretch',
        width: '1px',
        background: 'rgba(255, 255, 255, 0.13)'
    },
    textStyle: {
        px: 2,
        color: 'white',
        fontWeight: 'bold',
    },
    selectStyle: {
        background: 'transparent',
        px: 2,
        maxWidth: '200px',
        height: '50px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        'select': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        'label': {
            color: '#fff'
        },
        'div': {
            color: '#fff',
            '&:before': {
                display: 'none',
            },
            'svg': {
                color: '#fff'
            }
        }
    },
    mainSection: {
        display: 'flex',
    },


    leftSection: {
        width: '240px',
    },
    leftContent: {
        background: '#ebecf1',
        height: '100%'
    },
    rightSection: {
        width: 'calc(100% - 240px)',
        pt: {sm: 2},
        pl: {sm: 2},
    },
    loadPlan: {
        pt: 2,
        pb: 3,
        px: 1,
        borderBottom: 1,
        borderColor: 'divider'
    },
    loadPlanTitle: {
        fontSize: '18px',
        color: '#20232B',
    },
    tabs: {
        mt: 2,
        borderBottom: 1,
        borderColor: 'rgba(0, 0, 0, 0.1)',
        overflow: 'auto',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    tab: {
        display: 'inline-block',
        fontSize: '11px',
        color: '#535353',
        mx: '8px',
        px: '2px',
        py: '8px',
        borderBottom: 2,
        borderColor: 'transparent',
        cursor: 'pointer',
    },
    activeTab: {
        borderColor: '#4D3660',
    },
    transparentPaper: {
        background: 'transparent',
        boxShadow: 'none',
        px: '10px'
    },
    searchArea: {
        background: '#ebecf1',
        p: 2
    },
    chipsItem: {
        background:'#70778B', 
        color: '#fff',
        cursor: 'pointer',
    },
    searchButtons: {
        mt: 2,
        pt: 2,
        borderTop: 1,
        borderColor: 'divider',
        flexDirection: 'row-reverse'
    },
    multipleTables: {
        pl: 0,
        mt: 3,
        'table': {
            th: {
                fontSize: '14px',
            },
            td: {
                fontSize: '12px',
            },
            'tr': {
                'th:first-of-type': {
                    fontWeight: 'bold',
                },
                'td:first-of-type': {
                    fontWeight: 'bold',
                    fontSize: '13px'
                },
                'th:not(:first-of-type)': {
                    textAlign: 'right'
                },
                'td:not(:first-of-type)': {
                    textAlign: 'right'
                },
                'th': {
                    px: '6px',
                    py: '3px',
                },
                'td': {
                    px: '6px',
                    py: '3px',
                },
                'th:not(:first-of-type):not(:last-child)': {
                    px: '3px',
                },
                'td:not(:first-of-type):not(:last-child)': {
                    px: '3px',
                },
            },
        },
        '.firstColumn': {
            width: '10,33%',
        },
        '.sameColumn': {
            width: '6.89%',
        },
    },
    highlightRow: {
        background: '#e6e1eb',
        textAlign: 'center'
    },
    historicalRow: {
        background: '#f4f0e2',
        'td': {
            color: '#8e720b'
        }
    },
    highlightCol: {
        background: '#795298',
        color: '#fff',
        textAlign: 'center !important',
    },
    textCenter: {
        textAlign: 'center !important',
    },
    tableDay: {
        fontSize: '10px',
        fontWeight: 'normal'
    },
    tableMonth: {
        fontSize: '14px',
        fontWeight: 'bold',
        mt: '-5px'
    },
    valueContainer: {
        lineHeight: '29px',
        height: '29px',
    },
    valueInput:(isActive = false) => ({
        'input[type=text]': {
            p: '4px 6px',
            background: '#fff',
            borderRadius: '3px',
            fontSize: '12px',
            textAlign: isActive? 'center':'right'
        }
    }),
    tabPanel: {
        th: {
            color: '#20232B',
            fontSize: '11px',
            whiteSpace: 'nowrap',
            '&:first-of-type': {
                p: '12px 7px 7px 0',
                textAlign: 'left'
            },
            '&:not(:first-of-type)': {
                p: '12px 7px 7px',
                textAlign: 'right'
            }
        },
        td: {
            color: '#20232B',
            fontSize: '11px',
            '&:first-of-type': {
                p: '7px 7px 7px 0',
                textAlign: 'left'
            },
            '&:not(:first-of-type)': {
                p: '7px',
                textAlign: 'right'
            }
        }
    },
    loadingSection: {
        height: 400,
        display: 'flex',
        alignItems: 'center',
        background: '#fff',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
    },
    makeLiveButton: {
        color: 'white',
        fontSize: 12,
        background: 'rgba(255,255,255,0.1)',
        mr:0.5,
    },
    actionButton: {
        mr: 1,
    },
    graphContainer: {
        height: '250px',
        width: '100%',
        background: '#ddd',
        margin: '0',
        boxSizing: 'border-box',
        overflow: 'hidden',
        marginTop: '20px',
        border: '1px solid #eee',
    },
}
export default PlanStyle