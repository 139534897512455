import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Container,
	Grid,
	Paper,
	Skeleton,
	IconButton,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	TableSortLabel,
	Button,
	Tooltip,
} from '@mui/material';
import {
	LoadingButton
} from '@mui/lab'
import UserService from '../../services/UserService'
import SectionTitle from '../../components/common/SectionTitle'
import _ from 'underscore'
import UserModel from '../../models/User'
import TipContext from '../../providers/GlobalTip'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import UserProfile from '../../components/user/UserProfile'
import UserRolesEdit from '../../components/user/UserRolesEdit'
import AuthContext from '../../providers/AuthProvider'

const jss = {
	detailSection: {
		py: 2
	},
}

function UserDetail(props) {
	const tipContext = TipContext.useMessage()
	const [user, setUser] = useState({})
	let { user_id } = useParams()
	const navigate = useNavigate()
	const auth_context = AuthContext.useAuth()
	const [syncing, setSyncing] = useState(false)

	useEffect(() => {
		document.title = 'Retail ORBIT: User Details (Admin)'
		loadUser()
	}, [])

	async function loadUser() {
		try {
			const _user = await UserService.getUserById(user_id)
			setUser(new UserModel(_user))	
		} catch(e) {
			navigate('/users')
		}
	}

	function userUpdate(new_user) {
		setUser(new UserModel(new_user))
	}

	const resyncUserPermissions = async () => {
		if (!user_id) {
			return;
		}
		setSyncing(true)
		try {
			let result = await UserService.resyncUserPermissions(user_id)

			if (result.error) {
				tipContext.show(result.error)
				return
			}

			tipContext.showSuccess('User permissions resynced')
			loadUser()
		} catch(e) {
			tipContext.show('Failed to resync user permissions')
		}
		setSyncing(false)
	}

	return <Grid container spacing={2} sx={jss.detailSection}>
		<Grid item xs={12} sm={6}>
			{syncing ?
				<Button disabled>Resync User Permissions (running)</Button>
			:
				<Button onClick={resyncUserPermissions}>Resync User Permissions</Button>
			}
			<UserProfile user={user} onChange={userUpdate}/>
		</Grid>
		<Grid item xs={12} sm={6}>
			<UserRolesEdit user={user} />
		</Grid>
	</Grid>
}

export default UserDetail