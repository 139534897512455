import {useState, useEffect} from 'react'
import {
  Navigate,
  useNavigate,
  Link,
} from "react-router-dom";
import {
	Container,
	Box,
	TextField,
	Button,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	TableSortLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Chip,
	Stack,
} from '@mui/material'
import {
	LoadingButton
} from '@mui/lab'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import UserService from '../../services/UserService'
import RoleService from '../../services/RoleService'
import TipContext from '../../providers/GlobalTip'
import UserModel from '../../models/User'

function PermissionUserItem(props) {
	const [current_role_id, setCurrentRoleId] = useState("")
	const messageContext = TipContext.useMessage()
	const [user, setUser] = useState(props.user)
	const [creating, setCreating] = useState(false)

	async function handleDelete(link_id) {
		try {
			await UserService.unassignUserAndRole(user.id, link_id)
			let _links = user.role_links
			_links.forEach((item, index) => {
				if( item.id==link_id ) {
					delete _links[index]
				}
			})
			const updated_user = {
				...user,
				role_links: _links
			}
			setUser(new UserModel(updated_user))
		} catch(e) {
			messageContext.show(e.response?.data.message)
		}
	}

	function handleCurrentRoleChange(e) {
		setCurrentRoleId(e.target.value)
	}

	async function assignRole() {
		try {
			setCreating(true)
			const link = await UserService.assignUserToRole(user.id, current_role_id)
			setCurrentRoleId("")
			setCreating(false)
			let _links = user.role_links
			_links.push(link)
			const updated_user = {
				...user,
				roles: _links
			}
			setUser(new UserModel(updated_user))
		} catch(e) {
			setCreating(false)
			messageContext.show(e.response?.data.message)
		}
	}

	return <TableRow>
		<TableCell>
			<Box component={Link} to={`/users/${user.id}`}>{user.name}</Box>
		</TableCell>
		<TableCell>
			<Stack spacing={1} direction="row">
				{user.role_links.map(link => {
					if( link.role?.type=='ADMIN' ) {
						return <Chip
							key={`link_item_${link.role.id}`}
							label={link.role.name}
							color='primary'
							size="small"
							onDelete={e => {handleDelete(link.id)}}
							deleteIcon={<HighlightOffOutlinedIcon />}
						/>	
					}
				})}
			</Stack>
		</TableCell>
		<TableCell>
			<FormControl sx={{width: 200}} size="small">
				<InputLabel>Select Role</InputLabel>
				<Select
					value={current_role_id}
					label="Select Role"
					onChange={handleCurrentRoleChange}
				>
					{props.roles.map(item => {
						return <MenuItem key={`user_role_item_${item.id}`} value={item.id} >{item.name}</MenuItem>
					})}
				</Select>
			</FormControl>
			<LoadingButton 
				color="success"
				sx={{ml:1, height:38}}
				variant="contained"
				onClick={assignRole}
				disabled={!current_role_id}
				loading={creating}
			>
				<DoneOutlinedIcon />
			</LoadingButton>
		</TableCell>
	</TableRow>
}

export default PermissionUserItem