import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Chip,
	Paper,
	Switch,
	CircularProgress,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Checkbox,
	TextField,
	InputAdornment,
	FormGroup,
	FormControlLabel, Divider,
} from '@mui/material';
import jss from '../profile/ProfileCommonJss'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import LocationService from '../../../services/LocationService'
import ClientService from "../../../services/ClientService";


function ClientCategoryList(props) {
	const {
		categories,
		onCategoryChange,
		createCategory,
		clientId,
		currentCategory,
		isAdmin = false,
	} = props
	const [choosedCategory, setChoosedCategory] = useState([])

	useEffect(() => {
		if( currentCategory ) {
			setChoosedCategory([currentCategory])
		}
	}, [currentCategory])

	function chooseCategory(category) {
		onCategoryChange(category)
		setChoosedCategory([category])
	}

	async function addCategory() {
		let name = prompt('Enter category name')
		if (!name) {
			name = 'New Category'
		}
		let order = categories && categories.length ? Math.max(...categories.map(o => o.SortOrder)) + 1 : 0
		let category = await ClientService.createCategory(clientId, {Name: name, SortOrder: order})
		createCategory(category)
	}


	return <Box sx={jss.dataGridItem}>
		<Box sx={jss.dataTop}>
			<Box sx={jss.dataTitle}>
				Categories
			</Box>
		</Box>
		<Box sx={{...jss.actionSection, justifyContent: 'flex-end'}}>
			{isAdmin ?
				<Box>
					<IconButton size="small" onClick={e => addCategory()}><AddRoundedIcon /></IconButton>
				</Box>
			: null}
		</Box>
		{!categories &&
			<Box sx={jss.loadingSection}>
				<CircularProgress />
			</Box>
		}
		{ categories &&
			<Box sx={jss.dataContent}>
				{categories.map(_category => {
					return (
						<Box 
							sx={jss.dataContentItem} 
							className={choosedCategory.map(i => {return i.Id}).indexOf(_category.Id)>-1 ? '_active' : ''}
							key={`category_item_${_category.Id}`}
						>
							<Box sx={jss.dataContentItem.name} onClick={e => chooseCategory(_category)}>
								{_category.Name}
							</Box>
							<Box sx={jss.dataContentItem.action} onClick={e => chooseCategory(_category)}>
								{_category.SortOrder}
							</Box>
						</Box>
					)
				})}
				{ categories.length==0 &&
					<Box sx={jss.dataContent.empty}>
						<Box>There are no categories for the selected class.</Box>
						<Box>You can select category for this class.</Box>
					</Box>
				}
			</Box>	
		}
	</Box>
}
export default ClientCategoryList