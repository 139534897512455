import React from "react";
import { Chip, Tooltip, Box } from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
const CopyActuals = ({ label, onClickAction, tooltip, buttonPosition }) => {
  return (
    <Box sx={{ float: buttonPosition }}>
      <Tooltip title={tooltip}>
        <Chip
          onClick={() => onClickAction()}
          icon={
            <PublishIcon
              sx={{
                border: "1px solid white",
                borderRadius: "50%",
                padding: "2px",
              }}
              fontSize="small"
              color="success"
            ></PublishIcon>
          }
          label={label}
          color="success"
          size="small"
        />
      </Tooltip>
    </Box>
  );
};

export default CopyActuals;