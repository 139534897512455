import {useState, useEffect, useRef} from 'react'
import {
	TextField,
	Button,
	Box,
	FormControl,
	InputLabel,
	Select,
	Chip,
	MenuItem,
} from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { LoadingButton } from '@mui/lab'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import _ from 'underscore'
import PermissionService from '../../services/PermissionService'
import TipContext from '../../providers/GlobalTip'


const jss = {
	formControl: {
		mb:2,
	}
}


function PermissionAssignRoleModal(props) {
	const [roles, setRoles] = useState(props.permission.roles.map(item => {return item.id}))
	const message_context = TipContext.useMessage()
	const roles_mapping = useRef(_.indexBy(props.roles, 'id'))

	function handleRoleUpdate(e) {
		setRoles(e.target.value)
	}

	async function submit() {
		try {
			const _permission = await PermissionService.updatePermission(props.permission.id, { roles })
			message_context.showSuccess('Successful operation')
			props.onSave(_permission)
		} catch(e) {
			message_context.show(e.response?.data.message)
		}
	}

	return <Dialog
		onClose={e => props.onClose()}
        open={props.open}
        fullWidth
        maxWidth="xs"
	>
		<DialogTitle>
			Assign Roles
		</DialogTitle>
		{roles && 
			<DialogContent dividers>
				<Box sx={jss.formControl}>
					{props.roles &&
						<FormControl 
							fullWidth
						>
							<InputLabel id="demo-simple-select-label">Roles</InputLabel>
							<Select
								label="roles"
								multiple
								value={roles}
								onChange={handleRoleUpdate}
								renderValue={(selected) => (
									<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
										{selected.map((value) => (
											<Chip key={value} label={roles_mapping.current[value]?.name} />
										))}
									</Box>
								)}
							>
							{props.roles.map(_role => {
								return <MenuItem key={`assign_role_${_role.id}`} value={_role.id}>{_role.name}</MenuItem>
							})}
							</Select>
						</FormControl>
					}
				</Box>
				<Button onClick={submit} variant="contained" color="primary" size="large" startIcon={<SaveOutlinedIcon />}>
					Save
				</Button>
				<Button size="large" sx={{ml:1}} onClick={e => props.onClose()}>Cancel</Button>
			</DialogContent>
		}
	</Dialog>
}
export default PermissionAssignRoleModal