import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, InputAdornment, Alert, useMediaQuery, List, ListItem, IconButton, ListItemText, Box, Paper, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import CustomCalendarService from '../../services/CustomCalendarService';
import SimpleSearchableClientDropdown from '../client/SimpleSearchableClientDropdown';
import SectionTitle from '../common/SectionTitle';

const CustomCalendarModal = ({ open, onClose, onSubmit, onDelete, mode, initialData, tipContext = null, activeClientList = [] }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [name, setName] = useState(initialData?.title || '');
    const [description, setDescription] = useState(initialData?.description || '');
    const [startDayOfWeek, setStartDayOfWeek] = useState(initialData?.start_day_of_week || 0);
    const [months, setMonths] = useState([]);
    const [validWeeks, setValidWeeks] = useState(false);
    const [attachedClients, setAttachedClients] = useState([initialData?.clients_simple || []]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [calendarId, setCalendarId] = useState(null);

    useEffect(() => {
        setValidWeeks(months.reduce((acc, weeks) => acc + weeks, 0) === 52);
    }, [months]);

    useEffect(() => {
        if (initialData) {
            const newMonths = [];
            for (let i = 0; i < 12; i++) {
                newMonths.push(initialData[`month${i + 1}_weeks`] || 4);
            }
            setMonths(newMonths);
            setName(initialData.title);
            setDescription(initialData.description);
            setStartDayOfWeek(initialData.start_of_week);
            setAttachedClients(initialData.clients_simple ?? []);
            setCalendarId(initialData.id);
        }
    }, [initialData]);

    const handleMonthChange = (index, value) => {
        const newMonths = [...months];
        newMonths[index] = value >= 0 ? value : 0;
        setMonths(newMonths);
    };

    const handleSubmit = async () => {
        if (!validWeeks) {
            if (tipContext) {
                tipContext.show('Weeks must add up to 52');
            }
            return;
        }
        if (!name) {
            if (tipContext) {
                tipContext.show('Name is required');
            }
            return;
        }

        const data = {
            title: name,
            description,
            start_of_week: startDayOfWeek,
        };
        for (let i = 0; i < 12; i++) {
            data[`month${i + 1}_weeks`] = months[i];
        }

        try {
            if (mode === 'edit') {
                let response = await CustomCalendarService.updateCalendar(initialData.id, data);
                onSubmit(response.data);
                onClose();
            } else {
                let response = await CustomCalendarService.createCalendar(data);
                onSubmit(response.data);
                onClose();
            }

            if (tipContext) {
                tipContext.showSuccess('Custom calendar saved successfully');
            }
        } catch (error) {
            console.log(error);

            if (tipContext) {
                tipContext.show('Failed to save custom calendar');
            }
        }
    };

    const handleDelete = async () => {
        try {
            let response = await CustomCalendarService.deleteCalendar(initialData.id);
            onDelete(response.data);
            onClose();

            if (tipContext) {
                tipContext.showSuccess('Custom calendar deleted successfully');
            }
        } catch (error) {
            console.log(error);

            if (tipContext) {
                tipContext.show('Failed to delete custom calendar');
            }
        }
    };

    const handleRemoveClient = async (clientId) => {
        try {
            let response = await CustomCalendarService.removeClient(clientId)
            // remove where id matches
            setAttachedClients(attachedClients.filter((client) => client.Id !== clientId));

            if (tipContext) {
                tipContext.showSuccess('Client removed from custom calendar');
            }
        } catch (error) {
            console.log(error);

            if (tipContext) {
                tipContext.show('Failed to remove client from custom calendar');
            }
        }
    }

    const handleAddClient = async () => {
        if (!selectedClient) {
            if (tipContext) {
                tipContext.show('Please select a client');
            }
            return;
        }

        // confirm that client will be removed from any other calendars
        if (!window.confirm('This client will be removed from any other custom calendar. Continue?')) {
            return;
        }

        try {
            let response = await CustomCalendarService.addClient(calendarId, selectedClient.Id);
            setAttachedClients([...attachedClients, selectedClient]);

            if (tipContext) {
                tipContext.showSuccess('Client added to custom calendar');
            }
        } catch (error) {
            console.log(error);

            if (tipContext) {
                tipContext.show('Failed to add client to custom calendar');
            }
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" fullScreen={fullScreen}>
            <DialogTitle>{mode === 'edit' ? 'Edit Custom Calendar' : 'Create Custom Calendar'}</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="dense"
                    required
                />
                <TextField
                    fullWidth
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="dense"
                />
                <TextField
                    select
                    label="Start Day of Week"
                    value={startDayOfWeek}
                    onChange={(e) => setStartDayOfWeek(e.target.value)}
                    margin="dense"
                    fullWidth
                >
                    <MenuItem value={0}>Sunday</MenuItem>
                    <MenuItem value={1}>Monday</MenuItem>
                    <MenuItem value={2}>Tuesday</MenuItem>
                    <MenuItem value={3}>Wednesday</MenuItem>
                    <MenuItem value={4}>Thursday</MenuItem>
                    <MenuItem value={5}>Friday</MenuItem>
                    <MenuItem value={6}>Saturday</MenuItem>
                </TextField>
                {!validWeeks && (
                    <Alert severity="warning" sx={{ mt: 2 }}>
                        Weeks must add up to 52. Currently add to {months.reduce((acc, weeks) => acc + weeks, 0)}.
                    </Alert>
                )}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {months.map((weeks, index) => (
                        <Grid item xs={4} key={index}>
                            <TextField
                                label={`Month ${index + 1}`}
                                type="number"
                                value={weeks}
                                onChange={(e) => handleMonthChange(index, parseInt(e.target.value, 10))}
                                InputProps={{
                                    inputProps: { min: 0, step: 1 },
                                    endAdornment: <InputAdornment position="end">weeks</InputAdornment>,
                                }}
                                margin="dense"
                                fullWidth
                            />
                        </Grid>
                    ))}
                </Grid>
                {/* Client connections section */}
                {mode === 'edit' ?
                    <Paper elevation={3} sx={{ mt: 2 }}>
                        <SectionTitle label="Attached Clients" type="secondary" />
                        <Box sx={{ mt: 2, ml: 2, height: 'fit-content' }}>
                            <SimpleSearchableClientDropdown clients={activeClientList} selectedClient={selectedClient} setSelectedClient={setSelectedClient} styleOverrides={{ width: '75%' }} />
                            <Button variant="contained" color="primary" onClick={handleAddClient} sx={{ ml: 2, mt: 1 }}>
                                Add Client
                            </Button>
                        </Box>
                        <hr />
                        <List sx={{ minHeight: '200px', maxHeight: '200px', overflowY: attachedClients.length > 5 ? 'auto' : 'hidden' }}>
                            {attachedClients.map((client) => (
                                <ListItem key={client.Id} secondaryAction={
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveClient(client.Id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                }>
                                    <ListItemText primary={client.ClientCode} />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                : null}
                {/* End of client connections section */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={!validWeeks}>
                    {mode === 'edit' ? 'Save' : 'Create'}
                </Button>
                {mode === 'edit' && (
                    <Button onClick={handleDelete} color="secondary">
                        Delete
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CustomCalendarModal;
