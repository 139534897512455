import GeneralClientEditForm from './GeneralClientEditForm'

function GeneralStep() {
  return (
    <>
      <GeneralClientEditForm/>
    </>
  )
}

export default GeneralStep
