const ProfileCommonJss = {
	title: {
		fontSize:18,
		color:'text.secondary',
		fontWeight:'bold',
		height: 28,
		mb:1,
	},
	dataContentItem: {
		display:'flex',
		fontSize:12,
		justifyContent:'space-between',
		alignItems:'center',
		px:1,
		backgroundColor:"white",
		cursor:'pointer',
		border:'1px solid #ddd',
		borderBottom:'none',
		'&:last-child': {
			borderBottom:'1px solid #ddd',
		},
		name: {
			flex:1,
			cursor:'pointer',
			height:'40px',
			lineHeight:'40px',
		},
		action: {
			textAlign:'right',
			cursor:'pointer',
			'.MuiChip-root': {
				ml:1,
			}
		},
		'.MuiChip-root': {
			width:'24px',
			height:'24px',
			textAlign:'center',
			lineHeight:'24px',
			'.MuiChip-label': {
				px:0,
			}
		},
		'&._child': {
			height:'34px',
			lineHeight:'34px',
			backgroundColor:'#f3f4f5',
		},
		'&._override': {
			'.MuiChip-root': {
				backgroundColor: '#f97125 !important',
				border:'1px solid #f97125 !important',
			},
		},
		'&._active&._override': {
			color: '#f97125 !important',
			borderColor: '#f97125 !important',
			'.MuiChip-root': {
				backgroundColor: '#f97125 !important',
				border:'1px solid #f97125 !important',
			},
		},
		'&._active': {
			backgroundColor: '#c9b7d8',
			borderColor: '#ae93c5',
			borderBottom:'1px solid #ae93c5',
			borderLeftWidth:'2px',
			'.MuiChip-root': {
				backgroundColor:'#ae93c5',
				border:'1px solid #8b65ac',
			},
			'&._override': {
				color: '#f97125 !important'
			}
		},
		'&._parent_active': {
			'.MuiChip-root': {
				backgroundColor:'#ae93c5',
				border:'1px solid #8b65ac',
			}
		},
		'&._child_active': {
			backgroundColor: '#eee9f3',
			borderLeftColor: '#ae93c5',
			'.MuiChip-root': {
				backgroundColor:'#ae93c5',
			}
		},
		'&._deleted': {
			color:'error.main'
		},
	},
	dataGrid: {
		backgroundColor:'#eee',
		display:'flex',
	},
	dataGridItem: {
		px:2,
		py:1,
		flex:1,
		borderRight:'1px dashed #ddd',
		'&:last-child': {
			borderRight:'none',
		}
	},
	dataTop: {
		display:'flex',
		justifyContent:'space-between',
		alignItems:'center',
		mb:1,
	},
	dataContent: {
		overflowY:'auto',
		maxHeight:'75vh',
		minHeight:'150px',
		empty: {
			backgroundColor:'white',
			p:2,
			py:4,
			textAlign:'center',
			fontSize:12,
		}
	},
	actionSection: {
		backgroundColor:'white',
		display:'flex',
		p:1,
		justifyContent:'space-between',
		alignItems:'center',

	},
	dataTitle: {
		fontSize: 16,
		mt:1,
	},
	dataAction: {

	},
	loadingSection: {
		backgroundColor: 'white',
		textAlign:'center',
		py:2,
	},
	iconButtonActive: {
		backgroundColor:'primary.main',
		color:'white',
		"&:hover": {
			backgroundColor:'primary.main',
			color:'white',
		}
	}
}
export default ProfileCommonJss