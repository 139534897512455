import Request from './Request'

const PermissionService = {
	async createPermission(params) {
		return await Request.post('/api/permissions', params)
	},
	async updatePermission(id, params) {
		return await Request.post(`/api/permissions/${id}`, params)
	},
	async getPermissions() {
		return await Request.get('/api/permissions')
	},
}

export default PermissionService