import { useState, useEffect } from 'react';
import {
	Box,
	Typography,
	Container,
	Grid,
	Paper,
	Button,
	IconButton,
	Table,
	TableRow,
	TableContainer,
	TableCell,
	TableBody,
	TableHead,
	Chip,
	ToggleButtonGroup,
	ToggleButton,
	FormControlLabel,
	Checkbox,
	TextField,
	CircularProgress,
	Stack,
} from '@mui/material';
import {
	Navigate,
	useParams,
	Link,
	useNavigate,
} from "react-router-dom";
import SectionTitle from '../../components/common/SectionTitle';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import PlanService from '../../services/PlanService';
import TipContext from '../../providers/GlobalTip';
import _ from 'underscore';

function CopyPlanFileDetail(props) {

	const {
		mode='file'
	} = props
	const [loading, setLoading] = useState(false)
	const [copyPlanFile, setCopyPlanFile] = useState()
	const [copyPlanDetails, setCopyPlanDetails] = useState([])
	const tipContext = TipContext.useMessage()
	const navigate = useNavigate();

	const {id} = useParams();
	
	useEffect(() => {
		if( mode == 'file' ) {
			loadCopyPlanFileDetail(id)
		} else {
			loadCopyPlanDetail(id)
		}
	}, [])

	const loadCopyPlanDetail = async(id) => {
		setLoading(true)
		const data = await PlanService.getCopyPlanDetail(id)
		setCopyPlanDetails([data])
		setLoading(false)
	}

	const loadCopyPlanFileDetail = async(id) => {
		setLoading(true)
		const data = await PlanService.getCopyPlanFileDetail(id)
		setCopyPlanDetails(data.details)
		setCopyPlanFile(data)
		setLoading(false)
	}

	const processLine = async(copy_plan_id) => {
		try {
			await PlanService.copyPlanReprocess(copy_plan_id)
			tipContext.showSuccess()
		} catch(e) {
			tipContext.show(e.response?.data?.message)
		}
	}

	const reprocessFile = async() => {
		try {
			await PlanService.copyPlanFileReprocess(copyPlanFile.id)
			tipContext.showSuccess()
		} catch(e) {
			tipContext.show(e.response?.data?.message)
		}
	}

	const downloadFile = async() => {
		PlanService.downloadCopyPlanFile(copyPlanFile.id)
	}

	return <Box sx={{py:2}}>
		<Typography variant="h5">Copy plan detail</Typography>
		<Grid container spacing={2} sx={{mt: 1}}>
			{(mode=='file' && copyPlanFile) && (
				<Grid item xs={12}>
					<Paper elevation={2}>
						<SectionTitle 
							label="File Info" 
							rightComponent={(
								<>
									<Button variant="contained" sx={{mr:1,}} onClick={reprocessFile}>Process</Button>
									<Button variant="contained" sx={{}} onClick={downloadFile}>Download</Button>
								</>
							)}
						/>
						<Box sx={css.fileSection}>
							<Box sx={css.fileItem}>
								<Box sx={css.fileItemName}>File ID</Box>
								<Box sx={css.fileItemValue}>{copyPlanFile.id}</Box>
							</Box>
							<Box sx={css.fileItem}>
								<Box sx={css.fileItemName}>File Name</Box>
								<Box sx={css.fileItemValue}>{copyPlanFile.filename}</Box>
							</Box>
							<Box sx={css.fileItem}>
								<Box sx={css.fileItemName}>Created Date</Box>
								<Box sx={css.fileItemValue}>{copyPlanFile.created_at}</Box>
							</Box>
							<Box sx={css.fileItem}>
								<Box sx={css.fileItemName}>Processed Date</Box>
								<Box sx={css.fileItemValue}>{copyPlanFile.processed_date}</Box>
							</Box>
							<Box sx={{...css.fileItem, width:'500px'}}>
								<Box sx={css.fileItemName}>Errors</Box>
								<Box sx={css.fileItemValue}>
									{_.isArray(copyPlanFile.error) && copyPlanFile.error.map((e, i) => <Chip label={e} size="small" key={`file-error-${i}`} sx={{mr:1, mb:1,}}/>)}
								</Box>
							</Box>
							
						</Box>
					</Paper>
				</Grid>
			)}
			<Grid item xs={12}>
				<Paper elevation={2}>
					<SectionTitle label="Detail" />
					<Box sx={css.detailSection}>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Status</TableCell>
										<TableCell>Source Plan</TableCell>
										<TableCell>Destination Plan</TableCell>
										<TableCell>Source LC ID</TableCell>
										<TableCell>Source Epochmonth</TableCell>
										<TableCell>Destination LC ID</TableCell>
										<TableCell>Destination Epochmonth</TableCell>
										<TableCell>AR</TableCell>
										<TableCell>MD</TableCell>
										<TableCell>IMU</TableCell>
										<TableCell>Turn</TableCell>
										<TableCell>Sales Model Type</TableCell>
										<TableCell>IMU Model Type</TableCell>
										<TableCell>Future Plan</TableCell>
										<TableCell>Insert Date</TableCell>
										<TableCell>Processed Date</TableCell>
										<TableCell>Error</TableCell>
										<TableCell>Action</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
								{loading && (<TableRow>
									<TableCell colSpan={18} sx={{py: 10}} align='center'> 
										<CircularProgress />
									</TableCell>
								</TableRow>)}
								{!loading && (<>
									{copyPlanDetails.map((item, index) => (
										<TableRow key={`detail_item${index}`}>
											<TableCell>
												{!item.error && <Chip size="small" color="success" label="Complete" variant="outlined"/>}
												{item.error && <Chip size="small" color="error" label="Error" variant="contained"/>}
											</TableCell>
											<TableCell>
												{item.sourcePlan?.s_plan_id && (
													<IconButton onClick={() => navigate(`/clients/${item.sourcePlan.sClientCode}/live-plan/${item.sourcePlan.s_plan_id}/${item.sourcePlan.s_plan_version}`)}>
														<LaunchRoundedIcon />
													</IconButton>
												)}
											</TableCell>
											<TableCell>
												{item.destinationPlan?.d_plan_id && (
													<Box sx={{display: 'flex', alignItems: 'center',}}>
														<IconButton onClick={() => navigate(`/clients/${item.destinationPlan.dClientCode}/live-plan/${item.destinationPlan.d_plan_id}/${item.future_plan ? 1000 : item.destinationPlan.d_plan_version}`)}>
															<LaunchRoundedIcon />
														</IconButton>
														{item.destinationPlan.d_plan_id > 1 && <Box sx={css.extra_item}>P</Box>}
													</Box>
												)}
											</TableCell>
											<TableCell>{item.source_locationclassid}</TableCell>
											<TableCell>{item.source_epochmonth}</TableCell>
											<TableCell>{item.destination_locationclassid}</TableCell>
											<TableCell>{item.destination_epochmonth}</TableCell>
											<TableCell>{item.ar_override}</TableCell>
											<TableCell>{item.md_override}</TableCell>
											<TableCell>{item.imu_override}</TableCell>
											<TableCell>{item.turn_override}</TableCell>
											<TableCell>{item.sales_model_type}</TableCell>
											<TableCell>{item.imu_model_type}</TableCell>
											<TableCell>{item.future_plan}</TableCell>
											<TableCell>{item.insert_date}</TableCell>
											<TableCell>{item.copy_date}</TableCell>
											<TableCell>{item.error}</TableCell>
											<TableCell>
												<Button sx={{whiteSpace:'nowrap'}} variant="outlined" size="small" onClick={() => processLine(item.id)}>Process Line</Button>
											</TableCell>
										</TableRow>
									))}
								</>)}
									
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Paper>
			</Grid>
		</Grid>
	</Box>	
}

export default CopyPlanFileDetail

const css = {
	fileSection: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	fileItem: {
		m: 2,
	},
	fileItemName: {
		fontSize: 14,
		color: '#999',
		whiteSpace: 'nowrap'
	},
	fileItemValue: {

	},
	extra_item: {
		px: 0.4,
		backgroundColor: '#ffeb3b',
		fontWeight: 'bold',
	},
}