import React, { useEffect, useState } from 'react';
import {
    Box, Card, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, InputLabel,
    NativeSelect, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Button
} from "@mui/material";
import FlightService from '../../services/FlightService';
import PosService from '../../services/PosService';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

const cssInJs = {
    header: {
        backgroundColor: "#422e52",
        color: "#fff"
    },
    grayBlock: {
        background: '#fafafa',
        padding: '10px',
    },
    textInput: {
        marginRight: '20px',
        marginBottom: '15px'
    },
    formControl: {
        minWidth: 80,
        marginRight: '20px',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}
function FlightControlJobs({ onJobClick, onDataLoaded, setShowJobSettings }) {

    const [flightJobs, setFlightJobs] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentIcons, setCurrentIcons] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const jobs = await FlightService.getFlightJobs();
                const posData = await PosService.getPos();

                setFlightJobs(jobs);
                onDataLoaded(jobs);
                setPos(posData);
            } catch (error) {
                console.error('Error', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [onDataLoaded]);

    const handlePosChange = (event) => {
        setSelectedPos(event.target.value);
    };

    function generateUniqueId() {
        return new Date().getTime().toString(36);
    }

    const handleAddJob = (event) => {

        event.preventDefault();
        console.log(event);

        const newClientJob = {
            client: {
                ClientCode: '',
                Name: '',
                PointOfSaleId: '',
                ApiField: '',
                pos: { name: '' },
            },
        };

        setFlightJobs([newClientJob, ...flightJobs]);
        setSelectedJob(newClientJob);
    };

    setTimeout(() => {
        onDataLoaded();
    }, 2000);

    const handleTableRowClick = (job) => {
        onJobClick(job);
    };

    const handleIconClick = async (jobId) => {
        try {
            if (currentIcons[jobId] === 'PlayArrowIcon') {
                await FlightService.activateFlightJob(jobId);
            } else {
                await FlightService.deactivateFlightJob(jobId);
            }
            setCurrentIcons((prevIcons) => ({
                ...prevIcons,
                [jobId]: prevIcons[jobId] === 'PlayArrowIcon' ? 'StopIcon' : 'PlayArrowIcon',
            }));
        } catch (error) {
            console.error('Error', error);
        }
    };

    return (
        <Card>
            <CardHeader
                title={'Flight Control Job'}
                sx={{
                    ...cssInJs.header,
                    '& .MuiTypography-root': {
                        fontSize: '16px',
                    },
                }}
                action={
                    <Button onClick={(event) => handleAddJob(event)} variant="contained" sx={cssInJs.header}>
                        +
                    </Button>
                }
            />
            <Box sx={cssInJs.grayBlock}>
                
            </Box>
            <CardContent>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Code</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>POS</TableCell>
                                    <TableCell>UTC</TableCell>
                                    <TableCell>Client</TableCell>
                                    <TableCell>Stop Job</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {flightJobs.length > 0 ? (
                                    flightJobs.map((job) => (
                                        <TableRow key={job.id} onClick={() => handleTableRowClick(job)}>
                                        <TableCell>{job.client && job.client.ClientCode ? job.client.ClientCode : ""}</TableCell>
                                        <TableCell>{job.client && job.client.Name ? job.client.Name : ""}</TableCell>
                                        <TableCell>{job.client && job.client.PointOfSaleId ? job.client.PointOfSaleId : ""}</TableCell>
                                        <TableCell>{job.client && job.client.ApiField ? job.client.ApiField : ""}</TableCell>
                                        <TableCell>{job.client && job.client.pos && job.client.pos.name ? job.client.pos.name : ""}</TableCell>
                                        <TableCell key={job.id}>
                                            {currentIcons[job.id] === 'PlayArrowIcon' ? (
                                                <PlayArrowIcon onClick={() => handleIconClick(job.id)} />
                                            ) : (
                                                <StopIcon onClick={() => handleIconClick(job.id)} />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell align="left" colSpan={6}>
                                            No available data
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </CardContent>
        </Card>
    )

}

export default FlightControlJobs