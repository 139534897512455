import {
    useState,
    useEffect,
    useRef
} from "react";
import ImportClass from "./ImportClass";
import TableauService from "../../services/TableauService";
import ClientService from "../../services/ClientService";
import {useParams} from "react-router-dom";
import CurrentClientContext from "../../providers/CurrentClientProvider";
import AuthContext from "../../providers/AuthProvider";
import constants from '../../constants/constant'
import {Box, Button} from "@mui/material";

function ClientReportLibrary(props) {
    let { client_code, clientId } = useParams()
    const [source, setSource] = useState('bq')
    const auth_context = AuthContext.useAuth()
    const url = constants.BACKEND_DOMAIN + `/pdfreport/?user_id=${auth_context.user.id}&client_code=${clientId}&is_dev=1&source=${source}`

    useEffect(() => {
		document.title = 'Retail ORBIT: Client Report Library'
	}, [])

    return <>
        <Box style={{position: 'relative', height: 'auto', minHeight: '100vh'}}>
            <iframe key={source} style={{position: 'absolute', height: '100%', width: '100%', border: 'none'}} src={url}></iframe>
        </Box>
    </>
}

export default ClientReportLibrary