import { useEffect } from 'react'
import GeneralClientCreateForm from '../../components/client/setup/steps/GeneralClientCreateForm'

const ClientAdd = () => {
    useEffect(() => {
        document.title = 'Retail ORBIT: Add Client'
    }, [])

    return (
        <div>
            <GeneralClientCreateForm setSetupMode='create' />
        </div>
    )
}

export default ClientAdd