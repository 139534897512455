import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Box,
	Typography,
	IconButton,
	Paper,
	Switch,
	Chip,
	Menu,
	MenuItem,
	Collapse,
	TextField,
	Autocomplete,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
} from '@mui/material';
import {
	LoadingButton
} from '@mui/lab'
import SectionTitle from '../../../components/common/SectionTitle'
import ClientService from '../../../services/ClientService'
import LabelService from '../../../services/LabelService'
import _ from 'underscore'
import CurrentClientContext from '../../../providers/CurrentClientProvider'
import constant from '../../../constants/constant'
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';


function ClassBulkEdit(props) {
	const {classes, locations, onDeleted } = props
	const [labelData, setLabelData] = useState({})
	const currentClientContext = CurrentClientContext.useClient()
	const [saving, setSaving] = useState(false)
	const [deleting, setDeleting] = useState(false)
	const [openDeleteWarning, setOpenDeleteWarning] = useState(false)
	const [formData, setFormData] = useState({
		service_type_id: '',
		warehouse_location_id: '',
		labels: [],
		locations: [],
	})

	useEffect(() => {
		LabelService.getLabels().then(success => {
			setLabelData(success)
		})
	}, [])

	const common_input_props = {
		fullWidth: true,
		variant: "outlined",
		size: "small",
		onChange: e => updateFormData(e),
		sx: {mt:2},
	}

	function updateFormData(e) {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
	}

	function labelChange(event, newValue) {
		updateFormData({
			target: {
				name: 'labels',
				value: newValue
			}
		})
	}

	async function bulkDelete() {
		try {
			setDeleting(true)
			await ClientService.bulkDeleteClasses(currentClientContext.client.Id, {
				classes: classes.map(i => {return i.id}),
			})
			if( onDeleted ) {
				onDeleted(classes)
			}
		} catch(e) {
		}
		handleDeleteWarningClose()
		setDeleting(false)
	}

	async function bulkUpdate() {
		try {
			setSaving(true)
			await ClientService.bulkUpdateClasses(currentClientContext.client.Id, {
				classes: classes.map(i => {return i.id}),
				data: {
					service_type_id: formData.service_type_id,
					warehouse_location_id: formData.warehouse_location_id,
					labels: formData.labels.map(i => {return i.id}),
					locations: formData.locations.map(i => {return i.id}),
				}
			})
			setFormData({
				service_type_id: '',
				warehouse_location_id: '',
				labels: [],
				locations: []
			})
		} catch(e) {
		}
		setSaving(false)
	}

	function handleDeleteWarningClose() {
		setOpenDeleteWarning(false)
	}

	function locationChange(event, newValue) {
		updateFormData({
			target: {
				name: 'locations',
				value: newValue
			}
		})
	}

	return <Paper elevation={2}>
		<SectionTitle
			label={`Bulk edit classes (${classes.length})`}
			type="primary"
			rightComponent={
				<>
					<IconButton onClick={e => setOpenDeleteWarning(true)} sx={{color:'white'}}><DeleteForeverOutlinedIcon /></IconButton>
				</>
			}
		>
		</SectionTitle>
		<Box sx={{p:2}}>
			{!_.isEmpty(labelData) &&
				<>
					<TextField
						{...common_input_props}
						select
						name="service_type_id"
						value={formData.service_type_id}
						label="Service Type"
					>
 						<MenuItem value='1'>OTB</MenuItem>
 						<MenuItem value='2'>PNOTB</MenuItem>
 						<MenuItem value='3'>NOTB</MenuItem>
 						<MenuItem value='7'>History</MenuItem>
 						<MenuItem value='8'>Distress</MenuItem>
 						<MenuItem value='9'>Warehouse</MenuItem>
					</TextField>
					<TextField
						{...common_input_props}
						select
						name="warehouse_location_id"
						value={formData.warehouse_location_id}
						label="Warehouse Location"
					>
					{locations.map(_location => {
						return <MenuItem key={`Warehouse_location_${_location.client_location_id}`} value={_location.client_location_id}>{_location.location_name}</MenuItem>
					})}
					</TextField>
					<Autocomplete
						multiple
						disableCloseOnSelect
						options={labelData.labels}
						getOptionLabel={(option) => option.name}
						renderOption={(props, option) => {
							return (
							<li {...props} key={option.id}>
								{option.name}
							</li>
							);
						}}
						value={formData.labels}
						filterSelectedOptions
						renderInput={(params) => (
							<TextField
								{...params}
								{...common_input_props}
								label="M1 Tags"
							/>
						)}
						onChange={labelChange}
					/>
					<Autocomplete
						multiple
						disableCloseOnSelect
						options={locations}
						getOptionLabel={(option) => option.name}
						renderOption={(props, option) => {
							return (
							<li {...props} key={`assign_location_${option.id}`}>
								[{option.code}] {option.name}
							</li>
							);
						}}
						value={formData.locations}
						filterSelectedOptions
						renderInput={(params) => (
							<TextField
								{...params}
								{...common_input_props}
								label="Locations"
							/>
						)}
						onChange={locationChange}
					/>
					<Box sx={{mt:2}}>
						<LoadingButton
							loading={saving}
							variant="contained"
							startIcon={<SaveTwoToneIcon />}
							onClick={bulkUpdate}
						>Save</LoadingButton>
					</Box>
				</>
			}
		</Box>
		<Dialog
			open={openDeleteWarning}
			onClose={handleDeleteWarningClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Confirm delete?
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					You are deleting class:
					{classes.map(i => {
						return <Typography component="span" key={`delete_warning_class_${i.id}`}> {i.name}, </Typography>
					})}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleDeleteWarningClose}>Cancel</Button>
				<LoadingButton loading={deleting} onClick={bulkDelete} variant='contained' startIcon={<DeleteForeverOutlinedIcon />}>
					Delete
				</LoadingButton>
			</DialogActions> 
		</Dialog>
	</Paper>
}
export default ClassBulkEdit