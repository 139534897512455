import * as React from "react";

let CurrentClientContext = React.createContext({});

function Provider(props) {
  let [client, setClient] = React.useState();

  let inject = {
    client,
    setClient,
  }
  return <CurrentClientContext.Provider value={inject}>{props.children}</CurrentClientContext.Provider>;
}

function useClient() {
  return React.useContext(CurrentClientContext)
}

export default {
  Provider,
  useClient,
}
