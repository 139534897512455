import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, TextField } from '@mui/material';
import PlanService from '../../../services/PlanService';
import { UploadFile } from '@mui/icons-material';
import TipContext from '../../../providers/GlobalTip';
import { handleTrackError } from '../../../services/AmplitudeService';

const MassUploadPlanUploadModal = ({ open, onClose, client }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [saving, setSaving] = useState(false);
    const tipContext = TipContext.useMessage();

    // Handle file selection
    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const uploadFile = async (file) => {
        if (!file) {
            return;
        }
        if (!client) {
            tipContext.show('Something went wrong. No client found.');
            return;
        }
        setSaving(true);
        try {
            await PlanService.uploadMassUploadPlanFile(file, client.Id);
            tipContext.showSuccess('File uploaded successfully');
        } catch (error) {
            console.error('Upload failed:', error);
            tipContext.show(error.response?.data.message || 'Upload failed');

            handleTrackError({ error, pageName: 'Mass Upload Plan', overrideMessage: 'Failed to upload mass upload plan file' });
        } finally {
            setSaving(false);
        }
    };

    const downloadExampleFile = async () => {
        // download from public folder example_files/mass_copy_plan_example.xlsx
        window.open(`${process.env.PUBLIC_URL}/example_files/mass_copy_plan_example.xlsx`, '_blank');
    };    


    // Handle Save action
    const handleSave = () => {
        if (selectedFile) {
        uploadFile(selectedFile);
        }
    };

    return (
        <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        >
        <DialogTitle>Upload Mass Copy Plan File</DialogTitle>
        <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="body1" sx={{ backgroundColor: '#f0f4f8', padding: '12px', borderRadius: '8px' }}>
                <strong>Required Columns:</strong>
                <ul>
                    <li>LCI (number, req)</li>
                    <li>EpochMonth (number, req)</li>
                    <li>IMU (number, req)</li>
                    <li>SalesType (1 = Sales Percent or 2 Sales Dollars, req)</li>
                    <li>AnnualRate (number, req)</li>
                    <li>Sales1-12 (number, req)</li>
                    <li>InflowModel (number, req)</li>
                    <li>Turn (number, req)</li>
                    <li>InventoryType (1 = Stock to Sales, 2 = Inv Dollars, req)</li>
                    <li>Inventory1-12 (number, req)</li>
                    <li>Inventory13 (if blank uses first month inv. Must be $ amount if used.)</li>
                    <li>MarkdownType (1 = MD Percents, 2 = MD Dollars, req)</li>
                    <li>AnnualMarkdown% (decimal, req, don't use % symbol)</li>
                    <li>Markdown1-12 (number, req)</li>
                </ul>
            </Typography>

            {/* File upload button */}
            <Box display="flex" alignItems="center" gap={2}>
                <Button
                variant="outlined"
                component="label"
                startIcon={<UploadFile />}
                sx={{ textTransform: 'none' }}
                >
                    {selectedFile ? selectedFile.name : 'Choose File'}
                    <input type="file" accept=".xlsx, .xls" hidden onChange={handleFileSelect} />
                </Button>
                {selectedFile && (
                    <Typography variant="body2" color="textSecondary">
                        Selected file: {selectedFile.name}
                    </Typography>
                )}
            </Box>

            {/* Display when a file is selected */}
            {selectedFile && (
                <Box mt={2} p={2} border="1px dashed #ccc" borderRadius="8px">
                    <Typography variant="body2" color="textSecondary">
                        File details: {selectedFile.name} ({Math.round(selectedFile.size / 1024)} KB)
                    </Typography>
                </Box>
            )}
            </Box>
        </DialogContent>
            <DialogActions sx={{ padding: '16px' }}>
                <Button onClick={downloadExampleFile} color="primary" variant="outlined" sx={{ mr: 2 }}>
                    Download Example File
                </Button>
                <Button onClick={onClose} color="secondary" variant="outlined" sx={{ mr: 2 }}>
                    Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={handleSave} disabled={saving || !selectedFile}>
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MassUploadPlanUploadModal;
