import {
    useState,
    useEffect,
    useRef
} from "react";
import {
    Box,
    IconButton,
    Chip,
    Switch,
    CircularProgress,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
    TextField,
    InputAdornment,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import jss from '../profile/ProfileCommonJss'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import constant from '../../../constants/constant'
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ClientService from "../../../services/ClientService";
import TipContext from "../../../providers/GlobalTip";

function ClientCategoryClassList(props) {
    const {
        classes,
        allClasses,
        onClassChange,
        multiMode,
        onMultiModeChange=false,
        currentCategory,
        updateCategories,
        isAdmin = false,
    } = props
    const [existClasses, setExistClasses] = useState([])
    const [choosedClasses, setChoosedClasses] = useState([])
    const [keyword, setKeyword] = useState()
    const [showAllCheck, setShowAllCheck] = useState(false)
    const [filteredClasses, setFilteredClasses] = useState([])
    const [filter, setFilter] = useState({
        otb: false,
        pnotb: false,
        notb: false,
        deleted: false,
    })
    const [openFilter, setOpenFilter] = useState(false)
    const filterElement = useRef()
    const tipContext = TipContext.useMessage()

    const [classesUpdating, setClassesUpdating] = useState({})

    useEffect(() => {
        setExistClasses([])
        if (currentCategory) {
            let _existClasses = [];
            currentCategory.classes.map(_cl => _existClasses.push(_cl.id*1))
            setExistClasses(_existClasses)

            // for each class add {class_id: false} to classesUpdating
            let _classesUpdating = {}
            currentCategory.classes.map(_cl => _classesUpdating[_cl.id] = false)
            setClassesUpdating(_classesUpdating)
        }
    }, [currentCategory])

    useEffect(() => {
        if( classes ) {
            let _classes = classes.map(i => i)
            if (showAllCheck) {
                _classes = allClasses
            }
            let _filteredClasses = _classes.filter(item => {
                if( keyword && item.name.toLowerCase().indexOf(keyword.toLowerCase())==-1 ) {
                    return false
                }
                if( filter.deleted ) {
                    return true
                } else {
                    return !item.deleted_at
                }
            }).filter(item => {
                let result = false
                if( !filter.otb && !filter.pnotb && !filter.notb ) {
                    result = true
                } else {
                    if( filter.otb && item.service_type_id==constant.SERVICE_TYPE_ID.OTB ) {
                        result = true
                    }
                    if( filter.pnotb && item.service_type_id==constant.SERVICE_TYPE_ID.PNOTB ) {
                        result = true
                    }
                    if( filter.notb && item.service_type_id==constant.SERVICE_TYPE_ID.NOTB ) {
                        result = true
                    }
                }
                return result
            })

            setFilteredClasses(_filteredClasses)
        }
    }, [classes, showAllCheck, filter])

    function chooseClass(clientClass) {
        if( multiMode ) {
            let _choosedClasses = choosedClasses.map(i => {return i})
            if( choosedClasses.indexOf(clientClass)>-1 ) {
                _choosedClasses.splice(choosedClasses.indexOf(clientClass), 1)
            } else {
                _choosedClasses.push(clientClass)
            }
            setChoosedClasses(_choosedClasses)
        } else {
            setChoosedClasses([clientClass])
        }
    }

    function changeFilter(type) {
        if( type == 'all' ) {
            setFilter({...filter, otb:false, pnotb:false, notb:false})
        } else {
            setFilter({...filter, [type]: !filter[type]})
        }
    }

    function updateKeyword(e) {
        setKeyword(e.target.value)
    }

    function swtichAllCheck() {
        setShowAllCheck(!showAllCheck)
    }

    /**
     * Toggles the category assignment to a class
     * 
     * @param {} event 
     * @param {*} _class 
     */
    async function toggleCategoryForClass(event, _class) {
        setClassesUpdating({...classesUpdating, [_class.id]: true})

        // if the category is already assigned to the class, remove it, otherwise Add
        let adding = true
        if (existClasses.indexOf(_class.id * 1) !== -1) {
            adding = false
        }

        try {
            if (adding) {
                await ClientService.addCategoryToClass(currentCategory.ClientId, _class.id, currentCategory.Id)
                setExistClasses([...existClasses, _class.id * 1])
                onClassChange(_class, 'add');
            } else {
                await ClientService.removeCategoryFromClass(currentCategory.ClientId, _class.id, currentCategory.Id)
                setExistClasses(existClasses.filter(i => i !== _class.id * 1))
                onClassChange(_class, 'remove');
            }
            tipContext.showSuccess("Category toggled on class successfully")
        } catch (e) {
            console.log(e)

            tipContext.show("Something went wrong toggline the category for the class")
        }
        setClassesUpdating({...classesUpdating, [_class.id]: false})
    }


    return <Box sx={jss.dataGridItem}>
        <Box sx={jss.dataTop}>
            <Box sx={jss.dataTitle}>
                Classes
                {onMultiModeChange &&
                    <FormControlLabel
                        control={<Switch value={multiMode} size="small" onChange={onMultiModeChange}/>}
                        label="Multi select"
                        sx={{ml:2}}
                    />
                }
            </Box>
        </Box>
        <Box sx={jss.actionSection}>
            <TextField
                size="small"
                variant="outlined"
                placeholder="Search"
                value={keyword}
                onChange={updateKeyword}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Box sx={jss.dataAction}>
                <IconButton size="small" onClick={e => setOpenFilter(true)} ref={filterElement}><FilterListRoundedIcon /></IconButton>
                {isAdmin ? 
                    <IconButton size="small" sx={showAllCheck ? {...jss.iconButtonActive} : {}} onClick={swtichAllCheck}><PlaylistAddCheckIcon /></IconButton>
                : null}
                <Menu open={openFilter} anchorEl={filterElement.current} onClose={e => {setOpenFilter(false)}}>
                    <MenuItem sx={{width:200}} dense onClick={e => changeFilter('all')}>
                        <ListItemIcon>{(!filter.otb && !filter.pnotb && !filter.notb) && <Check />}</ListItemIcon>
                        <ListItemText >Show All</ListItemText>
                    </MenuItem>
                    <MenuItem sx={{width:200}} dense onClick={e => changeFilter('otb')}>
                        <ListItemIcon>{filter.otb && <Check />}</ListItemIcon>
                        <ListItemText >OTB</ListItemText>
                    </MenuItem>
                    <MenuItem sx={{width:200}} dense onClick={e => changeFilter('pnotb')}>
                        <ListItemIcon>{filter.pnotb && <Check />}</ListItemIcon>
                        <ListItemText >PNOTB</ListItemText>
                    </MenuItem>
                    <MenuItem sx={{width:200}} dense onClick={e => changeFilter('notb')}>
                        <ListItemIcon>{filter.notb && <Check />}</ListItemIcon>
                        <ListItemText >NOTB</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem sx={{width:200}} dense onClick={e => changeFilter('deleted')}>
                        <ListItemIcon>{filter.deleted && <Check />}</ListItemIcon>
                        <ListItemText >Dropped</ListItemText>
                    </MenuItem>
                </Menu>
            </Box>
        </Box>
        {!classes &&
            <Box sx={jss.loadingSection}>
                <CircularProgress />
            </Box>
        }
        {classes &&
            <Box sx={jss.dataContent}>
                {filteredClasses.map((_class, i) => {
                    let _className = ""
                    if( choosedClasses?.map(i=>{return i.id}).indexOf(_class.id)>-1 ) {
                        _className = '_active'
                    }
                    if( !multiMode ) {
                        if( choosedClasses?.map(i=>{return i.parent_class_id}).indexOf(_class.id)>-1 ) {
                            _className = '_parent_active'
                        } else if( choosedClasses.length>0 && choosedClasses?.map(i=>{return i.id}).indexOf(_class.parent_class_id)>-1 ) {
                            _className = '_child_active'
                        }
                    }
                    if( _class.deleted_at ) {
                        _className += ' _deleted '
                    }
                    if( _class?._type == 'child' ) {
                        _className += ' _child'
                    }
                    return <Box
                        sx={jss.dataContentItem}
                        className={_className}
                        key={`category_class_${_class.id}_${i}`}
                    >
                        {showAllCheck &&
                            <Checkbox
                                disabled={classesUpdating[_class.id]}
                                size="small"
                                checked={existClasses.indexOf(_class.id * 1) > -1}
                                onChange={e => toggleCategoryForClass(e, _class)}
                            />
                        }
                        <Box sx={jss.dataContentItem.name} onClick={e => chooseClass(_class)}>
                            {_class.name}
                        </Box>
                        <Box sx={jss.dataContentItem.action} onClick={e => chooseClass(_class)}>
                            {_class.code}
                            {_class.service_type_id==constant.SERVICE_TYPE_ID.OTB &&
                                <Chip label="O" size="small"/>
                            }
                            {_class.service_type_id==constant.SERVICE_TYPE_ID.PNOTB &&
                                <Chip label="P" size="small"/>
                            }
                            {_class.service_type_id==constant.SERVICE_TYPE_ID.NOTB &&
                                <Chip label="N" size="small"/>
                            }
                            {_class.service_type_id==constant.SERVICE_TYPE_ID.History &&
                                <Chip label="H" size="small"/>
                            }
                            {_class.service_type_id==constant.SERVICE_TYPE_ID.Distress &&
                                <Chip label="D" size="small"/>
                            }
                            {_class.service_type_id==constant.SERVICE_TYPE_ID.Warehouse &&
                                <Chip label="W" size="small"/>
                            }
                            {_class.service_type_id==constant.SERVICE_TYPE_ID.Child &&
                                <Chip label="C" size="small"/>
                            }
                        </Box>
                    </Box>
                })}
                {filteredClasses.length==0 &&
                    <Box sx={jss.dataContent.empty}>
                        <Box>There are no classes for the selected category.</Box>
                        <Box>You can select classes for this location.</Box>
                    </Box>
                }
            </Box>
        }

    </Box>
}

export default ClientCategoryClassList