import {useState, useEffect, useMemo} from 'react';
import {
  Modal,
  Box,
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'underscore';
import ClientService from '../../services/ClientService';
import { LoadingButton } from '@mui/lab';
import PlanService from '../../services/PlanService';
import MessageContext from '../../providers/GlobalTip';

function PlanCopyFromModal(props) {
  const {
    open,
    onClose,
    onConfirm,
    loading=false,
    type='base',
    defaultClientId=localStorage.getItem("defaultClient")??null,
    defaultLocationId=localStorage.getItem("defaultLocation")??null,
    defaultClassId=null,
  } = props;
  const [clients, setClients] = useState(null);
  const [selectClient, setSelectClient] = useState(null);
  const [selectClientDetail, setSelectClientDetail] = useState(null);
  const [selectLocation, setSelectLocation] = useState(null);
  const [selectClass, setSelectClass] = useState(null);
  const [finalLocationClass, setFinalLocationClass] = useState(null);
  const messageContext = MessageContext.useMessage();

  useEffect(() => {
    if (open) {
      loadClients();
    }
    return () => {
      setClients(null);
      setSelectClient(null);
      setSelectClientDetail(null);
      setSelectLocation(null);
      setSelectClass(null);
      setFinalLocationClass(null);
    }
  }, [open])

  useEffect(() => {
    if (clients && defaultClientId && !selectClient) {
      setSelectClient(_.find(clients, i => i.Id==defaultClientId))
    }
  }, [clients])

  useEffect(() => {
    if (selectClient) {
      loadClientDetail(selectClient.ClientCode);
    }
  }, [selectClient])

  useEffect(() => {
    if (selectClientDetail) {
      if (defaultLocationId) {
        setSelectLocation(_.find(selectClientDetail.locations, i => i.client_location_id==defaultLocationId))
      }
      if (defaultClassId) {
        setSelectClass(_.find(selectClientDetail.classes, i => i.id==defaultClassId))
      }
    }
  }, [selectClientDetail])

  useEffect(() => {
    if (selectLocation && selectClass) {
      const final = _.find(selectClientDetail.location_classes, i => i.client_location_id==selectLocation.client_location_id && i.clientclass_id==selectClass.id)
      if (final) {
        setFinalLocationClass(final)
      } else {
        setFinalLocationClass(null)
      }
    } else {
      setFinalLocationClass(null)
    }
  }, [selectLocation, selectClass]);

  const loadClients = async() => {
    const _clients = await ClientService.searchClients()
    setClients(_clients)
  }

  const loadClientDetail = async(client_code) => {
    const detail = await ClientService.getClientByCode(client_code)
    setSelectClientDetail(detail)
  }

  const onCopyFromBase = () => {
    onConfirm({
      'type': 'base', 
      'locationClass': finalLocationClass
    });
  }

  const onCopyFromHistory = () => {
    onConfirm({
      'type': 'history', 
      'locationClass': finalLocationClass
    });
  }

  const onCopyFromLive = () => {
    onConfirm({
      'type': 'live', 
      'locationClass': finalLocationClass
    });
  }

  const filteredClasses = useMemo(() => {
    if (selectClientDetail && selectLocation) {
      const availableClassIdList = _.pluck(selectClientDetail.location_classes.filter(i => i.client_location_id==selectLocation.client_location_id && PlanService.isPlannable(i)), 'clientclass_id');
      return _.sortBy(selectClientDetail.classes.filter(i => availableClassIdList.indexOf(parseInt(i.id))>-1), 'code');
    } else {
      return [];
    }
  }, [selectLocation, selectClientDetail])

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={css.container}>
        <Typography variant="h6">Copy from</Typography>
        {!clients && (
          <Box sx={css.loadingSection}>
            <CircularProgress />
          </Box>
        )}
        {_.isArray(clients) && (
          <Autocomplete
            sx={{mt: 2}}
            defaultValue={{ Id: defaultClientId }}
            getOptionLabel={(item) => `[${item.ClientCode}] ${item.Name}`}
            options={clients}
            value={selectClient}
            onChange={(e, newValue) => setSelectClient(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Client" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={`client_copy_${option.Id}`}>{`[${option.ClientCode}] ${option.Name}`}</li>
            )}
          />
        )}
        {_.isArray(clients) && selectClient && !selectClientDetail && (
          <Box sx={css.loadingSection}>
            <CircularProgress />
          </Box>
        )}
        {selectClientDetail && (
          <Autocomplete
            defaultValue={{ client_location_id: defaultLocationId }}
            getOptionLabel={(item) => `[${item.location_code}] ${item.location_name}`}
            options={selectClientDetail.locations}
            value={selectLocation}
            sx={{mt: 2}}
            onChange={(e, newValue) => setSelectLocation(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Location" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={`client_location_${option.client_location_id}`}>[{option.location_code}] {option.location_name}</li>
            )}
          />
        )}
        {selectClientDetail && selectLocation && (
          <Autocomplete
            getOptionLabel={(item) => `[${item.code}] ${item.name}`}
            options={filteredClasses}
            value={selectClass}
            sx={{mt: 2}}
            onChange={(e, newValue) => setSelectClass(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Class" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={`client_class_${option.id}`}>[{option.code}] {option.name}</li>
            )}
          />
        )}
        <Box sx={{mt: 2}}>
          {type=='base' && (<>
            <LoadingButton
              loading={loading}
              size="large"
              variant="contained"
              onClick={onCopyFromBase}
              disabled={!finalLocationClass}
            >Base Plan</LoadingButton>
            <LoadingButton
              loading={loading}
              sx={{ml: 2}}
              size="large"
              variant="contained"
              onClick={onCopyFromHistory}
              disabled={!finalLocationClass}
            >History Plan</LoadingButton>
          </>)}
          {type=='live' && (<>
            <LoadingButton
              loading={loading}
              size="large"
              variant="contained"
              onClick={onCopyFromLive}
              disabled={!finalLocationClass}
            >Live Plan</LoadingButton>
          </>)}
        </Box>
      </Box>
    </Modal>
  );
}

export default PlanCopyFromModal;

const css = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    px: 3,
    py: 2,
  },
  loadingSection: {
    textAlign: 'center',
    mt: 2,
  },
}