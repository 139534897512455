import { useState, useEffect, useRef } from "react";
import {
	Box,
	Typography,
	Paper,
	Skeleton,
	IconButton,
	Checkbox,
	Button,
	Grid,
} from '@mui/material';
import SectionTitle from '../../../components/common/SectionTitle'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClientService from '../../../services/ClientService'
import _ from 'underscore'

function ClientProfileClass(props) {
	const { client, isAdmin } = props
	const [data, setData] = useState({})

	useEffect(() => {
		if( client ) {
			loadStatistics()
		}
	}, [client])

	async function loadStatistics() {
		const data = await ClientService.getClientClassStatistics(client.Id)
		setData(_.indexBy(data, 'type'))
	}

	return <Paper elevation={2}>
		<SectionTitle 
			label="Classes"
			rightComponent={
				isAdmin ?
					<Button size="small">
						Manage Classes
					</Button>
				: null
			}/>
		<Grid container>
			<Grid item xs={3} sx={{p:2,textAlign:'center'}}>
				<Typography variant="body2">OTB</Typography>
				<Typography variant="h5" sx={{mt:1,color:'primary.main'}}>{data?.OTB ? data?.OTB.count : 0}</Typography>
			</Grid>
			<Grid item xs={3} sx={{p:2,textAlign:'center'}}>
				<Typography variant="body2">PNOTB</Typography>
				<Typography variant="h5" sx={{mt:1,color:'primary.main'}}>{data?.PNOTB ? data?.PNOTB.count : 0}</Typography>
			</Grid>
			<Grid item xs={3} sx={{p:2,textAlign:'center'}}>
				<Typography variant="body2">NOTB</Typography>
				<Typography variant="h5" sx={{mt:1,color:'primary.main'}}>{data?.NOTB ? data?.NOTB.count : 0}</Typography>
			</Grid>
			<Grid item xs={3} sx={{p:2,textAlign:'center'}}>
				<Typography variant="body2">Other</Typography>
				<Typography variant="h5" sx={{mt:1,color:'primary.main'}}>{data?.Child ? data?.Child.count : 0}</Typography>
			</Grid>
		</Grid>
	</Paper>
}
export default ClientProfileClass