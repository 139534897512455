import BaseModel from './BaseModel'
import constants from '../constants/constant'

export default class User extends BaseModel {
  constructor(object) {
    super(object)
  }

  get name() {
    return `${this.first_name} ${this.last_name}`
  }

  get roleTags() {
    let tags = []
    if( this.roles ) {
      this.roles.forEach(_role => {
        tags.push(_role.id)
      })
    }
    return tags
  }

  hasPermission(permission_slug) {
    let result = false
    if( this.permissions ) {
      this.permissions.forEach(_slug => {
        if( permission_slug == _slug ) {
          result = true
        }
      })
    }
    return result
  }

  getMyPodName(pods) {
    let result = null
    pods.map(_pod => {
      if( _pod.Id == this.pod_id ) {
        result = _pod.Name
      }
    })
    return result
  }



}