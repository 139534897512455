import React from 'react';
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import ClientService from '../../services/ClientService';
import TipContext from '../../providers/GlobalTip';

const LightspeedAuthLanding = () => {
    const [authorizing, setAuthorizing] = React.useState(false);
    const [authorized, setAuthorized] = React.useState(false);
    const tipContext = TipContext.useMessage();
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const clientCode = queryParams.get('state');

    React.useEffect(() => {
		document.title = 'Retail ORBIT: Lightspeed Auth Landing'
	}, [])

    React.useEffect(() => {
        // if code is available, send it to the server
        console.log(clientCode)
        if (code && clientCode) {
            ClientService.lightspeedAuthWithTempCode(clientCode, code)
                .then((response) => {
                    setAuthorized(true);
                    setAuthorizing(false);

                    // wait 3 seconds then redirect
                    setTimeout(() => {
                        navigate('/clients/' + clientCode + '/profile');
                    }, 3000);
                })
                .catch((error) => {
                    setAuthorizing(false);
                    console.log(error);
                    tipContext.show('Error authorizing lightspeed');
                });
        }
    }, [code, clientCode]);

    return (
        <div>
            {authorized ? 
                <>
                    <h1>Authorized. Redirecting in 3 seconds...</h1>
                    <p>If you are not redirected, click <a href={'/clients/' + client_code + '/profile'}>here</a></p>
                </>
            : null}
            {authorizing ? <h1>Authorizing</h1> : null}
            <p>You should be auto redirected once this process is complete</p>
        </div>
    );
};

export default LightspeedAuthLanding;