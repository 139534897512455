import TableauViz from "../components/tableau/TableauViz";
import { setUserId } from '@amplitude/analytics-browser';
import AuthProvider from "../providers/AuthProvider";
import React from "react";

function Home(props) {
	const auth = AuthProvider.useAuth()

	React.useEffect(() => {
		if (auth.user) {
			setUserId(auth.user.id)
		}
	}, [auth.user])

	React.useEffect(() => {
		document.title = 'Retail ORBIT: Home'
	}, [])

	return <>
		<script src="https://www.cognitoforms.com/f/seamless.js" data-key="zD8a_f7oHky2frHChpfffQ" data-form="5"></script>
		<TableauViz />
	</>
}

export default Home