import * as React from "react";
import {
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet
} from "react-router-dom";
import AuthService from '../services/AuthService'
import UserModel from '../models/User'
import _ from 'underscore';

let AuthContext = React.createContext(null);

function Provider(props) {
  let [user, setUser] = React.useState(AuthService.getLocalUser());
  let navigate = useNavigate()
  let [spoofing, setSpoofing] = React.useState(!!localStorage.getItem('spoof_origin_user'))
  let login = async (_user) => {
    setUser(_user)
    AuthService.setCurrentUser(_user)
    return _user
  }
  let logout = async () => {
    setUser(null);
    navigate('/login');
    return await AuthService.logout()
  }
  let checkPermission = (permission_slug) => {
    let result = false
    user = new UserModel(user)
    if( _.isArray(permission_slug) ) {
      permission_slug.forEach(_slug => {
        if( !result && user.hasPermission(_slug) ) {
          result = true
        }
      })
    } else {
      result = user.hasPermission(permission_slug)
    }
    return result
  }
  let spoofUser = async (user_id) => {
    const spoof_user = await AuthService.spoofUser(user_id)
    if( !localStorage.getItem('spoof_origin_user') ) {
      const current_user = AuthService.getLocalUser()
      localStorage.setItem('spoof_origin_user', JSON.stringify(current_user))
    }
    AuthService.setCurrentUser(spoof_user, true)
    setUser(spoof_user)
    setSpoofing(true)
    return true
  }
  let spoofBack = () => {
    const origin_user = JSON.parse(localStorage.getItem('spoof_origin_user'))
    AuthService.setCurrentUser(origin_user)
    localStorage.removeItem('spoof_origin_user')
    setUser(origin_user)
    setSpoofing(false)
  }
  let inject = { 
    user: JSON.stringify(user), 
    spoofing,
    login, 
    logout, 
    setUser,
    checkPermission,
    spoofUser,
    spoofBack,
  }
  return <AuthContext.Provider value={inject}>{props.children}</AuthContext.Provider>;
}

function useAuth() {
  let auth_context = React.useContext(AuthContext);
  if( typeof(auth_context.user) == 'string' ) {
    auth_context.user = new UserModel(JSON.parse(auth_context.user))
  }
  return auth_context
}

export default {
  Provider,
  useAuth,
}