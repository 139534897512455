export default {
	BACKEND_DOMAIN: process.env.REACT_APP_BACKEND_DOMAIN || 'http://localhost',
	SITE_DOMAIN: process.env.REACT_APP_DOMAIN || "http://localhost:3003",
	STATES: ['AK','AL','AR','AZ','CA','CO','CT','DC','DE','FL','GA','HI','IA','ID','IL','IN','KS','KY','LA','MA','MD','ME','MI','MN','MO','MS','MT','NC','ND','NE','NH','NJ','NM','NV','NY','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VA','VT','WA','WI','WV','WY'],
	ROLE_ID: {
		planner: '2',
		planmanager: '3',
		setup: '4',
		affiliate: '6',
		client: '7',
		referrer: '8',
		marketer: '9',
		reseller: '10',
		manager: '11',
		admin: 'd76bf050-3555-4a15-8e0a-3f6c57eb31a7',
		support: '8741bc0d-6bd5-4bdf-9b33-52fe9f25bf91'
	},
	SERVICE_TYPE_ID: {
		OTB: '1',
		PNOTB: '2',
		NOTB: '3',
		History: '7',
		Distress: '8',
		Warehouse: '9',
		Child: '10',
	}
}