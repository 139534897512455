import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	Link,
	useNavigate,
} from "react-router-dom";
import {
	Box,
	Typography,
	Container,
	Grid,
	Paper,
	Skeleton,
	IconButton,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	TableSortLabel,
	Button,
	Tooltip,
} from '@mui/material';
import {
	LoadingButton
} from '@mui/lab'
import UserService from '../../services/UserService'
import SectionTitle from '../../components/common/SectionTitle'
import AddIcon from '@mui/icons-material/Add';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import _ from 'underscore'
import UserModel from '../../models/User'
import TipContext from '../../providers/GlobalTip'
import AuthContext from '../../providers/AuthProvider'

const jss = {
	usersSection: {
		py: 2
	},
	filterContent: {
		p: 2
	},
	tableSection: {
		p: 2,
		pt: 0,
	}
}

function Users(props) {
	const tipContext = TipContext.useMessage()
	const [users, setUsers] = useState(null)
	const [sortColumn, setSortColumn] = useState('name')
	const [sortDirection, setSortDirection] = useState('asc')
	const [userCount, setUserCount] = useState(0)
	const [filteredUserCount, setFilteredUserCount] = useState(0)
	const [searchName, setSearchName] = useState()
	const auth_context = AuthContext.useAuth()
	const [filterTypes, setFilterTypes] = useState({
		affiliate: false,
		client: false,
		planner: false,
		managementone: false,
	})
	const roleIdMapping = {
		affiliate: '6',
		client: '7',
		planner: '2',
		managementone: 'd76bf050-3555-4a15-8e0a-3f6c57eb31a7',
	}
	const originUsers = useRef()
	const [openCreateUserForm, setOpenCreateUserForm] = useState(false)
	const [create_user_data, setCreateUserData] = useState({
		name: '',
		email: '',
	})
	const [create_user_form_errors, setCreateUserFormErrors] = useState({
		name: {value: false, message:''},
		email: {value: false, message:''},
	})
	const [creating, setCreating] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		document.title = 'Retail ORBIT: User Management (Admin)'

		if( !auth_context.checkPermission('user-page-show') ) {
			navigate('/')
			return
		}
		loadUsers()
	}, [])

	useEffect(() => {
		makeUserList()
	}, [sortColumn, sortDirection, filterTypes])

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			makeUserList()
		}, 600);
    	return () => clearTimeout(timeOutId)
	}, [searchName])

	function showNewUserForm() {
		setOpenCreateUserForm(true)
	}

	async function loadUsers() {
		const _users = await UserService.getUsers()
		setUserCount(_users.length)
		originUsers.current = _users.map(item => {
			return new UserModel(item)
		})
		makeUserList()
	}

	function makeUserList() {
		if( !originUsers.current ) {
			return
		}
		let _users = originUsers.current
		if( sortColumn ) {
			_users = _.sortBy(_users, sortColumn)
		}
		if( sortDirection=='desc' ) {
			_users = _users.reverse()
		}
		if( searchName ) {
			_users = _.filter(_users, item => {
				return (
					item.email.toLowerCase().indexOf(searchName.toLowerCase()) > -1 
					|| item.name.toLowerCase().indexOf(searchName.toLowerCase()) > -1 
				)
			})
		}
		if( 
			filterTypes.affiliate
			|| filterTypes.client
			|| filterTypes.planner
			|| filterTypes.managementone
		) {
			_users = _.filter(_users, item => {
				let _flag = false
				for(let i in filterTypes) {
					if( filterTypes[i] && !_flag ) {
						_flag = item.roleTags.indexOf( roleIdMapping[i] ) > -1 ? true : false
					}
				}
				return _flag
			})
		}
		setFilteredUserCount(_users.length)
		setUsers(_users.slice(0, 50))
	}

	function handleSort(column) {
		if( !sortColumn ) {
			setSortColumn(column)
		} else {
			if( sortColumn == column ) {
				setSortDirection( sortDirection=='asc' ? 'desc' : 'asc' )
			} else {
				setSortColumn(column)
				setSortDirection('asc')
			}
		}
	}

	function handleCreateForm(e) {
		setCreateUserData({
		 	...create_user_data, 
		 	[e.target.name]: e.target.value
		})
		setCreateUserFormErrors({
			...create_user_form_errors,
			[e.target.name]: { value: false, message: '' }
		})
	}

	async function saveUser() {
		if( !create_user_data.name ) {
			setCreateUserFormErrors({
				...create_user_form_errors,
				name: { value: true, message: 'Name is required'}
			})
			return
		}
		if( !create_user_data.email ) {
			setCreateUserFormErrors({
				...create_user_form_errors,
				email: { value: true, message: 'Email is required'}
			})
			return
		}
		if( !create_user_data.email.match(/.+?@.+?/g) ) {
			setCreateUserFormErrors({
				...create_user_form_errors,
				email: { value: true, message: 'Email format is incorrect'}
			})
			return
		}
		try {
			setCreating(true)
			const new_user = await UserService.createUser(create_user_data.name, create_user_data.email)
			tipContext.showSuccess('User create successfully')
			setOpenCreateUserForm(false)
			setCreateUserData({name: '', email: ''})
			setCreating(false)
			setUsers([new UserModel(new_user)].concat(users))
		} catch(e) {
			setCreating(false)
			tipContext.show(e.response?.data.message)
		}
	}

	function searchInput(e) {
		setSearchName(e.target.value)
	}

	async function spoofUser(user_id) {
		try {
			await auth_context.spoofUser(user_id)
			tipContext.showSuccess('Successful operation')
		} catch(e) {
			console.log(e)
			tipContext.show(e.response?.data.message)
		}
	}

	return <Grid container sx={jss.usersSection} spacing={2}>
		<Grid item xs={12} sm={3}>
			<Paper elevation={2}>
				<SectionTitle label="Filter" type="primary"/>
				<Box sx={jss.filterContent}>
					<TextField label="Search users" variant="outlined" fullWidth onChange={searchInput}/>
					<Typography variant="subtitle2" gutterBottom mt={3}>By type</Typography>
					<FormGroup>
						<FormControlLabel 
							control={
								<Checkbox 
									checked={filterTypes.affiliate}
									onChange={e => {
										setFilterTypes({...filterTypes, affiliate: e.target.checked})
									}}
								/>
							} 
							label="Affiliates" 
						/>
						<FormControlLabel 
							control={
								<Checkbox 
									checked={filterTypes.client}
									onChange={e => {
										setFilterTypes({...filterTypes, client: e.target.checked})
									}}
								/>
							} 
							label="Clients" 
						/>
						<FormControlLabel 
							control={
								<Checkbox 
									checked={filterTypes.planner}
									onChange={e => {
										setFilterTypes({...filterTypes, planner: e.target.checked})
									}}
								/>
							} 
							label="Planners" 
						/>
						<FormControlLabel 
							control={
								<Checkbox 
									checked={filterTypes.managementone}
									onChange={e => {
										setFilterTypes({...filterTypes, managementone: e.target.checked})
									}}
								/>
							} 
							label="Management One" 
						/>
					</FormGroup>
				</Box>
			</Paper>
		</Grid>
		<Grid item xs={12} sm={9}>
			<Paper elevation={2}>
				<SectionTitle 
					label="Users" 
					subLabel={users ? `Showing ${Math.min(users.length, userCount)} of ${filteredUserCount} [${userCount} Total]` : ''}
					rightComponent={
						<IconButton onClick={showNewUserForm}>
							<AddIcon color="primary"/>
						</IconButton>
					}
				/>
				<Box sx={jss.tableSection}>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left">
										Role
									</TableCell>
									<TableCell align="left">
										<TableSortLabel
											active={sortColumn=='name'}
											direction={sortDirection}
											onClick={e => {handleSort('name')}}
										>
											Name
										</TableSortLabel>
									</TableCell>
									<TableCell align="left">
										<TableSortLabel
											active={sortColumn=='email'}
											direction={sortDirection}
											onClick={e => {handleSort('email')}}
										>
											Email
										</TableSortLabel>
									</TableCell>
									<TableCell align="right">
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{openCreateUserForm && 
									<TableRow>
										<TableCell align="left"></TableCell>
										<TableCell align="left" sx={{verticalAlign: 'top'}}>
											<TextField 
												label="Name" 
												variant="standard" 
												fullWidth
												focused
												value={create_user_data.name} 
												error={create_user_form_errors.name.value}
												onChange={handleCreateForm}
												helperText={create_user_form_errors.name.message}
												name="name"
											/>
										</TableCell>
										<TableCell align="left" sx={{verticalAlign: 'top'}}>
											<TextField 
												label="Email" 
												type="email" 
												variant="standard" 
												fullWidth
												focused
												value={create_user_data.email} 
												error={create_user_form_errors.email.value}
												onChange={handleCreateForm}
												helperText={create_user_form_errors.email.message}
												name="email"
											/>
										</TableCell>
										<TableCell align="right">
											<LoadingButton loading={creating} color="primary" variant="contained" size="small" onClick={saveUser}>Create User</LoadingButton>
										</TableCell>
									</TableRow>
								}
								
								{users!==null && users.map(_user => {
									return <TableRow key={`user_${_user.id}`}>
										<TableCell align="left">
											{_user.roleTags.indexOf(roleIdMapping['affiliate']) > -1 && 
												<Tooltip title="Affiliate"><PersonOutlineOutlinedIcon /></Tooltip>
											}
											{_user.roleTags.indexOf(roleIdMapping['client']) > -1 && 
												<Tooltip title="Client"><BusinessOutlinedIcon /></Tooltip>
											}
											{_user.roleTags.indexOf(roleIdMapping['planner']) > -1 && 
												<Tooltip title="Planner"><BookmarkBorderOutlinedIcon /></Tooltip>
											}
											{_user.roleTags.indexOf(roleIdMapping['managementone']) > -1 && 
												<Tooltip title="Management One"><AdminPanelSettingsOutlinedIcon /></Tooltip>
											}
										</TableCell>
										<TableCell align="left">
											<Link to={`/users/${_user.id}`}>{_user.name}</Link>
										</TableCell>
										<TableCell align="left">
											<Link to={`/users/${_user.id}`}>{_user.email}</Link>
										</TableCell>
										<TableCell align="right">
											<Button color="primary" variant="contained" size="small" onClick={e => {spoofUser(_user.id)}}>Spoof</Button>
										</TableCell>
									</TableRow>
								})}
							</TableBody>
						</Table>
					</TableContainer>
					{users===null && 
						<>
							<Skeleton sx={{height:30, mt: 1.5}} />
							<Skeleton sx={{height:30, mt: 1.5}} />
							<Skeleton sx={{height:30, mt: 1.5}} />
							<Skeleton sx={{height:30, mt: 1.5}} />
							<Skeleton sx={{height:30, mt: 1.5}} />
							<Skeleton sx={{height:30, mt: 1.5}} />
							<Skeleton sx={{height:30, mt: 1.5}} />
							<Skeleton sx={{height:30, mt: 1.5}} />
						</>
					}
				</Box>
			</Paper>
		</Grid>
	</Grid>
}

export default Users