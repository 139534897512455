import constant from '../constants/constant'
import AuthService from "./AuthService";
const axios = require('axios')

const makeRequestHeader = () => {
	const access_token = localStorage.getItem('access_token')
	let headers = {}
	if( access_token ) {
		headers['Authorization'] = 'Bearer '+access_token
	}
	return headers
}

const get = async (uri, params, returnPromise = false) => {
	const headers = makeRequestHeader()
	try {
		if (returnPromise) {
			return axios.get(constant.BACKEND_DOMAIN+uri, {headers, params})
		}
		const response = await axios.get(constant.BACKEND_DOMAIN+uri, {headers, params})
		return response?.data
	} catch (e) {
		if (e.response.status === 401) {
			await AuthService.logout();
			window.location.href = '/login';
		}
	}
}

/**
 * Download file from api
 *
 * Sets responseType to 'blob' to download file
 *
 * @param {string} uri
 * @param {object} params
 *
 * @returns {Promise}
 */
const download = async (uri, params) => {
	const headers = makeRequestHeader()
	const response = await axios.get(constant.BACKEND_DOMAIN+uri, {headers, params, responseType: 'blob'})
	return response?.data
}

const post = async (uri, data, params) => {
	const headers = makeRequestHeader()
	const response = await axios.post(constant.BACKEND_DOMAIN+uri, data, {headers, params})
	return response?.data	
}

const formPost = async (uri, formData) => {
	const headers = makeRequestHeader()
	headers['Content-Type'] = 'multipart/form-data'
	const response = await axios.post(constant.BACKEND_DOMAIN+uri, formData, {headers})
	return response?.data	
}

const _delete = async (uri, params) => {
	const headers = makeRequestHeader()
	const response = await axios.delete(constant.BACKEND_DOMAIN+uri, {headers, params})
	return response?.data	
}

export default {
	get,
	post,
	formPost,
	_delete,
	download,
}