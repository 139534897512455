import React, { useEffect } from "react";
import {
    Outlet,
    useNavigate
} from "react-router-dom";
import AuthProvider from "../../providers/AuthProvider";

/**
 * Middleware component that checks user permissions before rendering the child components.
 * @param {Object} props - The component props.
 * @param {Array} props.permissions - The permissions required for accessing the child components.
 * @returns {JSX.Element} - The rendered Outlet component if the user has the required permissions, otherwise redirects to '/'.
 */
function PermissionMiddleware( { permissions } ) {
        const auth = AuthProvider.useAuth();
        const navigate = useNavigate();

        useEffect(() => {
                for (let permission of permissions) {
                        if (!auth.checkPermission(permission)) {
                                navigate('/');
                                return;
                        }
                }
        }, []);

    return <Outlet />;
}

export default PermissionMiddleware;
