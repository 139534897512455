import * as React from "react";
import {
	Container,
	Box,
} from '@mui/material'
import Typography from '@mui/material/Typography'

const jss = {
	footerSection: {

	}
}

function Footer(props) {
	return <Box sx={jss.footerSection}>
	</Box>;
}

export default Footer