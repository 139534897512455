import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
} from "react-router-dom";
import {
	Box,
	IconButton,
	TextField,
	Autocomplete,
	Collapse,
	CircularProgress,
} from '@mui/material'
import UserService from '../../services/UserService'
import constants from '../../constants/constant'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AuthContext from '../../providers/AuthProvider'

const jss = {
	clientName: {
		flex: 1,
		mr: 2,
		cursor: 'pointer',
	},
	item: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: '#f6fafd',
		borderBottom: '0.5px solid #ddd',
		px: 1.5,
		py: 0.5,
		":hover": {
			backgroundColor: '#f5ecf8'
		}
	},
}

function UserRoleItem(props) {
	const [user_role, setUserRole] = useState(props.role)
	const [deleting, setDeleting] = useState(false)
	const auth_context = AuthContext.useAuth()

	function toParentClientDetailPage(client_code) {
		window.parent.location.href = `${constants.RETAILORBIT_DOMAIN}/#/client/${client_code}`
	}

	async function deleteUserRole(link_id) {
		try {
			setDeleting(true)
			await UserService.unassignUserAndRole(user_role.auth_user_id, link_id)
			setUserRole(null)
		} catch(e) {
			setDeleting(false)
		}
	}

	if( user_role && user_role.client ) {
		return <Box sx={jss.item}>
			<Box onClick={e => {toParentClientDetailPage(user_role.client?.ClientCode)}} sx={jss.clientName}>{user_role.client?.ClientCode} - {user_role.client?.Name}</Box>
			{(auth_context.checkPermission('role-assign-user') && !deleting) && 
				<IconButton onClick={e => {deleteUserRole(user_role.id)}} >
					<DeleteForeverOutlinedIcon size="small" />
				</IconButton>
			}
			{deleting && 
				<IconButton disabled >
					<CircularProgress color="inherit" size={22} />
				</IconButton>
			}
		</Box>
	} else {
		return <></>
	}
	
}
export default UserRoleItem