import * as React from "react";
import {
	Outlet,
} from "react-router-dom";
import {
	CssBaseline,
	Container,
	Box,
	Button,
	Slide,
	Alert,
	Drawer,
	AppBar,
} from '@mui/material'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import Snackbar from '@mui/material/Snackbar'
import GlobalTip from '../providers/GlobalTip'
import TopAppBar from '../components/layout/TopAppBar'
import LeftNav from '../components/layout/LeftNav'

const cssInJs = {
  contentSection: { 
    flexGrow: 1, 
    px: 2,
    mt: "50px",
  }
}

const drawerWidth = 80;

function MessageTransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

function Layout(props) {

	let globalTipContext = GlobalTip.useMessage()
  const [navOpen, setNavOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setNavOpen(!navOpen);
  }

	return (
		<>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<TopAppBar width={drawerWidth} />
				<LeftNav width={drawerWidth} navOpen={navOpen} onNavClose={handleDrawerToggle} />
				<Box component="main" sx={{ 
          ...cssInJs.contentSection,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}>
        	<Outlet />
        </Box>

				<Snackbar open={globalTipContext.messageOpen} TransitionComponent={MessageTransitionDown} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
					<Alert variant="filled" severity="warning" sx={{ width: '100%' }}>
				    {globalTipContext.messageContent}
				  </Alert>
				</Snackbar>
				<Snackbar open={globalTipContext.successOpen} TransitionComponent={MessageTransitionDown} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
					<Alert variant="filled" severity="success" sx={{ width: '100%' }}>
				    {globalTipContext.successContent}
				  </Alert>
				</Snackbar>
			</Box>
		</>
	)
}

export default Layout