import React, {useState, useEffect} from 'react'
import {TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Card, CardContent} from "@mui/material"
import AddUserModal from './AddUserModal'
import ClientService from "../../../../services/ClientService";
import ClientContext from "../../../../providers/CurrentClientProvider";
import UserService from "../../../../services/UserService";

const cssInJs = {
    header: {
        backgroundColor: "#fafafa",
        color: "black",
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: '1px rgba(92,63,117,0.1) solid'

    },
    headerLink: {},
}

const UserStep = () => {
    const [isAddUserModalVisible, setAddUserModalVisible] = useState(false)
    const [userData, setUserData] = useState([]);
    const client_context = ClientContext.useClient()
    const [client, setClient] = useState({})

    useEffect(async () => {
        if (!userData.length && client_context.client.Id) {
            let ud = await ClientService.getClientUsersById(client_context.client.Id)
            setUserData(ud)
        }
    }, []);
    const toggleAddUserModal = (e) => {

        if (e) {
            e.preventDefault();
        }
        setAddUserModalVisible(!isAddUserModalVisible);
    }
    const handleSaveUserData = async (data, role) => {

        let newData = {
            user: data,
            role: role
        }

        let _ud = userData.map(u => u)
        _ud.push(newData)
        setUserData(_ud);
    };


    return (
        <Card>
            <div style={cssInJs.header}>
                <span>Users</span>
                <a href="#" style={cssInJs.headerLink} onClick={toggleAddUserModal}>
                    Add User
                </a>
            </div>
            <CardContent>
                <TableContainer>
                    <Table size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                            <TableCell align="left" style={{width: '25%'}}>Role</TableCell>
                            <TableCell style={{width: '25%'}}>Name</TableCell>
                            <TableCell style={{width: '50%'}}>Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                                {userData && userData.length ? (
                                    userData.map( user => {
                                        return <TableRow>
                                            <TableCell align="left">{user.role.name || ''}</TableCell>
                                            <TableCell>{((user.user.first_name ?? '') + ' ' +  (user.user.last_name ?? '')) || ''}</TableCell>
                                            <TableCell>{user.user.email || ''}</TableCell>
                                        </TableRow>
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell align="left">No available</TableCell>
                                        <TableCell align="left">No available</TableCell>
                                    </TableRow>
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <AddUserModal open={isAddUserModalVisible} onClose={toggleAddUserModal} onSave={handleSaveUserData} clientId={client_context.client.Id}/>
        </Card>
    )
}

export default UserStep
