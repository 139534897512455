import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Chip,
	Paper,
	Switch,
	CircularProgress,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Divider,
	TextField,
	InputAdornment,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import jss from './ProfileCommonJss'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import constant from '../../../constants/constant'
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import LocationService from '../../../services/LocationService'
import AddRoundedIcon from "@mui/icons-material/AddRounded";


function ClientProfileClassList(props) {
	const {
		classes, 
		currentLocations, 
		onClassChange, 
		onCurrentLocationChange, 
		multiMode,
		createClass,
		assignAvailable=false,
		onMultiModeChange=false,
		isAdmin = false,
	} = props
	const [existClasses, setExistClasses] = useState([])
	const [choosedClasses, setChoosedClasses] = useState([])
	const [keyword, setKeyword] = useState()
	const [showAllCheck, setShowAllCheck] = useState(false)
	const [allCheckedItems, setAllCheckedItems] = useState([])
	const [filter, setFilter] = useState({
		otb: false,
		pnotb: false,
		notb: false,
		dis: false,
		history: false,
		deleted: false
	})
	const [openFilter, setOpenFilter] = useState(false)
	const filterElement = useRef()

	useEffect(() => {
		if( currentLocations && currentLocations.length>0 ) {
			let _exists = []
			currentLocations.forEach(i => {
				i.class_links.forEach(_link => {
					if( _exists.indexOf(_link.clientclass_id)==-1 ) {
						_exists.push(_link.clientclass_id)
					}
				})
			})
			setExistClasses(_exists)
			setChoosedClasses([])
		}
	}, [currentLocations])

	useEffect(() => {
		if( choosedClasses.length>0 ) {
			onClassChange(choosedClasses)	
		}
	}, [choosedClasses])

	useEffect(() => {
		setChoosedClasses([])
	}, [multiMode])

	function chooseClass(clientClass) {
		if( multiMode ) {
			let _choosedClasses = choosedClasses.map(i => {return i})
			if( choosedClasses.indexOf(clientClass)>-1 ) {
				_choosedClasses.splice(choosedClasses.indexOf(clientClass), 1)
			} else {
				_choosedClasses.push(clientClass)
			}
			setChoosedClasses(_choosedClasses)
		} else {
			setChoosedClasses([clientClass])
		}
	}

	function changeFilter(type) {
		if( type == 'all' ) {
			setFilter({...filter, otb:false, pnotb:false, notb:false, history: false, dis: false})
		} else {
			setFilter({...filter, [type]: !filter[type]})
		}
	}

	function updateKeyword(e) {
		setKeyword(e.target.value)
	}

	function swtichAllCheck() {
		setShowAllCheck(!showAllCheck)
		if( showAllCheck==true ) {
			setAllCheckedItems([])
		}
	}

	async function changeClassLocationLink(clientClass) {
		try {
			const copyCurrentLocation = {...currentLocations[0]}
			if( existClasses.indexOf(clientClass.id)==-1 ) {
				const _link = await LocationService.linkLocationClass(currentLocations[0].client_id, currentLocations[0].id, clientClass.id)
				copyCurrentLocation.class_links.push(_link)
			} else {
				const _link = await LocationService.unlinkLocationClass(currentLocations[0].client_id, currentLocations[0].id, clientClass.id)
				let origin_class_links = currentLocations[0].class_links.filter(item => {
					return item.locationclassid!=_link.locationclassid
				})
				copyCurrentLocation.class_links = origin_class_links
			}
			if( onCurrentLocationChange ) {
				onCurrentLocationChange(copyCurrentLocation)	
			}
		} catch(e) {
		}
	}

	function addClass() {
		let name = 'New Class'
		createClass({name: name, id: -1, code: '', location_links: []})
	}

	let filtered_classes = []
	if( classes ) {
		filtered_classes = classes.filter(item => {
			if( keyword && item.name.toLowerCase().indexOf(keyword.toLowerCase())==-1 ) {
				return false
			}
			if( filter.deleted ) {
				return true
			} else {
				return !item.deleted_at
			}
		}).filter(item => {
			let result = false
			if( !filter.otb && !filter.pnotb && !filter.notb && !filter.dis && !filter.history ) {
				result = true
			} else {
				if( filter.otb && item.service_type_id==constant.SERVICE_TYPE_ID.OTB ) {
					result = true
				}
				if( filter.pnotb && item.service_type_id==constant.SERVICE_TYPE_ID.PNOTB ) {
					result = true
				}
				if( filter.notb && item.service_type_id==constant.SERVICE_TYPE_ID.NOTB ) {
					result = true
				}
				if( filter.history && item.service_type_id==constant.SERVICE_TYPE_ID.History ) {
					result = true
				}
				if( filter.dis && item.service_type_id==constant.SERVICE_TYPE_ID.Distress ) {
					result = true
				}
			}
			return result
		})
		if( !showAllCheck && assignAvailable ) {
			filtered_classes = filtered_classes.filter(item => {
				return existClasses.indexOf(parseInt(item.id)) > -1
			})
		}
	}

	return <Box sx={jss.dataGridItem}>
		<Box sx={jss.dataTop}>
			<Box sx={jss.dataTitle}>
				Classes
				{onMultiModeChange && isAdmin && 
					<FormControlLabel 
						control={<Switch value={multiMode} size="small" onChange={onMultiModeChange}/>} 
						label="Multi select" 
						sx={{ml:2}}
					/>
				}
			</Box>
		</Box>
		<Box sx={jss.actionSection}>
			<TextField 
				size="small"
				variant="outlined"
				placeholder="Search"
				value={keyword}
				onChange={updateKeyword}
				InputProps={{
					startAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
					),
				}}
			/>
			<Box sx={jss.dataAction}>
				<IconButton size="small" onClick={e => setOpenFilter(true)} ref={filterElement}><FilterListRoundedIcon /></IconButton>
				{!multiMode && assignAvailable && isAdmin ?
					<IconButton size="small" sx={showAllCheck ? {...jss.iconButtonActive} : {}} onClick={swtichAllCheck}><PlaylistAddCheckIcon /></IconButton>
				: null}
				<Menu open={openFilter} anchorEl={filterElement.current} onClose={e => {setOpenFilter(false)}}>
					<MenuItem sx={{width:200}} dense onClick={e => changeFilter('all')}>
						<ListItemIcon>{(!filter.otb && !filter.pnotb && !filter.notb && !filter.dis && !filter.history) && <Check />}</ListItemIcon>
						<ListItemText >Show All</ListItemText>
					</MenuItem>
					<MenuItem sx={{width:200}} dense onClick={e => changeFilter('otb')}>
						<ListItemIcon>{filter.otb && <Check />}</ListItemIcon>
						<ListItemText >OTB</ListItemText>
					</MenuItem>
					<MenuItem sx={{width:200}} dense onClick={e => changeFilter('pnotb')}>
						<ListItemIcon>{filter.pnotb && <Check />}</ListItemIcon>
						<ListItemText >PNOTB</ListItemText>
					</MenuItem>
					<MenuItem sx={{width:200}} dense onClick={e => changeFilter('notb')}>
						<ListItemIcon>{filter.notb && <Check />}</ListItemIcon>
						<ListItemText >NOTB</ListItemText>
					</MenuItem>
					<MenuItem sx={{width:200}} dense onClick={e => changeFilter('dis')}>
						<ListItemIcon>{filter.dis && <Check />}</ListItemIcon>
						<ListItemText >Distress</ListItemText>
					</MenuItem>
					<MenuItem sx={{width:200}} dense onClick={e => changeFilter('history')}>
						<ListItemIcon>{filter.history && <Check />}</ListItemIcon>
						<ListItemText >History</ListItemText>
					</MenuItem>
					<Divider />
					<MenuItem sx={{width:200}} dense onClick={e => changeFilter('deleted')}>
						<ListItemIcon>{filter.deleted && <Check />}</ListItemIcon>
						<ListItemText >Dropped</ListItemText>
					</MenuItem>
				</Menu>
				{isAdmin ? <IconButton size="small" onClick={e => addClass()}><AddRoundedIcon /></IconButton> : null}
			</Box>
		</Box>
		{!classes && 
			<Box sx={jss.loadingSection}>
				<CircularProgress />
			</Box>
		}
		{(classes && (!assignAvailable || currentLocations.length>0)) && 
			<Box sx={jss.dataContent}>
				{filtered_classes.map(_class => {
					let _className = ""
					if( choosedClasses?.map(i=>{return i.id}).indexOf(_class.id)>-1 ) {
						_className = '_active'
					} 
					if( !multiMode ) {
						if( choosedClasses?.map(i=>{return i.parent_class_id}).indexOf(_class.id)>-1 ) {
							_className = '_parent_active'
						} else if( choosedClasses.length>0 && choosedClasses?.map(i=>{return i.id}).indexOf(_class.parent_class_id)>-1 ) {
							_className = '_child_active'
						}
					}
					if( _class.deleted_at ) {
						_className += ' _deleted '
					}
					if( _class?._type == 'child' ) {
						_className += ' _child'
					}
					return <Box 
							sx={jss.dataContentItem} 
							className={_className} 
							key={`location_class_${_class.id}`} 
						>
						{showAllCheck &&
							<Checkbox 
								size="small" 
								checked={existClasses.indexOf(_class.id)>-1}
								onClick={e => changeClassLocationLink(_class)}
							/>
						}
						<Box sx={jss.dataContentItem.name} onClick={e => chooseClass(_class)}>
							{_class.name}
						</Box>
						<Box sx={jss.dataContentItem.action} onClick={e => chooseClass(_class)}>
							{_class.code}
							{_class.service_type_id==constant.SERVICE_TYPE_ID.OTB &&
								<Chip label="O" size="small"/>
							}
							{_class.service_type_id==constant.SERVICE_TYPE_ID.PNOTB &&
								<Chip label="P" size="small"/>
							}
							{_class.service_type_id==constant.SERVICE_TYPE_ID.NOTB &&
								<Chip label="N" size="small"/>
							}
							{_class.service_type_id==constant.SERVICE_TYPE_ID.History &&
								<Chip label="H" size="small"/>
							}
							{_class.service_type_id==constant.SERVICE_TYPE_ID.Distress &&
								<Chip label="D" size="small"/>
							}
							{_class.service_type_id==constant.SERVICE_TYPE_ID.Warehouse &&
								<Chip label="W" size="small"/>
							}
							{_class.service_type_id==constant.SERVICE_TYPE_ID.Child &&
								<Chip label="C" size="small"/>
							}
						</Box>
					</Box>	
				})}
				{assignAvailable && filtered_classes.length==0 &&
					<Box sx={jss.dataContent.empty}>
						<Box>There are no classes for the selected location.</Box>
						<Box>You can select classes for this location.</Box>
					</Box>
				}
			</Box>
		}
		
	</Box>
}
export default ClientProfileClassList