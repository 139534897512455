import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Paper,
	Switch,
	CircularProgress,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Chip,
	Menu,
	MenuItem,
} from '@mui/material';
import SectionTitle from '../../../components/common/SectionTitle'
import moment from 'moment'
import ClassDisplay from './ClassDisplay'
import ClassLocationOverwriteForm from './ClassLocationOverwriteForm'
import jss from "./ProfileCommonJss";
import LocalClassDisplay from "./LocalClassDisplay";
import ClientService from "../../../services/ClientService";
import LocationService from "../../../services/LocationService";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { trackEvent, handleTrackError } from "../../../services/AmplitudeService";
import CurrentClientProvider from "../../../providers/CurrentClientProvider";

function ClientLocationClassLinkEdit(props) {
	const {location, clientClass, locationClasses, categories, labels, isAdmin = false} = props
	const [classLocationLink, setClassLocationLink] = useState({})
	const currentClient = CurrentClientProvider.useClient()
	const [open_more, setOpenMore] = useState(false)
	const more_element = useRef()

	async function deleteData() {
		try {
			await LocationService.deleteLocationClassById(classLocationLink.ClientId, classLocationLink.locationclassid)
			setOpenMore(false)
			props.onSave({
				...clientClass,
				isActive: false,
				deleted_at: moment().format('YYYY-MM-DD HH:mm:ss')
			})

			trackEvent('Client Location Classes Event', {
				action: 'Deleted Location Class',
				clientCode: currentClient.client.ClientCode,
			})
		} catch(e) {
			console.log(e)

			handleTrackError({ error: e, pageName: 'Client Classes', additionalMetadata: { clientCode: currentClient.client.ClientCode, action: 'Deleted Location Class' } })
		}
	}

	async function undeleteData() {
		try {
			await LocationService.undeleteLocationClassById(classLocationLink.ClientId, classLocationLink.locationclassid)
			setOpenMore(false)
			props.onSave({
				...clientClass,
				isActive: true,
				deleted_at: null
			})

			trackEvent('Client Location Classes Event', {
				action: 'Undeleted Location Class',
				clientCode: currentClient.client.ClientCode,
			})
		} catch(e) {
			console.log(e)

			handleTrackError({ error: e, pageName: 'Client Classes', additionalMetadata: { clientCode: currentClient.client.ClientCode, action: 'Undeleted Location Class' } })
		}
	}

	useEffect(() => {
		if( location && clientClass ) {
			location.class_links.forEach(item => {
				if( item.id == clientClass.id ) {
					setClassLocationLink(item)
				}
			})
		}
	}, [location, clientClass])

	function switchOverride(e) {
		setClassLocationLink({...classLocationLink, isOverride:e.target.checked})
		props.onSave({...classLocationLink, isOverride:e.target.checked})
		ClientService.toggleOverride(classLocationLink.ClientId, classLocationLink.client_location_id, classLocationLink.locationclassid)
	}

	if( location && clientClass && classLocationLink ) {
		let customStyles = {}
		let customTitleStyle = {}
		if (classLocationLink.isOverride) {
			customStyles.backgroundColor = '#f97125 !important';
			customStyles.color = '#fff !important';
			customTitleStyle.color = '#fff !important';
		} else {
			customStyles = {}
			customTitleStyle = {}
		}
		return <Paper elevation={2} sx={{mt:2}}>
			<SectionTitle
				customStyles={customStyles}
				customTitleStyle={customTitleStyle}
				label={<Box sx={{fontSize:15}}>
					{`${clientClass.locationclass_name} ${clientClass.clientclass_code}`}
					<Box sx={{fontSize:14,color:'#999'}}>{`${location.location_name} class settings`}</Box>
				</Box>}
				rightComponent={
					isAdmin ?
						<Box>
							Override Setting?
							<Switch
								checked={!!classLocationLink.isOverride}
								onChange={switchOverride}
							/>
							<IconButton onClick={e => {setOpenMore(true)}} ref={more_element}>
								<MoreVertOutlinedIcon sx={{color: 'black'}} />
							</IconButton>
							<Menu
								open={open_more}
								anchorEl={more_element.current}
								onClose={e => {setOpenMore(false)}}
							>
								{!classLocationLink.deleted_at ?
									<MenuItem dense onClick={deleteData}>Drop class</MenuItem>
								: <MenuItem dense onClick={undeleteData}>Undelete class</MenuItem>}
							</Menu>
						</Box>
					: null}
			/>

			<Box sx={{p:2}}>
				{classLocationLink.isOverride && isAdmin ?
					<ClassLocationOverwriteForm clientClass={classLocationLink} locationClasses={locationClasses} onSave={props.onSave} categories={categories} labels={labels} isAdmin={isAdmin} client={currentClient?.client} />
				:
					<LocalClassDisplay clientClass={clientClass} isAdmin={isAdmin}/>
                }
			</Box>
		</Paper>
	} else {
		return <></>
	}
	
}
export default ClientLocationClassLinkEdit