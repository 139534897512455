import React, { useState, useEffect, useContext } from "react";
import styles from "../css/HotKeysDrawer.module.css";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { HotKeysContext } from "../../providers/HotKeysProvider";
import { Button, Tabs, Tab, Box } from "@mui/material";
import UserPrefClientDefault from "../../components/UserPrefClientDefault";

const HotKeysDrawer = () => {
  const hotKeyData = useContext(HotKeysContext);
  const storedHotKeys = JSON.parse(localStorage.getItem("planEditHotKeys"));
  const [hotKeys, setHotKeys] = useState(storedHotKeys || []); // State to hold hot key mappings
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to track whether drawer is open or closed
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Toggle drawer open/close state
  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };
  // hotkeysdefault function to reset the local storage with the default hotkeys
  const hotKeysInitialize = () => {
    // Default hot keys context Sensitive to caller

    const defaultHotKeys = [
      {
        id: "toggleDrawer",
        letter_map: "0",
        function_description: "Show Hot Key Shortcuts",
      },
      {
        id: "updatePlan",
        letter_map: "1",
        function_description: "Update Plan",
      },
      {
        id: "nextLocation",
        letter_map: "2",
        function_description: "Next Location",
      },
      {
        id: "nextClass",
        letter_map: "3",
        function_description: "Next Class",
      },
      {
        id: "setDisplayCopyFromModal",
        letter_map: "4",
        function_description: "Load Plan",
      },
      {
        id: "showCopyToModal",
        letter_map: "5",
        function_description: "Copy To Plan",
      },
      {
        id: "getKFlow",
        letter_map: "6",
        function_description: "Get KFlow",
      },
      {
        id: "reloadPlan",
        letter_map: "7",
        function_description: "Undo / Reload Plan",
      },
      {
        id: "clearCache",
        letter_map: "8",
        function_description: "Clear Cache",
      },
      {
        id: "createFuturePlan",
        letter_map: "9",
        function_description: "Create/Remove Future Plan Toggle",
      },
      {
        id: "showHideCopy",
        letter_map: "a",
        function_description: "Show/Hide Load Plan & Copy To Panel",
      },
      {
        id: "showHideTarget",
        letter_map: "b",
        function_description: "Show/Hide Target Panel",
      },
      {
        id: "showHideChart",
        letter_map: "c",
        function_description: "Show/Hide Chart Panel",
      },
      {
        id: "showHideHeader",
        letter_map: "d",
        function_description: "Show/Hide Table Header Annuals",
      },
      // Add more mappings as needed
    ];

    localStorage.setItem("planEditHotKeys", JSON.stringify(defaultHotKeys));
    setHotKeys(defaultHotKeys);
  };

  useEffect(() => {
    // Check if hot keys exist in local storage
    if (storedHotKeys) {
      setHotKeys(storedHotKeys);
    } else {
      // If no local storage, store default template in local storage
      hotKeysInitialize();
    }

    // Add event listener for keydown events
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Handle keydown events
  const handleKeyDown = (event) => {
    // Abort if not a hot key action
    if (!event.ctrlKey) {
      return;
    }

    const currentKeys = JSON.parse(localStorage.getItem("planEditHotKeys"));
    currentKeys.forEach((hotKey) => {
      const curKey = event.key;
      const curCtrl = event.ctrlKey;
      const letterKey = hotKey.letter_map;
      if (curKey.toLowerCase() === letterKey.toLowerCase() && hotKey.id) {
        switch (hotKey.id) {
          case "updatePlan":
            hotKeyData.updatePlan(
              hotKeyData.clientBasicData.current_plan.is_future_plan
            );
            break;
          case "nextClass":
            hotKeyData.nextClass();
            break;
          case "nextLocation":
            hotKeyData.nextLocation();
            break;
          case "setDisplayCopyFromModal":
            hotKeyData.setDisplayCopyFromModal(true);
            break;
          case "showCopyToModal":
            hotKeyData.showCopyToModal();
            break;
          case "toggleDrawer":
            toggleDrawer();
            break;
          case "getKFlow":
            hotKeyData.getKFlow();
            break;
          case "reloadPlan":
            hotKeyData.reloadPlan();
            break;
          case "clearCache":
            hotKeyData.clearCache();
            break;
          case "showHideCopy":
            hotKeyData.toggleCopyPanel();
            break;
          case "showHideTarget":
            hotKeyData.toggleTargetPanel();
            break;
          case "showHideChart":
            hotKeyData.toggleGraphPanel();
            break;
          case "showHideHeader":
            hotKeyData.toggleHeaderPanel();
            break;
          case "createFuturePlan":
            if (!!hotKeyData.clientBasicData?.current_plan?.is_future_plan) {
              console.log("Removing Future Plan...");
              hotKeyData.removeFuturePlan(hotKeyData.clientBasicData?.current_plan);
            } else {
              console.log("Creating Future Plan...");
              hotKeyData.createFuturePlan();
            } 
            break;
          default:
            break;
        }
      }
    });
  };

  // Function to handle hot key changes
  const handleHotKeyChange = (id, value) => {
    const updatedHotKeys = hotKeys.map((hotKey) =>
      hotKey.id === id ? { ...hotKey, letter_map: value } : hotKey
    );
    setHotKeys(updatedHotKeys);
    localStorage.setItem("planEditHotKeys", JSON.stringify(updatedHotKeys));
  };

  return (
    <>
      <div
        className={`${styles.drawerTab} ${
          isDrawerOpen ? styles.drawerTabOpen : ""
        }`}
        onClick={toggleDrawer}
      >
        <RocketLaunchIcon fontSize="large" style={{ color: "white" }} />
      </div>
      <div
        className={`${styles.drawerContainer} ${
          isDrawerOpen ? styles.drawerContainerOpen : ""
        }`}
      >
        <div>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Preference Tabs"
          >
            <Tab label="Hot Key Shortcuts" />
            <Tab label="Load Template" />
          </Tabs>
          {selectedTab === 0 && (
            <Box className={styles.drawerContent}>
              <Box className={styles.tabContentTitle}>
                <span>Plan Edit Hot Keys</span>
              </Box>
              {hotKeys.map((hotKey) => (
                <Box key={hotKey.id}>
                  <Box className={styles.hotKeyLabel}>
                    {hotKey.function_description}
                  </Box>
                  <Box className={styles.keyMapText}>
                    <span className={styles.ctrlDisplay}>CTRL </span> +{" "}
                    <input
                      className={styles.hotKeyInput}
                      key={hotKey.id}
                      type="text"
                      maxLength={1}
                      value={hotKey.letter_map}
                      onChange={(e) =>
                        handleHotKeyChange(hotKey.id, e.target.value)
                      }
                    />
                  </Box>
                </Box>
              ))}
              <Button
                sx={{ margin: "25px 5px 5px 5px !important" }}
                variant="outlined"
                className={styles.drawerButton}
                onClick={hotKeysInitialize}
              >
                Reset Hot Keys
              </Button>
            </Box>
          )}
          {selectedTab === 1 && (
            <div
              className={styles.drawerContent}
              style={{ textAlign: "center" }}
            >
              <UserPrefClientDefault open={isDrawerOpen} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HotKeysDrawer;
