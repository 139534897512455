import {useState, useEffect, useRef} from 'react'
import {
  Navigate,
  useNavigate
} from "react-router-dom";
import {
	Container,
	Box,
	TextField,
	Button,
	Grid,
	Typography,
	Paper,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	TableSortLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Chip,
	Stack,
	Skeleton,
	InputAdornment,
} from '@mui/material'
import {
	LoadingButton
} from '@mui/lab'
import UserService from '../../services/UserService'
import RoleService from '../../services/RoleService'
import PermissionService from '../../services/PermissionService'
import SectionTitle from '../../components/common/SectionTitle'
import _ from 'underscore'
import TipContext from '../../providers/GlobalTip'
import AuthContext from '../../providers/AuthProvider'
import AddSharpIcon from '@mui/icons-material/AddSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import EmptyIcon from '../../components/icons/EmptyIcon'
import CreatePermissionModal from '../../components/permission/CreatePermissionModal'
import PermissionItem from '../../components/permission/PermissionItem'

const jss = {
	permissionSection: {
		py: 2,
	},
	permissionFilterSection: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	empty: {
		textAlign: 'center'
	}
}

function Permission(props) {
	const auth_context = AuthContext.useAuth()
	const message_context = TipContext.useMessage()
	const navigate = useNavigate()
	const [roles, setRoles] = useState([])
	const [permissions, setPermissions] = useState()
	const origin_permissions = useRef()
	const [permission_creating, setPermissionCreating] = useState(false)
	const [search_name, setSearchName] = useState('')
	const [modal_open, setModalOpen] = useState(false)

	useEffect(() => {
		document.title = 'Retail ORBIT: User Permissions'

		if( !auth_context.checkPermission('permission-page-show') ) {
			navigate('/')
			return
		}
		loadRoles()
		loadPermissions()
	}, [])

	async function loadRoles() {
		const _roles = await RoleService.getRoles(['USER', 'ADMIN'])
		setRoles(_roles)
	}

	async function loadPermissions() {
		const _permissions = await PermissionService.getPermissions()
		origin_permissions.current = _permissions
		setPermissions(_permissions)
	}

	function searchUpdate(e) {
		setSearchName(e.target.value)
		if( e.target.value ) {
			const _permissions = _.filter(origin_permissions.current, item => {
				return item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
			})
			setPermissions(_permissions)	
		} else {
			setPermissions(origin_permissions.current)	
		}
	}

	return <Box sx={jss.permissionSection}>
		<Paper elevation={2}>
			<SectionTitle label="Permissions"/>
			<Box sx={{p:2}}>
				<Box sx={jss.permissionFilterSection}>
					<Box>
						<TextField
							size="small"
							sx={{width: '300px'}}
							label="Search"
							value={search_name}
							onChange={searchUpdate}
							InputProps={{
			          startAdornment: (
			            <InputAdornment position="start">
			              <SearchSharpIcon />
			            </InputAdornment>
			          ),
			        }}
						/>
					</Box>
				</Box>
				<TableContainer sx={{mt: 3}}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Permission</TableCell>
								<TableCell>Roles</TableCell>
								<TableCell>Action</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!_.isArray(permissions) && 
								<>
									<TableRow>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
										<TableCell><Skeleton sx={{height:'30px'}}/></TableCell>
									</TableRow>
								</>
							}
							{_.isArray(permissions) && permissions.length>0 && permissions.map(_permission => {
								return <PermissionItem permission={_permission} roles={roles} key={`permission_item_${_permission.id}`}/>
							})}
							{_.isArray(permissions) && permissions.length==0 &&
								<TableRow >
									<TableCell colSpan={3} sx={jss.empty}>
										<EmptyIcon sx={{fontSize: '120px', color: '#ddd', my:8}}/>
									</TableCell>
								</TableRow>
							}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
			<CreatePermissionModal open={modal_open} onClose={e => {setModalOpen(false)}} />
		</Paper>
	</Box>
}

export default Permission