import Request from './Request'

const TableauService = {
    async getTableauUserToken() {
        return await Request.get(`/api/tableau/user-token`)
    },
    async getProjects(clientId) {
        return await Request.get(`/api/tableau/projects-w-workbook?clientId=${clientId}`);
    }
}

export default TableauService