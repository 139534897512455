import { createTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default createTheme({
	palette: {
		primary: {
			main: '#5c4174',
			dark: '#4e2b6e',
			light: '#ac82ce',
	    },
	    secondary: {
			main: '#1d9ef4',
			dark: '#0070c1',
			light: '#6dcfff',
	    },
	    text: {
			primary: 'rgba(32, 35, 43, 1)',
			secondary: 'rgba(112, 119, 139, 1)',
			disabled: 'rgba(202, 209, 221, 1)',
			hint: 'rgba(32, 35, 43, 1)',
		},
	    background: {
			default: "#f3f4f5"
		}
	},
	typography: {
		fontFamily: [
			"Roboto",'Helvetica','Arial',"sans-serif"
		].join(','),
		fontSize: 13,
	},
	shape: {
		borderRadius: 3,
	},
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true
			},
		},
		MuiInputBase: {
			styleOverrides: {
				colorError: {
					color: '#d32f2f'
				}
			}
		},
		MuiPaper: {
			defaultProps: {
				elevation: 2,
			},
			styleOverrides: {
				root: {
					borderRadius: 3,
					overflow: 'hidden'
				}
			}
		}
	}
})