import {
	Box,
} from '@mui/material';

const jss = {
	titleSection: {
		display: 'flex',
		width: "100%",
		alignItems: 'center',
		px: 2,
		borderBottom: '1px solid rgba(92,63,117,0.1)',
	},
	title: {
		flex: 1,
		fontSize: 17,
		py: 2,
	},
	subLabel: {
		fontSize: 12,
		display: 'inline-block',
		ml: 2,

	},
	rightComponentSection: {

	}
}

function SectionTitle(props) {
	const {
		type,
		label,
		subLabel,
		rightComponent,
		customStyles = {},
		customTitleStyle = {}
	} = props
	return <Box sx={{
			...jss.titleSection,
			backgroundColor: type=='primary' ? 'primary.main' : '#fafafa',
			borderBottomColor: type=='primary' ? 'primary.main' : 'rgba(92,63,117,0.1)',
			...customStyles,
		}}>
		<Box sx={{
			...jss.title,
			color: type=='primary' ? 'white' : 'text.primary',
			...customTitleStyle
		}}>
			{label}
			{subLabel && 
				<Box sx={{
					...jss.subLabel,
					color: type=='primary' ? 'white' : 'text.secondary',
					...customTitleStyle
				}}>
					{subLabel}
				</Box>
			}
		</Box>
		{rightComponent && 
			<Box sx={jss.rightComponentSection}>
				{rightComponent}
			</Box>
		}
	</Box>
}

export default SectionTitle