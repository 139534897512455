import {useState, useEffect} from 'react'
import {
	Box,
	TextField,
	Button,
	TableRow,
	TableCell,
	TableHead,
	TableSortLabel,
	InputLabel,
	Chip,
	Stack,
	IconButton,
} from '@mui/material'
import {
	LoadingButton
} from '@mui/lab'
import PermissionAssignRoleModal from './PermissionAssignRoleModal'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

function PermissionItem(props) {
	const [open, setOpen] = useState(false)
	const [permission, setPermission] = useState(props.permission)

	function handlePermissionUpdate(updated_permission) {
		setOpen(false)
		setPermission(updated_permission)
	}

	return <TableRow>
		<TableCell>
			{permission.name}
		</TableCell>
		<TableCell>
			<Stack spacing={1} direction="row">
			{permission.roles.map(_role => {
				return <Chip 
					key={`link_item_${_role.id}`}
					label={_role.name}
				/>
			})}
			</Stack>
		</TableCell>
		<TableCell>
			<IconButton onClick={e => setOpen(true)}>
				<ManageAccountsOutlinedIcon />
			</IconButton>
			<PermissionAssignRoleModal 
				permission={permission} 
				open={open} 
				onClose={e=>setOpen(false)} 
				onSave={handlePermissionUpdate} 
				roles={props.roles}
			/>
		</TableCell>
	</TableRow>	
}
export default PermissionItem