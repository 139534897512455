import { createContext, useContext, useState, useRef, useCallback, useEffect } from "react";
import {
  Button,
  Box,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';

const ConfirmDialog = createContext()

export function ConfirmDialogProvider({ children }) {
  const [state, setState] = useState({ isOpen: false })
  const fn = useRef() 

  let confirm = useCallback((data) => { 
    return new Promise((resolve) => {
      setState({ ...data, isOpen: true })
      fn.current = (choice) => { 
        resolve(choice) 
        setState({ isOpen: false })
      }
    })
  }, [ setState ])

  return (
    <ConfirmDialog.Provider value={confirm}>
      {children}
      <Dialog
        open={state.isOpen}
        onClose={() => fn.current(false)}
      >
        <DialogContent>{state?.description}</DialogContent>
        <DialogActions>
          <Button onClick={() => fn.current(false)}>Cancel</Button>
          <Button onClick={() => fn.current(true)} autoFocus variant="contained">Ok</Button>
        </DialogActions>
      </Dialog>
    </ConfirmDialog.Provider>
  )
}

export default function useConfirm() {
  return useContext(ConfirmDialog)
}