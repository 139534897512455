import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Box, TextField, Grid } from '@mui/material';
import moment from 'moment';
import TipContext from '../../../providers/GlobalTip';
import PlanService from '../../../services/PlanService';
import './FuturePlanEditModal.css';

const now = moment();
const nowMonth = now.month();
const nowYear = now.year();
const nowEpochMonth = (nowYear - 1970) * 12 + nowMonth;

const next12Months = Array.from({ length: 12 }, (_, i) => moment().add(i, 'months').format('MMM'));

const monthDataFormat = {
    "sales_monthly_percentage_plan": 7.69,
    "sales_monthly_plan": 0,
    "stock_to_sales_plan": 0,
    "inventory_bom_plan": 0,
    "inflow_retail_plan": 0,
    "inflow_cost_plan": 0,
    "markdown_plan": 0,
    "markdown_percentage_plan": 7.69,
}

const rowSettings = {
    "sales_monthly_percentage_plan": {
        "label": "Sales %",
        'enabled': true
    },
    "sales_monthly_plan": {
        "label": "Sales",
        'enabled': false
    },
    "stock_to_sales_plan": {
        "label": "Stock to Sales",
        'enabled': true,
    },
    "inventory_bom_plan": {
        "label": "Inventory BOM",
        'enabled': true
    },
    "inflow_retail_plan": {
        "label": "Inflow Retail",
        'enabled': false
    },
    "inflow_cost_plan": {
        "label": "Inflow Cost",
        'enabled': false
    },
    "markdown_plan": {
        "label": "Markdown",
        'enabled': false
    },
    "markdown_percentage_plan": {
        "label": "Markdown %",
        'enabled': true
    },
}

const FuturePlanEditModal = ({ open, onClose, planSettingId, epochMonth, fullScreen = true }) => {
    const tipContext = TipContext.useMessage();
    const [openEpochMonth, setOpenEpochMonth] = useState(nowEpochMonth);
    const [formData, setFormData] = useState(Array(12).fill(monthDataFormat));
    const [next12MonthsData, setNext12MonthsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [annualValues, setAnnualValues] = useState({
        'sales_annual': 0,
        'markdown_annual': 0,
        'turn_annual': 0,
        'imu_annual': 0,
    });
    const [averagedValues, setAveragedValues] = useState({
        'stock_to_sales_plan': 0,
        'inventory_bom_plan': 0,
    });

    useEffect(() => {
        if (!open) return;

        if (open && next12MonthsData[epochMonth]) {
            // // reset values to what is in next12MonthsData
            // const openValues = JSON.parse(next12MonthsData[epochMonth].open_values_json);
            // setOpenEpochMonth(openValues.open_epoch_month);

            // const newFormData = Array(12).fill(monthDataFormat);
            // for (const key in next12MonthsData) {
            //     const item = next12MonthsData[key];
            //     if (item.open_values_json) {
            //         const openValues = JSON.parse(item.open_values_json);
            //         const index = key - epochMonth;
            //         newFormData[index] = openValues;
            //     }
            // }
            // setFormData(newFormData);
            return
        }

        console.log('Fetching data for plan setting id:', planSettingId);
        if (!planSettingId) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                let result = await PlanService.getNext12MonthsForPlanSetting(planSettingId, epochMonth)

                // sort result by plan_epoch_month
                result = result.sort((a, b) => a.plan_epoch_month - b.plan_epoch_month);

                // keep only plan_settings_id, this_plan_epoch_month, plan_epoch_month, and open_values_json then index by plan_epoch_month and set to state
                const data = result.reduce((acc, item) => {
                    acc[item.plan_epoch_month] = {
                        plan_settings_id: item.plan_settings_id,
                        this_plan_epoch_month: item.this_plan_epoch_month,
                        plan_epoch_month: item.plan_epoch_month,
                        open_values_json: item.open_values_json
                    };
                    return acc;
                }, {});

                setNext12MonthsData(data);

                // from first data item, get open_values_json if not null and get open_epoch_month from that
                if (data[epochMonth] && data[epochMonth].open_values_json) {
                    const openValues = JSON.parse(data[epochMonth].open_values_json);
                    setOpenEpochMonth(openValues.open_epoch_month);
                } else {
                    tipContext.show('No existing data for this plan setting', 5);
                }

                // for each data item, get open_values_json if not null and get values from that to go into formData
                const newFormData = Array(12).fill(monthDataFormat);
                for (const key in data) {
                    const item = data[key];
                    if (item.open_values_json) {
                        const openValues = JSON.parse(item.open_values_json);
                        const index = key - epochMonth;
                        newFormData[index] = openValues;
                    }
                }

                // round each value to 2 decimal places
                newFormData.forEach((monthData) => {
                    for (const key in monthData) {
                        monthData[key] = parseFloat(parseFloat(monthData[key]).toFixed(2));
                    }
                });

                setFormData(newFormData);
                calculateAveragedValues(newFormData);

                // calculate annuals
                let salesAnnual = 0;
                let markdownAnnual = 0;
                let invAvg = 0;
                for (const monthData of newFormData) {
                    salesAnnual += monthData['sales_monthly_plan'];
                    markdownAnnual += monthData['markdown_plan'];
                    invAvg += monthData['inventory_bom_plan'];
                }

                invAvg = invAvg / newFormData.length;

                setAnnualValues({
                    'sales_annual': salesAnnual,
                    'markdown_annual': markdownAnnual,
                    'turn_annual': parseFloat((salesAnnual / invAvg).toFixed(2)),
                    'imu_annual': Math.abs(parseFloat((1 - (newFormData[0]['inflow_cost_plan'] / newFormData[0]['inflow_retail_plan']) * 100).toFixed(2))),
                });
            } catch (error) {
                console.log(error)
                tipContext.show('Failed to fetch data', 5);
            }
            setLoading(false);
        }

        fetchData();
    }, [planSettingId, epochMonth, open]);

    useEffect(() => {
        if (openEpochMonth < nowEpochMonth) {
            tipContext.show('Open Epoch Month cannot be in the past', 5);
            setOpenEpochMonth(nowEpochMonth);
        }
    }, [openEpochMonth]);

    useEffect(() => {
        // if percentage column is updated, update the corresponding value column based on the total for the year
        const percentageRows = ['sales_monthly_percentage_plan', 'markdown_percentage_plan'];
    }, [formData]);

    const calculateAveragedValues = (overrideData) => {
        let formData = overrideData ?? [...formData];
        let stockToSalesAvg = parseFloat(formData.reduce((acc, monthData) => {
            return acc + parseFloat(monthData['stock_to_sales_plan']);
        }, 0) / formData.length).toFixed(2);

        let inventoryBomAvg = parseFloat(formData.reduce((acc, monthData) => {
            return acc + parseFloat(monthData['inventory_bom_plan']);
        }, 0) / formData.length).toFixed(2);

        setAveragedValues({
            'stock_to_sales_plan': stockToSalesAvg,
            'inventory_bom_plan': inventoryBomAvg,
        });
    };

    const getInflowRetail = (nextInv, currentInv, sales, markdown) => {
        // formula: (next_inv - (curr_inv - sales - markdowns)).toFixed(0);
        return Math.abs((nextInv - (currentInv - sales - markdown)).toFixed(0));
    };

    const getInflowCostFromRetail = (inflowRetail) => {
        return inflowRetail * (1 - annualValues['imu_annual'] / 100);
    };

    const updateTurnAnnual = () => {
        // update turn based on sales / inventory bom avg
        let invAvg = 0;

        for (let i = 0; i < formData.length; i++) {
            invAvg += formData[i]['inventory_bom_plan'];
        }

        invAvg = invAvg / formData.length;

        let turn = parseFloat((annualValues['sales_annual'] / invAvg).toFixed(2));
        setAnnualValues({ ...annualValues, 'turn_annual': turn });
    };

    const updateInflow = (updatedFormData = null) => {
        // for each month, calculate inflow retail and inflow cost
        // formula: (next_inv - (curr_inv - sales - markdowns)).toFixed(0);
        updatedFormData = updatedFormData ?? [...formData];

        for (let i = 0; i < formData.length; i++) {
            let nextInv = i === formData.length - 1 ? 0 : formData[i + 1]['inventory_bom_plan'];
            let currInv = formData[i]['inventory_bom_plan'];
            let sales = formData[i]['sales_monthly_plan'];
            let markdown = formData[i]['markdown_plan'];

            let inflow = getInflowRetail(nextInv, currInv, sales, markdown);
            
            updatedFormData[i] = {
                ...updatedFormData[i],
                'inflow_retail_plan': inflow,
                'inflow_cost_plan': getInflowCostFromRetail(inflow)
            };
        }

        setFormData(updatedFormData);
    };

    const handleFocusOut = (e, ind) => {
        let updatedFormData = [...formData];
        let shouldUpdateTurn = false;

        switch (e.target.name) {
            case 'sales_monthly_percentage_plan':
                // update sales plan at ind based on annuals and percentage
                updatedFormData[ind] = {
                    ...updatedFormData[ind],
                    'sales_monthly_plan': parseFloat((annualValues['sales_annual'] * e.target.value / 100).toFixed(2))
                };

                break;
            case 'markdown_percentage_plan':
                updatedFormData[ind] = {
                    ...updatedFormData[ind],
                    'markdown_plan': parseFloat((annualValues['markdown_annual'] * e.target.value / 100).toFixed(2))
                };
                break;
            case 'stock_to_sales_plan':
                // recalculate inventory bom based on stock to sales
                let inventoryBom = parseFloat((updatedFormData[ind]['sales_monthly_plan'] * e.target.value).toFixed(2));
                updatedFormData[ind] = {
                    ...updatedFormData[ind],
                    'inventory_bom_plan': inventoryBom
                };

                calculateAveragedValues(updatedFormData);

                shouldUpdateTurn = true;
                break;
            case 'inventory_bom_plan':
                // recalculate stock to sales based on inventory bom
                let stockToSales = parseFloat(e.target.value / (updatedFormData[ind]['sales_monthly_plan']).toFixed(2));
                updatedFormData[ind] = {
                    ...updatedFormData[ind],
                    'stock_to_sales_plan': stockToSales
                };
                calculateAveragedValues(updatedFormData);
                break;
            default:
                break;
        }
    
        if (!shouldUpdateTurn) {
            // update inflow retail
            let nextInv = ind === updatedFormData.length - 1 ? 0 : updatedFormData[ind + 1]['inventory_bom_plan'];
            let currInv = updatedFormData[ind]['inventory_bom_plan'];
            let sales = updatedFormData[ind]['sales_monthly_plan'];
            let markdown = updatedFormData[ind]['markdown_plan'];

            let inflowRetail = getInflowRetail(nextInv, currInv, sales, markdown);

            updatedFormData[ind] = {
                ...updatedFormData[ind],
                'inflow_retail_plan': inflowRetail,
                'inflow_cost_plan': getInflowCostFromRetail(inflowRetail)
            };
        }

        setFormData(updatedFormData);
        if (shouldUpdateTurn) {
            updateTurnAnnual();
            updateInflow(updatedFormData);
        }
    };

    const handleUpdatedAnnuals = (updateTarget) => {
        let updatedFormData = [...formData];
        switch (updateTarget) {
            case 'sales_annual':
                // update sales plan based on annuals and percentage
                updatedFormData = updatedFormData.map((monthData) => {
                    return {
                        ...monthData,
                        'sales_monthly_plan': parseFloat((annualValues['sales_annual'] * monthData['sales_monthly_percentage_plan'] / 100).toFixed(2))
                    };
                }
                );
                break;
            case 'markdown_annual':
                updatedFormData = updatedFormData.map((monthData) => {
                    return {
                        ...monthData,
                        'markdown_plan': parseFloat((annualValues['markdown_annual'] * monthData['markdown_percentage_plan'] / 100).toFixed(2))
                    };
                }
                );
                break;
            case 'turn_annual':
                // update inventory bom based on turn
                let currentInvAvg = 0;
                for (let i = 0; i < updatedFormData.length; i++) {
                    currentInvAvg += updatedFormData[i]['inventory_bom_plan'];
                }
                currentInvAvg = currentInvAvg / updatedFormData.length;

                let newInvAvg = parseFloat((annualValues['sales_annual'] / annualValues['turn_annual']).toFixed(2));
                let invDiff = newInvAvg - currentInvAvg;

                updatedFormData = updatedFormData.map((monthData) => {
                    return {
                        ...monthData,
                        'inventory_bom_plan': parseFloat((monthData['inventory_bom_plan'] + invDiff).toFixed(2)),
                    };
                });

                // update stock to sales based on inventory bom
                updatedFormData = updatedFormData.map((monthData) => {
                    return {
                        ...monthData,
                        'stock_to_sales_plan': parseFloat((monthData['inventory_bom_plan'] / monthData['sales_monthly_plan']).toFixed(2)),
                    };
                });
                break;
            case 'imu_annual':
                // update inflow cost to reflect imu
                updatedFormData = updatedFormData.map((monthData) => {
                    return {
                        ...monthData,
                        'inflow_cost_plan': getInflowCostFromRetail(monthData['inflow_retail_plan'])
                    };
                });
            default:
                break;
        }

        setFormData(updatedFormData);
    };

    const handleAveragesInputBlur = (e, fieldName) => {
        if (isNaN(parseFloat(e.target.value))) return;
        let updatedFormData = [...formData];
        
        switch (fieldName) {
            case 'stock_to_sales_plan':
                let stockToSalesAvg = parseFloat(updatedFormData.reduce((acc, monthData) => {
                    return acc + parseFloat(monthData[fieldName]);
                }, 0) / updatedFormData.length).toFixed(2);

                let diff = e.target.value - stockToSalesAvg;
                updatedFormData = updatedFormData.map((monthData) => {
                    return {
                        ...monthData,
                        [fieldName]: parseFloat((parseFloat(monthData[fieldName]) + diff).toFixed(2))
                    };
                });
                break;
            case 'inventory_bom_plan':
                let inventoryBomAvg = parseFloat(updatedFormData.reduce((acc, monthData) => {
                    return acc + parseFloat(monthData[fieldName]);
                }, 0) / updatedFormData.length).toFixed(2);

                let diffInv = e.target.value - inventoryBomAvg;
                updatedFormData = updatedFormData.map((monthData) => {
                    return {
                        ...monthData,
                        [fieldName]: parseFloat((parseFloat(monthData[fieldName]) + diffInv).toFixed(2))
                    };
                });
                break;
            default:
                break;
        }

        setFormData(updatedFormData);
    }

    const resetValues = () => {
        setFormData(Array(12).fill(monthDataFormat));
        setOpenEpochMonth(nowEpochMonth);
    };

    const handleSave = async () => {
        if (saving) return;
        if (!planSettingId) return;
        
        // all values must be greater than or equal to 0
        for (const monthData of formData) {
            for (const key in monthData) {
                if (monthData[key] < 0) {
                    tipContext.show('All values must be greater than or equal to 0', 5);
                    return;
                }
            }
        }

        // the percentage rows must add up to 100 across all months
        // const percentageRows = ['sales_monthly_percentage_plan', 'markdown_percentage_plan'];
        // for (const row of percentageRows) {
        //     const total = formData.reduce((acc, monthData) => acc + monthData[row], 0);
        //     if (total !== 100) {
        //         tipContext.show('The percentage rows must add up to 100 across all months', 5);
        //         return;
        //     }
        // }

        // save data
        setSaving(true);
        try {
            await PlanService.updateNext12MonthsForPlanSetting(planSettingId, epochMonth, formData, openEpochMonth);
            tipContext.showSuccess('Data saved successfully', 5);

            // reset next12MonthsData to force refetch
            setNext12MonthsData([]);

            // onClose();
        } catch (error) {
            tipContext.show(error.message, 5);
        }
        setSaving(false);
    };

    /**
     * Render Methods
     */
    const renderTableRows = () => {
        const fieldNames = Object.keys(monthDataFormat); // Using keys from monthDataFormat as field names
        return fieldNames.map((fieldName) => (
            <TableRow key={fieldName}>
                <TableCell component="th" scope="row">
                    {rowSettings[fieldName].label}
                </TableCell>
                {formData.map((monthData, index) => (
                    <TableCell key={index}
                        sx={{ minWidth: '50px', padding: '3px' }}
                    >
                        <input
                            type="number"
                            value={monthData[fieldName]}
                            name={fieldName}
                            onChange={(e) => {
                                const updatedFormData = [...formData];
                                updatedFormData[index] = {
                                    ...updatedFormData[index],
                                    [fieldName]: e.target.value
                                };
                                setFormData(updatedFormData);
                            }}
                            onBlur={
                                (e) => {
                                    // if it can't be parsed as float, return
                                    if (isNaN(parseFloat(e.target.value))) return;
                                    handleFocusOut(e, index)
                            }}
                            disabled={!rowSettings[fieldName].enabled}
                            style={{ width: '100%', padding: '3px' }}
                            onWheel={(e) => e.target.blur()} // disable scrolling with mouse wheel
                        />
                    </TableCell>
                ))}
                <TableCell>
                    {fieldName == 'stock_to_sales_plan' || fieldName == 'inventory_bom_plan' ?
                        (
                            <input
                                type="number"
                                value={averagedValues[fieldName]}
                                onChange={(e) => {
                                    setAveragedValues({ ...averagedValues, [fieldName]: e.target.value });
                                }}
                                onBlur={(e) => {
                                    if (isNaN(parseFloat(e.target.value))) return;
                                    handleAveragesInputBlur(e, fieldName)
                                }}
                                style={{ width: '100%', padding: '3px' }}
                            />
                        )
                        : (formData.reduce((acc, monthData) => {
                            const value = parseFloat(monthData[fieldName]);
                            return acc + (!isNaN(value) ? value : 0);
                        }, 0)).toFixed(2)
                    }
                </TableCell>
            </TableRow>
        ));
    };
    

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            fullScreen={fullScreen}
        >
            <DialogTitle>Edit Future Plan Settings</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={6}>
                        <TextField
                            label="Annual Sales"
                            type="number"
                            value={annualValues['sales_annual']}
                            onChange={(e) => setAnnualValues({ ...annualValues, 'sales_annual': parseFloat(e.target.value) })}
                            onBlur={() => handleUpdatedAnnuals('sales_annual')}
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Annual Markdown"
                            type="number"
                            value={annualValues['markdown_annual']}
                            onChange={(e) => setAnnualValues({ ...annualValues, 'markdown_annual': parseFloat(e.target.value) })}
                            onBlur={() => handleUpdatedAnnuals('markdown_annual')}
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Annual Turn"
                            type="number"
                            value={annualValues['turn_annual']}
                            onChange={(e) => setAnnualValues({ ...annualValues, 'turn_annual': parseFloat(e.target.value) })}
                            onBlur={() => handleUpdatedAnnuals('turn_annual')}
                            fullWidth
                            variant="outlined"
                            InputProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Annual IMU"
                            type="number"
                            value={annualValues['imu_annual']}
                            onChange={(e) => setAnnualValues({ ...annualValues, 'imu_annual': parseFloat(e.target.value) })}
                            onBlur={() => handleUpdatedAnnuals('imu_annual')}
                            fullWidth
                            variant="outlined"
                            InputProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ mt: 2 }}>
                    <TextField
                        label="Open Epoch Month"
                        type="number"
                        value={openEpochMonth}
                        onChange={(e) => setOpenEpochMonth(parseInt(e.target.value))}
                        fullWidth
                        variant="outlined"
                    />
                </Box>
                <Box sx={{ mt: 2 }}>
                    {!loading ? 
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="future plan table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Value</TableCell>
                                        {next12Months.map((month) => (
                                            <TableCell key={month}>{month}</TableCell>
                                        ))}
                                        <TableCell>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {renderTableRows()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    : <Box>Loading...</Box>}
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: '16px' }}>
                <Button onClick={resetValues} color="secondary" variant="outlined" sx={{ mr: 2 }}>
                    Reset
                </Button>
                <Button onClick={onClose} color="secondary" variant="outlined" sx={{ mr: 2 }}>
                    Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={handleSave} disabled={saving}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FuturePlanEditModal;
