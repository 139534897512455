import { useState, useEffect, useRef } from "react";
import {
	Box,
	Typography,
	Paper,
	Skeleton,
	IconButton,
	Checkbox,
	Button,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Tabs,
	Tab,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	MenuItem,
	TextField,
	Autocomplete,
	CircularProgress,
	Grid,
	Switch,
	FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab'
import SectionTitle from '../../../components/common/SectionTitle'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClientService from '../../../services/ClientService'
import UserService from '../../../services/UserService'
import TipContext from '../../../providers/GlobalTip'
import constants from '../../../constants/constant'
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import useConfirm from "../../../providers/ConfirmDialog";
import AddUserModal from "../setup/steps/AddUserModal";
import { trackEvent, handleTrackError } from "../../../services/AmplitudeService";

const jss = {
	userTable: {
		'th,td': {
			px:'6px',
			wordBreak: 'break-word',
			fontSize:12,
		}
	}
}


function ClientUser(props) {
	const { client, isAdmin } = props
	const tip_context = TipContext.useMessage()
	const [userLinks, setUserLinks] = useState([])
	const [creating, setCreating] = useState(false)
	const [allUserLoading, setAllUserLoading] = useState(false)
	const [openAddUser, setOpenAddUser] = useState(false)
	const [allUsers, setAllUsers] = useState([])
	const [addUserFormData, setAddUserFormData] = useState({
		role: '',
		user_id: '',
	})
	const [openUpdateUser, setOpenUpdateUser] = useState(false)
	const [updating, setUpdating] = useState(false)
	const [updateUserLink, setUpdateUserLink] = useState({})
	const [updateUserFormData, setUpdateUserFormData] = useState({
		share_percent: 0,
		otb_rate: 0,
		pnotb_rate: 0,
		notb_rate: 0,
		is_payable: 0,
		otb_setup_rate: 0,
		pnotb_setup_rate: 0,
		notb_setup_rate: 0,
		shuttle_rate: 0,
		headCount_rate: 0,
	})
	const confirm = useConfirm();

	useEffect(() => {
		if( client ) {
			loadClientUsers()
		}
	}, [client])

	async function loadAllUsers() {
		if( allUsers.length>0 ) {
			return
		}
		setAllUserLoading(true)
		const _users = await UserService.getUsers()
		setAllUsers(_users.map(item => {
			return {
				id: item.id,
				first_name: item.first_name,
				last_name: item.last_name,
				email: item.email,
			}
		}))
		setAllUserLoading(false)
	}

	async function loadClientUsers() {
		const _user_links = await ClientService.getClientUsers(client.Id)
		setUserLinks(_user_links)
	}

	function handleCloseAddUser() {
		setOpenAddUser(false)
		setAddUserFormData({
			role: '',
			user_id: '',
		})
	}

	async function addUserLink() {
		try {
			setCreating(true)
			await UserService.assignUserToRole(addUserFormData.user_id, addUserFormData.role, client.Id)
			loadClientUsers()
			handleCloseAddUser()

			trackEvent('Client Profile Event', {
				action: 'Added User to Client',
				clientCode: client.ClientCode,
				userId: addUserFormData.user_id,
				userEmail: allUsers.find(item => item.id == addUserFormData.user_id)?.email,
				role: addUserFormData.role,
			})
		}catch(e) {
			tip_context.show(e.response?.data.message)

			handleTrackError({ error: e, pageName: 'Client Profile', additionalMetadata: { clientCode: client.ClientCode, action: 'Added User to Client' } })
		}
		setCreating(false)
	}

	function handlerAddUserSelect(event, value, reason) {
		if( reason == 'selectOption' ) {
			setAddUserFormData({...addUserFormData, user_id: value.id})
		}
	}

	function handleCloseUpdateUser() {
		setOpenUpdateUser(false)
		setUpdateUserFormData({
			share_percent: 0,
			otb_rate: 0,
			pnotb_rate: 0,
			notb_rate: 0,
			is_payable: 0,
			otb_setup_rate: 0,
			pnotb_setup_rate: 0,
			notb_setup_rate: 0,
			shuttle_rate: 0,
			headCount_rate: 0,
		})
	}

	function openUpdateUserModal(user_link) {
		setUpdateUserLink(user_link)
		setOpenUpdateUser(true)
		setUpdateUserFormData({
			share_percent: user_link.config ? user_link.config.share_percent : 0,
			otb_rate: user_link.config ? user_link.config.otb_rate : 0,
			pnotb_rate: user_link.config ? user_link.config.pnotb_rate : 0,
			notb_rate: user_link.config ? user_link.config.notb_rate : 0,
			is_payable: user_link.config ? user_link.config.is_payable : 0,
			otb_setup_rate: user_link.config ? user_link.config.otb_setup_rate : 0,
			pnotb_setup_rate: user_link.config ? user_link.config.pnotb_setup_rate : 0,
			notb_setup_rate: user_link.config ? user_link.config.notb_setup_rate : 0,
			shuttle_rate: user_link.config ? user_link.config.shuttle_rate : 0,
			headCount_rate: user_link.config ? user_link.config.headCount_rate : 0,
		})
	}
	
	async function saveUserConfig() {
		try {
			setUpdating(true)
			await ClientService.updateClientUserLink(updateUserLink.client_id, updateUserLink.id, updateUserFormData)
			loadClientUsers()
			handleCloseUpdateUser()
		}catch(e) {
			tip_context.show(e.response?.data.message)
		}
		setUpdating(false)
	}

	function handleUpdateFormData(e) {
		setUpdateUserFormData({
			...updateUserFormData,
			[e.target.name]: e.target.value
		})
	}

	const common_input_props = {
		fullWidth: true, 
		variant: "outlined",
		size: "small",
		type: "number",
		onChange: e => handleUpdateFormData(e),
	}

	async function unlinkUser(user) {
		const choice = await confirm({
			description: "Are you sure to delete?"
		});
		if (!choice) return false;
		try {
			setUpdating(true)
			await ClientService.unlinkUserCompany(user.client_id, user.id)

			trackEvent('Client Profile Event', {
				action: 'Unlinked User from Client',
				clientId: client.ClientCode,
				userId: user.user_id,
				userEmail: allUsers.find(item => item.id == user.user_id)?.email,
				role: user.role.name,
			})
		}catch(e) {
			tip_context.show(e.response?.data.message)

			handleTrackError({ error: e, pageName: 'Client Profile', additionalMetadata: { clientCode: client.ClientCode, action: 'Unlinked User from Client' } })
		}

		loadClientUsers()
		setUpdating(false)
	}

	return <Paper elevation={2}>
		<SectionTitle 
			label="Users"
			rightComponent={
				<Button size="small" onClick={e => setOpenAddUser(true)}>
					Add User
				</Button>
			}
		/>
		<Table size="small" sx={jss.userTable}>
			<TableHead>
				<TableRow>
					<TableCell>Role</TableCell>
					<TableCell>Name</TableCell>
					<TableCell>Email</TableCell>
					<TableCell align="right">Actions</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{userLinks.map(_link => {
					return <TableRow hover key={`user_link_${_link.id}`}>
						<TableCell>{_link.role.name}</TableCell>
						<TableCell>{!_link.user?.first_name && !_link.user?.last_name ? _link.user?.email : ''} {_link.user?.first_name} {_link.user?.last_name}</TableCell>
						<TableCell>{_link.user?.email}</TableCell>
							<TableCell align="right">
								{isAdmin ? 
									<IconButton onClick={e => openUpdateUserModal(_link)} size="small" color="primary"><EditOutlinedIcon /></IconButton>
								: null}
								<IconButton onClick={e => unlinkUser(_link)} size="small" color="primary"><DeleteOutlinedIcon /></IconButton>
							</TableCell>
					</TableRow>
				})}
			</TableBody>
		</Table>

		{isAdmin ?
			<Dialog open={openAddUser} onClose={handleCloseAddUser} fullWidth>
				<DialogTitle>Add new user</DialogTitle>
				<DialogContent>
					<TextField
						label="Role"
						fullWidth
						select
						sx={{mt:2}}
						value={addUserFormData.role}
						onChange={e => setAddUserFormData({...addUserFormData, role: e.target.value})}
					>
						<MenuItem value={constants.ROLE_ID['affiliate']}>Affiliate</MenuItem>
						<MenuItem value={constants.ROLE_ID['client']}>Client</MenuItem>
						<MenuItem value={constants.ROLE_ID['marketer']}>Marketer</MenuItem>
						<MenuItem value={constants.ROLE_ID['planner']}>Planner</MenuItem>
						<MenuItem value={constants.ROLE_ID['referrer']}>Referrer</MenuItem>
						<MenuItem value={constants.ROLE_ID['reseller']}>Reseller</MenuItem>
						<MenuItem value={constants.ROLE_ID['setup']}>Setup</MenuItem>
					</TextField>
					<Autocomplete 
						options={allUsers}
						getOptionLabel={(option) => `${option.first_name ?? ''} ${option.last_name ?? ''} (${option.email})`}
						sx={{backgroundColor: 'white',mt:3}}
						loadingText={
							<CircularProgress color="inherit" />
						}
						onOpen={loadAllUsers}
						loading={allUserLoading}
						renderInput={params => 
							<TextField 
								{...params} 
								label="User email" 
							/>
						}
						onChange={handlerAddUserSelect}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseAddUser}>Cancel</Button>
					<LoadingButton disabled={!addUserFormData.user_id || !addUserFormData.role} loading={creating} onClick={addUserLink} variant="contained">Save</LoadingButton>
				</DialogActions>
			</Dialog>
		: <AddUserModal open={openAddUser} onClose={handleCloseAddUser} clientId={client?.Id ?? -1} onSave={loadClientUsers} />}

		{isAdmin ? 
			<Dialog open={openUpdateUser} onClose={handleCloseUpdateUser} fullWidth>
				<DialogTitle>Add new user</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Switch checked={!!updateUserFormData.is_payable} onChange={e => handleUpdateFormData({target:{name: 'is_payable', value:e.target.checked}})} />
								}
								label="Is user payable?"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...common_input_props}
								label="Share (%)"
								name="share_percent"
								value={updateUserFormData.share_percent || 0}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="subtitle2">RATES</Typography>
						</Grid>
						<Grid item xs={4}>
							<TextField
								{...common_input_props}
								label="OTB"
								name="otb_rate"
								value={updateUserFormData.otb_rate || 0}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								{...common_input_props}
								label="PNOTB"
								name="pnotb_rate"
								value={updateUserFormData.pnotb_rate || 0}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								{...common_input_props}
								label="NOTB"
								name="notb_rate"
								value={updateUserFormData.notb_rate || 0}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="subtitle2">SETUP RATES</Typography>
						</Grid>
						<Grid item xs={4}>
							<TextField
								{...common_input_props}
								label="OTB"
								name="otb_setup_rate"
								value={updateUserFormData.otb_setup_rate || 0}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								{...common_input_props}
								label="PNOTB"
								name="pnotb_setup_rate"
								value={updateUserFormData.pnotb_setup_rate || 0}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								{...common_input_props}
								label="NOTB"
								name="notb_setup_rate"
								value={updateUserFormData.notb_setup_rate || 0}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...common_input_props}
								label="Shuttle rate"
								name="shuttle_rate"
								value={updateUserFormData.shuttle_rate || 0}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...common_input_props}
								label="HeadCount rate"
								name="headCount_rate"
								value={updateUserFormData.headCount_rate || 0}
							/>
						</Grid>

					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseUpdateUser}>Cancel</Button>
					<LoadingButton loading={updating} onClick={saveUserConfig} variant="contained">Save</LoadingButton>
				</DialogActions>
			</Dialog>
		: null}

	</Paper>
}
export default ClientUser