import React from 'react'
import {
	Box,
	Modal,
  TextField,
  NativeSelect,
  Button,
} from '@mui/material';
import { Controller } from 'react-hook-form'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { ConnectForm } from './GeneralClientEditForm'

const cssInJs = {
	modalSection: {
		backgroundColor: 'white',
		p:3,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 500,
		borderRadius:2,
	},
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    marginBottom: '15px',
  },
}

const selectedAccountStatusOptions = [
  {
    label: 'Current Status: Fully Operational',
    value: 'Current Status: Fully Operational',
  },
  {
    label: 'Dropped',
    value: 'Dropped'
  },
  {
    label: 'Hiatus',
    value: 'Hiatus'
  },
  {
    label: 'Static Plan',
    value: 'Static Plan'
  },
  {
    label: 'Fully Operational',
    value: 'Fully Operational'
  },
  {
    label: 'Credit Hold',
    value: 'Credit Hold'
  },
]

const selectedStatusReasonOptions = [
  {
    label: 'Select Reason',
    value: ''
  },
  {
    label: 'Store Closed',
    value: 'Store Closed'
  },
  {
    label: 'New Ownership',
    value: 'New Ownership'
  },
  {
    label: 'Planning Issue',
    value: 'Planning Issue'
  },
  {
    label: 'Affiliate Issue',
    value: 'Affiliate Issue'
  },
  {
    label: 'No Value Perceived',
    value: 'No Value Perceived'
  },
  {
    label: 'Other',
    value: 'Other'
  }
]

const ClientStatusModal = (props) => {
  const { accountState } = props

  const isSelectedStatusReasonVisible = accountState === 'Dropped'

  return (
    <Modal open={props.open} onClose={e => props.onClose()}>
      <>
        <ConnectForm>
          {({ register, control }) => {
            return (
            <Box sx={cssInJs.modalSection}>
              <div>Client Status</div>
              <NativeSelect
                variant="standard"
                sx={cssInJs.select}
                fullWidth
                {...register('selectedAccountStatus')}
              >
                <option>Select State</option>
                {selectedAccountStatusOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
              </NativeSelect>
              {isSelectedStatusReasonVisible && <NativeSelect
                variant="standard"
                sx={cssInJs.select}
                fullWidth
                {...register('selectedStatusReason')}
              >
                <option>Select State</option>
                {selectedStatusReasonOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
              </NativeSelect>}
              <TextField
                id="notes"
                label="General notes"
                variant="standard"
                fullWidth
                multiline
                rows={4}
                {...register('statusNotes')}
              />
              <Controller
                control={control}
                name='changeDate'
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Change Date"
                      onChange={onChange}
                      value={value}
                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        variant="standard"
                        size="small"
                        sx={{mt:2}} 
                      />}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                control={control}
                name='expectedUpdateDate'
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Estimated Return Date"
                      onChange={onChange}
                      value={value}
                      renderInput={(params) => <TextField {...params}
                        fullWidth
                        variant="standard"
                        size="small"
                        sx={{mt:2}}
                      />}
                    />
                  </LocalizationProvider>
                )}
              />
              <br /><br />
              <Button
                variant="contained"
                onClick={() => props.onClose()}
              >
                Save Client Status
              </Button>
            </Box>
          )}}
        </ConnectForm>
      </>
    </Modal>
  )
}

export default ClientStatusModal