import {
  Navigate,
} from "react-router-dom";

function NotFound(props) {
	return <>
		Page not found
	</>
}

export default NotFound