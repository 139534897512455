import { useState, useEffect, useRef } from "react";
import {
	Box,
	Typography,
	Paper,
	Skeleton,
	IconButton,
	Checkbox,
	Button,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import SectionTitle from '../../../components/common/SectionTitle'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClientService from '../../../services/ClientService'
import _ from 'underscore'

function ClientLocation(props) {
	const { client, isAdmin } = props
	const [data, setData] = useState([])

	useEffect(() => {
		if( client ) {
			loadStatistics()
		}
	}, [client])

	async function loadStatistics() {
		const data = await ClientService.getClientLocationClassStatistics(client.Id)
		setData(data)
	}

	return <Paper elevation={2}>
		<SectionTitle 
			label="Locations"
			rightComponent={
				isAdmin ? <Button size="small">
					Manage Locations
				</Button>
				: null
			}
		/>
		<TableContainer sx={{py:2}}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell align="right">OTB</TableCell>
						<TableCell align="right">PNOTB</TableCell>
						<TableCell align="right">NOTB</TableCell>
						<TableCell align="right">Location</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{ data ? data.map((item, index) => {
					return <TableRow key={`location_statistics_${index}`}>
						<TableCell>{item.location.location_name}</TableCell>
						<TableCell align="right">{item.count?.OTB ? item.count.OTB : 0}</TableCell>
						<TableCell align="right">{item.count?.PNOTB ? item.count.PNOTB : 0}</TableCell>
						<TableCell align="right">{item.count?.NOTB ? item.count.NOTB : 0}</TableCell>
						<TableCell align="right">{item.location.city ? item.location.city+', ' : ''} {item.location.state ? item.location.state+', ' : ''}</TableCell>
					</TableRow>
				}) : null}
				</TableBody>
			</Table>
		</TableContainer>
	</Paper>
}
export default ClientLocation