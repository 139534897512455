import Request from './Request'
import constants from '../constants/constant'
import moment from "moment/moment";

const DataFileService = {
	async uploadDataFile(client_id, file, data={}) {
		let formData = new FormData()
		// append file
		formData.append("data", file)

		// build form data params
		for(let i in data) {
			formData.append(i, data[i])
		}

		// call api to upload the file
		return await Request.formPost(`/api/clients/${client_id}/data-files/upload`, formData)
	},
	async getClientDataFiles(client_id, params={}) {
		return await Request.get(`/api/clients/${client_id}/data-files`, params)
	},
	async downloadDataFileOriginFile(client_id, id) {
		return await Request.get(`/api/clients/${client_id}/data-files/${id}/download`)
	},
	async getClientDataFileById(client_id, id, params={}) {
		return await Request.get(`/api/clients/${client_id}/data-files/${id}`, params)
	},
	async finalizeDataFile(client_id, id) {
		return await Request.post(`/api/clients/${client_id}/data-files/${id}/finalize`)
	},
	async unfinalizeDataFile(client_id, id) {
		return await Request.post(`/api/clients/${client_id}/data-files/${id}/unfinalize`)
	},
	async deleteDataFile(client_id, id) {
		return await Request._delete(`/api/clients/${client_id}/data-files/${id}`)
	},
	async processDataFile(client_id, id) {
		return await Request.post(`/api/clients/${client_id}/data-files/${id}/process`)
	},
	async updateDataFile(client_id, id, data) {
		return await Request.post(`/api/clients/${client_id}/data-files/${id}`, data)
	},
	async getStatisticsByType(client_id, id, type) {
		return await Request.get(`/api/clients/${client_id}/data-files/${id}/statistics/${type}`)
	},
	async downloadDataFileOriginData(client_id, id) {
		window.open(`${constants.BACKEND_DOMAIN}/backend-clients/${client_id}/data-files/${id}/download?_access_token=${localStorage.getItem('access_token')}`, '_blank')
	},
	async clientDataFileBackup(client_id) {
		window.open(`${constants.BACKEND_DOMAIN}/clients/${client_id}/data-files/backup?_access_token=${localStorage.getItem('access_token')}`, '_blank')
	},
	async backdate(client_id, periodStart, months) {
		return Request.post(`/api/clients/${client_id}/data-file/backdate`, {periodStart, months})
	},
	async perpetuate(client_id, periodStart, months) {
		return Request.post(`/api/clients/${client_id}/data-file/perpetuate`, {periodStart, months})
	},

	/**
	 * Batch Finalize files given period range
	 * @param {*} client_id
	 * @param {*} periodStart
	 * @param {*} periodEnd
	 * @returns promise
	 */
	async batchFinalize(client_id, periodStart, periodEnd) {
		return await Request.post(`/api/clients/${client_id}/data-file/finalize/batch`, {periodStart, periodEnd})
	},

	/**
	 * Batch unfinalize files given period range
	 * @param {*} client_id 
	 * @param {*} periodStart 
	 * @param {*} periodEnd 
	 * @returns promise
	 */
	async batchUnfinalize(client_id, periodStart, periodEnd) {
		return await Request.post(`/api/clients/${client_id}/data-file/unfinalize/batch`, {periodStart, periodEnd})
	},

	async syncCloudData(client_id) {
		return await Request.post(`/api/clients/${client_id}/sync-flight-data`)
	},

	/**
	 * Download example data file
	 *
	 * @param {string} client_id
	 * @param {string} clientCode
	 */
	async downloadDataTemplate(client_id, clientCode) {
		Request.download(`/api/clients/${client_id}/data-file-template/download`).then(response => {
			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement("a");
			link.href = url;

			// build file name as yymm with moment
			const moment = require('moment')
			const fileName = "client_" + clientCode + moment().format('YYMM') + '_data.xlsx'

			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
		})
	},

	async downloadDataSource(client_id, period, fileName) {
		return Request.download(`/api/clients/${client_id}/data-file/${period}/download`, {})
	}
}

export default DataFileService