import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Chip,
	Paper,
	Switch,
	CircularProgress,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Divider,
	TextField,
	InputAdornment,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import jss from '../profile/ProfileCommonJss'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import constant from '../../../constants/constant'
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import LocationService from '../../../services/LocationService'
import { handleTrackError } from "../../../services/AmplitudeService";


function ClientLocationClassList(props) {
	const {
		classes,
		globalClasses,
		currentLocations,
		onClassChange, 
		onCurrentLocationChange, 
		multiMode,
		assignAvailable=false,
		onMultiModeChange=false,
		isAdmin=false,
	} = props
	const [existClasses, setExistClasses] = useState([])
	const [choosedClasses, setChoosedClasses] = useState([])
	const [keyword, setKeyword] = useState()
	const [showAllCheck, setShowAllCheck] = useState(false)
	const [allCheckedItems, setAllCheckedItems] = useState([])
	const [filteredClasses, setFilteredClasses] = useState([])
	const [filter, setFilter] = useState({
		otb: false,
		pnotb: false,
		notb: false,
		deleted: false,
	})
	const [openFilter, setOpenFilter] = useState(false)
	const filterElement = useRef()

	useEffect(() => {
		let _filtered_classes = []
		if( classes ) {
			console.log('updated')
			console.log(filter)
			_filtered_classes = classes.filter(item => {
				// filtered for location classes
				if (item.locationclass_name && !item.class) return false;
				if( keyword && item.locationclass_name.toLowerCase().indexOf(keyword.toLowerCase())==-1 ) {
					return false
				}
				if( filter.deleted ) {
					return true
				} else {
					return !item.deleted_at
				}
			}).filter(item => {
				let result = false
				if( !filter.otb && !filter.pnotb && !filter.notb ) {
					result = true
				} else {
					if( filter.otb && item.servicetypeid == constant.SERVICE_TYPE_ID.OTB ) {
						result = true
					}
					if( filter.pnotb && item.servicetypeid == constant.SERVICE_TYPE_ID.PNOTB ) {
						result = true
					}
					if( filter.notb && item.servicetypeid == constant.SERVICE_TYPE_ID.NOTB ) {
						result = true
					}
				}
				return result
			}).map(item => {
				if (!item.servicetypeid) {
					item.servicetypeid = item.service_type_id
				}

				if (!item.clientclass_id) {
					item.clientclass_id = item.id
				}

				// if (item.class && item.class.service_type_id != item.servicetypeid) {
				// 	item.isOverride = true
				// }
				return item
			})

			setFilteredClasses(_filtered_classes)
		}
	}, [classes, filter, keyword])

	useEffect(() => {
		if( currentLocations && currentLocations.length>0 ) {
			let _exists = []
			currentLocations.forEach(i => {
				i?.class_links.forEach(_link => {
					if( _exists.indexOf(_link.clientclass_id * 1)==-1 ) {
						_exists.push(_link.clientclass_id * 1)
					}
				})
			})
			setExistClasses(_exists)
			setChoosedClasses([])
		}
	}, [currentLocations])

	useEffect(() => {
		if( choosedClasses.length>0 ) {
			onClassChange(choosedClasses)	
		}
	}, [choosedClasses])

	useEffect(() => {
		setChoosedClasses([])
	}, [multiMode])

	function chooseClass(clientClass) {
		if( multiMode ) {
			let _choosedClasses = choosedClasses.map(i => {return i})
			if( choosedClasses.indexOf(clientClass)>-1 ) {
				_choosedClasses.splice(choosedClasses.indexOf(clientClass), 1)
			} else {
				_choosedClasses.push(clientClass)
			}
			setChoosedClasses(_choosedClasses)
		} else {
			setChoosedClasses([clientClass])
		}
	}

	function changeFilter(type) {
		if( type == 'all' ) {
			setFilter({...filter, otb:false, pnotb:false, notb:false})
		} else {
			let _f = filter
			_f[type] = !_f[type]
			setFilter({..._f})
		}
	}

	function updateKeyword(e) {
		setKeyword(e.target.value)
	}

	function swtichAllCheck() {
		setShowAllCheck(!showAllCheck)
		if( showAllCheck==true ) {
			setAllCheckedItems([])
		}
	}

	async function changeClassLocationLink(clientClass) {
		try {
			const copyCurrentLocation = {...currentLocations[0]}
			if( existClasses.indexOf(clientClass.id * 1)==-1 ) {
				setExistClasses([...existClasses, clientClass.id * 1])
				const _link = await LocationService.linkLocationClass(currentLocations[0].clientid, currentLocations[0].client_location_id, clientClass.id)
				copyCurrentLocation.class_links.push(_link)
			} else {
				setExistClasses(existClasses.filter(id => id !== clientClass.id * 1))
				const _link = await LocationService.unlinkLocationClass(currentLocations[0].clientid, currentLocations[0].client_location_id, clientClass.id)
				let origin_class_links = currentLocations[0].class_links.map(item => {
					if (item.client_location_id ==_link.client_location_id) {
						return _link
					} else {
						return item
					}
					// return item.locationclassid!=_link.locationclassid
				})
				copyCurrentLocation.class_links = origin_class_links
			}
			if( onCurrentLocationChange ) {
				onCurrentLocationChange(copyCurrentLocation)	
			}
		} catch(e) {
			console.log(e)

			handleTrackError({ error: e, pageName: 'Client Location Class List', overrideMessage: 'Failed to link location class' })
		}
	}

	return <Box sx={jss.dataGridItem}>
		<Box sx={jss.dataTop}>
			<Box sx={jss.dataTitle}>
				Classes
				{onMultiModeChange && 
					<FormControlLabel 
						control={<Switch value={multiMode} size="small" onChange={onMultiModeChange}/>} 
						label="Multi select" 
						sx={{ml:2}}
					/>
				}
			</Box>
		</Box>
		<Box sx={jss.actionSection}>
			<TextField 
				size="small"
				variant="outlined"
				placeholder="Search"
				value={keyword}
				onChange={updateKeyword}
				InputProps={{
					startAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
					),
				}}
			/>
			<Box sx={jss.dataAction}>
				<IconButton size="small" onClick={e => setOpenFilter(true)} ref={filterElement}><FilterListRoundedIcon /></IconButton>
				{!multiMode && assignAvailable && isAdmin ?
					<IconButton size="small" sx={showAllCheck ? {...jss.iconButtonActive} : {}} onClick={swtichAllCheck}><PlaylistAddCheckIcon /></IconButton>
				: null}
				<Menu open={openFilter} anchorEl={filterElement.current} onClose={e => {setOpenFilter(false)}}>
					<MenuItem sx={{width:200}} dense onClick={e => changeFilter('all')}>
						<ListItemIcon>{(!filter.otb && !filter.pnotb && !filter.notb) && <Check />}</ListItemIcon>
						<ListItemText >Show All</ListItemText>
					</MenuItem>
					<MenuItem sx={{width:200}} dense onClick={e => changeFilter('otb')}>
						<ListItemIcon>{filter.otb && <Check />}</ListItemIcon>
						<ListItemText >OTB</ListItemText>
					</MenuItem>
					<MenuItem sx={{width:200}} dense onClick={e => changeFilter('pnotb')}>
						<ListItemIcon>{filter.pnotb && <Check />}</ListItemIcon>
						<ListItemText >PNOTB</ListItemText>
					</MenuItem>
					<MenuItem sx={{width:200}} dense onClick={e => changeFilter('notb')}>
						<ListItemIcon>{filter.notb && <Check />}</ListItemIcon>
						<ListItemText >NOTB</ListItemText>
					</MenuItem>
					<Divider />
					<MenuItem sx={{width:200}} dense onClick={e => changeFilter('deleted')}>
						<ListItemIcon>{filter.deleted && <Check />}</ListItemIcon>
						<ListItemText >Dropped</ListItemText>
					</MenuItem>
				</Menu>
			</Box>
		</Box>
		{!classes && 
			<Box sx={jss.loadingSection}>
				<CircularProgress />
			</Box>
		}
		{
			showAllCheck &&
			<>
				<Box sx={jss.dataContent}>
					{globalClasses.map(_class => {
						return <Box
							sx={jss.dataContentItem}
							key={`class_${_class.id}`}
						>
							<Checkbox
								size="small"
								checked={existClasses.indexOf(_class.id * 1) > -1}
								onChange={e => changeClassLocationLink(_class)}
							/>
							<Box sx={jss.dataContentItem.name}>
								{_class.name}
								{existClasses.indexOf(_class.id * 1)}
								{_class.id * 1}
							</Box>
							<Box sx={jss.dataContentItem.action}>
								{_class.code}
								{_class.service_type_id == constant.SERVICE_TYPE_ID.OTB &&
									<Chip label="O" size="small"/>
								}
								{_class.service_type_id == constant.SERVICE_TYPE_ID.PNOTB &&
									<Chip label="P" size="small"/>
								}
								{_class.service_type_id == constant.SERVICE_TYPE_ID.NOTB &&
									<Chip label="N" size="small"/>
								}
								{_class.service_type_id == constant.SERVICE_TYPE_ID.History &&
									<Chip label="H" size="small"/>
								}
								{_class.service_type_id == constant.SERVICE_TYPE_ID.Distress &&
									<Chip label="D" size="small"/>
								}
								{_class.service_type_id == constant.SERVICE_TYPE_ID.Warehouse &&
									<Chip label="W" size="small"/>
								}
								{_class.service_type_id == constant.SERVICE_TYPE_ID.Child &&
									<Chip label="C" size="small"/>
								}
							</Box>
						</Box>
					})}
					{assignAvailable && globalClasses.length == 0 &&
						<Box sx={jss.dataContent.empty}>
							<Box>There are no classes.</Box>
						</Box>
					}
				</Box>
			</>
		}
		{!!classes && !showAllCheck &&
			<>
				<Box sx={jss.dataContent}>
					{filteredClasses.map(_class => {
						let _className = ""
						if (choosedClasses?.map(i => {
							return i.id
						}).indexOf(_class.id) > -1) {
							_className = '_active'
						}
						if (!multiMode) {
							if (choosedClasses?.map(i => {
								return i.parent_class_id
							}).indexOf(_class.id) > -1) {
								_className = '_parent_active'
							} else if (choosedClasses.length > 0 && choosedClasses?.map(i => {
								return i.id
							}).indexOf(_class.parent_class_id) > -1) {
								_className = '_child_active'
							}
						}
						if (_class.deleted_at) {
							_className += ' _deleted '
						}
						if (_class?._type == 'child') {
							_className += ' _child'
						}
						if (_class.isOverride) {
							_className += ' _override';
						}
						return <Box
							sx={jss.dataContentItem}
							className={_className}
							key={`location_class_${_class.id}`}
						>
							{showAllCheck &&
								<Checkbox
									size="small"
									checked={existClasses.indexOf(_class.id) > -1}
									onClick={e => changeClassLocationLink(_class)}
								/>
							}
							<Box sx={jss.dataContentItem.name} onClick={e => chooseClass(_class)}>
								{_class.locationclass_name}
							</Box>
							<Box sx={jss.dataContentItem.action} onClick={e => chooseClass(_class)}>
								{_class.clientclass_code}
								{_class.servicetypeid == constant.SERVICE_TYPE_ID.OTB &&
									<Chip label="O" size="small"/>
								}
								{_class.servicetypeid == constant.SERVICE_TYPE_ID.PNOTB &&
									<Chip label="P" size="small"/>
								}
								{_class.servicetypeid == constant.SERVICE_TYPE_ID.NOTB &&
									<Chip label="N" size="small"/>
								}
								{_class.servicetypeid == constant.SERVICE_TYPE_ID.History &&
									<Chip label="H" size="small"/>
								}
								{_class.servicetypeid == constant.SERVICE_TYPE_ID.Distress &&
									<Chip label="D" size="small"/>
								}
								{_class.servicetypeid == constant.SERVICE_TYPE_ID.Warehouse &&
									<Chip label="W" size="small"/>
								}
								{_class.servicetypeid == constant.SERVICE_TYPE_ID.Child &&
									<Chip label="C" size="small"/>
								}
							</Box>
						</Box>
					})}
					{assignAvailable && filteredClasses.length == 0 &&
						<Box sx={jss.dataContent.empty}>
							<Box>There are no classes for the selected location.</Box>
							<Box>You can select classes for this location.</Box>
						</Box>
					}
				</Box>
			</>
		}
		
	</Box>
}
export default ClientLocationClassList