import Request from './Request'
import constants from '../constants/constant'

const LocationService = {
	async deleteById(client_id, location_id) {
		return await Request._delete(`/api/clients/${client_id}/locations/${location_id}`)
	},

	async deleteLocationClassById(ClientId, locationClassId) {
		return await Request._delete(`/api/clients/${ClientId}/location_class/${locationClassId}`)
	},

	async undeleteLocationClassById(ClientId, locationClassId) {
		return await Request.post(`/api/clients/${ClientId}/location_class/${locationClassId}/restore`)
	},

	async undeleteById(client_id, location_id) {
		return await Request.post(`/api/clients/${client_id}/locations/${location_id}/restore`)
	},

	async updateLocation(client_id, location_id, data) {
		return await Request.post(`/api/clients/${client_id}/locations/${location_id}`, data)
	},
	async linkLocationClass(client_id, location_id, class_id) {
		return await Request.post(`/api/clients/${client_id}/locations/${location_id}/classes/${class_id}/link`)
	},
	async unlinkLocationClass(client_id, location_id, class_id) {
		return await Request.post(`/api/clients/${client_id}/locations/${location_id}/classes/${class_id}/unlink`)
	},
	async createLocation(client_id, data) {
		return await Request.post(`/api/clients/${client_id}/locations/add`, data)
	},
}

export default LocationService