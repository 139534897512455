import Request from './Request'

const UserService = {
	async getUsers() {
		const users = await Request.get('/api/users')
		return users
	},
	async getUserById(id) {
		const user = await Request.get(`/api/users/${id}`)
		return user
	},

	/**
	 * Create new user
	 * @param {*} name 
	 * @param {*} email 
	 * @param {*} additionalParams 
	 * @returns 
	 */
	async createUser(name, email, additionalParams={}) {
		const user = await Request.post('/api/users', {
			name, email, ...additionalParams
		})
		return user
	},
	async updateUserById(id, data) {
		return await Request.post(`/api/users/${id}`, data)
	},
	async deleteById(id) {
		return await Request._delete(`/api/users/${id}`)
	},
	async undeleteById(id) {
		return await Request.post(`/api/users/${id}/restore`)
	},
	async getUserRoles(id) {
		const links = await Request.get(`/api/users/${id}/roles`)
		return links
	},

	/**
	 * Login the user with given credentials
	 * 
	 * @param {*} email 
	 * @param {*} password 
	 * @param {bool} returnSingleClient Whether to return user's client if they only have one. Default false.
	 * @returns 
	 */
	async login(email, password, returnSingleClient=false) {
		return await Request.post('/api/login', {email, password, returnSingleClient})
	},

	async getCurrentUser() {
		return await Request.get('/api/user/current')
	},
	async resetActivationKey(id) {
		return await Request.post(`/api/users/${id}/reset-activation-key`)
	},
	async assignUserToRole(user_id, role_id, client_id=null, location_id=null) {
		return await Request.post(`/api/users/${user_id}/role-links/${role_id}`, {
			client_id, 
			location_id
		})
	},
	async unassignUserAndRole(user_id, link_id) {
		return await Request._delete(`/api/users/${user_id}/role-links/${link_id}`)
	},
	async sendResetPasswordEmail(user_id) {
		return await Request.post(`/api/users/${user_id}/reset-password`)
	},
	async getAdminUsers() {
		return await Request.get('/api/admin-users')
	},
	async getMyCurrentPermissions() {
		return await Request.get('/api/user/permissions/current')
	},

	/**
	 * Returns list of ids for the clients that the auth user has access to
	 */
	async getUserClients() {
		return await Request.get('/api/user/client-list')
	},

	async getZendUrl() {
		return Request.get("/api/zendesk/get-zendesk-payload");
	},

	/**
	 * Resync user permissions from hero db
	 *
	 * @param {string} user_id
	 *
	 * @returns {Promise<void>}
	 */
	async resyncUserPermissions(user_id) {
		return await Request.get(`/api/users/${user_id}/resync-permissions`)
	},

	/**
	 * Get client if user is assigned to only one client or return false otherwise
	 *
	 * @returns {Promise<false|Object>}
	 */
	async getSingleClient() {
		return await Request.get('/api/user/client')
	},
}

export default UserService