import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Switch,
	CircularProgress,
	Chip,
	Menu,
	MenuItem,
	Alert,
	TextField,
	Autocomplete, FormControl, RadioGroup, FormControlLabel, Radio,
} from '@mui/material';
import constant from '../../../constants/constant'
import ClientService from '../../../services/ClientService'
import LabelService from "../../../services/LabelService";
import ClearIcon from "@mui/icons-material/Clear";
import {LoadingButton} from "@mui/lab";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import { handleTrackError, trackEvent } from "../../../services/AmplitudeService";


const jss = {
	tableLabel: {
		fontWeight: 'bolder',
		width: '25%',
		textAlign: 'right'
	},
	tagSection: {
		'.MuiChip-root': {
			mr:1,
			mt:1,
		}
	},
	editTitle: {
		mt: 2
	}
}

const common_input_props = {
	fullWidth: true,
	variant: "outlined",
	size: "small",
	sx: {mt:2},
}


function ClassLocationOverwriteForm(props) {
	const { clientClass, locationClasses, labels, categories, client } = props
	// const [labels, setLabels] = useState()
	// const [categories, setCategories] = useState()
	const [form_data, setFormData] = useState()
	const [saving, setSaving] = useState(false)

	useEffect(() => {
		if( clientClass ) {
			setFormData({
				servicetypeid: clientClass.servicetypeid,
				tags: clientClass.labels,
				categories: clientClass.categories,
				child_classes: clientClass.children,
				parent_class: clientClass.parent_class,
				Imu: clientClass.Imu,
				LocationClassModelId: clientClass.LocationClassModelId
			})
			// loadLabels();
			// loadCategories();
		}
	}, [clientClass])

	// function updateServiceType(serviceType) {
	// 	setFormData({
	// 		...form_data,
	// 		servicetypeid: serviceType
	// 	})
	// }

	function updateFormData(e) {
		setFormData({
			...form_data,
			[e.target.name]: e.target.value
		})
	}

	function imuOptionsChange(event, newValue) {
		updateFormData({
			target: {
				name: 'LocationClassModelId',
				value: newValue
			}
		})
	}

	function updateLabel(e) {
		const _tags = form_data.tags
		_tags.push(e.target.value)
		updateFormData({
			target:{
				name:'tags',
				value:_tags
			},
		})
	}
	function updateCategory(e) {
		const _tags = form_data.categories
		_tags.push(e.target.value)
		updateFormData({
			target:{
				name:'categories',
				value:_tags
			},
		})
	}

	function updateChildClasses(e) {
		const _child_classes = form_data.child_classes
		_child_classes.push(e.target.value)
		updateFormData({
			target:{
				name:'child_classes',
				value:_child_classes
			},
		})
	}

	function handleDeleteTag(label_id) {
		const _tags = form_data.tags.filter(item => {
			return item.id != label_id
		})
		updateFormData({
			target:{
				name:'tags',
				value:_tags
			},
		})
	}

	function handleDeleteCategory(category_id) {
		const _categories = form_data.categories.filter(item => {
			return item.Id != category_id
		})
		updateFormData({
			target:{
				name:'categories',
				value:_categories
			},
		})
	}

	function handleDeleteChild(child_id) {
		const _children = form_data.child_classes.filter(item => {
			return item.id != child_id
		})
		updateFormData({
			target:{
				name:'child_classes',
				value:_children
			},
		})
	}

	async function loadLabels() {
		const data = await LabelService.getLabels()
		setLabels(data)
	}

	async function loadCategories() {
		const data = await ClientService.getClientCategories(clientClass.ClientId)
		setCategories(data)
	}

	async function saveLocationClass() {
		// if client service type is changing from plannable (OTB, PNOTB) to non plannable
		const plannableIds = ["1", "2"];
		if (!plannableIds.includes(form_data.servicetypeid) && plannableIds.includes(clientClass.servicetypeid)) {
			if (!window.confirm('Are you sure you want to change the service type from OTB to not OTB?')) {
				return;
			}
		}
		setSaving(true);
		try {
			let response = await ClientService.updateClientLocationClass(clientClass.ClientId, clientClass.client_location_id, clientClass.locationclassid, form_data);
			
			trackEvent('Client Location Classes Event', {
				action: 'Updated Location Class',
				clientCode: client?.ClientCode,
			})
		} catch (e) {
			console.log(e)

			handleTrackError({ error: e, pageName: 'Client Location Classes', additionalMetadata: { action: 'Update Location Class', clientCode: client?.ClientCode } })
		}
		setSaving(false);
		props.onSave(response)
	}

	if( clientClass && labels && categories && form_data ) {
		return <Box>
			<TextField
				{...common_input_props}
				label="Service Type"
				name="servicetypeid"
				value={form_data ? form_data.servicetypeid : ''}
				select
				onChange={e => updateFormData(e)}
			>
				{
					Object.keys(constant.SERVICE_TYPE_ID).map(item => {
						return <MenuItem key={constant.SERVICE_TYPE_ID[item]} value={constant.SERVICE_TYPE_ID[item]}>{ item }</MenuItem>;
					})
				}
			</TextField>
			{labels?.labels &&
				<>
					<Box sx={jss.editTitle}>M1 Tags</Box>
					<Box sx={jss.tagSection}>
						{form_data.tags.map(_tag => {
							return <Chip key={`location_edit_tag_${_tag.id}`} label={_tag.name} onDelete={e=>handleDeleteTag(_tag.id)} deleteIcon={<ClearIcon />}/>
						})}
					</Box>
					<TextField
						{...common_input_props}
						label="Store Profile"
						name="store_profile"
						select
						onChange={e => updateLabel(e)}
					>
						{labels.labels.filter(item => {return (item.label_type_id==6 && form_data.tags.indexOf(item)==-1)}).map(item => {
							return <MenuItem key={`label_${item.id}`} value={item}>{item.name}</MenuItem>
						})}
					</TextField>
					<TextField
						{...common_input_props}
						label="Store Type"
						name="store_type"
						select
						onChange={e => updateLabel(e)}
					>
						{labels.labels.filter(item => {return (item.label_type_id==7 && form_data.tags.indexOf(item)==-1)}).map(item => {
							return <MenuItem key={`label_${item.id}`} value={item}>{item.name}</MenuItem>
						})}
					</TextField>
					<TextField
						{...common_input_props}
						label="Location"
						name="location"
						select
						onChange={e => updateLabel(e)}
					>
						{labels.labels.filter(item => {return (item.label_type_id==3 && form_data.tags.indexOf(item)==-1)}).map(item => {
							return <MenuItem key={`label_${item.id}`} value={item}>{item.name}</MenuItem>
						})}
					</TextField>
					<TextField
						{...common_input_props}
						label="Geographic"
						name="geographic"
						select
						onChange={e => updateLabel(e)}
					>
						{labels.labels.filter(item => {return (item.label_type_id==4 && form_data.tags.indexOf(item)==-1)}).map(item => {
							return <MenuItem key={`label_${item.id}`} value={item}>{item.name}</MenuItem>
						})}
					</TextField>
					<TextField
						{...common_input_props}
						label="Divisions"
						name="divisions"
						select
						onChange={e => updateLabel(e)}
					>
						{labels.labels.filter(item => {return (item.label_type_id==5 && form_data.tags.indexOf(item)==-1)}).map(item => {
							return <MenuItem key={`label_${item.id}`} value={item}>{item.name}</MenuItem>
						})}
					</TextField>
				</>
			}
			{
				form_data?.categories && categories &&
					<>
						<Box sx={jss.editTitle}>Categories</Box>
						<Box sx={jss.tagSection}>
							{form_data.categories.map(_tag => {
								return <Chip key={`location_edit_category_${_tag.Id}`} label={_tag.Name} onDelete={e=>handleDeleteCategory(_tag.Id)} deleteIcon={<ClearIcon />}/>
							})}
						</Box>
						<TextField
							{...common_input_props}
							label="Categories"
							name="categories"
							select
							onChange={e => updateCategory(e)}
						>
							{categories.filter(item => {return (form_data.categories.findIndex(i => i.Id == item.Id) === -1)}).map(item => {
								return <MenuItem key={`label_${item.Id}`} value={item}>{item.Name}</MenuItem>
							})}
						</TextField>
					</>
			}
			{
				!clientClass.parent_locationclassid &&
					<>
						<Box sx={jss.editTitle}>Child Classes</Box>
						{
							form_data && form_data.child_classes &&
							<>
								<Box sx={jss.tagSection}>
									{form_data.child_classes.map(child => {
										return <Chip key={`location_edit_child_${child.id}`} label={child.locationclass_name} onDelete={e=>handleDeleteChild(child.id)} deleteIcon={<ClearIcon />}/>
									})}
								</Box>
								<TextField
									{...common_input_props}
									label="Child Classess"
									name="child_classes"
									select
									onChange={e => updateChildClasses(e)}
								>
									{locationClasses.filter(item => {return (item.id !== clientClass.id && form_data.child_classes.findIndex(i => i.id == item.id) === -1)}).map(item => {
										return <MenuItem key={`location_class_${item.id}`} value={item}>{item.locationclass_name}</MenuItem>
									})}
								</TextField>
							</>
						}
					</>
			}

			{
				!!form_data &&
				<>
					<TextField
						{...common_input_props}
						label="Imu"
						name="Imu"
						value={form_data.Imu}
						type="number"
						onChange={e => updateFormData(e)}
					/>

					<FormControl>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							value={form_data.LocationClassModelId}
							name="radio-buttons-group"
							onChange={imuOptionsChange}
						>
							<FormControlLabel value="0" control={<Radio />} label="Receiving" />
							<FormControlLabel value="1" control={<Radio />} label="On Order" />
							<FormControlLabel value="2" control={<Radio />} label="Plan" />
							<FormControlLabel value="3" control={<Radio />} label="Override" />
						</RadioGroup>
					</FormControl>
				</>
			}

			{
				clientClass.parent_locationclassid &&
					<>
						<Box sx={jss.editTitle}>Parent Class</Box>
						<TextField
							{...common_input_props}
							label="Parent Class"
							name="parent_class"
							value={form_data.parent_class ? form_data.parent_class.id : ''}
							select
						>
							{locationClasses.map(item => {
								return <MenuItem key={`location_class_parent_${item.id}`} value={item}>{item.locationclass_name}</MenuItem>
							})}
						</TextField>
					</>
			}

			<Box sx={{mt:2}}>
				<LoadingButton loading={saving} size="large" variant="contained" onClick={saveLocationClass} startIcon={<SaveTwoToneIcon />}>
					Save
				</LoadingButton>
			</Box>

		</Box>
	} else {
		return <Box sx={jss.loadingSection}>
			<CircularProgress />
		</Box>
	}
}
export default ClassLocationOverwriteForm