import {useState, useEffect} from 'react'
import {
    Navigate,
    useNavigate
} from "react-router-dom";
import {
    Container,
    Box,
    TextField,
    Button,
    Grid,
    Typography,
    Paper,
    Link
} from '@mui/material'
import {
    LoadingButton
} from '@mui/lab'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import AuthService from '../services/AuthService'

function ForgotPassword(props) {
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({})
    const [requesting, setRequesting] = useState(false)
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Retail ORBIT: Forgot Password'

        resetError()
    }, [])

    function handleEmail(e) {
        setEmail(e.target.value)
        resetError()
    }
    function resetError() {
        setErrors({
            email: false,
            email_message: '',
        })
    }

    async function forgotPassword() {
        if( !email.match(/.+?@.+?/) ) {
            setErrors({
                ...errors,
                email: true,
                email_message: 'Please enter the correct email'
            })
            return
        }
        setRequesting(true)
        try {
            const _request = await AuthService.forgotPassword(email)
            if (_request.result) {
                setSuccess(true)
            }
            setRequesting(false)
        } catch (e) {
            setErrors({
                ...errors,
                email_message: e.response?.data.message
            })
            setRequesting(false)
        }
    }

    return <>
        <Container fixed>
            <Grid container sx={{pt: 20}}>
                <Grid item xs={0} sm={2} lg={3}></Grid>
                <Grid item xs={12} sm={8} lg={6}>
                    <Paper sx={{p: 3, pb:4}}>
                        <Typography variant="h5">
                            Retail ORBIT®
                        </Typography>
                        {!success && (
                            <>
                                <Typography variant="subtitle1" sx={{mb:3}}>
                                    Reset your password
                                </Typography>
                                <TextField
                                    fullWidth
                                    label="Email Address"
                                    value={email}
                                    name="email"
                                    onChange={handleEmail}
                                    error={errors.email}
                                    helperText={errors.email_message}
                                    sx={{backgroundColor: 'white'}}
                                />
                                <LoadingButton
                                    variant="contained"
                                    loading={requesting}
                                    color='primary'
                                    sx={{mt: 2, mb: 2}}
                                    fullWidth
                                    size="large"
                                    onClick={forgotPassword}
                                    startIcon={<ExitToAppRoundedIcon />}
                                >
                                    Recover
                                </LoadingButton>
                            </>
                        )}
                        {success && (
                            <>
                                <Typography variant="subtitle1" sx={{mb:3}}>
                                    Your password recovery instructions have been sent.
                                </Typography>
                                <Link
                                    href="login"
                                    underline="hover"
                                    sx={{ textTransform: 'uppercase'}}
                                >
                                    Go to login
                                </Link>
                            </>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    </>
}

export default ForgotPassword