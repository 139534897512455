import {
    useState,
    useEffect,
} from "react";

import {
    Box,
    Paper,
    Chip,
    MenuItem,
    Fade,
    Collapse,
    Alert,
    TextField,
} from '@mui/material';
import {
    LoadingButton
} from '@mui/lab'
import SectionTitle from '../../../components/common/SectionTitle'
import LocationService from '../../../services/LocationService'
import PosService from '../../../services/PosService'
import moment from 'moment'
import ClearIcon from '@mui/icons-material/Clear';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import TagService from "../../../services/TagService";
import { handleTrackError, trackEvent } from "../../../services/AmplitudeService";


const jss = {
    tableLabel: {
        fontWeight: 'bolder',
        width: '25%',
        textAlign: 'right'
    },
    editTitle: {
        fontSize: 16,
        fontWeight: 'bolder',
        mt: 3,
    },
    tagSection: {
        '.MuiChip-root': {
            mr: 1,
            mt: 1,
        }
    }
}


function ClientLocationCreate(props) {
    const { location, client } = props
    const [saving, setSaving] = useState(false)
    const [has_change, setHasChange] = useState(false)
    const [form_data, setFormData] = useState({})
    const [tagData, setTagData] = useState()
    const [pos, setPos] = useState([])

    useEffect(() => {
        if (location) {
            setHasChange(false)
            // loadLabels()
            loadTags()
            loadPos()
            setFormData({
                location_code: '',
                location_name: location.location_name,
                tags: [],
                address: '',
                address2: '',
                city: '',
                state: '',
                zipcode: '',
                province: '',
                country: '',
                phone: '',
                fax: '',
                pos_id: '',
                fiscal_year_type: '',
                fiscal_year_date: '',
                breakeven: '',
                imu: 0,
                active_date: '',
                billing_start_date: '',
                setup_billed_date: '',
            })
        }
    }, [location])

    async function loadTags() {
        const data = await TagService.getTagGroups()
        setTagData(data)
    }

    async function loadPos() {
        let _pos = await PosService.getPos()
        setPos(_pos)
    }

    const common_input_props = {
        fullWidth: true,
        variant: "outlined",
        size: "small",
        onChange: e => updateFormData(e),
        sx: {mt: 2},
    }

    function updateFormData(e) {
        setFormData({
            ...form_data,
            [e.target.name]: e.target.value
        })
        if (!has_change) {
            setHasChange(true)
        }
    }

    function handleDeleteTag(label_id) {
        const _tags = form_data.tags.filter(item => {
            return item.id != label_id
        })
        updateFormData({
            target: {
                name: 'tags',
                value: _tags
            },
        })
    }

    function updateTag(e) {
        const _tags = form_data.tags
        _tags.push(e.target.value)
        updateFormData({
            target: {
                name: 'tags',
                value: _tags
            },
        })
    }

    async function saveLocation() {
        try {
            setSaving(true)
            const _location = await LocationService.createLocation(location.clientid, form_data)
            setHasChange(false)
            if (props.onSave) {
                props.onSave(_location, location.location_name)
            }

            trackEvent("Client Locations Event", {
                action: "Create Location",
                location: location.location_name,
                clientCode: client.ClientCode,
            })
        } catch (e) {
            console.log(e.response?.data.message)

            handleTrackError({ error: e, pageName: 'Client Locations', additionalMetadata: { action: 'Failed To Create Location', clientCode: client.ClientCode } })
        }
        setSaving(false)
    }

    if (location) {
        return <Paper elevation={2}>
            <SectionTitle
                label={location.location_name}
                subLabel="Create Location Profile"
                type="primary"
            />
            <Box sx={{p: 2}}>
                <Fade timeout={500} in>
                    <Box>
                        <Collapse in={has_change}>
                            <Alert sx={{mt: 2}} severity="warning">You have unsaved changes, please hit save before
                                continuing.</Alert>
                        </Collapse>

                        <Box sx={{...jss.editTitle, mt: 0}}>General</Box>
                        <TextField
                            {...common_input_props}
                            label="Code"
                            name="location_code"
                            value={form_data.location_code || ''}
                        />
                        <TextField
                            {...common_input_props}
                            label="Name"
                            name="location_name"
                            value={form_data.location_name || ''}
                        />

                        {(form_data?.tags && tagData) &&
                            <>
                                <Box sx={jss.editTitle}>Tags</Box>
                                <Box sx={jss.tagSection}>
                                    {form_data.tags.map(_tag => {
                                        return <Chip key={`location_edit_tag_${_tag.id}`} label={_tag.name}
                                                     onDelete={e => handleDeleteTag(_tag.id)}
                                                     deleteIcon={<ClearIcon/>}/>
                                    })}
                                </Box>
                                {
                                    tagData.map(_g => {
                                        return <TextField
                                                    {...common_input_props}
                                                    label={_g.name + ' Tags'}
                                                    name="_g.name"
                                                    select
                                                    onChange={e => updateTag(e)}
                                                    key={`tagData_${_g.id}`}
                                                >
                                                    {_g.tags.map(item => {
                                                        return <MenuItem key={`tag_g_${item.id}`}
                                                                         value={item}>{item.name}</MenuItem>
                                                    })}
                                                </TextField>
                                    })
                                }
                            </>
                        }

                        <Box sx={jss.editTitle}>Address</Box>
                        <TextField
                            {...common_input_props}
                            label="Address 1"
                            name="address"
                            value={form_data.address || ''}
                        />
                        <TextField
                            {...common_input_props}
                            label="Address 2"
                            name="address2"
                            value={form_data.address2 || ''}
                        />
                        <TextField
                            {...common_input_props}
                            label="City"
                            name="city"
                            value={form_data.city || ""}
                        />
                        <TextField
                            {...common_input_props}
                            label="State"
                            name="state"
                            value={form_data.state || ""}
                        />
                        <TextField
                            {...common_input_props}
                            label="ZipCode"
                            name="zipcode"
                            value={form_data.zipcode || ""}
                        />
                        <TextField
                            {...common_input_props}
                            label="Province"
                            name="province"
                            value={form_data.province || ""}
                        />
                        <TextField
                            {...common_input_props}
                            label="Country"
                            name="country"
                            value={form_data.country || ""}
                        />

                        <Box sx={jss.editTitle}>Contact Detail</Box>
                        <TextField
                            {...common_input_props}
                            label="Phone"
                            name="phone"
                            value={form_data.phone || ""}
                        />
                        <TextField
                            {...common_input_props}
                            label="Fax"
                            name="fax"
                            value={form_data.fax || ""}
                        />

                        <Box sx={jss.editTitle}>Technical</Box>
                        <TextField
                            {...common_input_props}
                            label="POS System"
                            name="pos_id"
                            value={form_data.pos_id || ""}
                            select
                        >
                            {
                                pos.map(item => {
                                    if (item.name) {
                                        return <MenuItem key={`pos_option_${item.id}`}
                                                         value={item.id}>{item.name}</MenuItem>
                                    }
                                })
                            }
                        </TextField>
                        <TextField
                            {...common_input_props}
                            label="Fiscal Year Type"
                            name="fiscal_year_type"
                            value={form_data.fiscal_year_type || ""}
                            select
                        >
                            <MenuItem value='calendar'>Calendar</MenuItem>
                            <MenuItem value='4-5-4'>4-5-4</MenuItem>
                            <MenuItem value='4-4-5'>4-4-5</MenuItem>
                        </TextField>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Fiscal Year End"
                                value={form_data.fiscal_year_date}
                                inputFormat="MMM dd"
                                mask=""
                                onChange={e => updateFormData({
                                    target: {
                                        value: moment(e).format("YYYY-MM-DD HH:mm:ss"),
                                        name: 'fiscal_year_date'
                                    }
                                })}
                                renderInput={(params) => <TextField {...params}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    sx={{mt: 2}}
                                />}
                            />
                        </LocalizationProvider>
                        <TextField
                            {...common_input_props}
                            label="Breakeven"
                            name="breakeven"
                            value={form_data.breakeven || ""}
                            type="number"
                        />
                        <TextField
                            {...common_input_props}
                            label="Default Imu"
                            name="imu"
                            value={form_data.imu || ""}
                            type="number"
                        />

                        <Box sx={jss.editTitle}>Commercial</Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Active Date"
                                value={form_data.active_date}
                                inputFormat="MMM dd yyyy"
                                mask=""
                                onChange={e => updateFormData({
                                    target: {
                                        value: moment(e).format("YYYY-MM-DD HH:mm:ss"),
                                        name: 'active_date'
                                    }
                                })}
                                renderInput={(params) => <TextField {...params}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    sx={{mt: 2}}
                                />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Billing Start Date"
                                value={form_data.billing_start_date}
                                inputFormat="MMM dd yyyy"
                                mask=""
                                onChange={e => updateFormData({
                                    target: {
                                        value: moment(e).format("YYYY-MM-DD HH:mm:ss"),
                                        name: 'billing_start_date'
                                    }
                                })}
                                renderInput={(params) => <TextField {...params}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    sx={{mt: 2}}
                                />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Setup Billed Date"
                                value={form_data.setup_billed_date}
                                inputFormat="MMM dd yyyy"
                                mask=""
                                onChange={e => updateFormData({
                                    target: {
                                        value: moment(e).format("YYYY-MM-DD HH:mm:ss"),
                                        name: 'setup_billed_date'
                                    }
                                })}
                                renderInput={(params) => <TextField {...params}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    sx={{mt: 2}}
                                />}
                            />
                        </LocalizationProvider>

                        <Box sx={{mt: 2}}>
                            <LoadingButton loading={saving} size="large" variant="contained" onClick={saveLocation}
                                           startIcon={<SaveTwoToneIcon/>}>
                                Save
                            </LoadingButton>
                        </Box>

                    </Box>
                </Fade>
            </Box>
        </Paper>
    } else {
        return <></>
    }
}

export default ClientLocationCreate