import Request from './Request'
import constants from '../constants/constant'

const axios = require('axios')

const ClientService = {
    cached_clients: null,
    async searchClients(keyword = "", use_cache = false) {
        let params = {}
        if (keyword) {
            params['keyword'] = keyword
        }
        let _pods;
        if (use_cache) {
            _pods = ClientService.cached_clients
            if (!_pods) {
                _pods = await Request.get('/api/clients/search', params)
                ClientService.cached_clients = _pods
            }
        } else {
            _pods = await Request.get('/api/clients/search', params)
        }
        return _pods
    },
    async getClientById(id) {
        return await Request.get(`/api/clients/${id}`)
    },

    /**
     * Return simple list of clients, just id, name, client code
     * @returns {Promise}
     */
    async listClientsSimple() {
        return await Request.get('/api/clients/simple')
    },

    /**
     * Update a given client with given data
     * 
     * @param {*} id 
     * @param {*} data 
     * @returns 
     */
    async updateById(id, data) {
        return await Request.post(`/api/clients/${id}`, data)
    },

    async getClientByCode(code, ignoreWith = 0) {
        return await Request.get(`/api/clients/code/${code}?ignore_with=${ignoreWith}`)
    },
    async deleteById(id) {
        return await Request._delete(`/api/clients/${id}`)
    },
    async undeleteById(id) {
        return await Request.post(`/api/clients/${id}/restore`)
    },
    getStatusNameByStatusId(status_id) {
        const status_mapping = {
            1: 'Dropped',
            2: 'Hiatus',
            3: 'Static Plan',
            4: 'Fully Operational',
            5: 'Credit Hold'
        }
        return status_mapping[status_id]
    },
    async createClientStatusHistory(data) {
        return await Request.post(`/api/client-status-histories`, data)
    },
    async getMasterCategories() {
        return await Request.get(`/api/master-categories`)
    },
    async getClientLegacyClasses(client_id) {
        return await Request.get(`/api/clients/${client_id}/legacy-classes`)
    },
    async batchAssignMasterClassToClientClass(client_id, data) {
        return await Request.post(`/api/clients/${client_id}/classes/batch-assign-master-class`, {data: data})
    },
    async uploadClassData(client_id, file) {
        let formData = new FormData()
        formData.append("data", file)
        return await Request.formPost(`/api/clients/${client_id}/upload-class`, formData)
    },
    async getClientChangeLogs(client_id) {
        return await Request.get(`/api/clients/${client_id}/change-logs`)
    },
    async getClientChangeLogsActions(client_id) {
        return await Request.get(`/api/clients/${client_id}/change-logs/actions`)
    },
    async getClientChangeLogById(client_id, id) {
        return await Request.get(`/api/clients/${client_id}/change-logs/${id}`)
    },
    async deleteClientChangeLogById(client_id, id) {
        return await Request._delete(`/api/clients/${client_id}/change-logs/${id}`)
    },
    async downloadClientChangeLog(client_id, id) {
        window.open(`${constants.BACKEND_DOMAIN}/backend-clients/${client_id}/change-logs/${id}/export?_access_token=${localStorage.getItem('access_token')}`, '_blank')
    },
    async processClientChangeLog(client_id, id) {
        return await Request.post(`/api/clients/${client_id}/change-logs/${id}/process`)
    },
    async getClientClassStatistics(client_id) {
        return await Request.get(`/api/clients/${client_id}/class/statistics`)
    },
    async getClientLocationClassStatistics(client_id) {
        return await Request.get(`/api/clients/${client_id}/location-class/statistics`)
    },
    async getClientUsers(client_id) {
        return await Request.get(`/api/clients/${client_id}/users`)
    },
    async updateClientUserLink(client_id, user_role_link_id, data) {
        return await Request.post(`/api/clients/${client_id}/user-links/${user_role_link_id}/config`, data)
    },
    async getClientClasses(client_id, params = {}) {
        return await Request.get(`/api/clients/${client_id}/classes`, params)
    },
    async getClientClassById(client_id, class_id, params = {}) {
        return await Request.get(`/api/clients/${client_id}/classes/${class_id}`, params)
    },
    async getClientLocations(client_id, params = {}) {
        return await Request.get(`/api/clients/${client_id}/locations`, params)
    },
    async getClientCategories(client_id, include = []) {
        return await Request.get(`/api/clients/${client_id}/categories`, {include: include})
    },
    async bulkUpdateClasses(client_id, data) {
        return await Request.post(`/api/clients/${client_id}/classes/bulk`, data)
    },
    async bulkDeleteClasses(client_id, data) {
        return await Request.post(`/api/clients/${client_id}/classes/bulk/delete`, data)
    },
    async deleteClassById(id, class_id) {
        return await Request._delete(`/api/clients/${id}/classes/${class_id}`)
    },
    async undeleteClassById(id, class_id) {
        return await Request.post(`/api/clients/${id}/classes/${class_id}/restore`)
    },
    async updateClassById(id, class_id, data) {
        return await Request.post(`/api/clients/${id}/classes/${class_id}`, data)
    },
    async getClientRelatedTags(id) {
        return await Request.get(`/api/clients/${id}/tags`)
    },
    // Tmp method
    syncClientPlanData(id) {
        return axios.post(`https://mgmt-report-job-dev-q4tbeglceq-uc.a.run.app/api/clients/${id}/plan-data/sync?key=scHzcX1L4ZFjj5exMg57dB1aq1HtF0`);
        // return axios.post(`http://localhost/api/clients/${id}/plan-data/sync?key=scHzcX1L4ZFjj5exMg57dB1aq1HtF0`);
    },

    async getPosClassMapData(clientId) {
        return await Request.get(`/api/clients/${clientId}/pos-class`)
    },

    async getClientPosList(clientId) {
        return await Request.get(`/api/clients/${clientId}/client-pos-list`)
    },

    /**
     * Get mapping of department/class combo to locations for client
     * @param {*} clientId 
     * @returns {Promise}
     */
    async getDepartmentLocationMapping(clientId) {
        return await Request.get(`/api/clients/${clientId}/department-class-location-mapping`)
    },

    async togglePosClassesMapping(clientId, classId, depId) {
        return await Request.get(`/api/clients/${clientId}/toggle-pos-list-map/${classId}/${depId}`)
    },

    async uploadClientPos(clientId, file) {
        let formData = new FormData()
        formData.append("data", file)
        return await Request.formPost(`/api/clients/${clientId}/upload-pos`, formData)
    },

    getEpochMonthFromDate(date) {
        const epochDate = new Date('01 January 1970 00:00');
        return (date.getMonth() - epochDate.getMonth() + 12 * (date.getFullYear() - epochDate.getFullYear()))
    },
    async updateClientLocationClass(clientId, locationId, clientLocationClassId, formData) {
        return await Request.post(`/api/clients/${clientId}/locations/${locationId}/${clientLocationClassId}`, formData);
    },
    async updateCategory(categoryId, formData) {
        return await Request.post(`/api/categories/${categoryId}/`, formData);
    },
    async deleteCategory(categoryId) {
        return await Request._delete(`/api/categories/${categoryId}`)
    },

    async addCategoryToClass(clientId, classId, categoryId) {
        return await Request.get(`/api/clients/${clientId}/class/${classId}/category/${categoryId}/add`);
    },

    async removeCategoryFromClass(clientId, classId, categoryId) {
        return await Request.get(`/api/clients/${clientId}/class/${classId}/category/${categoryId}/remove`);
    },

    async createCategory(clientId, form_data) {
        return await Request.post(`/api/clients/${clientId}/categories/`, form_data);
    },

    async unlinkUserCompany(clientId, authUserId) {
        return await Request._delete(`/api/clients/${clientId}/users/${authUserId}`)
    },

    async updateLocationPos(clientId, locId, pos) {
        return await Request.get(`/api/clients/${clientId}/locations/${locId}/update-pos`, {pos: pos})
    },

    async toggleOverride(clientId, locId, classId) {
        return await Request.get(`/api/clients/${clientId}/locations/${locId}/classes/${classId}/toggle-override`)
    },

    async createClass(clientId, formData) {
        return await Request.post(`/api/clients/${clientId}/classes`, formData)
    },

    async createNewClient(formData) {
        return await Request.post(`/api/clients/save`, formData);
    },

    async saveClientUsers(clientId, formData) {
        return await Request.post(`/api/clients/${clientId}/users`, formData);
    },

    // Client Setup
    async save(formData) {

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(formData.client_code)
            }, 2000)
        })
    },

    async uploadClassFile(clientId, file) {
        const formData = new FormData();
        formData.append('data', file);
        return await Request.formPost(`/api/clients/${clientId}/upload-class`, formData);
    },

    async getClientUsersById(clientId)
    {
        return await Request.get(`/api/clients/${clientId}/get-clients-by-id`)
    },

    async syncClientByCode(clientId) {
        return await Request.get(`/api/clients/${clientId}/sync`);
    },

    /**
     * Generate new auth key. Does not save it, just generates.
     *
     * @return string
     */
    async generateAuthKey() {
        let result = await Request.get('/api/clients/generate-new-auth-key');
        return result
    },

    /**
     * Get next client code for user
     *
     * @return string
     */
    async getNextClientCode() {
        let result = await Request.get('/api/clients/next-client-code');
        return result
    },

    /**
     * Submit client for approval
     *
     * @param {string} clientCode
     *
     * @return {Promise}
     */
    async submitForApproval(clientCode) {
        return await Request.get(`/api/clients/${clientCode}/submit-for-approval`);
    },

    /**
     * Store lightspeed auth refresh token for client
     * 
     * @param {string} clientCode
     * @param {string} temporaryCode
     * 
     * @return {Promise}
     */
    async lightspeedAuthWithTempCode(clientCode, temporaryCode) {
        return await Request.post(`/api/clients/${clientCode}/lightspeed/store-auth-token`, {code: temporaryCode});
    },

    /**
     * Get last month data file finalized for client
     * 
     * @param {int} clientId
     * 
     * @return {Promise}
     */
    async getLastMonthDataFileFinalized(clientId) {
        return await Request.get(`/api/clients/${clientId}/data-file/last-month-finalized`);
    },

    /**
     * Generate new class plans for client
     * 
     * @param {int} clientId
     */
    async generateNewClassPlans(clientId) {
        return Request.post(`/api/clients/${clientId}/generate-new-class-plans`);
    },
    
    /**
     * Generate new month plans for client
     * 
     * @param {*} clientId 
     */
    async generateNewMonthPlans(clientId) {
        return Request.post(`/api/clients/${clientId}/generate-new-month-plans`);
    },

    async regenerateCache(clientId) {
        return await Request.get(`/api/clients/${clientId}/cache/0/regenerate`);
    },

    async clearCache(clientId) {
        return await Request.get(`/api/clients/${clientId}/cache/0/0`);
    },

    /**
     * Send plans published notification for client
     * 
     * @param {int} clientId
     * 
     * @return {Promise}
     */
    async notifyPlansPublished(clientId) {
        return Request.post(`/api/clients/${clientId}/notify-plans-published`);
    },
}

export default ClientService
