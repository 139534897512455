import React from 'react'
import {
  Button,
  Card,
  Typography,
  CardContent,
} from "@mui/material"

const cssInJs = {
	header: {
		backgroundColor: "#fafafa",
    color: "black",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: '1px rgba(92,63,117,0.1) solid'
    
  },
  headerLink: {
  },
}

const SubmitProfileStep = () => {
  return (
    <Card>
      <div style={cssInJs.header}>
        <span>Complete Setup</span>
      </div>
      <CardContent>
        <h4>Please review your billing, locations, and classes to make sure everything looks correct.</h4>
        <h4>You will still need to include the following:</h4>
        <ul>
          <li>24 months Data History</li>
          <li>Financial Statement</li>
          <li>Breakeven</li>
          <li>PPW</li>
          <li>Do you need the plans this month?</li>
        </ul>
        <h4>If everything is ready you can now submit your setup for approval.</h4>
      </CardContent>
    </Card>
  )
}

export default SubmitProfileStep