import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Paper,
	Box,
	Typography,
	Container,
	TextField,
	Skeleton,
	Button,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableHead,
	TableBody,
	MenuItem,
	Tooltip,
	Divider,
	ClickAwayListener,
	IconButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import _ from 'underscore'
import ClientContext from '../../providers/CurrentClientProvider'
import ClientService from '../../services/ClientService'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

const jss = {
	classTitle: {
		textDecoration:'underline dotted',
		cursor:'pointer',
		display: 'inline-block',
		':hover': {
			textDecoration:'underline',
		}
	}
}


function ClientClassRow(props) {
	useEffect(() => {
		document.title = 'Retail ORBIT: Master Class'
	}, [])

	const [form_data, setFormData] = useState({
		master_category_id: (!_.isEmpty(props.data.masterClass) && props.data.masterClass.master_category_id) ? props.data.masterClass.master_category_id : '',
		master_class_id: (!_.isEmpty(props.data.masterClass) && props.data.masterClass.master_class_id) ? props.data.masterClass.master_class_id : '',
		custom_category: (!_.isEmpty(props.data.masterClass) && props.data.masterClass.custom_category) ? props.data.masterClass.custom_category : '',
		custom_class: (!_.isEmpty(props.data.masterClass) && props.data.masterClass.custom_class) ? props.data.masterClass.custom_class : '',
	})
	const [openChildClass, setOpenChildClass] = useState(false)

	function updateFormData(e) {
		const update_data = {
			...form_data,
			[e.target.name]: e.target.value
		}
		setFormData(update_data)
		props.onChange(props.data.Id, update_data)
	}

	return <TableRow>
		<TableCell >
			<ClickAwayListener onClickAway={e => setOpenChildClass(false)}>
				<Tooltip 
					title={<>
						<Typography variant="body2">Child Class</Typography>
						<Divider />
						{props.data.childClasses.map(_class => {
							return <Box sx={{my:0.5}} key={`child_class_${_class.Id}`} variant="">{_class.Name}</Box>
						})}
					</>}
					onClose={e => setOpenChildClass(false)}
	                open={openChildClass}
	                arrow
	                disableFocusListener
	                disableHoverListener
	                disableTouchListener
				>
					<Box sx={jss.classTitle}  onClick={e => setOpenChildClass(true)}>{props.data.Name}</Box>
				</Tooltip>
			</ClickAwayListener>
		</TableCell>
		<TableCell>
			<TextField 
				select
				size="small"
				value={form_data.master_category_id}
				name="master_category_id"
				onChange={updateFormData}
				label="Master category"
				fullWidth
			>
			{props.master_categories.map(_category => {
				return <MenuItem value={_category.id} key={`category_selection_${props.data.Id}_${_category.id}`}>{_category.name} {_category.master_classes.filter(item => {return item.client_count>10}).length}/{_category.master_classes.length}</MenuItem>
			})}
			</TextField>
		</TableCell>
		<TableCell>
			<TextField 
				select
				size="small"
				value={form_data.master_class_id}
				name="master_class_id"
				onChange={updateFormData}
				disabled={!props.master_categories.find(_category => {return _category.id==form_data.master_category_id})}
				label="Master class"
				fullWidth
			>
			{!props.master_categories.find(_category => {return _category.id==form_data.master_category_id}) && 
				<MenuItem value="" >Select master class</MenuItem>
			}
			{props.master_categories.find(_category => {return _category.id==form_data.master_category_id})?.master_classes.map(_class => {
				return <MenuItem value={_class.id} key={`class_selection_${props.data.Id}_${_class.id}`}>{_class.name} {_class.client_count}</MenuItem>
			})}
			</TextField>
		</TableCell>
		<TableCell>
			<TextField 
				size="small"
				value={form_data.custom_category}
				name="custom_category"
				onChange={updateFormData}
			/>
		</TableCell>
		<TableCell>
			<TextField 
				size="small"
				value={form_data.custom_class}
				name="custom_class"
				onChange={updateFormData}
			/>
		</TableCell>
	</TableRow>
}

function MasterClass(props) {
	const [classes, setClasses] = useState([])
	const [master_categories, setMasterCategories] = useState([])
	const client_context = ClientContext.useClient();
	const [loading, setLoading] = useState(true)
	const [batch_update_data, setBatchUpdateData] = useState([])
	const [saving, setSaving] = useState(false)

	useEffect(() => {
		if( !_.isEmpty(client_context.client) ) {
			getClientClasses()
			getMasterCategories()
		}
	}, [client_context.client])

	async function getClientClasses() {
		try {
			setMasterCategories( await ClientService.getMasterCategories() )
		} catch(e) {
		}
	}

	async function getMasterCategories() {
		try {
			setLoading(true)
			setClasses( await ClientService.getClientLegacyClasses(client_context.client.Id) )
		} catch(e) {
		}
		setLoading(false)
	}

	function updateClasses(class_id, new_data) {
		let matched = false
		batch_update_data.forEach(item => {
			if( item.class_id == class_id ) {
				item.data = new_data
				matched = true
			}
		})
		if( !matched ) {
			batch_update_data.push({
				class_id: class_id,
				data: new_data
			})
		}
		setBatchUpdateData(batch_update_data)
	}

	async function saveAll() {
		try {
			setSaving(true)
			await ClientService.batchAssignMasterClassToClientClass(client_context.client.Id, batch_update_data)
		} catch(e) {
		}
		setSaving(false)
	}

	return <Box sx={{py:2}}>
		<Typography variant="h5">Master Class</Typography>
		<Paper sx={{my:2}}>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Client Class</TableCell>
							<TableCell>Master Category</TableCell>
							<TableCell>Master Class</TableCell>
							<TableCell>Custom Category</TableCell>
							<TableCell>Custom Class</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{classes.map(_class => {
							return <ClientClassRow 
								key={`class_item_${_class.Id}`} 
								data={_class} 
								master_categories={master_categories}
								client={client_context.client}
								onChange={(class_id, new_data) => updateClasses(class_id, new_data)}
							/>
						})}
						{!loading && 
							<TableRow>
								<TableCell>
									<LoadingButton 
										loading={saving}
										variant="contained"
										startIcon={<SaveRoundedIcon />}
										onClick={saveAll}
									>
										Save
									</LoadingButton>
								</TableCell>
							</TableRow>
						}
						{loading && 
							<>
								<TableRow>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
								</TableRow>
								<TableRow>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
								</TableRow>
								<TableRow>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
								</TableRow>
								<TableRow>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
									<TableCell><Skeleton /></TableCell>
								</TableRow>
							</>
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	</Box>
}
export default MasterClass