import Request from './Request'

export default class CustomCalendarService {
    /**
     * Retrieve list of custom calendars
     * @returns {Promise}
     */
    static async getCalendars() {
        return Request.get('/api/custom-calendars');
    }

    /**
     * Retrieve a specific custom calendar
     * @param {string} id
     * @returns {Promise}
     */
    static async getCalendar(id) {
        return Request.get(`/api/custom-calendar/${id}`);
    }

    /**
     * Create a custom calendar
     * @param {Object} calendar
     * @returns {Promise}
     */
    static async createCalendar(calendar) {
        return Request.post('/api/custom-calendar', calendar);
    }

    /**
     * Update a custom calendar
     * @param {string} id
     * @param {Object} calendar
     * @returns {Promise}
     */
    static async updateCalendar(id, calendar) {
        return Request.post(`/api/custom-calendar/${id}`, calendar);
    }

    /**
     * Delete a custom calendar
     * @param {string} id
     * @returns {Promise}
     */
    static async deleteCalendar(id) {
        return Request._delete(`/api/custom-calendar/${id}`);
    }

    /**
     * Remove calendar from client
     * @param {string} clientId
     */
    static async removeClient(clientId) {
        return Request._delete(`/api/clients/${clientId}/custom-calendar`);
    }

    /**
     * Add client to calendar
     * @param {string} calendarId
     * @param {string} clientId
     */
    static async addClient(calendarId, clientId) {
        return Request.post(`/api/clients/${clientId}/custom-calendar/${calendarId}`);
    }
}