import Request from './Request'

const RoleService = {
	async createRole(name, type) {
		return await Request.post('/api/roles', {name, type})
	},
	async updateAdminRole(id, name) {
		return await Request.post(`/api/admin-roles/${id}`, { name })
	},
	async getAdminRoles() {
		return await Request.get('/api/admin-roles')
	},
	async getRoles(filter=['USER']) {
		return await Request.get('/api/roles', {filter})
	},
}

export default RoleService