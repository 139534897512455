import {useState, useEffect, useMemo} from 'react';
import {
  Modal,
  Box,
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'underscore';
import ClientService from '../../services/ClientService';
import { LoadingButton } from '@mui/lab';
import PlanService from '../../services/PlanService';
import MessageContext from '../../providers/GlobalTip';
import useConfirm from '../../providers/ConfirmDialog';
import PlanCalculatorService from '../../services/PlanCalculatorService';

function PlanCopyToModal(props) {
  const {
    open,
    onClose,
    buildtype='base_plan',
    formdata={},
    annualform={},
    tabledata={},
    plandata={},
  } = props;
  const [clients, setClients] = useState(null);
  const [selectClient, setSelectClient] = useState(null);
  const [selectClientDetail, setSelectClientDetail] = useState(null);
  const [selectLocations, setSelectLocations] = useState([]);
  const [selectClientClasses, setSelectClientClasses] = useState([]);
  const [saving, setSaving] = useState(false);
  const messageContext = MessageContext.useMessage();
  const confirm = useConfirm();

  useEffect(() => {
    if (open) {
      loadClients();
    }
    return () => {
      setSaving(false);
      setClients(null);
      setSelectClient(null);
      setSelectClientDetail(null);
      setSelectLocations([]);
    }
  }, [open])

  useEffect(() => {
    if (selectClient) {
      loadClientDetail(selectClient.ClientCode);
    }
  }, [selectClient])

  const loadClients = async() => {
    const _clients = await ClientService.searchClients()
    setClients(_clients)
  }

  const loadClientDetail = async(client_code) => {
    const detail = await ClientService.getClientByCode(client_code)
    setSelectClientDetail(detail)
  }

  const filteredClasses = useMemo(() => {
    if (selectLocations.length>0 && selectClientDetail) {
      const locationsIds = _.pluck(selectLocations, 'client_location_id')
      const filteredLocationClasses = selectClientDetail.location_classes.filter(i => _.indexOf(locationsIds, `${i.client_location_id}`)>-1)
      const filteredClassIds = _.pluck(filteredLocationClasses, 'clientclass_id');
      return selectClientDetail.classes.filter(i => _.indexOf(filteredClassIds, parseInt(i.id))>-1);  
    } else {
      return [];
    }
  }, [selectClientDetail, selectLocations]);

  const submitCopyTo = async() => {
    const choice = await confirm({ 
      description: "Are you sure you want to copy?"
    });
    if (choice) { 
      setSaving(true);
      try {
        const allLocationIds = _.pluck(selectLocations, 'id');
        const allClassIds = _.pluck(selectClientClasses, 'id');
        let finalClasses = selectClientDetail.location_classes.filter(i => _.indexOf(allLocationIds, `${i.client_location_id}`)>-1)
        if (allClassIds.length>0) {
          finalClasses = finalClasses.filter(i => _.indexOf(allClassIds, `${i.clientclass_id}`)>-1)
        }
        if (buildtype=='base_plan') {
          const requestData = PlanCalculatorService.getSubmitData(plandata, annualform, tabledata)
          await PlanService.copyToBasePlanFromLocationClass({
            location_class_ids: _.pluck(finalClasses, 'locationclassid'),
            data: requestData,
          });
        } else if (buildtype=='future_plan') {
          await PlanService.bulkSaveClientFuturePlan(
            selectClientDetail.Id,
            'customize',
            {
              ...formdata,
              location_class_ids: _.pluck(finalClasses, 'locationclassid'),
            },
          );
        } else if (buildtype=='live_plan') {
          const requestData = PlanCalculatorService.getSubmitData(plandata, annualform, tabledata, null, true)
          await PlanService.copyToLivePlan({
            location_class_ids: _.pluck(finalClasses, 'locationclassid'),
            data: requestData,
          });
        }
        messageContext.showSuccess('Build successfully')
      } catch(e) {
        console.log('ERR', e)
        messageContext.show(e.response?.data?.message)
      }
      setSaving(false);
      onClose();
    }
  }

  return (
    <Modal
      {...props}
    >
      <Box sx={css.container}>
        <Typography variant="h6">Copy To</Typography>
        {!clients && (
          <Box sx={css.loadingSection}>
            <CircularProgress />
          </Box>
        )}
        {_.isArray(clients) && (
          <Autocomplete
            sx={{mt: 2}}
            getOptionLabel={(item) => `[${item.ClientCode}] ${item.Name}`}
            options={clients}
            value={selectClient}
            onChange={(e, newValue) => setSelectClient(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Clients" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={`client_copy_${option.Id}`}>{`[${option.ClientCode}] ${option.Name}`}</li>
            )}
          />
        )}
        {_.isArray(clients) && selectClient && !selectClientDetail && (
          <Box sx={css.loadingSection}>
            <CircularProgress />
          </Box>
        )}

        {selectClientDetail && (
          <Autocomplete
            multiple
            disableCloseOnSelect
            getOptionLabel={(item) => `[${item.location_code}] ${item.location_name}`}
            options={selectClientDetail.locations}
            sx={{mt: 2}}
            onChange={(e, newValue) => setSelectLocations(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Locations" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={`client_location_${option.client_location_id}`}>[{option.location_code}] {option.location_name}</li>
            )}
          />
        )}

        {filteredClasses?.length>0 && (
          <Autocomplete
            multiple
            disableCloseOnSelect
            getOptionLabel={(item) => `[${item.code}] ${item.name}`}
            options={filteredClasses}
            sx={{mt: 2}}
            onChange={(e, newValue) => setSelectClientClasses(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Classes" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={`client_class_${option.id}`}>[{option.code}] {option.name}</li>
            )}
          />
        )}
        {selectClientDetail && (
          <LoadingButton
            loading={saving}
            sx={{mt: 2}}
            size="large"
            variant="contained"
            onClick={submitCopyTo}
          >Copy</LoadingButton>
        )}
      </Box>
    </Modal>
  );
}

export default PlanCopyToModal;

const css = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    px: 3,
    py: 2,
  },
  loadingSection: {
    textAlign: 'center',
    mt: 2,
  },
}