import Request from './Request'

const LabelService = {
	cached_labels: null,
	async getLabels() {
		if( !LabelService.cached_labels ) {
			const _data = await Request.get(`/api/labels`)
			LabelService.cached_labels = _data
		}
		return LabelService.cached_labels
	},
}

export default LabelService