import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
} from "react-router-dom";
import {
	Box,
	Paper,
	Skeleton,
	IconButton,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Button,
} from '@mui/material';
import {
	LoadingButton
} from '@mui/lab'
import UserService from '../../services/UserService'
import SectionTitle from '../../components/common/SectionTitle'
import _ from 'underscore'
import UserRoleEditItem from './UserRoleEditItem'
import constants from '../../constants/constant' 

const jss = {
	tableSection: {
		p: 2,
		pt: 0,
	},
	tableLabel: {
		fontWeight: 'bolder',
		width: '20%',
		textAlign: 'right'
	}
}

function UserRolesEdit(props) {
	const user = props.user
	const [loading, setLoading] = useState(false)
	const [roles, setRoles] = useState({})

	useEffect(() => {
		if( !_.isEmpty(user) ) {
			getUserRoles()
		}
	}, [user])

	async function getUserRoles() {
		setLoading(true)
		const _roles = await UserService.getUserRoles(user.id)
		setRoles(_.groupBy(_roles, item => {
			return item.auth_role_id
		}))
		setLoading(false)
	}

	return <>
		{!loading && 
			<Paper elevation={2}>
				<SectionTitle label="Role List"/>
				<UserRoleEditItem user={user} role={roles?.[constants.ROLE_ID.client]} type="client" onChange={getUserRoles}/>
				<UserRoleEditItem user={user} role={roles?.[constants.ROLE_ID.affiliate]} type="affiliate" onChange={getUserRoles}/>
				<UserRoleEditItem user={user} role={roles?.[constants.ROLE_ID.planner]} type="planner" onChange={getUserRoles}/>
				<UserRoleEditItem user={user} role={roles?.[constants.ROLE_ID.referrer]} type="referrer" onChange={getUserRoles}/>
				<UserRoleEditItem user={user} role={roles?.[constants.ROLE_ID.reseller]} type="reseller" onChange={getUserRoles}/>
				<UserRoleEditItem user={user} role={roles?.[constants.ROLE_ID.marketer]} type="marketer" onChange={getUserRoles}/>
			</Paper>
		}
		{loading && 
			<Paper elevation={2}>
				<SectionTitle label="Role List"/>
				<Box sx={jss.tableSection}>
					<Skeleton sx={{mt: 1.5}} />
					<Skeleton sx={{mt: 1.5}} />
					<Skeleton sx={{mt: 1.5}} />
					<Skeleton sx={{mt: 1.5}} />
					<Skeleton sx={{mt: 1.5}} />
				</Box>
			</Paper>
		}
	</>
}

export default UserRolesEdit