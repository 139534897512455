import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import SectionTitle from "../../components/common/SectionTitle";

/**
 * Mapping of Point of Sale IDs to their corresponding names.
 * @type {Object<number, string>}
 */
const pointOfSaleMapping = {
    1113: 'Lightspeed',
    1139: 'RunIT',
    1140: 'Heartland'
}

/**
 * A sortable table header cell component.
 * @param {Object} props - The component props.
 * @param {string} props.label - The label of the table header cell.
 * @param {string} props.sortKey - The key to sort the table by.
 * @param {Object} props.sortConfig - The current sort configuration.
 * @param {Function} props.onSort - The function to handle sorting.
 * @param {string} props.searchValue - The current search value.
 * @param {Function} props.onSearch - The function to handle searching.
 * @returns {JSX.Element} The sortable table header cell component.
 */
const SortableTableHeaderCell = ({ label, sortKey, sortConfig, onSort, searchValue, onSearch }) => {
    /**
     * Handles the sorting of the table.
     */
    const handleSort = () => {
        let direction = 'asc';
        if (sortConfig.key === sortKey && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        onSort({ key: sortKey, direction });
    };

    return (
        <TableCell>
            <TableSortLabel
                active={sortConfig.key === sortKey}
                direction={sortConfig.direction}
                onClick={handleSort}
            >
                {label}
            </TableSortLabel>
            <TextField
                label="Search"
                variant="outlined"
                size="small"
                value={searchValue}
                onChange={onSearch}
            />
        </TableCell>
    );
}

/**
 * The FlightClients component.
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.clients - The array of client objects.
 * @param {Object} props.selectedClient - The selected client object.
 * @param {Function} props.setSelectedClient - The function to set the selected client.
 * @returns {JSX.Element} The FlightClients component.
 */
const FlightClients = ({ clients, selectedClient, setSelectedClient, loading }) => {
    const [search, setSearch] = useState({ Id: "", ClientCode: "", Name: "", PointOfSaleId: "" });
    const [sortConfig, setSortConfig] = useState({ key: 'Id', direction: 'asc' });

    const sortedClients = [...clients].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const filteredClients = sortedClients.filter(client =>
        Object.keys(search).every(key => {
          if (key === "PointOfSaleId" && search[key]) {
            return pointOfSaleMapping[client[key]].toString().toLowerCase().includes(search[key].toLowerCase());
          }
          return client[key].toString().toLowerCase().includes(search[key].toLowerCase());
        })
    );

    const selectClient = (client) => {
        console.log(loading)
        if (loading) {
            return;
        }
        setSelectedClient(client);
    }

    return (
        <div>
            <Paper elevation={2} sx={{mb:2}}>
                <Box sx={{mb:2}} spacing={2}>
                    <SectionTitle label="Clients" type="primary"/>
                    <Button variant="contained" color="primary" sx={{ m: 1 }}onClick={() => setSelectedClient(null)}>Unselect Client</Button>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <SortableTableHeaderCell
                                        label="Id"
                                        sortKey="Id"
                                        sortConfig={sortConfig}
                                        onSort={setSortConfig}
                                        searchValue={search.Id}
                                        onSearch={e => setSearch({ ...search, Id: e.target.value })}
                                    />
                                    <SortableTableHeaderCell
                                        label="Code"
                                        sortKey="ClientCode"
                                        sortConfig={sortConfig}
                                        onSort={setSortConfig}
                                        searchValue={search.ClientCode}
                                        onSearch={e => setSearch({ ...search, ClientCode: e.target.value })}
                                    />
                                    <SortableTableHeaderCell
                                        label="POS"
                                        sortKey="PointOfSaleId"
                                        sortConfig={sortConfig}
                                        onSort={setSortConfig}
                                        searchValue={search.PointOfSaleId}
                                        onSearch={e => setSearch({ ...search, PointOfSaleId: e.target.value })}
                                    />
                                    <SortableTableHeaderCell
                                        label="Name"
                                        sortKey="Name"
                                        sortConfig={sortConfig}
                                        onSort={setSortConfig}
                                        searchValue={search.Name}
                                        onSearch={e => setSearch({ ...search, Name: e.target.value })}
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredClients.map((client) => (
                                    <TableRow 
                                        key={client.Id} 
                                        onClick={() => selectClient(client)}
                                        sx={{ cursor: 'pointer', backgroundColor: selectedClient && selectedClient.Id === client.Id ? '#f0f0f0' : 'inherit' }}
                                    >
                                        <TableCell>{client.Id}</TableCell>
                                        <TableCell>{client.ClientCode}</TableCell>
                                        <TableCell>{pointOfSaleMapping[client.PointOfSaleId]}</TableCell>
                                        <TableCell>{client.Name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>
        </div>
    );
}

export default FlightClients;