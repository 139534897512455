import BaseModel from "./BaseModel"

export const BasePlanAnnualForm = {
    annual_sales: {
        value: '0',
        label: 'Annual Sales', 
        orig: '0',
        current: '0',
        smt: '0',
        isBlur: false,
        chipProps: {
            linear_sales: {
                orig: '0', current: '0', value: '1', smt: '2', label: 'Linear'
            },
            calc_trend_sales_3: {
                orig: '0', current: '0', value: '2', smt: '4', label: 'CALC 3.0'
            },
            annual_sales_calc_trend: {
                orig: '0', current: '0', value: '3', smt: '3', label: 'Calc'
            }
        }
    },
    annual_markdowns: {
        value: '0',
        label: 'Annual Markdowns',
        orig: '0',
        current: '0',
        isBlur: false,
        chipProps: {
            actual_markdowns: {value: '0', label: 'Actual Markdowns'}
        }
    },
    turn: {
        value: '0',
        label: 'Annual Turn',
        orig: '0',
        current: '0',
        isBlur: false,
        chipProps: {
            actual_turn: {value: '0', label: 'Actual Turn'}
        }
    },
    imu: {
        value: '0',
        label: 'Annual IMU',
        orig: '0',
        current: '0',
        imt: '0',
        isBlur: false,
        chipProps: {
            receiving_imu_all_locations: {orig: '0', current: '0', value: '0', imt: '2', label: 'Actual IMU'}
        }
    }
}

export class BasePlanTableRow extends BaseModel {
    constructor(props) {
        super(props)
    }

    summary;
    avg;
    static _innerData;

    set innerData(innerData = []) {
        const _innerData = innerData.map(({value, current, orig}) => ({value: Number(value), current: Number(current), orig: Number(orig)}))
        this._innerData = _innerData;
        this.summary = _innerData?.slice(0, 12).reduce((temp, item) => temp + item.value * 10000, 0) / 10000;
        this.avg = this.summary / 12
    }

    get innerData() {
        return this._innerData ?? [];
    }
}

const salesData = [
    {
        label: 'Actual',
        lastItem: 'sum',
        key: 'Sales',
        sourceKey: 'actualdetails',
        displayFloat: false,
    },
    {
        isActive: true,
        label: 'Plan',
        lastItem: 'sum',
        key: 'SalesPlan',
        sourceKey: 'plandetails',
        displayFloat: false,
    },
    {
        isActive: true,
        isInput: true,
        label: 'Plan %',
        lastItem: 'sum',
        key: 'SalesPercentPlan',
        sourceKey: 'plandetails',
        suffix: '%',
        displayFloat: true,
    },
    {
        label: 'Actual %',
        lastItem: 'sum',
        key: 'SalesPercent',
        sourceKey: 'actualdetails',
        suffix: '%',
        displayFloat: true,
    },
    {
        label: 'Plan % Historical',
        lastItem: 'sum',
        key: 'sales_plan_vs_historical',
        sourceKey: 'plandetails',
        suffix: '%',
        displayFloat: true,
    },
];

const inventoryData = [
    {
        label: 'Actual Inv BOM',
        lastItem: 'avg',
        key: 'InventoryTotal_PrevMon',
        sourceKey: 'actualdetails',
        displayFloat: false,
    },
    {
        label: 'Actual S/S	',
        lastItem: 'avg',
        key: 'StockToSales',
        sourceKey: 'actualdetails',
        displayFloat: true,
    },
    {
        label: 'Plan S/S',
        lastItem: 'input',
        isActive: true,
        isInput: true,
        key: 'StockToSalesPlan',
        sourceKey: 'plandetails',
        displayFloat: true,
    },
    {
        label: 'Plan Inv BOM',
        lastItem: 'input',
        isActive: true,
        isInput: true,
        key: 'InventoryBomPlan',
        sourceKey: 'plandetails',
        displayFloat: false,
    },
    {
        label: 'Plan Inf Ret',
        lastItem: 'sum',
        isActive: true,
        key: 'InflowPlanRetail',
        sourceKey: 'plandetails',
        displayFloat: false,
    },
    {
        label: 'Plan Inf Cost',
        lastItem: 'sum',
        isActive: true,
        key: 'InflowPlanCost',
        sourceKey: 'plandetails',
        displayFloat: false,
    },
    {
        label: 'Actual Inf Ret',
        lastItem: 'sum',
        key: 'InflowRetail',
        sourceKey: 'actualdetails',
        displayFloat: false,
    },
    {
        label: 'Actual Inf Cost',
        lastItem: 'sum',
        key: 'InflowCost',
        sourceKey: 'actualdetails',
        displayFloat: false,
    },
    {
        label: 'Plan % Historical',
        lastItem: 'sum',
        key: 'radio_plan_vs_historical',
        sourceKey: 'plandetails',
        suffix: '%',
        displayFloat: true,
    },
]

const markdownsData = [
    {
        label: 'Actual',
        lastItem: 'sum',
        key: 'Markdowns',
        sourceKey: 'actualdetails',
        displayFloat: false,
    },
    {
        isActive: true,
        label: 'Plan',
        lastItem: 'sum',
        key: 'MarkdownsPlan',
        sourceKey: 'plandetails',
        displayFloat: false,
    },
    {
        isActive: true,
        isInput: true,
        label: 'Plan %',
        lastItem: 'sum',
        key: 'MarkdownsPercentPlan',
        sourceKey: 'plandetails',
        suffix: '%',
        displayFloat: true,
    },
    {
        label: 'Actual %',
        lastItem: 'sum',
        key: 'MarkdownsPercent',
        sourceKey: 'actualdetails',
        suffix: '%',
        displayFloat: true,
    },
    {
        label: 'Plan % Historical',
        lastItem: 'sum',
        key: 'markdowns_plan_vs_historical',
        sourceKey: 'plandetails',
        suffix: '%',
        displayFloat: true,
    },
];

export const BasePlanTableData = [
    {key: 'Sales', data: salesData},
    {key: 'Inventory', data: inventoryData},
    {key: 'Markdowns', data: markdownsData}
]

export const colorMap = {
    'Sales': '#5db85c',
    'Inventory': '#f0ad4e',
    'Markdowns': '#d85350',
}

export const BasePlanTableDataMap = new Map(BasePlanTableData.map(item => [item.key, new Map(item.data.map(val => [val.key, new BasePlanTableRow(val)]))]))
