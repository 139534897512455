import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Chip,
	Paper,
	Switch,
} from '@mui/material';
import jss from '../../components/client/profile/ProfileCommonJss'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ClientService from '../../services/ClientService'
import TipsContext from '../../providers/GlobalTip'
import CurrentClientContext from '../../providers/CurrentClientProvider'
import ClientProfileLocationList from '../../components/client/profile/ClientProfileLocationList'
import ClientLocationEdit from '../../components/client/profile/ClientLocationEdit'
import ClientLocationClassLinkEdit from '../../components/client/profile/ClientLocationClassLinkEdit'
import ClassBulkEdit from '../../components/client/profile/ClassBulkEdit'
import _ from 'underscore'
import ClientLocationClassList from "../../components/client/location/ClientLocationClassList";
import ClientClassLocationList from "../../components/client/location/ClientClassLocationList";
import ClientLocationCreate from "../../components/client/location/ClientLocationCreate";
import LabelService from "../../services/LabelService";
import AuthProvider from "../../providers/AuthProvider";


function ClientLocations(props) {
	const [locations, setLocations] = useState()
	const [classes, setClasses] = useState()
	const [globalClasses, setGlobalClasses] = useState()
	const tip_context = TipsContext.useMessage()
	const currentClientContext = CurrentClientContext.useClient()
	const [currentLocations, setCurrentLocations] = useState([])
	const [currentClasses, setCurrentClasses] = useState([])
	const [multiMode, setMultiMode] = useState(false)
	const [labelData, setLabelData] = useState()
	const [categories, setCategories] = useState()
	const [isAdmin, setIsAdmin] = useState(false)
	const auth = AuthProvider.useAuth()

	useEffect(() => {
		document.title = 'Retail ORBIT: Client Locations'
	}, [])

	// is admin
	useEffect(() => {
		// role-create marks admin, but we don't have role specific checks currently
		if (auth.checkPermission('role-create')) {
			setIsAdmin(true)
		} else {
			setIsAdmin(false)
		}
	}, [auth])

	useEffect(() => {
		if( currentClientContext.client ) {
			// getClientClasses()
			getClientLocations()
			getClasses()
			loadLabels()
			loadCategories()
		}
	}, [currentClientContext.client])

	async function getClientLocations() {
		const result = await ClientService.getClientLocations(currentClientContext.client.Id, {
			include: ['classLinks.labels', 'classLinks.class', 'classLinks.masterClasses.masterClass', 'classLinks.masterCategories.masterCategory', 'classLinks.children', 'classLinks.categories', 'classLinks.parentClass', 'pos', 'labels', 'tags']
		})
		setLocations(result)
		if (result[0]) {
			setClasses(result[0].class_links)
		}
		setCurrentClasses([])
		setCurrentLocations([result[0]])
	}

	async function loadLabels() {
		const data = await LabelService.getLabels()
		setLabelData(data)
	}

	async function loadCategories() {
		const data = await ClientService.getClientCategories(currentClientContext.client.Id)
		setCategories(data)
	}

	async function getClasses() {
		const result = await ClientService.getClientClasses(currentClientContext.client.Id)
		setGlobalClasses(result)
	}

	function locationChoose(items) {
		setCurrentLocations(items)
		if (items[0]) {
			setClasses(items[0].class_links)
		}
		setCurrentClasses([])
	}

	function classChoose(items) {
		setCurrentClasses(items)
	}

	function handleSingleLocationUpdate(_l) {
		console.log(_l)
		let _locations = locations.map(i => {
			if( i.client_location_id == _l.client_location_id ) {
				return _l
			} else {
				return i
			}
		})

		if (_l.drop_date) {
			setCurrentLocations([])
		}
		setLocations(_locations)
	}

	function handleSaveLocationClass(_c) {
		let _classes = classes.map(i => {
			if (i.id == _c.id) {
				return _c;
			} else {
				return i;
			}
		})
		setClasses(_classes)
	}

	function onBulkDeleted(deletedClasses) {
		setCurrentClasses(currentClasses.filter(item => {
			return deletedClasses.map(i => {return i.id}).indexOf(item.id)>-1
		}))
	}

	function createLocation(item, removeTmp = false) {
		let _l = locations.map(_loc => _loc)
		if (removeTmp) {
			_l = _l.filter(_loc => {
				if (_loc.client_location_id === -1 && removeTmp === _loc.location_name) {
					return false;
				}
				return true;
			})
		} else {
		  _l = _l.filter((_loc) => _loc.client_location_id !== -1);
		}

		item.clientid = currentClientContext.client.Id
		_l.push(item)

		setLocations(_l)
	}

	return <Box sx={{py:2}}>
		<Grid container spacing={2}>
			<Grid item xs={12} sm={7}>
				<Box sx={jss.title}>LOCATIONS</Box>
				<Box sx={jss.dataGrid}>
					<ClientClassLocationList
						locations={locations}
						onLocationChange={locationChoose}
						onMultiModeChange={e => setMultiMode(e.target.checked)}
						createLocation={createLocation}
						multiMode={multiMode}
						isAdmin={isAdmin}
					/>
					<ClientLocationClassList
						classes={classes}
						globalClasses={globalClasses}
						currentLocations={currentLocations}
						onClassChange={classChoose}
						onCurrentLocationChange={handleSingleLocationUpdate}
						multiMode={multiMode}
						assignAvailable={true}
						isAdmin={isAdmin}
					/>
				</Box>
			</Grid>
			<Grid item xs={12} sm={5}>
				<Box sx={jss.title}> </Box>
				{
					!multiMode && currentLocations.length === 1 && currentLocations[0]?.client_location_id === -1 &&
					<>
						<ClientLocationCreate location={currentLocations[0]} onSave={createLocation} client={currentClientContext.client} />
					</>
				}
				{!multiMode && currentLocations.length>0 && currentLocations[0]?.client_location_id !== -1 &&
					<>
						<ClientLocationEdit location={currentLocations[0]} onSave={handleSingleLocationUpdate} isAdmin={isAdmin} />
					</>
				}
				{currentClasses.length>0 &&
					<ClientLocationClassLinkEdit location={currentLocations[0]}
												 clientClass={currentClasses[0]}
												 locationClasses={currentLocations[0].class_links}
												 categories={categories}
												 labels={labelData}
												 onSave={handleSaveLocationClass}
												 isAdmin={isAdmin}
					/>
				}
				{multiMode && currentLocations.length>0 && currentClasses.length>0 &&
					<>
						<ClassBulkEdit classes={currentClasses} locations={locations} onDeleted={onBulkDeleted}/>
					</>
				}
			</Grid>
		</Grid>
	</Box>
}

export default ClientLocations
