import {
    useState,
    useEffect, useRef, useMemo,
} from "react";

import {
    Box,
    Paper,
    MenuItem,
    Fade,
    Collapse,
    Alert,
    TextField, IconButton, Autocomplete, FormControlLabel, RadioGroup, FormControl, FormLabel, Radio,
} from '@mui/material';
import {
    LoadingButton
} from '@mui/lab'
import SectionTitle from '../../../components/common/SectionTitle'
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import LabelService from "../../../services/LabelService";
import ClientService from "../../../services/ClientService";
import _ from "underscore";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";


const jss = {
    tableLabel: {
        fontWeight: 'bolder',
        width: '25%',
        textAlign: 'right'
    },
    editTitle: {
        fontSize: 16,
        fontWeight: 'bolder',
        mt: 3,
    },
    tagSection: {
        '.MuiChip-root': {
            mr: 1,
            mt: 1,
        }
    }
}


function ClientClassCreate(props) {
    const {
        classItem,
        allClasses,
        locations,
        masterCategories,
        labelData,
        categories,
        tip_context,
    } = props
    const [saving, setSaving] = useState(false)
    const [has_change, setHasChange] = useState(false)
    const [formData, setFormData] = useState()
    // const [labelData, setLabelData] = useState()
    // const [categories, setCategories] = useState()
    // const [masterCategories, setMasterCategories] = useState([])

    useEffect(() => {
        if (classItem) {
            setHasChange(false)
            // loadLabels()
            // loadCategories()
            // loadMasterCategories();
            setFormData({
                code: '',
                name: classItem.name,
                service_type_id: '',
                warehouse_location_id: '',
                labels: [],
                categories: [],
                parent_class_id: '',
                imu: 0,
                child_classes: [],
                master_classes: [],
                locations: []
            })
        }
    }, [classItem])

    // async function loadLabels() {
    //     const data = await LabelService.getLabels()
    //     setLabelData(data)
    // }
    //
    // async function loadCategories() {
    //     const data = await ClientService.getClientCategories(classItem.client_id)
    //     setCategories(data)
    // }
    //
    // async function loadMasterCategories() {
    //     const data = await ClientService.getMasterCategories();
    //     setMasterCategories(data)
    // }

    const masterClasses = useMemo(() => {
        let t = [];
        masterCategories.forEach(mc => {
            t = [...t, ...mc.master_classes]
        })
        return _.sortBy(t, 'id');
    }, [masterCategories]);

    const common_input_props = {
        fullWidth: true,
        variant: "outlined",
        size: "small",
        onChange: e => updateFormData(e),
        sx: {mt: 2},
    }

    function updateFormData(e) {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
        if (!has_change) {
            setHasChange(true)
        }
    }

    function labelChange(event, newValue) {
        updateFormData({
            target: {
                name: 'labels',
                value: newValue
            }
        })
    }

    function locationChange(event, newValue) {
        updateFormData({
            target: {
                name: 'locations',
                value: newValue
            }
        })
    }

    function categoryChange(event, newValue) {
        console.log(newValue)
        updateFormData({
            target: {
                name: 'categories',
                value: newValue
            }
        })
    }

    function childClassChange(event, newValue) {
        updateFormData({
            target: {
                name: 'child_classes',
                value: newValue
            }
        })
    }

    function masterClassChange(e, newValue) {
        updateFormData({
            target: {
                name: 'master_classes',
                value: newValue
            }
        })
    }

    async function saveClass(e) {
        e.preventDefault()
        try {
            setSaving(true)
            const _class = await ClientService.createClass(classItem.client_id, {
                ...formData,
                labels: formData.labels.map(i => {
                    return i.id
                }),
                categories: formData.categories.map(i => {
                    return i.Id
                }),
                child_classes: formData.child_classes.map(i => {
                    return i.id
                }),
                master_classes: formData.master_classes.map(i => {
                    return i.id
                }),
            })
            setHasChange(false)
            if (props.onSave) {
                props.onSave(_class, classItem.name)
            }
        } catch (e) {
            console.log(e.response?.data.message)
            tip_context.show(e.response?.data?.message)
        }
        setSaving(false)
    }

    if (classItem) {
        return <Paper elevation={2}>
            <SectionTitle
                label={classItem.name}
                subLabel="Create Class"
                type="primary"
            />
            <Box sx={{p: 2, pt: 0}}>
                {formData && labelData && masterClasses && categories &&
                    <Fade timeout={500} in>
                        <Box>
                            <Collapse in={has_change}>
                                <Alert sx={{mt: 2}} severity="warning">You have unsaved changes, please hit save before
                                    continuing.</Alert>
                            </Collapse>

                            <Box sx={jss.editTitle}>General</Box>
                            <form onSubmit={saveClass} autoComplete="off">
                                <TextField
                                    {...common_input_props}
                                    label="Code"
                                    name="code"
                                    value={formData.code || ''}
                                    required
                                />
                                <TextField
                                    {...common_input_props}
                                    label="Name"
                                    name="name"
                                    value={formData.name || ''}
                                    required
                                />
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    options={locations}
                                    getOptionLabel={(option) => option.location_name}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={`fd_loc_${option.id}`}>
                                                [{option.location_code}] {option.location_name}
                                            </li>
                                        );
                                    }}
                                    value={formData.locations}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            {...common_input_props}
                                            label="Locations"
                                        />
                                    )}
                                    onChange={locationChange}
                                />
                                <TextField
                                    {...common_input_props}
                                    select
                                    name="service_type_id"
                                    value={formData.service_type_id}
                                    label="Service Type"
                                    required
                                >
                                    <MenuItem value='1'>OTB</MenuItem>
                                    <MenuItem value='2'>PNOTB</MenuItem>
                                    <MenuItem value='3'>NOTB</MenuItem>
                                    <MenuItem value='7'>History</MenuItem>
                                    <MenuItem value='8'>Distress</MenuItem>
                                    <MenuItem value='9'>Warehouse</MenuItem>
                                    <MenuItem value='10'>Child</MenuItem>
                                </TextField>

                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    options={labelData.labels}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={`label_${option.id}`}>
                                                {option.name}
                                            </li>
                                        );
                                    }}
                                    value={formData.labels}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            {...common_input_props}
                                            label="M1 Tags"
                                        />
                                    )}
                                    onChange={labelChange}
                                />
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    options={categories}
                                    getOptionLabel={(option) => option.Name}
                                    renderOption={(props, option) => {
                                        console.log(props)
                                        return (
                                            <li {...props} key={`category_${option.Id}`}>
                                                {option.Name}
                                            </li>
                                        );
                                    }}
                                    value={formData.categories}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            {...common_input_props}
                                            label="Categories"
                                        />
                                    )}
                                    onChange={categoryChange}
                                />
                                {formData.service_type_id == '10' &&
                                    <TextField
                                        {...common_input_props}
                                        select
                                        name="parent_class_id"
                                        value={formData.parent_class_id}
                                        label="Parent Class"
                                    >
                                        {
                                            allClasses.filter(item => {
                                                return !item.parent_class_id
                                            }).map(item => {
                                                return <MenuItem value={item.id}
                                                                key={`parent_class_${item.id}`}>[{item.code}] {item.name}</MenuItem>
                                            })
                                        }
                                    </TextField>
                                }
                                {formData.service_type_id != '10' &&
                                    <Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        options={allClasses.filter(item => {
                                            return !!item.parent_class_id
                                        })}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={`child_class_${option.id}`}>
                                                    [{option.code}] {option.name}
                                                </li>
                                            );
                                        }}
                                        value={formData.child_classes}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...common_input_props}
                                                label="Child Classes"
                                            />
                                        )}
                                        onChange={childClassChange}
                                    />
                                }
                                <TextField
                                    {...common_input_props}
                                    select
                                    name="warehouse_location_id"
                                    value={formData.warehouse_location_id}
                                    label="Warehouse Location"
                                >
                                    {locations.map(_location => {
                                        return <MenuItem key={`Warehouse_location_${_location.client_location_id}`}
                                                        value={_location.client_location_id}>[{_location.location_code}] {_location.location_name}</MenuItem>
                                    })}
                                </TextField>
                                <TextField
                                    {...common_input_props}
                                    label="Imu"
                                    name="imu"
                                    value={formData.imu || 0}
                                    type="number"
                                    required
                                />

                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value="0" control={<Radio />} label="Receiving" />
                                        <FormControlLabel value="1" control={<Radio />} label="On Order" />
                                        <FormControlLabel value="2" control={<Radio />} label="Plan" />
                                        <FormControlLabel value="3" control={<Radio />} label="Override" />
                                    </RadioGroup>
                                </FormControl>

                                <Autocomplete
                                    multiple
                                    options={masterClasses}
                                    getOptionLabel={(option) => `[${option.id}] ${option.name}`}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={`master_class_${option.id}`}>
                                                [{option.id}] {option.name}
                                            </li>
                                        );
                                    }}
                                    value={formData.master_classes}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            {...common_input_props}
                                            label="Master Classes"
                                        />
                                    )}
                                    onChange={masterClassChange}
                                />

                                <Box sx={{mt: 2}}>
                                    <LoadingButton 
                                        loading={saving} 
                                        size="large" 
                                        variant="contained" 
                                        startIcon={<SaveTwoToneIcon/>}
                                        type="submit"
                                    >
                                        Save
                                    </LoadingButton>
                                </Box>
                            </form>

                        </Box>
                    </Fade>
                }
            </Box>
        </Paper>
    } else {
        return <></>
    }
}

export default ClientClassCreate