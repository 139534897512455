import React from "react";
import {
  Routes,
  Route,
  Outlet,
  useLocation,
  Navigate,
  useSearchParams,
  useNavigate
} from "react-router-dom";
import AuthContext from '../../providers/AuthProvider'
import AuthService from '../../services/AuthService'
import UserService from '../../services/UserService'
import NotLogin from '../../pages/NotLogin'


function AuthMiddleware() {
  let auth = AuthContext.useAuth();
  let location = useLocation();
  let navigate = useNavigate()
  let [searcharams, setSearchParams] = useSearchParams();
  const tmpAccessToken = searcharams.get('token') || searcharams.get('access_token');

  React.useEffect(() => {
    const _accessToken = AuthService.getAccessToken();
    if( tmpAccessToken ) {
      // If get token from URL params, then save it into localstorage
      AuthService.setAccessToken(tmpAccessToken)
      // Query logged in user info 
      UserService.getCurrentUser().then(success => {
        AuthService.setCurrentUser(success, true)
        auth.setUser(success)
      }, failed => {
        auth.logout()
        navigate('/login')
      })
    } else {
      if (!_accessToken) {
        navigate('/login')
      }
    }
  }, [tmpAccessToken]);

  return <Outlet />
}

export default AuthMiddleware