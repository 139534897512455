import * as React from "react";
import {
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import _ from 'underscore'

let MessageContext = React.createContext(null);

function Provider(props) {
  let [messageOpen, setMessageOpen] = React.useState(false);
  let [messageContent, setMessageContent] = React.useState(null);
  let [successOpen, setSuccessOpen] = React.useState(false);
  let [successContent, setSuccessContent] = React.useState(null);

  /**
   *
   * @param message Message to display
   * @param timeout Time to persist message (in seconds)
   */
  let show = (message, timeout = 5) => {
    timeout = timeout * 1000
    if( _.isObject(message) ) {
      message = "Something unexpected happened. Please try again later."
    }
    setMessageContent(message)
    setMessageOpen(true)
    setTimeout(() => {
      setMessageContent(null)
      setMessageOpen(false)
    }, timeout)
  }

  let showSuccess = (message='Successful operation', timeout = 5) => {
    timeout = timeout * 1000
    setSuccessContent(message)
    setSuccessOpen(true)
    setTimeout(() => {
      setSuccessContent(null)
      setSuccessOpen(false)
    }, timeout)
  }

  /**
   * Clear any current messages
   */
  let clear = () => {
    setSuccessContent(null)
    setSuccessOpen(false)
    setMessageOpen(false)
    setMessageContent(null)
  }

  let inject = { 
    messageOpen, 
    messageContent,
    successOpen,
    successContent,
    show, 
    showSuccess,
    clear,
  }
  return <MessageContext.Provider value={inject}>{props.children}</MessageContext.Provider>;
}

function useMessage() {
  return React.useContext(MessageContext)
}

export default {
  Provider,
  useMessage,
}