import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const BasicTable = ({ data, headers, columnOrder, onRowClick = null }) => {
    const theme = useTheme();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {headers.map((header, index) => (
                        <TableCell key={index} style={{ color: theme.palette.primary.main }}>
                            {header}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
            {data.map((row, rowIndex) => (
                <TableRow key={rowIndex} onClick={() => onRowClick && onRowClick(row)}>
                    {columnOrder.map((column, colIndex) => {
                    if (typeof column === 'object') {
                        return (
                            <TableCell key={colIndex} style={{ color: theme.palette.text.primary }}>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                                    {Object.entries(column).map(([dictKey, dictVal]) => (
                                        <div key={dictKey} style={{ marginBottom: '4px' }}>
                                        <div style={{ fontWeight: 'bold' }}>{dictVal}</div>
                                        <div>{row[dictKey]}</div>
                                        </div>
                                    ))}
                                </div>
                            </TableCell>
                        );
                    } else {
                        return (
                        <TableCell key={colIndex} style={{ color: theme.palette.text.primary }}>
                            {row[column]}
                        </TableCell>
                        );
                    }
                    })}
                </TableRow>
            ))}
            </TableBody>
        </Table>
    );
};

export default BasicTable;
